import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Container, Col, Spinner, Card } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import propTypes from 'prop-types';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FileUpload from '../component/FileUpload';
import getLoadState from '../component/services/load/getLoadState';
// import { withLoadProvider } from '../component/context/LoadStateContext';
import { getLoad, removeLoad } from '../component/services/load/saveLoad';
import processFileParse from '../component/services/load/processFileParse';
import getLoadProcessHandler from '../component/services/load/getLoadProcessHandler';

const DataIngest = props => {
  const { setLoading, loading } = props;
  // const [zip, setZip] = useState('');
  // const [bedrooms, setBedrooms] = useState('');
  const [apiLoading] = useState(false);

  useEffect(() => {
    const id = getLoad();
    if (id) {
      getLoadState(id).then(async ({ loadFinished }) => {
        if (!loadFinished) {
          setLoading(true);
          const loadChange = getLoadProcessHandler(props);
          const res = await processFileParse(id, loadChange);
          removeLoad();
          confirmAlert({
            title: res.status === 200 ? 'Success!' : 'Error',
            message:
              res.status === 200
                ? `${res.result} AirDNA properties successfully imported to the database`
                : res.result,
            buttons: [{ label: 'Ok', onClick: () => setLoading(false) }],
          });
        }
      });
    }
  }, [setLoading]);

  // const onChange = e => {
  //   if (e.target.name === 'zip') {
  //     setZip(e.target.value);
  //   } else if (e.target.name === 'bedrooms') {
  //     setBedrooms(e.target.value);
  //   }
  // };

  // const datafinitiButtonPressed = () => {
  //   confirmAlert({
  //     title: 'Confirm Import',
  //     message: `Are you sure you want to import properties with zip: ${
  //       zip || 'all'
  //     } and bedrooms: ${bedrooms || 'all'}?`,
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: datafinitiSearchConfirmed,
  //       },
  //       {
  //         label: 'No',
  //       },
  //     ],
  //   });
  // };

  // const datafinitiSearchConfirmed = () => {
  //   setApiLoading(true);
  //   callApiDatafinitiSearch()
  //     .then(res => {
  //       setApiLoading(false);
  //       if (res.status === 200 && res.result) {
  //         confirmAlert({
  //           title: 'Are you sure?',
  //           message: `There are ${res.result} records for this search. This will cost ${res.result} Datafiniti credits. Are you sure you want to continue with the import?`,
  //           buttons: [
  //             {
  //               label: 'Yes',
  //               onClick: datafinitiDownloadConfirmed,
  //             },
  //             {
  //               label: 'No',
  //             },
  //           ],
  //         });
  //       } else if (res.status === 200 && !res.result) {
  //         confirmAlert({
  //           title: 'No results',
  //           message:
  //             'No records were found matching your search terms. Please try again.',
  //           buttons: [{ label: 'Ok' }],
  //         });
  //       } else {
  //         confirmAlert({
  //           title: 'Error',
  //           message: res.result,
  //           buttons: [{ label: 'Ok' }],
  //         });
  //       }
  //     })
  //     .catch(err => console.log(err));
  // };

  // const callApiDatafinitiSearch = async () => {
  //   let requestZip = zip || 'all';
  //   let requestBedrooms = bedrooms || 'all';
  //   const response = await fetch(
  //     `${process.env.REACT_APP_API_BASEURL}/datafiniti/search/zip/${requestZip}/bedrooms/${requestBedrooms}`,
  //     {
  //       method: 'POST',
  //     }
  //   );
  //   const body = {
  //     status: response.status,
  //     result: await response.json(),
  //   };
  //   return body;
  // };

  // const datafinitiDownloadConfirmed = () => {
  //   setApiLoading(true);
  //   callApiDatafinitiDownload()
  //     .then(res => {
  //       setApiLoading(false);
  //       confirmAlert({
  //         title: res.status === 200 ? 'Success!' : 'Error',
  //         message:
  //           res.status === 200
  //             ? `${res.result} Datafiniti properties successfully imported to the database`
  //             : res.result,
  //         buttons: [{ label: 'Ok' }],
  //       });
  //     })
  //     .catch(err => console.log(err));
  // };

  // const callApiDatafinitiDownload = async () => {
  //   let requestZip = zip || 'all';
  //   let requestBedrooms = bedrooms || 'all';
  //   const response = await fetch(
  //     `${process.env.REACT_APP_API_BASEURL}/datafiniti/download/zip/${requestZip}/bedrooms/${requestBedrooms}`,
  //     {
  //       method: 'POST',
  //     }
  //   );
  //   const body = {
  //     status: response.status,
  //     result: await response.json(),
  //   };
  //   return body;
  // };

  return (
    <section className="mt-4 mb-4">
      <Container>
        <Row className="justify-content-center">
          <Col s={12} md={10} lg={8}>
            <h1 className="ingest-heading">Wanderhome P3</h1>
            <div className={apiLoading ? '' : 'd-none'}>
              <Spinner className="mt-4" animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
              <p className="mt-3">Loading...</p>
            </div>
            <div className={loading || apiLoading ? 'd-none' : ''}>
              <Card className="mt-3 new-card">
                <Card.Header className="ingest-card-header">
                  AirDNA CSV Import
                </Card.Header>
                <Card.Body>
                  <FileUpload title="Monthly Property Data:" type="monthly" />
                  <FileUpload title="Annual Property Data:" type="annual" />
                  <FileUpload
                    title="Property Description Data:"
                    type="property"
                  />
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

DataIngest.defaultProps = {
  loading: false,
  setLoading: () => {},
};

DataIngest.propTypes = {
  setLoading: propTypes.func,
  loading: propTypes.bool,
};

export default DataIngest;

// class DataIngest extends Component {
//   state = {
//     zip: '',
//     bedrooms: '',
//     apiLoading: false
//   };

//   componentDidMount() {
//     const id = getLoad();
//     const { setLoading } = this.props;
//     if (id) {
//       getLoadState(id).then(async ({ loadFinished }) => {
//         if (!loadFinished) {
//           setLoading(true);
//           const loadChange = getLoadProcessHandler(this.props);
//           const res = await processFileParse(id, loadChange);
//           removeLoad();
//           confirmAlert({
//             title: res.status === 200 ? 'Success!' : 'Error',
//             message: res.status === 200 ? `${res.result} AirDNA properties successfully imported to the database` : res.result,
//             buttons: [{ label: 'Ok', onClick: () => setLoading(false) }]
//           });
//         }
//       });
//     }
//   }

//   onChange = e => this.setState({ [e.target.name]: e.target.value })

//   // Datafiniti API Button Pressed
//   datafinitiButtonPressed = () => {
//     const { zip, bedrooms } = this.state;
//     confirmAlert({
//       title: 'Confirm Import',
//       message: `Are you sure you want to import properties with zip: ${zip || 'all'} and bedrooms: ${bedrooms || 'all'}?`,
//       buttons: [
//         {
//           label: 'Yes',
//           onClick: () => this.datafinitiSearchConfirmed()
//         },
//         {
//           label: 'No'
//         }
//       ]
//     });
//   };

//   // Datafiniti Search Confirmed
//   datafinitiSearchConfirmed = () => {
//     this.setState({ loading: true });
//     this.callApiDatafinitiSearch()
//       .then((res) => {
//         this.setState({
//           loading: false
//         });
//         if (res.status === 200 && res.result) {
//           confirmAlert({
//             title: 'Are you sure?',
//             message: `There are ${res.result} records for this search. This will cost ${res.result} Datafiniti credits. Are you sure you want to continue with the import?`,
//             buttons: [
//               {
//                 label: 'Yes',
//                 onClick: () => this.datafinitiDownloadConfirmed()
//               },
//               {
//                 label: 'No'
//               }
//             ]
//           });
//         } else if (res.status === 200 && !res.result) {
//           confirmAlert({
//             title: 'No results',
//             message: 'No records were found matching your search terms. Please try again.',
//             buttons: [{ label: 'Ok' }]
//           });
//         } else {
//           confirmAlert({
//             title: 'Error',
//             message: res.result,
//             buttons: [{ label: 'Ok' }]
//           });
//         }
//       })
//       .catch(err => console.log(err));
//   };

//   // Call Datafiniti Search API
//   callApiDatafinitiSearch = async () => {
//     let { zip, bedrooms } = this.state;
//     if (!zip) zip = 'all';
//     if (!bedrooms) bedrooms = 'all';
//     const response = await fetch(`${process.env.REACT_APP_API_BASEURL}/datafiniti/search/zip/${zip}/bedrooms/${bedrooms}`, {
//       method: 'POST',
//     });
//     const body = {
//       status: response.status,
//       result: await response.json(),
//     };
//     return body;
//   };

//   // Datafiniti Download Confimed
//   datafinitiDownloadConfirmed = () => {
//     this.setState({ apiLoading: true });
//     this.callApiDatafinitiDownload()
//       .then((res) => {
//         this.setState({
//           apiLoading: false
//         });
//         confirmAlert({
//           title: res.status === 200 ? 'Success!' : 'Error',
//           message: res.status === 200 ? `${res.result} Datafiniti properties successfully imported to the database` : res.result,
//           buttons: [{ label: 'Ok' }]
//         });
//       })
//       .catch(err => console.log(err));
//   }

//   // Call Datafiniti Download API
//   callApiDatafinitiDownload = async () => {
//     let { zip, bedrooms } = this.state;
//     if (!zip) zip = 'all';
//     if (!bedrooms) bedrooms = 'all';
//     const response = await fetch(`${process.env.REACT_APP_API_BASEURL}/datafiniti/download/zip/${zip}/bedrooms/${bedrooms}`, {
//       method: 'POST',
//     });
//     const body = {
//       status: response.status,
//       result: await response.json(),
//     };
//     return body;
//   };

//   render() {
//     const { loading } = this.props;
//     const { apiLoading } = this.state;
//     return (
//       <section className="mt-4 mb-4">
//         <Container>
//           <Row className="justify-content-center">
//             <Col s={12} md={10} lg={8}>
//               <h1 className='ingest-heading'>Wanderhome P3</h1>
//                <div className={apiLoading ? '' : 'd-none'}>
//                 <Spinner className="mt-4" animation="border" role="status">
//                   <span className="sr-only">Loading...</span>
//                 </Spinner>
//                 <p className="mt-3">Loading...</p>
//                </div>
//               <div className={loading || apiLoading ? 'd-none' : ''}>
//                 <Card className="mt-3 new-card" >
//                   <Card.Header className='ingest-card-header'>AirDNA CSV Import</Card.Header>
//                   <Card.Body>
//                     <FileUpload
//                       title="Monthly Property Data:"
//                       type="monthly"
//                     />
//                     <FileUpload
//                       title="Annual Property Data:"
//                       type="annual"
//                     />
//                     <FileUpload
//                       title="Property Description Data:"
//                       type="property"
//                     />
//                   </Card.Body>
//                 </Card>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//     );
//   }
// }

// export default withLoadProvider(DataIngest);
