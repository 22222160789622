import React, { useEffect, memo } from 'react';
import {
  // Outlet,
  Link,
  // BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userInfo } from '../../redux/user/api';
import {
  getCurrentLocation,
  getPropertyTypeGroupings,
  getStateAbbrs,
  updateSavedSearchUpdateFrequency,
  getSurveyStatuses,
} from '../../redux/fieldAnalysis/fieldAnalysisSlice';
import FATHeader from '../../component/FATHeader';
import RecentProperties from './RecentProperties';
import NewSearch from './NewSearch';
// import SavedSearches from './SavedSearches';
import NewlyListed from './NewlyListed';
// import PrintPropertyList from './PrintPropertyList';
import Print from './Print';
import SendPropertyList from './SendPropertyList';
import CreateNewHome from './CreateNewHome';
import CurrentOffers from './CurrentOffers';
import Shortlist from './Shortlist';
import TodaysList from './TodaysList';
import Survey from './Survey';
import './LoadingScreen.css';
import './FieldAnalysisTool.css';
import { notifyError } from '../../component/Toaster';

const FieldAnalysisTool = () => {
  const dispatch = useDispatch();

  // Load userInfo if user is logged in
  useEffect(() => {
    if (localStorage.getItem('token')) {
      Promise.all([
        dispatch(userInfo()),
        dispatch(getCurrentLocation()),
        dispatch(getStateAbbrs()),
        dispatch(getPropertyTypeGroupings()),
        dispatch(updateSavedSearchUpdateFrequency()),
        dispatch(getSurveyStatuses()),
      ]).catch(error => notifyError(error));
    }
  }, [localStorage.getItem('token')]);

  const user = useSelector(state => state.user);
  const loading = useSelector(state => state.fieldAnalysis.loading);

  return (
    <>
      {loading && (
        <div className="loading-screen">
          <div className="loading-spinner" />
        </div>
      )}
      {user?.userInfo?.userRoles?.fieldtoolUser === 1 ||
      user?.userInfo?.userRoles?.fieldtoolManager === 1 ? (
        <>
          <div className="d-print-none">
            <FATHeader />
          </div>
          <div className="container-fluid p-4">
            {/* <Outlet /> */}
            {/* <BrowserRouter> */}
            <Routes>
              <Route exact path="newSearch" element={<NewSearch />} />
              <Route
                exact
                path="recentProperties"
                element={<RecentProperties />}
              />
              <Route exact path="savedSearches" element={<NewSearch />} />
              <Route exact path="newlyListed" element={<NewlyListed />} />
              <Route exact path="todaysList" element={<TodaysList />} />
              <Route exact path="shortList" element={<Shortlist />} />
              <Route exact path="offers" element={<CurrentOffers />} />
              <Route exact path="newHome" element={<CreateNewHome />} />
              <Route exact path="send" element={<SendPropertyList />} />
              <Route exact path="print" element={<Print />} />
              <Route exact path="survey/:id" element={<Survey />} />
              <Route path="" element={<Navigate to="/fat/newSearch" />} />
            </Routes>
            {/* </BrowserRouter> */}
          </div>
        </>
      ) : (
        <div className="text-center">
          {!loading && (
            <>
              <h2 className="text-center mt-4 pt-4 mb-4">Unauthorized!</h2>
              <Link to="/">
                <span>Back to login</span>
              </Link>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default memo(FieldAnalysisTool);
