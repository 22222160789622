// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="checkbox"] {
  width: 1.5rem;
  height: 1.5rem;
}

label {
  display: flex;
  margin-bottom: 0.5rem;
  line-height: 1.5rem;
  align-items: center;
}

#property-tabs {
  padding: 0;
  display: block;
  padding: 1rem 0 0 0.5rem;
  border-bottom: 1px solid #878282;
  width: 100%;
  text-align: left;
  margin-bottom: 2rem;
}

#property-tabs li button {
  display: inline-block;
  border-color: #878282;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  padding: 0.5rem 1.5rem;
  margin-right: 0.5rem;
  border-radius: 5px 5px 0 0;
  text-decoration: none;
  color: #878282;
}

#property-tabs li:hover button, #property-tabs li.active button {
  color: #fff;
  background-color: #878282;
}

#characteristics h6 {
  color: #2472BB;
  margin-top: 2rem;
}

.inplace-editor {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.inplace-editor label {
  display: flex;
}

.inplace-editor label span {
  white-space: nowrap;
}

.inplace-editor input[type="text"] {
  border: none;
  border-radius: 0;
  background-color: rgba(217, 217, 217, 0.3);
}
`, "",{"version":3,"sources":["webpack://./src/pages/FieldAnalysisTool/newSearch.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,cAAc;EACd,wBAAwB;EACxB,gCAAgC;EAChC,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,mBAAmB;EACnB,2BAA2B;EAC3B,sBAAsB;EACtB,oBAAoB;EACpB,0BAA0B;EAC1B,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,0CAA0C;AAC5C","sourcesContent":["input[type=\"checkbox\"] {\n  width: 1.5rem;\n  height: 1.5rem;\n}\n\nlabel {\n  display: flex;\n  margin-bottom: 0.5rem;\n  line-height: 1.5rem;\n  align-items: center;\n}\n\n#property-tabs {\n  padding: 0;\n  display: block;\n  padding: 1rem 0 0 0.5rem;\n  border-bottom: 1px solid #878282;\n  width: 100%;\n  text-align: left;\n  margin-bottom: 2rem;\n}\n\n#property-tabs li button {\n  display: inline-block;\n  border-color: #878282;\n  border-style: solid;\n  border-width: 1px 1px 0 1px;\n  padding: 0.5rem 1.5rem;\n  margin-right: 0.5rem;\n  border-radius: 5px 5px 0 0;\n  text-decoration: none;\n  color: #878282;\n}\n\n#property-tabs li:hover button, #property-tabs li.active button {\n  color: #fff;\n  background-color: #878282;\n}\n\n#characteristics h6 {\n  color: #2472BB;\n  margin-top: 2rem;\n}\n\n.inplace-editor {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n}\n\n.inplace-editor label {\n  display: flex;\n}\n\n.inplace-editor label span {\n  white-space: nowrap;\n}\n\n.inplace-editor input[type=\"text\"] {\n  border: none;\n  border-radius: 0;\n  background-color: rgba(217, 217, 217, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
