import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import propTypes from 'prop-types';
import data from '../../utils/data.json';

const SegmentFilter = props => {
  const { title, setOption, defaultValue, removeFilter, toBeDeleted } = props;
  const changeCountryType = e => {
    if (e.target.value) {
      setOption('Sleeps', e.target.value);
    }
  };
  const handleBedsAndBath = e => {
    if (e.target.value) {
      setOption('BedsAndBath', e.target.value);
    }
  };

  const renderOptions = options => {
    return options?.map(res => {
      return (
        <option key={res.optionValue} value={res.optionValue}>
          {res.optionName}
        </option>
      );
    });
  };

  return (
    <div>
      <div className="position-relative mb-2">
        <Row className="row-width">
          <Col xs={2}>{title}</Col>
          <Col>
            <span className="label-heading">Sleeps</span>
            <Form.Select
              onChange={changeCountryType}
              value={defaultValue.Sleeps}
              na
            >
              {renderOptions(data?.sleepsOption)}
            </Form.Select>{' '}
          </Col>
          <Col>
            <span className="label-heading">Bedroom/Bathroom</span>
            <Form.Select
              onChange={handleBedsAndBath}
              value={defaultValue.BedsAndBath}
              na
            >
              {renderOptions(data?.bedroomOption)}
            </Form.Select>{' '}
          </Col>
          <Col xs={1} className="mt-4">
            <div className="add-remove">
              <button
                type="button"
                // eslint-disable-next-line react/no-unknown-property
                variant="primary"
                className="close"
                onClick={() => removeFilter(toBeDeleted)}
              >
                <FontAwesomeIcon icon={faClose} />
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

SegmentFilter.propTypes = {
  title: propTypes.string.isRequired,
  setOption: propTypes.func.isRequired,
  defaultValue: propTypes.string.isRequired,
  removeFilter: propTypes.func.isRequired,
  toBeDeleted: propTypes.string.isRequired,
};

export default SegmentFilter;
