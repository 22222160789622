import React from 'react';
import { useLocation } from 'react-router-dom';
import propTypes from 'prop-types';
import Header from '../Header';

const Layout = ({ children, isLoggedIn }) => {
  const { pathname } = useLocation();
  return (
    <div className="main-container">
      {isLoggedIn &&
        pathname !== '/' &&
        pathname !== '/settings' &&
        pathname &&
        !pathname.startsWith('/fat') && (
          <div className="">
            <Header />
          </div>
        )}
      <div
        className="layout-style"
        style={{
          paddingTop: `${
            isLoggedIn &&
            pathname !== '/' &&
            pathname &&
            !pathname.startsWith('/fat')
              ? '70px'
              : '0px'
          }`,
        }}
      >
        <div
          className="
          layout-content"
          style={{
            flexGrow: '1',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: propTypes.node.isRequired,
  isLoggedIn: propTypes.bool.isRequired,
};

export default React.memo(Layout);
