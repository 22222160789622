import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const setMessageAccordingToCurrentDrilldownTab = (
  currentSelectedTab,
  setState
) => {
  switch (currentSelectedTab) {
    case 'Prevalence':
    case 'Home Size':
    case 'Profitability':
    case 'Seasonal':
      setState('Enter City or ZIP in the search bar.');
      break;
    default:
      setState('Enter City or ZIP in the search bar.');
  }
};

const NoRecord = () => {
  const [noRecordMessage, setNoRecordMessage] = useState(
    'Enter City or ZIP in the search bar.'
  );
  const currentCharacteristicsDrilldownTab = useSelector(
    state => state?.navigation.currentCharacteristicsDrilldownTab
  );

  useEffect(() => {
    setMessageAccordingToCurrentDrilldownTab(
      currentCharacteristicsDrilldownTab,
      setNoRecordMessage
    );
  }, [currentCharacteristicsDrilldownTab]);

  return (
    <Row>
      <Col xs={12}>
        <div className="mb-3 mt-2">
          <b>{noRecordMessage}</b>
        </div>
      </Col>
    </Row>
  );
};

export default NoRecord;
