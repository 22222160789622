/* eslint-disable no-dupe-keys */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import moment from 'moment';

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 2,
});

const formatAsPercent = num => {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    maximumSignificantDigits: 2,
  }).format(num > 1 ? num / 100 : Math.floor(num));
};

function headerFormatter(column, colIndex, { sortElement }, showBoldColumn) {
  return (
    <div
      style={{
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        fontWeight: showBoldColumn ? 600 : 500,
      }}
    >
      {column.firstColumnText && (
        <div style={{ width: '100%' }}>{column.firstColumnText}</div>
      )}
      <div style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...(column.lineHeight && { height: column.lineHeight }),
            ...(column.headerSecondaryText &&
              !column.preventTextSpacing && { padding: '26px 0 0' }),
            ...(column.textStyle && { ...column.textStyle }),
          }}
        >
          {column.text}
          {sortElement}
        </div>
        {column.headerPrimaryText && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              ...(column.text && { borderTop: '1px solid #878282' }),
            }}
          >
            {column.headerPrimaryText.split(',').map(dt => (
              <div key={dt} style={{ width: '100%' }}>
                <div style={{ padding: '2px 0' }}>{dt}</div>
                {column.headerSecondaryText && (
                  <div
                    style={{
                      display: 'flex',
                      borderTop: '1px solid #878282',
                      padding: '2px 0',
                    }}
                  >
                    {column.headerSecondaryText.split(',').map(dtx => (
                      <div key={dtx} style={{ width: '100%' }}>
                        {dtx}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

const getCellData = (row, fdx, dollarFields, percentageFields, dateFields) => {
  const field = fdx?.trim();
  const data = row[field];
  if (row?.DataQuality === 0) return 'n/a';
  if (percentageFields?.includes(fdx?.trim())) return formatAsPercent(data);
  if (dollarFields?.includes(fdx?.trim())) return USDollar.format(data);
  if (dateFields?.includes(fdx?.trim()))
    return moment(data).utc().format('MM/DD/YYYY');
  if (data) return data;
  return 0;
};

const cellFormatter = (
  cell,
  row,
  fields,
  dollarFields,
  percentageFields,
  dateFields
) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {fields?.length > 0 ? (
        fields?.map((fd, idx) => {
          if (fd?.includes(',')) {
            return (
              <div
                key={idx}
                style={{ display: 'flex', alignItems: 'center', width: '100%' }}
              >
                {fd?.split(',').map((fdx, idxx) => (
                  <div
                    key={idxx}
                    style={{
                      width: '100%',
                      textWrap: 'wrap',
                      padding: '0 6px',
                    }}
                  >
                    {getCellData(
                      row,
                      fdx,
                      dollarFields,
                      percentageFields,
                      dateFields
                    )}
                  </div>
                ))}
              </div>
            );
          }
          return (
            <div
              key={idx}
              style={{ width: '100%', textWrap: 'wrap', padding: '0 6px' }}
            >
              {getCellData(row, fd, dollarFields, percentageFields, dateFields)}
            </div>
          );
        })
      ) : (
        <div style={{ width: '100%', textWrap: 'wrap', padding: '0 6px' }}>
          {dateFields?.length > 0
            ? moment(cell).utc().format('MM/DD/YYYY')
            : cell || 0}
        </div>
      )}
    </div>
  );
};

export { headerFormatter, cellFormatter };
