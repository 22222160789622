/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import debounce from 'debounce-promise';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
// import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import NextGenBsTable from '../../component/NextGenBsTable';
import NoRecord from '../../component/NoRecord';
import {
  getProfitabilitySearchCitiesList,
  getProfitabilitySearchZipsList,
  getProfitabilitySearchCitiesCharList,
  getProfitabilityCities,
  getProfitabilityZips,
} from '../../redux/characteristics/api';
import { profitbilityColumn } from '../../utils/columns';
import Filter from '../../component/FilterDropdown';
import data from '../../utils/data.json';
import {
  getMoreDataCharacteristicToDataUpdate,
  setSearchValue,
} from '../../redux/characteristics/characteristicsSlice';
import {
  removeFilter,
  renderFiltersInputType,
  setSelectFilterOption,
} from '../../utils/helperInput';
import { updateCurrentSurveyTab } from '../../redux/navigation/navigationSlice';

const processData = (Data = []) => {
  let previousSegmentID = null;
  let HomeSize = '';

  return Data.map(item => {
    if (previousSegmentID !== item.SegmentID) {
      // If SegmentID changes, update homeSize
      HomeSize = `${item.Sleeps}/${item.Beds}/${item.Baths}`;
      previousSegmentID = item.SegmentID;
    } else {
      // If SegmentID is the same, keep homeSize blank
      HomeSize = '';
    }

    return {
      ...item,
      HomeSize,
    };
  });
};

const TabProfitability = ({ selectedTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getProfitabilityRequest, setGetProfitabilityRequest] = useState();
  const [selectOption, setFilterOption] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [selectedFilterOption, setSelectedFilterOption] = useState({});
  const [selectedSortingOption, setSelectedSortingOption] = useState({});
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedZip, setSelectedZip] = useState('');
  const [selectedCharacteristic, setSelectedCharacteristic] = useState('');
  const [filterByData, setFilterByData] = useState(
    data?.characteristicsFilters
  );
  const profitabilityCitiesStructural = useSelector(
    state => state.characteristics.profitabilityCitiesStructural
  );
  const profitabilityZipsStructural = useSelector(
    state => state.characteristics.profitabilityZipsStructural
  );
  const profitabilityCitiesAmenities = useSelector(
    state => state.characteristics.profitabilityCitiesAmenities
  );
  const profitabilityZipsAmenities = useSelector(
    state => state.characteristics.profitabilityZipsAmenities
  );
  const profitabilityCitiesStructuralCount = useSelector(
    state => state.characteristics.profitabilityCitiesStructuralCount
  );
  const profitabilityCitiesAmenitiesCount = useSelector(
    state => state.characteristics.profitabilityCitiesAmenitiesCount
  );
  const profitabilityZipsStructuralCount = useSelector(
    state => state.characteristics.profitabilityZipsStructuralCount
  );
  const profitabilityZipsAmenitiesCount = useSelector(
    state => state.characteristics.profitabilityZipsAmenitiesCount
  );
  const profitabilityCitiesLoader = useSelector(
    state => state.characteristics.profitabilityCitiesLoader
  );
  const profitabilityZipsLoader = useSelector(
    state => state.characteristics.profitabilityZipsLoader
  );
  const quickSearchValue = useSelector(
    state => state.characteristics.searchValue
  );

  const setSelectedOption = options => {
    if (!options || options.length === 0) {
      setSelectedCity('');
      setSelectedZip('');
      setSelectedCharacteristic('');
    }

    let newSelectedCity = '';
    let newSelectedZip = '';
    let newSelectedCharacteristics = '';

    options.forEach(o => {
      if (o?.DataFrom === 'City') newSelectedCity = o.value;
      if (o?.DataFrom === 'Zip') newSelectedZip = o.value;
      if (o?.DataFrom === 'Char') newSelectedCharacteristics = o.value;
    });

    setSelectedCity(newSelectedCity);
    setSelectedZip(newSelectedZip);
    setSelectedCharacteristic(newSelectedCharacteristics);

    dispatch(setSearchValue(options));
  };

  const setSelectFilterOptionFunction = value => {
    setSelectFilterOption(
      value,
      data,
      selectOption,
      setFilterOption,
      setSelectedFilter,
      selectedFilterOption,
      setSelectedFilterOption,
      filterByData,
      setFilterByData
    );
  };

  const setSortingOption = option => {
    const tempOption = {};
    if (option !== 'default') {
      setSelectedSortingOption(option);
      if (option === 'monthly_revenue') {
        tempOption.Revenue_Baseline = true;
      } else {
        tempOption[option] = true;
      }
    }

    setSelectedSortingOption(tempOption);
  };

  const inputOnChangeFunction = (type, value) => {
    const tempSelectedFilterOption = cloneDeep(selectedFilterOption);
    tempSelectedFilterOption[type] = value;
    setSelectedFilterOption(tempSelectedFilterOption);
  };

  const removeFilterFunction = id => {
    removeFilter(
      id,
      data.characteristicsFilters,
      selectOption,
      selectedFilterOption,
      setFilterOption,
      setSelectedFilter,
      setSelectedFilterOption,
      setFilterByData,
      filterByData
    );
  };

  const resetFilters = () => {
    setFilterOption([]);
    setSelectedFilter('');
    setSelectedSortingOption({});
    setSelectedFilterOption({});
  };

  const renderFilterOptions = () => {
    return (
      <Row className="mb-2 align-items-end">
        {selectOption?.map((res, i) => {
          return (
            // TODO: fix the key
            // eslint-disable-next-line react/no-array-index-key
            <Col xs={3} key={`filters-${i}`}>
              {renderFiltersInputType(
                res,
                i,
                data,
                data.characteristicsFilters,
                inputOnChangeFunction,
                removeFilterFunction,
                [],
                [],
                [],
                selectedFilterOption,
                [],
                'dropdown-select',
                'minimumOccupancyPercentage'
              )}
            </Col>
          );
        })}
        <Col xs={3}>
          <div className="mb-2">
            {selectOption?.length ? (
              <button
                type="button"
                className="allbutton"
                onClick={() => {
                  resetFilters();
                }}
              >
                Reset Filter
              </button>
            ) : null}
          </div>
        </Col>
      </Row>
    );
  };

  const getProfitability = (start, limit, sort = {}) => {
    const sortings = { ...selectedSortingOption, ...sort };
    if (getProfitabilityRequest) getProfitabilityRequest?.abort();
    if (selectedZip) {
      const promise = dispatch(
        getProfitabilityZips({
          filters: {
            ...selectedFilterOption,
            CharacteristicName: selectedCharacteristic,
          },
          sortings,
          searchQuery: selectedZip,
        })
      );
      setGetProfitabilityRequest(promise);
    }
    if (selectedCity) {
      dispatch(
        getProfitabilityCities({
          filters: {
            ...selectedFilterOption,
            CharacteristicName: selectedCharacteristic,
          },
          sortings,
          searchQuery: selectedCity,
        })
      );
    }
  };

  useEffect(() => {
    if (
      selectedCity ||
      selectedZip ||
      selectedCharacteristic ||
      selectedTab === 'Profitability'
    )
      getProfitability();
  }, [
    selectedCity,
    selectedZip,
    selectedCharacteristic,
    selectedFilterOption,
    selectedSortingOption,
    selectedTab,
  ]);

  useEffect(() => {
    setSelectedOption(quickSearchValue);
  }, [selectedTab]);

  const debounceFunc = debounce(async inputValue => {
    let options = [];
    if (inputValue.length <= 2) {
      return [];
    }

    const isNumber = !Number.isNaN(Number(inputValue));
    if (isNumber) {
      const zipsList = await getProfitabilitySearchZipsList({
        searchQuery: inputValue,
      });

      if (zipsList?.length)
        options = [
          ...options,
          { value: '', label: '----Zips----', isDisabled: true },
          ...zipsList,
        ];
      else
        options = [
          ...options,
          { value: '', label: '----Zips----', isDisabled: true },
          { value: '', label: 'No zips found', isDisabled: true },
        ];

      return options;
    }
    const citiesList = await getProfitabilitySearchCitiesList({
      searchQuery: inputValue,
    });

    if (citiesList?.length)
      options = [
        ...options,
        { value: '', label: '----Cities----', isDisabled: true },
        ...citiesList,
      ];
    else
      options = [
        ...options,
        { value: '', label: '----Cities----', isDisabled: true },
        { value: '', label: 'No cities found', isDisabled: true },
      ];

    const charList = await getProfitabilitySearchCitiesCharList({
      Char: inputValue,
    });

    if (charList?.length)
      options = [
        ...options,
        { value: '', label: '----Characteristics----', isDisabled: true },
        ...charList.map(d => ({
          value: d.CharacteristicName,
          label: d.CharacteristicName,
          DataFrom: d.DataFrom,
        })),
      ];
    else {
      options = [
        ...options,
        { value: '', label: '----Characteristics----', isDisabled: true },
        { value: '', label: 'No characteristics found', isDisabled: true },
      ];
    }

    return options;
  }, 1000);

  const handleGetMoreData = () => {
    const DataFrom =
      selectedCity !== '' ? 'City' : selectedZip !== '' ? 'Zip' : 'char';
    if (DataFrom === 'char') {
      dispatch(getMoreDataCharacteristicToDataUpdate({ DataFrom, value: '' }));
      dispatch(updateCurrentSurveyTab('Dataupdate'));
      navigate('/survey');
    } else {
      const value = selectedCity !== '' ? selectedCity : selectedZip;
      dispatch(getMoreDataCharacteristicToDataUpdate({ DataFrom, value }));
      dispatch(updateCurrentSurveyTab('Dataupdate'));
      navigate('/survey');
    }
  };

  return (
    <div>
      <Row className="mb-2">
        <Col xs={6}>
          <h6>Quick Search</h6>
          <AsyncSelect
            isMulti
            isClearable
            loadOptions={debounceFunc}
            onChange={setSelectedOption}
            getOptionValue={option => option.label}
            placeholder={
              <div style={{ width: '100vh' }}>
                Enter City or ZIP Code(s) or charecteristic name separated by
                commas
              </div>
            }
            value={quickSearchValue}
          />
        </Col>
        <Col xs={2}>
          <Filter
            setOption={setSelectFilterOptionFunction}
            options={filterByData}
            title="Filter By"
            value={selectedFilter}
          />
        </Col>
        <Col xs={2}>
          <Filter
            setOption={setSortingOption}
            options={data?.characteristicsSorting}
            title="Sort By"
          />
        </Col>
      </Row>
      <Row>{renderFilterOptions()}</Row>
      {isEmpty(quickSearchValue) ? (
        <NoRecord />
      ) : (
        <Row>
          <Col xs={8}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="mb-3">
                  <b> Current Geography:</b> {selectedCity || selectedZip}
                </div>
                <div className="mb-3">
                  <b>Structural Characteristics:</b>
                </div>
              </div>
              <button
                type="button"
                className="allbutton"
                onClick={handleGetMoreData}
              >
                Get More Data
              </button>
            </div>
            <NextGenBsTable
              columns={profitbilityColumn}
              data={
                (selectedZip
                  ? processData(profitabilityZipsStructural)
                  : processData(profitabilityCitiesStructural)) || []
              }
              keyField="id"
              loading={
                selectedZip
                  ? profitabilityZipsLoader
                  : profitabilityCitiesLoader
              }
              count={
                selectedZip
                  ? profitabilityZipsStructuralCount
                  : profitabilityCitiesStructuralCount
              }
              hideSizePerPage
              enableSearching={false}
              striped
            />
            <div className="mb-3 mt-2">
              <b>Amenities:</b>
            </div>
            <NextGenBsTable
              columns={profitbilityColumn}
              data={
                (selectedZip
                  ? processData(profitabilityZipsAmenities)
                  : processData(profitabilityCitiesAmenities)) || []
              }
              keyField="id"
              loading={
                selectedZip
                  ? profitabilityZipsLoader
                  : profitabilityCitiesLoader
              }
              count={
                selectedZip
                  ? profitabilityZipsAmenitiesCount
                  : profitabilityCitiesAmenitiesCount
              }
              hideSizePerPage
              enableSearching={false}
              striped
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

// TabProfitability.propTypes = {
//   search: propTypes.object.isRequired,
//   // setActiveKey: propTypes.func.isRequired,
//   selectedFilterOption: propTypes.string.isRequired,
//   selectedSortingOption: propTypes.string.isRequired,
//   characteristicsList: propTypes.array.isRequired,
// };

export default TabProfitability;
