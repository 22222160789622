import lodash from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'debounce-promise';
import Filter from '../../component/FilterDropdown';
import NextGenBsTable from '../../component/NextGenBsTable';
import {
  getDrilldownBaseline,
  getDrilldownOptimized,
  getDrilldownZipsList,
} from '../../redux/drilldown/api';
import data from '../../utils/data.json';
import {
  drilldownBaselineColumn,
  drilldownOptimizedColumn,
} from '../../utils/columns';
import { cityList, downloadPdf } from '../../redux/survey/api';
import {
  checkedApiCall,
  removeFilter,
  renderFiltersInputType,
  setSelectFilterOption,
} from '../../utils/helperInput';
import DataUpdateModal from '../../component/ModelPop/DataUpdateModal';
import { notify } from '../../component/Toaster';
import {
  updateCurrentDrilldownTab,
  updateCurrentSurveyTab,
  updateSurveyToDrillDownData,
  updateUpdateTabNavigationData,
} from '../../redux/navigation/navigationSlice';
import useFilters from '../../hooks/useFilters';
import { useDebounce } from '../../hooks/useDebouncing';
import { convertInputToZipArray } from '../../utils/helper';
import PdfModal from '../../component/ModelPop/PdfDownloadingModal';

const DrilDown = () => {
  const {
    selectOption,
    selectedFilterOption,
    setFilterOptions,
    setSelectedFilterOptions,
  } = useFilters({ moduleName: 'drilldown' });
  const inputRef = useRef(null);
  const { cloneDeep, isEmpty } = lodash;
  const [seletedFilter, setSelectedFilter] = useState('');
  const drilldown = useSelector(state => state.drilldown);
  const surveyToDrillDownData = useSelector(
    state => state.navigation.survey_To_DrillDown_Data
  );
  const currentDrilldownTab = useSelector(
    state => state?.navigation.currentDrilldownTab
  );
  // survey_To_DrillDown_Data
  const [activeTab, setActiveTab] = useState(currentDrilldownTab || 'Baseline');
  const [selectedSortingOption, setSelectedSortingOption] = useState({});
  const stateData = useSelector(state => state?.ranking?.states);
  const cityData = useSelector(state => state?.drilldown?.drilldownCityList);
  const zipCodeData = useSelector(state => state?.drilldown?.drilldowmZipList);
  const [searchByZip, setSearchByZip] = useState([]);
  const [filterByData, setFilterByData] = useState(data?.drillDownFilterBy);
  const dispatch = useDispatch();
  const [resetPage, setResetPage] = useState(1);
  const [show, setShow] = useState(false);
  const [surveyForUpdate] = useState({});
  const handleClose = () => setShow(!show);
  const [showPdfModal, setShowPdfModel] = useState(false);
  const isPdfDownloading = useSelector(state => state?.pdf?.isPdfDownloading);
  // const handleShow = () => setShow(true);
  const [topping] = useState('Zip');
  const navigate = useNavigate();

  const handleClosePdfModel = () => {
    setShowPdfModel(false);
  };

  useEffect(() => {
    setShowPdfModel(isPdfDownloading);
  }, [isPdfDownloading]);

  useEffect(() => {
    setResetPage(1);
  }, []);

  const setFilterOption = value => {
    dispatch(setFilterOptions(value));
  };

  const setSelectedFilterOption = option => {
    dispatch(setSelectedFilterOptions(option));
  };

  const onSelectDropDown = async (e, row) => {
    // function for click update button
    if (e === 'print') {
      notify('Download pdf is in process');
      dispatch(
        downloadPdf({
          SurveyType: row?.SurveyType,
          ZIPCode: row?.ZIPCode,
          City: row?.City,
          StateAbbr: row?.StateAbbr,
          MarketName: row?.MarketName,
        })
      );
    }
    if (e === 'update') {
      // const filteredZipData = zipCodeData.filter(
      //   zip => zip.value === row.ZIPCode
      // );
      dispatch(updateCurrentSurveyTab('Dataupdate'));
      dispatch(updateUpdateTabNavigationData(row));
      navigate('/survey', {
        state: { prevRoute: '/drilldown', row },
      });
    }
  };

  const drilldownBaselineColumnData = drilldownBaselineColumn(onSelectDropDown);
  const drilldownOptimizedColumnData =
    drilldownOptimizedColumn(onSelectDropDown);

  const updateFilter = () => {
    const { id, SurveyType, City, StateAbbr, ZIPCode, MarketName } =
      surveyToDrillDownData || {};

    if (id === undefined) return;

    switch (SurveyType) {
      case 1:
        setFilterOption(['City']);
        setSelectedFilterOption({
          ...selectedFilterOption,
          City: [
            {
              value: `${City}, ${StateAbbr}`,
              label: `${City}, ${StateAbbr}`,
              DataFrom: 'City',
            },
          ],
        });
        break;
      case 2:
        setFilterOption(['ZIPCode']);
        setSelectedFilterOption({
          ...selectedFilterOption,
          ZIPCode: [{ value: ZIPCode, label: MarketName, DataFrom: 'ZIPCode' }],
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      selectedFilterOption?.StateAbbr !== undefined &&
      selectedFilterOption?.StateAbbr !== ''
    ) {
      setSearchByZip([]);
      if (inputRef.current) inputRef.current.value = '';
    }
  }, [selectedFilterOption]);

  useEffect(() => {
    updateFilter();
  }, [surveyToDrillDownData]);

  useEffect(() => {
    if (selectOption.length > 0) {
      const filteredData = filterByData.filter(
        filter => filter.optionValue !== selectOption[selectOption.length - 1]
      );
      setFilterByData(filteredData);
    } else {
      setSelectedFilter('');
      setFilterByData(data?.drillDownFilterBy);
    }
  }, [selectOption]);

  const getDrilldata = async (start = 0, limit = 10) => {
    if (activeTab === 'Baseline') {
      dispatch(
        getDrilldownBaseline({
          filters: selectedFilterOption,
          sortings: selectedSortingOption,
          searchByZip,
          start,
          limit,
        })
      );
    } else {
      dispatch(
        getDrilldownOptimized({
          filters: selectedFilterOption,
          sortings: selectedSortingOption,
          searchByZip,
          start,
          limit,
        })
      );
    }
  };

  const debouncedApiCall = useDebounce(() => {
    const isCallApi = checkedApiCall(selectedFilterOption);
    if (isCallApi || Object.keys(selectedSortingOption).length > 0) {
      getDrilldata();
    }
  }, 1000);

  useEffect(() => {
    debouncedApiCall();
  }, [activeTab, selectedSortingOption, selectedFilterOption, searchByZip]);

  const setSelectFilterOptionFunction = value => {
    setSelectFilterOption(
      value,
      data,
      selectOption,
      setFilterOption,
      setSelectedFilter,
      selectedFilterOption,
      setSelectedFilterOption,
      filterByData,
      setFilterByData
    );
  };

  const removeFilterFunction = id => {
    if (
      (selectOption[id] === 'ZIPCode' || selectOption[id] === 'City') &&
      !isEmpty(updateUpdateTabNavigationData)
    )
      dispatch(updateSurveyToDrillDownData({}));
    removeFilter(
      id,
      activeTab === 'Baseline'
        ? data.drillDownFilterBy
        : data.drillDownFilterByOptimized,
      selectOption,
      selectedFilterOption,
      setFilterOption,
      setSelectedFilter,
      setSelectedFilterOption,
      setFilterByData,
      filterByData
    );
  };

  const resetFilters = () => {
    // if (activeTab === 'Baseline') setFilterByData(data.drillDownFilterBy);
    // else setFilterByData(data.drillDownFilterByOptimized);

    setFilterOption([]);
    setSelectedFilter('');
    setSelectedSortingOption({});
    setSelectedFilterOption({});
    dispatch(updateSurveyToDrillDownData({}));
  };

  const setSortingOption = option => {
    const tempOption = {};
    if (option !== 'default') {
      if (option === 'monthly_revenue') {
        tempOption.Revenue_Baseline = true;
      } else {
        tempOption[option] = true;
      }
    }

    setSelectedSortingOption(tempOption);
    setResetPage(1);
  };

  const inputOnChangeFunction = (type, value) => {
    const tempSelectedFilterOption = cloneDeep(selectedFilterOption);
    if (type === 'StateAbbr') {
      const tempData = [];
      value?.map(res => {
        return tempData.push(res.value);
      });
      tempSelectedFilterOption[type] = tempData;
      dispatch(cityList(tempData));
    }
    tempSelectedFilterOption[type] = value;
    setSelectedFilterOption(tempSelectedFilterOption);
  };

  const renderFilterOptions = () => {
    return (
      <Row className="mb-2">
        {selectOption?.map((res, i) => {
          return (
            // TODO: fix the key
            // eslint-disable-next-line react/no-array-index-key
            <Col xs={3} key={`filters-${i}`}>
              {renderFiltersInputType(
                res,
                i,
                data,
                activeTab === 'Baseline'
                  ? data.drillDownFilterBy
                  : data.drillDownFilterByOptimized,
                inputOnChangeFunction,
                removeFilterFunction,
                stateData,
                cityData,
                zipCodeData,
                selectedFilterOption
              )}
            </Col>
          );
        })}
      </Row>
    );
  };

  const handleClickGetMoreData = () => {
    dispatch(updateCurrentSurveyTab('Dataupdate'));
    navigate('/survey');
  };

  const setSelectedOption = options => {
    if (options.length > 0) {
      const arrayOfZips = [];
      options.map(option => arrayOfZips.push(option.value));
      setSearchByZip([...arrayOfZips]);
      getDrilldata(0, 10);
    } else {
      setSearchByZip([]);
      getDrilldata(0, 10);
    }
  };

  const debounceFunc = debounce(async inputValue => {
    if (inputValue.length <= 2) {
      return [];
    }
    const value = inputValue.trim();
    const trimmedZipValue = convertInputToZipArray(value)[0];
    const res = await getDrilldownZipsList({
      searchQuery: trimmedZipValue,
    });
    if (res?.data?.length) {
      return [
        { value: '', label: '----ZIPCodes----', isDisabled: true },
        ...res.data,
      ];
    }
    return [{ value: '', label: 'No record found', isDisabled: true }];
  }, 1000);

  return (
    <Container className="p-3">
      <Row>
        <Col xs={12}>
          <h2 className="servey">Drilldown</h2>
        </Col>
        {surveyToDrillDownData?.MarketName &&
          surveyToDrillDownData?.SurveyType === 1 && (
            <Col xs={12}>
              <h5 className="h4-text">
                Top Performing Segments in {surveyToDrillDownData?.City},
                {surveyToDrillDownData?.StateAbbr}{' '}
              </h5>
            </Col>
          )}
      </Row>

      <Row className="mb-2 no-wrap">
        <div className="width-35">
          <h6>Quick ZIP Search</h6>
          <AsyncSelect
            isMulti
            isClearable
            getOptionValue={option => option.label}
            onChange={setSelectedOption}
            loadOptions={debounceFunc}
            placeholder={
              <div style={{ width: '100vh' }}>
                Enter ZIP Code(s) separated by commas
              </div>
            }
          />
        </div>
        <Col xs={3}>
          <Filter
            setOption={setSelectFilterOptionFunction}
            options={filterByData}
            title="Filter By"
            value={seletedFilter}
          />
        </Col>
        <Col xs={3}>
          <Filter
            setOption={setSortingOption}
            options={
              activeTab === 'Baseline'
                ? data?.drillDownBaselineSortBy
                : data?.drillDownOptimizedSortBy
            }
            title="Sort By"
          />
        </Col>
        <Col xs={3} className="reset-top getbtn-end">
          <div className="reset-get-div">
            {selectOption?.length ? (
              <button
                type="button"
                // variant="primary"
                className="allbutton"
                onClick={() => {
                  resetFilters();
                }}
              >
                Reset Filter
              </button>
            ) : null}

            <button
              type="button"
              // variant="primary"
              className="allbutton"
              onClick={handleClickGetMoreData}
            >
              Get More Data
            </button>
          </div>
        </Col>
      </Row>
      {renderFilterOptions()}
      <div>
        <Tabs
          defaultActiveKey={activeTab}
          onSelect={e => {
            setResetPage(1);
            dispatch(updateCurrentDrilldownTab(e));
            setActiveTab(e);
            if (e === 'Optimized') {
              setFilterByData(data.drillDownFilterByOptimized);
            } else {
              setFilterByData(data.drillDownFilterBy);
            }
            resetFilters();
            updateFilter();
          }}
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Baseline" title="Baseline">
            <div className="drilldown-table-class">
              <NextGenBsTable
                columns={drilldownBaselineColumnData}
                data={drilldown.drilldownBaseline}
                keyField="id"
                loading={drilldown?.drilldownBaselineLoader}
                hideSizePerPage
                enableSearching={false}
                striped
                count={drilldown?.drilldownBaselineCount}
                fetchData={getDrilldata}
                remote
                resetPage={resetPage}
                setResetPage={setResetPage}
              />
            </div>
          </Tab>
          <Tab eventKey="Optimized" title="Optimized">
            <NextGenBsTable
              columns={drilldownOptimizedColumnData}
              data={drilldown.drilldownOptimized}
              keyField="id"
              loading={drilldown?.drilldownOptimizedLoader}
              hideSizePerPage
              enableSearching={false}
              striped
              count={drilldown?.drilldownOptimizedCount}
              fetchData={getDrilldata}
              remote
              resetPage={resetPage}
              setResetPage={setResetPage}
            />
          </Tab>
        </Tabs>
      </div>
      {show && (
        <DataUpdateModal
          show={show}
          handleClose={handleClose}
          surveyDataForUpdate={surveyForUpdate}
          geo={topping}
        />
      )}
      {showPdfModal && (
        <PdfModal show={showPdfModal} onClose={handleClosePdfModel} />
      )}
    </Container>
  );
};
export default DrilDown;
