/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  LoadScript,
  Circle,
  OverlayView,
} from '@react-google-maps/api';
import { ScaleLoader } from 'react-spinners';
import isEmpty from 'lodash/isEmpty';

const mapContainerStyle = {
  width: '100%',
  height: '500px',
};

const color = [
  '#EE3E32',
  '#F68838',
  '#FBB021',
  '#1B8A5A',
  '#1D4877',
];

const options = {
  strokeColor: '#8BC34A',
  strokeOpacity: 0.5,
  strokeWeight: 1,
  fillColor: '#FF0000',
  fillOpacity: 0.75,
  visible: true,
  radius: 20000,
  zIndex: 1,
};

const divStyle = {
  background: 'white',
  border: '1px solid #ccc',
  padding: 10,
  paddingBottom: 2,
  borderRadius: 5,
  borderTopLeftRadius: 0,
  zIndex: 999999,
};

const MyMapComponent = ({ selectedSurvey, onCircleClick, coordinates }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [selectedCircle, setSelectedCircle] = useState({});
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(8);
  const [isMount, setMount] = useState(false);

  const onZoomChanged = () => {
    if (!map) return;
    setZoom(map?.getZoom());
  };

  const handleCloseButton = () => {
    setSelectedCircle({});
    setShowInfo(false);
  };

  useEffect(() => {
    if (!isEmpty(selectedSurvey)) {
      setShowInfo(true);
      if (coordinates.length === 1) setSelectedCircle(coordinates[0]);
      else
        setSelectedCircle(
          coordinates.find(
            coord => coord.SurveyID === selectedSurvey.datasurveys_id,
          ),
        );
    }

    return () => {
      setShowInfo(false);
      setSelectedCircle({});
    };
  }, [coordinates, selectedSurvey]);

  useEffect(() => {
    if (coordinates.length > 0) {
      setZoom(4);

      if (coordinates.length > 1 && window.google) {
        // Calculate bounds to fit all coordinates
        const bounds = new window.google.maps.LatLngBounds();
        coordinates.forEach(coord =>
          bounds.extend({
            lat: parseFloat(coord.Latitude),
            lng: parseFloat(coord.Longitude),
          }),
        );

        // will recenter the map only component onmount
        if (!isMount) {
          // Set the map to fit the bounds
          map?.fitBounds(bounds);

          // Calculate the center of the bounds
          const calCenter = bounds.getCenter();

          // Set the new center of the map
          map?.setCenter(calCenter);
          map?.setZoom(4);
          setMount(true);
        }
      } else {
        const selectedCircleCoordinates = {
          lat: parseFloat(coordinates[0].Latitude),
          lng: parseFloat(coordinates[0].Longitude),
        };

        // Set the map to fit the bounds of the selected circle
        map?.panTo(selectedCircleCoordinates);
        map?.setZoom(4); // Adjust the zoom level as needed
      }
    }
  }, [coordinates]);

  return (
    <LoadScript
      region="us"
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
      libraries={['drawing', 'visualization', 'places']}
    >
      <GoogleMap
        id="circle-example"
        zoom={8}
        onLoad={m => setMap(m)}
        mapContainerStyle={mapContainerStyle}
        onZoomChanged={onZoomChanged}
      >
        {coordinates.length === 0 ? (
          <div className="loading-overlay-block">
            <div className="loading-for-block">
              <ScaleLoader
                color="#000"
                loading
                height={100}
                width={4}
                margin={2}
                radius={2}
              />
            </div>
          </div>
        ) : (
          coordinates.map((circle, idx) => (
            <Circle
              // eslint-disable-next-line react/no-array-index-key
              key={`map${idx}`}
              // required
              center={{
                lat: parseFloat(circle.Latitude),
                lng: parseFloat(circle.Longitude),
              }}
              // required
              options={{
                ...options,
                fillColor: color[circle.ColorScaleIndex_Baseline % 5],
                zIndex: circle.SizeIndex_Baseline,
                radius:
                  2 ** (12 - zoom) * 33 * (circle.SizeIndex_Baseline + 20),
              }}
              onMouseOver={() => {
                setShowInfo(true);
                setSelectedCircle(circle);
              }}
              onMouseOut={handleCloseButton}
              onClick={() => onCircleClick(circle)}
            />
          ))
        )}

        {showInfo && selectedCircle && (
          <OverlayView
            position={{
              lat: parseFloat(selectedCircle.Latitude),
              lng: parseFloat(selectedCircle.Longitude),
            }}
            mapPaneName="floatPane"
          >
            <div style={divStyle}>
              <h6>
                {selectedCircle?.ZIPCode
                  ? `${selectedCircle?.ZIPCode} (${selectedCircle?.City})`
                  : `${selectedCircle?.City}, ${selectedCircle?.StateAbbr}`}
              </h6>
            </div>
          </OverlayView>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default React.memo(MyMapComponent);
