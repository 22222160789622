/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/images/logo.png';
import { userInfo } from '../../redux/user/api';
import ConfirmModal from '../ModelPop/ConfirmModal';
import {
  stateList,
  cityListRanking,
  cityListSurvey,
  cityListSurveyUpdate,
  zipListRanking,
  zipListSurvey,
  zipListSurveyUpdate,
} from '../../redux/survey/api';
import { cityListDrilldown, zipListDrilldown } from '../../redux/drilldown/api';
import {
  zipListHomeReview,
  cityListHomeReview,
} from '../../redux/homeReview/api';
import { setIsUserLoggedIn } from '../../redux/user/userSlice';
import { notifyError } from '../Toaster';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const handleLogout = () => {
    dispatch(setIsUserLoggedIn(false));
    localStorage.clear();
    navigate('/');
  };
  const getUserInfo = async () => {
    try {
      await dispatch(userInfo());
    } catch (error) {
      handleLogout();
      notifyError('Session expired, please login again');
    }
  };
  useEffect(() => {
    if (localStorage.getItem('token')) {
      getUserInfo();
    }
  }, [localStorage.getItem('token')]);

  useEffect(() => {
    dispatch(stateList());
    dispatch(cityListRanking());
    dispatch(cityListSurvey());
    dispatch(cityListSurveyUpdate());
    dispatch(zipListRanking());
    dispatch(zipListSurvey());
    dispatch(zipListSurveyUpdate());
    dispatch(cityListDrilldown());
    dispatch(zipListDrilldown());
    dispatch(zipListHomeReview());
    dispatch(cityListHomeReview());
  }, []);
  return (
    <div className="header-fix">
      <div className="bg-header">
        <div className="space-between">
          <div>
            <img
              className="logo-Class"
              src={logo}
              alt="Logo"
              onClick={() => navigate('/dashboard')}
            />
          </div>
          <div className="header-style">
            {user?.userInfo?.id && (
              <ul>
                {user?.userInfo?.userRoles?.data_ingest === 1 && (
                  <li className="list-item--active">
                    <Link
                      to="dataingest"
                      className={
                        location?.pathname === '/dataingest'
                          ? 'hover-active'
                          : 'hover-hov'
                      }
                      data-caption=" Data ingest"
                    >
                      Data Ingest
                    </Link>
                  </li>
                )}
                <li>
                  <Link
                    to="survey"
                    className={
                      location?.pathname === '/survey'
                        ? 'hover-active'
                        : 'hover-hov'
                    }
                  >
                    Survey
                  </Link>
                </li>
                {user?.userInfo?.userRoles?.drilldown === 1 && (
                  <li>
                    <Link
                      to="drilldown"
                      className={
                        location?.pathname === '/drilldown'
                          ? 'hover-active'
                          : 'hover-hov'
                      }
                    >
                      Drilldown
                    </Link>
                  </li>
                )}
                {user?.userInfo?.userRoles?.homes_review === 1 && (
                  <li>
                    <Link
                      to="homesreview"
                      className={
                        location?.pathname === '/homesreview'
                          ? 'hover-active'
                          : 'hover-hov'
                      }
                    >
                      Homes Review
                    </Link>
                  </li>
                )}
                {user?.userInfo?.userRoles?.fieldtoolUser === 1 ||
                user?.userInfo?.userRoles?.fieldtoolManager === 1 ? (
                  <li>
                    <Link
                      to="characteristicsdrilldown"
                      className={
                        location?.pathname === '/characteristicsdrilldown'
                          ? 'hover-active'
                          : 'hover-hov'
                      }
                    >
                      Characteristics
                    </Link>
                  </li>
                ) : null}
                {user?.userInfo?.userRoles?.characteristic === 1 ? (
                  <li>
                    <Link
                      to="fat"
                      className={
                        location?.pathname === '/fat'
                          ? 'hover-active'
                          : 'hover-hov'
                      }
                    >
                      Field Tool
                    </Link>
                  </li>
                ) : null}
                <li>
                  <Link
                    to="account"
                    className={
                      location?.pathname === '/account'
                        ? 'hover-active'
                        : 'hover-hov'
                    }
                  >
                    Account
                  </Link>
                </li>
                {user?.userInfo?.role === 0 && (
                  <li>
                    <Link
                      to="users"
                      className={
                        location?.pathname === '/users'
                          ? 'hover-active'
                          : 'hover-hov'
                      }
                    >
                      Users
                    </Link>
                  </li>
                )}

                <li>
                  <div
                    role="button"
                    onClick={() => {
                      setShow(true);
                    }}
                    className={show ? 'hover-active' : 'hover-hov'}
                  >
                    Logout
                  </div>

                  {/* <Link
                    to="logout"
                    className={
                      location?.pathname === '/' ? 'hover-active' : 'hover-hov'
                    }
                  >
                    Logout
                  </Link> */}
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      {show && (
        <ConfirmModal
          heading="Logout"
          message="Are you sure?"
          show={show}
          setShow={setShow}
          onConfirm={handleLogout}
        />
      )}
    </div>
  );
};

export default Header;
