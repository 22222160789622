/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  calculateScoreAsync,
  clearRecentPropertiesAsync,
  fetchAllSegmentsAsync,
  getCurrentLocationAsync,
  getPropertyPrintDetailsAsync,
  getPropertyTypeGroupingsAsync,
  getStateAbbrsAsync,
  saveCurrentLocationAsync,
  storeSavedSearchAsync,
  deleteSavedSearchAsync,
  updateNotesAndRatingsAsync,
  getMediaAsync,
  saveUserMediaAsync,
  updateSavedSearchUpdateFrequencyAsync,
  getMatchingSearchesAsync,
  clearMatchingSearchesAsync,
  clearNotificationsAsync,
  getSurveyCharacteristicsAsync,
  parseCharacteristicsAsync,
  addCharacteristicNotesAsync,
  sendEmailAsync,
  getSurveyStatusesAsync,
  createPropertyAsync,
  reorderTodaysListAsync,
} from './fieldAnalysisApi';

const initialState = {
  loading: false,
  location: {
    zipCodes: [],
    segmentIds: [],
    isAllSegments: true,
    isInPerson: false,
    geographyId: null,
    locationStr: 'Select Location',
    found: false,
  },
  masterData: {
    segments: [],
    propertyTypeGroupings: [],
    states: [],
    surveyStatuses: [],
  },
  notification: {
    unreadMessages: [],
    readMessages: [],
  },
  newSearch: {},
  recentProperties: {},
  savedSearches: {},
  newlyListed: {},
  todaysList: {},
  shortlist: {},
  currentOffers: {},
};

const fetchAllSegments = createAsyncThunk(
  'fieldTool/fetchAllSegments',
  fetchAllSegmentsAsync
);

const getCurrentLocation = createAsyncThunk(
  'fieldTool/getCurrentLocation',
  getCurrentLocationAsync
);

const saveCurrentLocation = createAsyncThunk(
  'fieldTool/saveCurrentLocation',
  saveCurrentLocationAsync
);

const getStateAbbrs = createAsyncThunk(
  'fieldTool/getStateAbbrs',
  getStateAbbrsAsync
);

const getPropertyTypeGroupings = createAsyncThunk(
  'fieldTool/getPropertyTypeGroupings',
  getPropertyTypeGroupingsAsync
);

const getPropertyPrintDetails = createAsyncThunk(
  'fieldTool/getPropertyPrintDetails',
  getPropertyPrintDetailsAsync
);

const clearRecentProperties = createAsyncThunk(
  'fieldTool/clearRecentProperties',
  clearRecentPropertiesAsync
);

const calculateScore = createAsyncThunk(
  'fieldTool/calculateScore',
  calculateScoreAsync
);

const storeSavedSearch = createAsyncThunk(
  'fieldTool/storeSavedSearch',
  storeSavedSearchAsync
);

const deleteSavedSearch = createAsyncThunk(
  'fieldTool/deleteSavedSearch',
  deleteSavedSearchAsync
);

const updateNotesAndRatings = createAsyncThunk(
  'fieldTool/updateNotesAndRatings',
  updateNotesAndRatingsAsync
);

const getMedia = createAsyncThunk('fieldTool/getMedia', getMediaAsync);

const saveUserMedia = createAsyncThunk(
  'fieldTool/saveUserMedia',
  saveUserMediaAsync
);

const updateSavedSearchUpdateFrequency = createAsyncThunk(
  'fieldTool/updateSavedSearchUpdateFrequency',
  updateSavedSearchUpdateFrequencyAsync
);

const getMatchingSearches = createAsyncThunk(
  'fieldTool/getMatchingSearches',
  getMatchingSearchesAsync
);

const clearMatchingSearches = createAsyncThunk(
  'fieldTool/clearMatchingSearches',
  clearMatchingSearchesAsync
);

const clearNotifications = createAsyncThunk(
  'fieldTool/clearNotifications',
  clearNotificationsAsync
);

const getSurveyCharacteristics = createAsyncThunk(
  'fieldTool/getSurveyCharacteristics',
  getSurveyCharacteristicsAsync
);

const parseCharacteristics = createAsyncThunk(
  'fieldTool/parseCharacteristics',
  parseCharacteristicsAsync
);

const addCharacteristicNotes = createAsyncThunk(
  'fieldTool/addCharacteristicNotes',
  addCharacteristicNotesAsync
);

const sendEmail = createAsyncThunk('fieldTool/sendEmail', sendEmailAsync);

const getSurveyStatuses = createAsyncThunk(
  'fieldTool/getSurveyStatuses',
  getSurveyStatusesAsync
);

const createProperty = createAsyncThunk(
  'fieldTool/createProperty',
  createPropertyAsync
);

const reorderTodaysList = createAsyncThunk(
  'fieldTool/reorderTodaysList',
  reorderTodaysListAsync
);

const getLocationString = zipcodeString => {
  if (!zipcodeString || zipcodeString.length === 0) {
    return 'Select Location';
  }
  const zipCodes = zipcodeString.split(',');
  if (zipCodes.length === 1) {
    return `ZIP Code ${zipCodes[0]}`;
  }
  if (zipCodes.length <= 3) {
    return `ZIP Codes ${zipcodeString}`;
  }
  return `ZIP Codes ${zipCodes.slice(0, 3).join(', ')} +
    ${zipCodes.length - 3} more`;
};

const getSegmentString = segmentString => {
  if (!segmentString || segmentString.length === 0) {
    return '';
  }
  const segments = segmentString.split(',');
  if (segments.length === 1) {
    return `Sleeps ${segments[0]}`;
  }
  return 'Multiple Segments';
};

export const fieldAnalysisSlice = createSlice({
  name: 'fieldAnalysis',
  initialState,
  reducers: {
    showLocationModal: (state, action) => {
      state.location.showModal = action.payload;
    },
  },
  extraReducers(builders) {
    builders.addCase(fetchAllSegments.fulfilled, (state, action) => {
      state.masterData.segments = action.payload.map(
        segment =>
          `${segment.SleepsCountMax}/${segment.Bedrooms}Bd/${segment.Bathrooms}Br`
      );
    });
    builders.addCase(fetchAllSegments.pending, () => {});
    builders.addCase(fetchAllSegments.rejected, () => {});

    builders.addCase(getCurrentLocation.fulfilled, (state, action) => {
      if (action.payload) {
        state.location = {
          isAllSegments: action.payload.Segments,
          isInPerson: action.payload.InPerson,
          zipCodes: action.payload.ZIPCodes?.split(',') || [],
          segmentIds: action.payload.SegmentIDs?.split(',') || [],
          locationStr: getLocationString(action.payload.ZIPCodes), // `ZIP Codes ${action.payload.ZIPCodes}`,
          segmentStr: getSegmentString(action.payload.SegmentIDs),
          geographyId: action.payload.GeographyID,
          found: true,
        };
      } else {
        state.location = initialState.location;
        state.location.found = false;
      }
      state.loading = false;
    });
    builders.addCase(getCurrentLocation.rejected, state => {
      state.loading = false;
    });
    builders.addCase(getCurrentLocation.pending, state => {
      state.loading = true;
    });

    builders.addCase(saveCurrentLocation.fulfilled, (state, action) => {
      state.location = {
        isAllSegments: action.payload.isAllSegments,
        isInPerson: action.payload.isInPerson,
        zipCodes: action.payload.zipCodes?.split(',') || [],
        segmentIds: action.payload.segmentIds?.split(',') || [],
        locationStr: getLocationString(action.payload.zipCodes), // `ZIP Codes ${action.payload.ZIPCodes}`,
        segmentStr: getSegmentString(action.payload.segmentIds),
        geographyId: action.payload.geographyID,
        found: true,
      };
      state.loading = false;
    });
    builders.addCase(saveCurrentLocation.rejected, state => {
      state.loading = false;
    });
    builders.addCase(saveCurrentLocation.pending, state => {
      state.loading = true;
    });

    builders.addCase(getStateAbbrs.fulfilled, (state, action) => {
      state.masterData.states = action.payload;
    });
    builders.addCase(getStateAbbrs.rejected, () => {});
    builders.addCase(getStateAbbrs.pending, () => {});
    builders.addCase(getPropertyTypeGroupings.fulfilled, (state, action) => {
      state.masterData.propertyTypeGroupings = action.payload;
    });
    builders.addCase(getPropertyTypeGroupings.rejected, () => {});
    builders.addCase(getPropertyTypeGroupings.pending, () => {});

    builders.addCase(getPropertyPrintDetails.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(getPropertyPrintDetails.rejected, state => {
      state.loading = false;
    });
    builders.addCase(getPropertyPrintDetails.pending, state => {
      state.loading = true;
    });
    builders.addCase(clearRecentProperties.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(clearRecentProperties.rejected, state => {
      state.loading = false;
    });
    builders.addCase(clearRecentProperties.pending, state => {
      state.loading = true;
    });
    builders.addCase(calculateScore.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(calculateScore.rejected, state => {
      state.loading = false;
    });
    builders.addCase(calculateScore.pending, state => {
      state.loading = true;
    });
    builders.addCase(storeSavedSearch.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(storeSavedSearch.rejected, state => {
      state.loading = false;
    });
    builders.addCase(storeSavedSearch.pending, state => {
      state.loading = true;
    });
    builders.addCase(deleteSavedSearch.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(deleteSavedSearch.rejected, state => {
      state.loading = false;
    });
    builders.addCase(deleteSavedSearch.pending, state => {
      state.loading = true;
    });
    builders.addCase(updateNotesAndRatings.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(updateNotesAndRatings.rejected, state => {
      state.loading = false;
    });
    builders.addCase(updateNotesAndRatings.pending, state => {
      state.loading = true;
    });
    builders.addCase(getMedia.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(getMedia.rejected, state => {
      state.loading = false;
    });
    builders.addCase(getMedia.pending, state => {
      state.loading = true;
    });
    builders.addCase(saveUserMedia.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(saveUserMedia.rejected, state => {
      state.loading = false;
    });
    builders.addCase(saveUserMedia.pending, state => {
      state.loading = true;
    });
    builders.addCase(updateSavedSearchUpdateFrequency.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(updateSavedSearchUpdateFrequency.rejected, state => {
      state.loading = false;
    });
    builders.addCase(updateSavedSearchUpdateFrequency.pending, state => {
      state.loading = true;
    });
    builders.addCase(getMatchingSearches.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(getMatchingSearches.rejected, state => {
      state.loading = false;
    });
    builders.addCase(getMatchingSearches.pending, state => {
      state.loading = true;
    });
    builders.addCase(clearMatchingSearches.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(clearMatchingSearches.rejected, state => {
      state.loading = false;
    });
    builders.addCase(clearMatchingSearches.pending, state => {
      state.loading = true;
    });
    builders.addCase(clearNotifications.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(clearNotifications.rejected, state => {
      state.loading = false;
    });
    builders.addCase(clearNotifications.pending, state => {
      state.loading = true;
    });
    builders.addCase(getSurveyCharacteristics.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(getSurveyCharacteristics.rejected, state => {
      state.loading = false;
    });
    builders.addCase(getSurveyCharacteristics.pending, state => {
      state.loading = true;
    });
    builders.addCase(parseCharacteristics.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(parseCharacteristics.rejected, state => {
      state.loading = false;
    });
    builders.addCase(parseCharacteristics.pending, state => {
      state.loading = true;
    });
    builders.addCase(addCharacteristicNotes.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(addCharacteristicNotes.rejected, state => {
      state.loading = false;
    });
    builders.addCase(addCharacteristicNotes.pending, state => {
      state.loading = true;
    });
    builders.addCase(sendEmail.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(sendEmail.rejected, state => {
      state.loading = false;
    });
    builders.addCase(sendEmail.pending, state => {
      state.loading = true;
    });
    builders.addCase(getSurveyStatuses.fulfilled, (state, action) => {
      state.masterData.surveyStatuses = action.payload;
      state.loading = false;
    });
    builders.addCase(getSurveyStatuses.rejected, state => {
      state.loading = false;
    });
    builders.addCase(getSurveyStatuses.pending, state => {
      state.loading = true;
    });
    builders.addCase(createProperty.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(createProperty.rejected, state => {
      state.loading = false;
    });
    builders.addCase(createProperty.pending, state => {
      state.loading = true;
    });
    builders.addCase(reorderTodaysList.fulfilled, state => {
      state.loading = false;
    });
    builders.addCase(reorderTodaysList.rejected, state => {
      state.loading = false;
    });
    builders.addCase(reorderTodaysList.pending, state => {
      state.loading = true;
    });
  },
});

export {
  calculateScore,
  clearMatchingSearches,
  clearNotifications,
  clearRecentProperties,
  deleteSavedSearch,
  fetchAllSegments,
  getCurrentLocation,
  getMatchingSearches,
  getMedia,
  getPropertyPrintDetails,
  getPropertyTypeGroupings,
  getStateAbbrs,
  saveCurrentLocation,
  saveUserMedia,
  storeSavedSearch,
  updateNotesAndRatings,
  updateSavedSearchUpdateFrequency,
  getSurveyCharacteristics,
  parseCharacteristics,
  addCharacteristicNotes,
  sendEmail,
  getSurveyStatuses,
  createProperty,
  reorderTodaysList,
};

export const { showLocationModal } = fieldAnalysisSlice.actions;

export default fieldAnalysisSlice.reducer;
