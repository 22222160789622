const calendar = require('./calendar.png');
const exit = require('./exit.png');
const home = require('./home.png');
const location = require('./location.png');
const menuActive = require('./menu-active.png');
const menu = require('./menu.png');
const newList = require('./new-list.png');
const notificationActive = require('./notification-active.png');
const notification = require('./notification.png');
const offers = require('./offers.png');
const savedSearches = require('./saved-searches.png');
const search = require('./search.png');
const shortlist = require('./shortlist.png');
const recent = require('./recent.png');
const dragHandle = require('./Fluid.png');

export default {
  calendar,
  exit,
  home,
  location,
  menu,
  menuActive,
  newList,
  notification,
  notificationActive,
  offers,
  savedSearches,
  search,
  shortlist,
  recent,
  dragHandle,
};
