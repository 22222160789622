import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import propTypes from 'prop-types';
import { uploadFile } from '../services/load/uploadFile';
import { useLoadContext } from '../context/LoadStateContext';
import getLoadProcessHandler from '../services/load/getLoadProcessHandler';

const FileUpload = ({ type, title }) => {
  const [file, setFile] = useState(null);
  const handleFileSelect = e => setFile(e.target.files[0]);
  const loadContext = useLoadContext();
  const upload = async () => {
    const { setLoading } = loadContext;
    setLoading(true);
    // eslint-disable-next-line no-shadow
    const loadChange = getLoadProcessHandler(loadContext);
    const confirmResume = () =>
      new Promise(res => {
        confirmAlert({
          title: 'Resume upload?',
          message: 'Do you want to resume the previous upload of this file?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => res(true),
            },
            {
              label: 'No',
              onClick: () => res(false),
            },
          ],
        });
      });
    const res = await uploadFile(type, file, loadChange, confirmResume);
    setLoading(false);
    confirmAlert({
      title: res.status === 200 ? 'Success!' : 'Error',
      message:
        res.status === 200
          ? `${res.result} AirDNA properties successfully imported to the database`
          : res.result,
      buttons: [{ label: 'Ok' }],
    });
  };
  const handleFileImport = () => {
    if (!file || file.type !== 'text/csv') {
      confirmAlert({
        title: 'Error',
        message: 'Please choose a valid CSV file.',
        buttons: [{ label: 'Ok' }],
      });
    } else {
      confirmAlert({
        title: 'Confirm Import',
        message: `Are you sure you want to import the CSV file: ${file.name}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: upload,
          },
          {
            label: 'No',
          },
        ],
      });
    }
  };
  return (
    <div className="row d-flex justify-content-center align-items-center p-2 p-md-0">
      <div className="col-12 col-md-3 pl-md-0 text-md-right">{title}</div>
      <input
        className="p-2 col-auto ingest-input"
        type="file"
        name="file"
        onChange={handleFileSelect}
      />
      <div className="col-auto pr-md-0">
        <Button
          className="my-2 ingest-btn"
          bsstyle="primary"
          type="button"
          onClick={handleFileImport}
        >
          Import
        </Button>
      </div>
    </div>
  );
};

FileUpload.propTypes = {
  type: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
};

export default FileUpload;
