import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import {
  getMatchingSearches,
  clearMatchingSearches,
} from '../../redux/fieldAnalysis/fieldAnalysisSlice';
import { notifyError } from '../../component/Toaster';
import FATTable from '../../component/FATTable/FATTable';
import axiosConfig from '../../redux/axiosConfig';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  bgcolor: 'background.paper',
  border: '0 solid #000',
  borderRadius: '8px',
  boxShadow: 4,
  p: 2,
};

const NewlyListed = () => {
  const [propertiesGroups, setPropertiesGroups] = useState([]);
  const [propertyIds, setPropertyIds] = useState([]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const [context, setContext] = useState('');

  useEffect(() => {
    dispatch(getMatchingSearches())
      .then(action => {
        if (action.error) {
          notifyError(action.error.message);
        }
        if (action.payload) {
          const properties = {};
          setPropertyIds(action.payload.map(search => search.id));
          action.payload.forEach(search => {
            properties[search.SectionHeading] =
              properties[search.SectionHeading] || [];
            properties[search.SectionHeading].push(search);
          });
          setPropertiesGroups(properties);
        }
      })
      .catch(error => notifyError(error.message));
  }, []);

  const clearList = () => {
    dispatch(clearMatchingSearches()).then(action => {
      if (action.error) {
        notifyError(action.error.message);
      }
      if (action.payload) {
        setPropertiesGroups({});
      }
      setOpen(false);
      setContext('');
    });
  };

  const generatePDF = (propertyList, pdfTitle) => {
    setOpen(true);
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    axiosConfig
      .post('/fat/print', {
        selectedProperties: propertyList,
        title: pdfTitle,
        preparedBy: user.userInfo.user_name,
        datePreparedString: new Date().toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        }),
      })
      .then(res => {
        const { data: { Location } = {} } = res;
        const newWin = window.open(Location, '_blank');
        if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
          notifyError('Please allow pop-ups for this website');
        }
      })
      .catch(err => {
        notifyError(err.message);
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const title = 'Newly Listed Properties';

  const sendList = () => {
    navigate('/fat/send', { state: { propertyIds, title } });
  };

  const printList = () => {
    generatePDF(propertyIds, title);
  };

  useEffect(() => {
    if (context === 'clearList') {
      setOpen(true);
    }
    if (context === 'sendList') {
      sendList();
    }
    if (context === 'printList') {
      printList();
    }
  }, [context]);

  const handleClose = () => {
    if (context !== 'printList') {
      setOpen(false);
      setContext('');
    }
  };

  const renderPrintModal = () => {
    return (
      <>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ color: 'rgba(0, 111, 255, 1)', fontSize: '18px' }}
        >
          Generating PDF
        </Typography>
        <Stack spacing={2} className="pt-2">
          <Typography variant="body1">
            Your download will begin soon. Please wait...
          </Typography>
        </Stack>
      </>
    );
  };

  const renderClearListModal = () => {
    return (
      <>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ color: 'rgba(0, 111, 255, 1)', fontSize: '18px' }}
        >
          Clear List
        </Typography>
        <Stack spacing={2} className="pt-2">
          <Typography variant="body1">
            Are you sure you want to clear the list?
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              onClick={() => clearList()}
            >
              OK
            </Button>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </>
    );
  };

  const renderModalBody = () => {
    switch (context) {
      case 'clearList':
        return renderClearListModal();
      case 'printList':
        return renderPrintModal();
      default:
        return null;
    }
  };

  return (
    <>
      <div className="container-flex p-4">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <h5>New Listings Matching Saved Searches</h5>
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              onClick={() => setContext('clearList')}
              disabled={Object.keys(propertiesGroups).length === 0}
            >
              Clear List
            </Button>
            <Button
              variant="contained"
              onClick={() => setContext('sendList')}
              disabled={Object.keys(propertiesGroups).length === 0}
            >
              Send
            </Button>
            <Button
              variant="contained"
              onClick={() => setContext('printList')}
              disabled={Object.keys(propertiesGroups).length === 0}
            >
              Print
            </Button>
          </Stack>
        </Stack>
        {Object.keys(propertiesGroups).map(group => (
          <div style={{ paddingTop: '2rem' }}>
            <h6>{group}</h6>
            <div>
              <FATTable
                properties={propertiesGroups[group]}
                title="Property Details"
                columns={[
                  { id: 'PropertySize', label: 'Property Size' },
                  { id: 'Address', label: 'Property Address' },
                  {
                    id: 'WHRank',
                    label: 'WH Rank',
                    format: v => (v ? `${v}/10` : 'NA'),
                  },
                  {
                    id: 'AlgorithmicRating',
                    label: 'Algorithmic Rating',
                    format: v => (v ? `${v}/10` : 'NA'),
                  },
                  { id: 'Status', label: 'Status', default: 'Active' },
                ]}
                onRowClick={row => navigate(`/fat/survey/${row.ID}`)}
              />
            </div>
            <div>
              <small>
                {propertiesGroups[group].length}{' '}
                {propertiesGroups[group].length > 1 ? 'Homes' : 'Home'}
              </small>
            </div>
          </div>
        ))}
        {propertyIds.length === 0 && (
          <Stack spacing={2}>
            <Typography variant="body1">
              No Matching Properties Found
            </Typography>
          </Stack>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{renderModalBody()}</Box>
      </Modal>
    </>
  );
};

export default NewlyListed;
