/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'debounce-promise';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
// import propTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import NextGenBsTable from '../../component/NextGenBsTable';
import NoRecord from '../../component/NoRecord';
import {
  getPrevalenceSearchCitiesList,
  getPrevalenceSearchZipsList,
  getPrevalenceSearchCitiesCharList,
  getPrevalenceCities,
  getPrevalenceZips,
} from '../../redux/characteristics/api';
import { prevalenceColumns } from '../../utils/columns';
import {
  removeFilter,
  renderFiltersInputType,
  setSelectFilterOption,
} from '../../utils/helperInput';
import Filter from '../../component/FilterDropdown';
import data from '../../utils/data.json';
import {
  getMoreDataCharacteristicToDataUpdate,
  setSearchValue,
} from '../../redux/characteristics/characteristicsSlice';
import { updateCurrentSurveyTab } from '../../redux/navigation/navigationSlice';

const TabPrevalence = ({
  setActiveKey,
  setSelectedPrevalenceRow,
  selectedTab,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [resetPage, setResetPage] = useState(1);
  const [selectOption, setFilterOption] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [selectedFilterOption, setSelectedFilterOption] = useState({});
  const [selectedSortingOption, setSelectedSortingOption] = useState({});
  const [selectedCharacteristic, setSelectedCharacteristic] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedZip, setSelectedZip] = useState('');
  const [filterByData, setFilterByData] = useState(
    data?.characteristicsFilters
  );
  const prevalenceCitiesStructural = useSelector(
    state => state.characteristics.prevalenceCitiesStructural
  );
  const prevalenceZipsStructural = useSelector(
    state => state.characteristics.prevalenceZipsStructural
  );
  const prevalenceCitiesAmenities = useSelector(
    state => state.characteristics.prevalenceCitiesAmenities
  );
  const prevalenceZipsAmenities = useSelector(
    state => state.characteristics.prevalenceZipsAmenities
  );
  const prevalenceCitiesStructuralCount = useSelector(
    state => state.characteristics.prevalenceCitiesStructuralCount
  );
  const prevalenceCitiesAmenitiesCount = useSelector(
    state => state.characteristics.prevalenceCitiesAmenitiesCount
  );
  const prevalenceZipsStructuralCount = useSelector(
    state => state.characteristics.prevalenceZipsStructuralCount
  );
  const prevalenceZipsAmenitiesCount = useSelector(
    state => state.characteristics.prevalenceZipsAmenitiesCount
  );
  const prevalenceCitiesLoader = useSelector(
    state => state.characteristics.prevalenceCitiesLoader
  );
  const prevalenceZipsLoader = useSelector(
    state => state.characteristics.prevalenceZipsLoader
  );
  const quickSearchValue = useSelector(
    state => state.characteristics.searchValue
  );

  const getData = type => {
    switch (type) {
      case 'structural':
        if (selectedCity) return prevalenceCitiesStructural;
        if (selectedZip) return prevalenceZipsStructural;
        return [];
      case 'amenities':
        if (selectedCity) return prevalenceCitiesAmenities;
        if (selectedZip) return prevalenceZipsAmenities;
        return [];
      default:
        return [];
    }
  };

  const getCount = type => {
    switch (type) {
      case 'structural':
        if (selectedCity) return prevalenceCitiesStructuralCount;
        if (selectedZip) return prevalenceZipsStructuralCount;
        return 0;
      case 'amenities':
        if (selectedCity) return prevalenceCitiesAmenitiesCount;
        if (selectedZip) return prevalenceZipsAmenitiesCount;
        return 0;
      default:
        return 0;
    }
  };

  const isLoading = () => {
    if (selectedCity) return prevalenceCitiesLoader;
    if (selectedZip) return prevalenceZipsLoader;
    return false;
  };

  const getPrevalence = (start = 0, limit = 10, sort = {}) => {
    const sortings = { ...selectedSortingOption, ...sort };
    if (selectedZip) {
      dispatch(
        getPrevalenceZips({
          filters: {
            ...selectedFilterOption,
            CharacteristicName: selectedCharacteristic,
          },
          sortings,
          searchQuery: selectedZip,
          start,
          limit,
        })
      );
    }
    if (selectedCity) {
      dispatch(
        getPrevalenceCities({
          filters: {
            ...selectedFilterOption,
            CharacteristicName: selectedCharacteristic,
          },
          sortings,
          searchQuery: selectedCity,
          start,
          limit,
        })
      );
    }
  };

  useEffect(() => {
    if (selectedTab === 'Prevalence') getPrevalence();
  }, [
    selectedCity,
    selectedZip,
    selectedCharacteristic,
    selectedFilterOption,
    selectedSortingOption,
    selectedTab,
  ]);

  const navigateToHome = row => {
    setSelectedPrevalenceRow(row);
    setActiveKey('Home Size');
  };

  const navigateToSeasonal = () => {
    setActiveKey('Seasonal');
  };

  const column = prevalenceColumns(navigateToHome, navigateToSeasonal);

  const setSelectedOption = options => {
    if (!options || options.length === 0) {
      setSelectedCity('');
      setSelectedZip('');
      setSelectedCharacteristic('');
    }

    let newSelectedCity = '';
    let newSelectedZip = '';
    let newSelectedCharacteristics = '';

    options.forEach(o => {
      if (o?.DataFrom === 'City') newSelectedCity = o.value;
      if (o?.DataFrom === 'Zip') newSelectedZip = o.value;
      if (o?.DataFrom === 'Char') newSelectedCharacteristics = o.value;
    });

    setSelectedCity(newSelectedCity);
    setSelectedZip(newSelectedZip);
    setSelectedCharacteristic(newSelectedCharacteristics);

    dispatch(setSearchValue(options));
  };

  const setSelectFilterOptionFunction = value => {
    setSelectFilterOption(
      value,
      data,
      selectOption,
      setFilterOption,
      setSelectedFilter,
      selectedFilterOption,
      setSelectedFilterOption,
      filterByData,
      setFilterByData
    );
  };

  const setSortingOption = option => {
    const tempOption = {};
    if (option !== 'default') {
      setSelectedSortingOption(option);
      if (option === 'monthly_revenue') {
        tempOption.Revenue_Baseline = true;
      } else {
        tempOption[option] = true;
      }
    }

    setSelectedSortingOption(tempOption);
  };

  const removeFilterFunction = id => {
    removeFilter(
      id,
      data.characteristicsFilters,
      selectOption,
      selectedFilterOption,
      setFilterOption,
      setSelectedFilter,
      setSelectedFilterOption,
      setFilterByData,
      filterByData
    );
  };

  const inputOnChangeFunction = (type, value) => {
    const tempSelectedFilterOption = cloneDeep(selectedFilterOption);
    tempSelectedFilterOption[type] = value;
    setSelectedFilterOption(tempSelectedFilterOption);
  };

  const resetFilters = () => {
    setFilterOption([]);
    setSelectedFilter('');
    setSelectedSortingOption({});
    setSelectedFilterOption({});
  };

  const renderFilterOptions = () => {
    return (
      <Row className="mb-2 align-items-end">
        {selectOption?.map((res, i) => {
          return (
            // TODO: fix the key
            // eslint-disable-next-line react/no-array-index-key
            <Col xs={3} key={`filters-${i}`}>
              {renderFiltersInputType(
                res,
                i,
                data,
                data.characteristicsFilters,
                inputOnChangeFunction,
                removeFilterFunction,
                [],
                [],
                [],
                selectedFilterOption,
                [],
                'dropdown-select',
                'minimumOccupancyPercentage'
              )}
            </Col>
          );
        })}
        <Col xs={3}>
          <div className="mb-2">
            {selectOption?.length ? (
              <button
                type="button"
                className="allbutton"
                onClick={() => {
                  resetFilters();
                }}
              >
                Reset Filter
              </button>
            ) : null}
          </div>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    setSelectedOption(quickSearchValue);
  }, [selectedTab]);

  const debounceFunc = debounce(async inputValue => {
    let options = [];
    if (inputValue.length <= 2) {
      return [];
    }

    const isNumber = !Number.isNaN(Number(inputValue));
    if (isNumber) {
      const zipsList = await getPrevalenceSearchZipsList({
        searchQuery: inputValue,
      });

      if (zipsList?.length)
        options = [
          ...options,
          { value: '', label: '----Zips----', isDisabled: true },
          ...zipsList,
        ];
      else
        options = [
          ...options,
          { value: '', label: '----Zips----', isDisabled: true },
          { value: '', label: 'No zips found', isDisabled: true },
        ];

      return options;
    }
    const citiesList = await getPrevalenceSearchCitiesList({
      searchQuery: inputValue,
    });

    if (citiesList?.length)
      options = [
        ...options,
        { value: '', label: '----Cities----', isDisabled: true },
        ...citiesList,
      ];
    else
      options = [
        ...options,
        { value: '', label: '----Cities----', isDisabled: true },
        { value: '', label: 'No cities found', isDisabled: true },
      ];

    const charList = await getPrevalenceSearchCitiesCharList({
      Char: inputValue,
    });

    if (charList?.length)
      options = [
        ...options,
        { value: '', label: '----Characteristics----', isDisabled: true },
        ...charList.map(d => ({
          value: d.CharacteristicName,
          label: d.CharacteristicName,
          DataFrom: d.DataFrom,
        })),
      ];
    else {
      options = [
        ...options,
        { value: '', label: '----Characteristics----', isDisabled: true },
        { value: '', label: 'No characteristics found', isDisabled: true },
      ];
    }

    return options;
  }, 1000);

  const handleClickGetMoreData = () => {
    const DataFrom =
      selectedCity !== '' ? 'City' : selectedZip !== '' ? 'Zip' : 'char';
    if (DataFrom === 'char') {
      dispatch(getMoreDataCharacteristicToDataUpdate({ DataFrom, value: '' }));
      dispatch(updateCurrentSurveyTab('Dataupdate'));
      navigate('/survey');
    } else {
      const value = selectedCity !== '' ? selectedCity : selectedZip;
      dispatch(getMoreDataCharacteristicToDataUpdate({ DataFrom, value }));
      dispatch(updateCurrentSurveyTab('Dataupdate'));
      navigate('/survey');
    }
  };

  return (
    <>
      <Row className="mb-2">
        <Col xs={6}>
          <h6>Quick Search</h6>
          <AsyncSelect
            isMulti
            isClearable
            loadOptions={debounceFunc}
            onChange={setSelectedOption}
            getOptionValue={option => option.label}
            placeholder={
              <div style={{ width: '100vh' }}>
                Enter City or ZIP Code(s) or charecteristic name separated by
                commas
              </div>
            }
            value={quickSearchValue}
          />
        </Col>
        <Col xs={2}>
          <Filter
            setOption={setSelectFilterOptionFunction}
            options={filterByData}
            title="Filter By"
            value={selectedFilter}
          />
        </Col>
        <Col xs={2}>
          <Filter
            setOption={setSortingOption}
            options={data?.characteristicsSorting}
            title="Sort By"
          />
        </Col>
      </Row>
      <Row>{renderFilterOptions()}</Row>
      {isEmpty(quickSearchValue) ? (
        <NoRecord />
      ) : (
        <Row>
          <Col xs={10}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="mb-3">
                  <b> Current Geography:</b> {selectedCity || selectedZip}
                </div>
                <div className="mb-3">
                  <b>Structural Characteristics:</b>
                </div>
              </div>
              <button
                type="button"
                className="allbutton"
                onClick={handleClickGetMoreData}
              >
                Get More Data
              </button>
            </div>
            <NextGenBsTable
              columns={column}
              data={getData('structural')}
              keyField="id"
              loading={isLoading()}
              count={getCount('structural')}
              fetchData={getPrevalence}
              hideSizePerPage
              enableSearching={false}
              striped
              remote
              resetPage={resetPage}
              setResetPage={setResetPage}
            />
            <div className="mb-3 mt-2">
              <b>Amenities:</b>
            </div>
            <NextGenBsTable
              columns={column}
              data={getData('amenities')}
              keyField="id"
              loading={isLoading()}
              count={getCount('amenities')}
              fetchData={getPrevalence}
              hideSizePerPage
              enableSearching={false}
              striped
              remote
              resetPage={resetPage}
              setResetPage={setResetPage}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

// TabPrevalence.propTypes = {
// search: propTypes.object.isRequired,
// setActiveKey: propTypes.func.isRequired,
// selectedFilterOption: propTypes.object.isRequired,
// selectedSortingOption: propTypes.object.isRequired,
// characteristicsList: propTypes.array.isRequired,
// relativeOverperformanceChangeState: propTypes.func.isRequired,
// relativeUnderperformanceChangeState: propTypes.func.isRequired,
// };

export default TabPrevalence;
