import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import propTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';

const PdfModal = ({ show, onClose }) => {
  return (
    <Modal show={show} onHide={onClose} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Pdf Downloading</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p className="text-center">
          Your PDF is being prepared. Please wait...
        </p>
        <ClipLoader color="#1470af" loading size={20} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={onClose}
          style={{ height: 40, fontSize: 16 }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

PdfModal.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default PdfModal;
