import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import TabDataUpdate from './Tabs/TabDataUpdate';
import TabMap from './Tabs/TabMap';
import TabRanking from './Tabs/TabRanking';
import TabSurvey from './Tabs/TabSurvey';
import TabTrends from './Tabs/TabTrends';

import { updateCurrentSurveyTab } from '../redux/navigation/navigationSlice';

const Survey = () => {
  const dispatch = useDispatch();
  // const location = useLocation();
  // let row;
  // let filteredZipData;
  // if (location?.state?.prevRoute === '/drilldown') {
  //   row = location?.state.row;
  //   filteredZipData = location?.state.filteredZipData;
  // }

  const currentSurveyTab = useSelector(
    state => state?.navigation.currentSurveyTab
  );

  const [activeKey, setActiveKey] = useState(currentSurveyTab || 'Ranking');
  const [surveyOptions, setSurveyOptions] = useState({});
  const [surveyprops, setSurveyProps] = useState({});
  const [surveyUpdate] = useState({});

  return (
    <Container className="p-3">
      <Row>
        <Col xs={12}>
          <h3>Wanderhome Markets Overview</h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Tabs
            //  defaultActiveKey={activeKey}
            activeKey={activeKey}
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={e => {
              setActiveKey(e);
              dispatch(updateCurrentSurveyTab(e));
            }}
          >
            <Tab eventKey="Ranking" title="Ranking">
              <TabRanking />
            </Tab>
            <Tab eventKey="Map" title="Map">
              <TabMap
                setActiveKey={setActiveKey}
                setSurveyProps={setSurveyProps}
                setSurveyOptions={setSurveyOptions}
              />
            </Tab>
            <Tab eventKey="Survey" title="Survey">
              <TabSurvey
                defaultSurvey={surveyOptions}
                defaultSurveyProps={surveyprops}
                setActiveTab={setActiveKey}
                currentSurveyTab={currentSurveyTab}
              />
            </Tab>
            <Tab eventKey="Trends" title="Trends">
              <TabTrends />
            </Tab>
            <Tab eventKey="Dataupdate" title="Data Update">
              <TabDataUpdate defaultSurvey={surveyUpdate} />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default Survey;
