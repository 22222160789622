/* eslint-disable jsx-a11y/scope */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { notify, notifyError } from '../Toaster';
import { createUser, usersList, updateUser } from '../../redux/user/api';
import {
  payloadObj,
  addUserSchema,
  updateUserSchema,
} from '../../utils/helper';

const UserModal = ({ show, setShow, initVals, actionType }) => {
  const [initialValues, setInitialValues] = useState({});
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setInitialValues(initVals);
  }, [initVals, actionType]);

  const errorMessageStyle = {
    fontSize: '12px',
    color: 'red',
  };

  function getButtonText(isSubmitting) {
    let buttonText;

    if (isSubmitting) {
      buttonText = 'Please wait...';
    } else if (actionType === 'addUser') {
      buttonText = 'ADD USER';
    } else {
      buttonText = 'UPDATE USER';
    }
    return buttonText;
  }

  return (
    <div>
      <Modal size="lg" show={show} onHide={handleClose} centered animation>
        <Modal.Header closeButton>
          <Modal.Title>
            {actionType === 'addUser' ? ' Add User' : 'Edit User'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={
              actionType === 'addUser' ? addUserSchema : updateUserSchema
            }
            enableReinitialize
            onSubmit={async (values, { setSubmitting }) => {
              const payload = payloadObj(values);
              if (actionType === 'addUser') {
                const res = await dispatch(createUser(payload));
                if (res.payload.status === 201) {
                  dispatch(usersList({ start: 0, limit: 10, columnSort: {} }));
                  notify('User created successfully');
                  setShow(false);
                  setSubmitting(false);
                } else {
                  notifyError(
                    res?.payload?.message
                      ? 'A user with this email address already exists.'
                      : 'Somthing went wrong'
                  );
                }
              } else {
                const res = await updateUser(payload.id, payload);
                if (res.status === 200) {
                  dispatch(usersList({ start: 0, limit: 10, columnSort: {} }));
                  notify('User updated successfully');
                  setShow(false);
                  setSubmitting(false);
                } else {
                  notifyError('Somthing went wrong');
                }
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Row>
                  <Col lg={6}>
                    <div className="form-group-login">
                      <label className="label-class">Email</label>
                      <Field
                        name="email"
                        className="form-control form-field"
                        type="text"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="errorClass"
                        style={errorMessageStyle}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group-login">
                      {actionType === 'addUser' && (
                        <>
                          {' '}
                          <label className="label-class">Password</label>
                          <Field
                            name="password"
                            className="form-control form-field"
                            type="text"
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="errorClass"
                            style={errorMessageStyle}
                          />
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div className="form-group-login mt-3">
                      <label className="label-class">Name</label>
                      <Field
                        name="name"
                        className="form-control form-field"
                        type="text"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="errorClass"
                        style={errorMessageStyle}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group-login mt-3">
                      <label className="label-class">Description</label>
                      <Field
                        name="description"
                        className="form-control form-field"
                        type="text"
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="errorClass"
                        style={errorMessageStyle}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div className="form-group-login mt-3">
                      <label className="label-class">User Type</label>
                      <div className="radio-box mt-2 mb-4">
                        <div
                          className="radio-main-div"
                          role="group"
                          aria-labelledby="my-radio-group"
                        >
                          <div className="model-radio-div">
                            <Field
                              type="radio"
                              name="role"
                              value="user"
                              className=" model-radio-margin"
                            />
                            <label className="radio-label mb-0">User</label>
                          </div>
                          <div className=" model-radio-div model-radio-margin">
                            <Field
                              type="radio"
                              name="role"
                              value="admin"
                              className=" model-radio-margin"
                            />
                            <label className="radio-label mb-0">Admin</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group-login mt-3">
                      <label className="label-class">Status</label>
                      <div className="radio-box mt-2 mb-4">
                        <div
                          className="radio-main-div"
                          role="group"
                          aria-labelledby="my-radio-group"
                        >
                          <div className="model-radio-div">
                            <Field
                              type="radio"
                              name="status"
                              value="active"
                              className=" model-radio-margin"
                            />
                            <label className="radio-label mb-0">Active</label>
                          </div>
                          <div className=" model-radio-div model-radio-margin">
                            <Field
                              type="radio"
                              name="status"
                              value="inactive"
                              className=" model-radio-margin"
                            />
                            <label className="radio-label mb-0">Inactive</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div className="form-group-login">
                      <label className="label-class">User Roles</label>
                      <div className="radio-box">
                        <table>
                          <tbody className="adduser-model-table">
                            <tr>
                              <td scope="row">1.</td>
                              <td>
                                <label className="radio-label ml-5 mb-0">
                                  Data Ingest{' '}
                                </label>
                              </td>

                              <td>
                                <Field
                                  type="checkbox"
                                  name="userRoles.data_ingest"
                                  className=" model-radio-margin"
                                  style={{ width: '1rem', height: '1rem' }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td scope="row">2.</td>
                              <td className="">
                                <label className="radio-label ml-5 mb-0">
                                  Drilldown
                                </label>
                              </td>
                              <td>
                                <Field
                                  type="checkbox"
                                  name="userRoles.drilldown"
                                  className=" model-radio-margin"
                                  style={{ width: '1rem', height: '1rem' }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td scope="row">3.</td>
                              <td>
                                <label className="radio-label ml-5 mb-0">
                                  Home Review
                                </label>
                              </td>
                              <td>
                                <Field
                                  type="checkbox"
                                  name="userRoles.homes_review"
                                  className=" model-radio-margin"
                                  style={{ width: '1rem', height: '1rem' }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td scope="row">4.</td>
                              <td>
                                <label className="radio-label ml-5 mb-0">
                                  Data Update
                                </label>
                              </td>
                              <td>
                                <Field
                                  type="checkbox"
                                  name="userRoles.data_update"
                                  className=" model-radio-margin"
                                  style={{ width: '1rem', height: '1rem' }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td scope="row">5.</td>
                              <td>
                                <label className="radio-label ml-5 mb-0">
                                  Characteristic
                                </label>
                              </td>
                              <td>
                                <Field
                                  type="checkbox"
                                  name="userRoles.characteristic"
                                  className=" model-radio-margin"
                                  style={{ width: '1rem', height: '1rem' }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td scope="row">6.</td>
                              <td>
                                <label className="radio-label ml-5 mb-0">
                                  Survey
                                </label>
                              </td>
                              <td>
                                <div className="radio-box">
                                  <div
                                    className="radio-main-div"
                                    role="group"
                                    aria-labelledby="my-radio-group"
                                  >
                                    <div className="model-radio-div">
                                      <Field
                                        type="radio"
                                        name="survey"
                                        value="full"
                                        className=" model-radio-margin"
                                      />
                                      <label className="radio-label mb-0">
                                        Full
                                      </label>
                                    </div>
                                    <div className=" model-radio-div model-radio-margin">
                                      <Field
                                        type="radio"
                                        name="survey"
                                        value="readonly"
                                        className=" model-radio-margin"
                                      />
                                      <label className="radio-label mb-0">
                                        Survey
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="form-group-login panel__forms ">
                  <button
                    type="submit"
                    className="allbutton update-btn"
                    disabled={isSubmitting}
                  >
                    {getButtonText(isSubmitting)}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

UserModal.propTypes = {
  show: propTypes.bool.isRequired,
  setShow: propTypes.func.isRequired,
  actionType: propTypes.string.isRequired,
  initVals: propTypes.func.isRequired,
};

export default UserModal;
