import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import propTypes from 'prop-types';

const ConfirmModal = ({ heading, message, show, setShow, onConfirm }) => {
  const handleYes = () => {
    setShow(false);
    onConfirm();
  };
  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className="allbutton"
          onClick={handleYes}
          style={{ height: 40, fontSize: 16 }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  heading: propTypes.string.isRequired,
  message: propTypes.string.isRequired,
  show: propTypes.bool.isRequired,
  setShow: propTypes.func.isRequired,
  onConfirm: propTypes.func.isRequired,
};

export default ConfirmModal;
