/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import MyMapComponent from '../../component/Map';
import NextGenDataTable from '../../component/NextGenBsTable';
import {
  getSurveyMapCities,
  getSurveyMapHistory,
  getSurveyMapZips,
  saveUserMapHistory,
} from '../../redux/survey/api';
import { mapHistoryColumns } from '../../utils/columns';
import {
  updateCurrentSurveyTab,
  updateSurveyTabNavigationData,
} from '../../redux/navigation/navigationSlice';
import { getItem, setItem } from '../../component/services/localStorage';

const TabMap = () => {
  // fetching all local storage variables
  const surveyTypeFromLocalStorage = getItem('MapTab-surveyType');
  const toppingFromLocalStorage = getItem('MapTab-selectedTopping');

  const [radioState, setRadioState] = useState(
    toppingFromLocalStorage !== null
      ? {
          city: toppingFromLocalStorage === 'City',
          zip: toppingFromLocalStorage === 'Zip',
        }
      : {
          city: true,
          zip: false,
        }
  );
  const [surveyType, setSurveyType] = useState(
    parseInt(surveyTypeFromLocalStorage) || 0
  );
  const [activeKey, setActiveKey] = useState('Map');
  const [surveyTypeData, setSurveyTypeData] = useState(
    parseInt(surveyTypeFromLocalStorage) === 1 ? 'MySurvey' : 'AllSurvey'
  );
  const [topping, setTopping] = useState(toppingFromLocalStorage || 'City');
  const [selectedSurvey, setSelectedSurvey] = useState({});

  const dispatch = useDispatch();
  const surveyMapZip = useSelector(state => state.ranking.surveyMapZips) || [];
  const surveyMapCity =
    useSelector(state => state.ranking.surveyMapCities) || [];
  const surveyMapHistory =
    useSelector(state => state.ranking.surveyMapHistory) || [];

  useEffect(() => {
    setItem('MapTab-surveyType', surveyType);
    setItem('MapTab-selectedTopping', topping);
  }, [surveyType, topping]);

  useEffect(() => {
    if (radioState.city) {
      dispatch(
        getSurveyMapCities({
          surveyType,
          SurveyID: selectedSurvey?.datasurveys_id || null,
        })
      );
    } else {
      dispatch(
        getSurveyMapZips({
          surveyType,
          SurveyID: selectedSurvey?.datasurveys_id || null,
        })
      );
    }
  }, [radioState, surveyType, selectedSurvey]);
  useEffect(() => {
    dispatch(getSurveyMapHistory());
  }, []);

  const onCircleClick = async row => {
    dispatch(
      updateSurveyTabNavigationData({
        ...row,
        SurveyType: row?.ZIPCode ? 2 : 1,
      })
    );
    dispatch(updateCurrentSurveyTab('Survey'));
    await saveUserMapHistory({ UserID: row.UserID, SurveyID: row.SurveyID });
  };

  const surveyTypeDataChange = e => {
    // setResetPage(1)
    setSurveyTypeData(e.target.value);
    if (e.target.value === 'AllSurvey') {
      setSurveyType(0);
    } else {
      setSurveyType(1);
    }
  };

  const onOptionChange = e => {
    // setResetPage(1)
    setSelectedSurvey({});
    setTopping(e.target.value);
    if (e.target.value === 'City') setRadioState({ city: true, zip: false });
    if (e.target.value === 'Zip') setRadioState({ city: false, zip: true });
  };

  const onClickRow = (e, row) => {
    setSelectedSurvey(row);
    if (row?.survey_type === 1) {
      setTopping('City');
      setRadioState({ city: true, zip: false });
    } else {
      setTopping('Zip');
      setRadioState({ city: false, zip: true });
    }
    setActiveKey('Map');
  };

  return (
    <div>
      <Container className="p-3">
        <Row>
          <Tabs
            activeKey={activeKey}
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={e => {
              setActiveKey(e);
            }}
          >
            <Tab eventKey="Map" title="Map">
              <Row>
                <Col xs={4} className="flex-style-map">
                  <div className="margin-bottom-for-map-radio">
                    <b>Show:</b>
                  </div>

                  <div className="flex-style-map">
                    <label className="cursor-pointer" htmlFor="AllSurveymap">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        name="AllSurveymap"
                        value="AllSurvey"
                        id="AllSurveymap"
                        checked={surveyTypeData === 'AllSurvey'}
                        onChange={surveyTypeDataChange}
                      />
                      All Surveys
                    </label>

                    <label className="cursor-pointer" htmlFor="MySurveyMap">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        name="AllSurveymap"
                        value="MySurvey"
                        id="MySurveyMap"
                        checked={surveyTypeData === 'MySurvey'}
                        onChange={surveyTypeDataChange}
                      />
                      My Surveys
                    </label>
                  </div>
                </Col>
                <Col xs={6} className="flex-style-map">
                  <div className="margin-bottom-for-map-radio">
                    <b>Geography:</b>
                  </div>

                  <div className="flex-style-map">
                    <label className="cursor-pointer" htmlFor="CityMap">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        name="toppingMap"
                        value="City"
                        id="CityMap"
                        checked={topping === 'City'}
                        onChange={onOptionChange}
                      />
                      Group by cities
                    </label>

                    <label className="cursor-pointer" htmlFor="ZipMap">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        name="toppingMap"
                        value="Zip"
                        id="ZipMap"
                        checked={topping === 'Zip'}
                        onChange={onOptionChange}
                      />
                      Show individual ZIP Codes
                    </label>
                  </div>
                </Col>
              </Row>
              {/* --- */}
              <Row>
                <MyMapComponent
                  coordinates={radioState.city ? surveyMapCity : surveyMapZip}
                  selectedSurvey={selectedSurvey}
                  onCircleClick={onCircleClick}
                />
              </Row>
            </Tab>
            <Tab eventKey="History" title="History">
              <>
                <h3>History</h3>
                <Row className="mt-2">
                  <Col xs={8}>
                    <div className="geo-table-div">
                      <NextGenDataTable
                        columns={mapHistoryColumns}
                        data={surveyMapHistory}
                        keyField="id"
                        hideSizePerPage
                        enableSearching={false}
                        onClickRow={onClickRow}
                        hoverClass="row-hoverclass"
                        remote={false}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            </Tab>
          </Tabs>
        </Row>
      </Container>
    </div>
  );
};

export default TabMap;
