import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFilterOptions,
  setSelectedFilterOptions,
  setListAndOptions,
} from '../redux/filters/filterSlice';
import {
  objectsToQueryParams,
  queryParamsToObject,
} from '../utils/queryParams';

const ALLOWED_FILTERS = {
  characteristicsdrilldown: [
    'BaselineMedianAnnualRevenue',
    'BaselineMedianADR',
    'BaselineOccupancyPercent',
    'RelativeUnderperformance',
    'RelativePerformance',
  ],
  drilldown: [
    'BaselineMedianADR',
    'StateAbbr',
    'ZIPCode',
    'City',
    'BaselineROI',
    'BaselineMedianAnnualRevenue',
    'MeetsInvestmentCriteria_Baseline',
    'MeetsInvestmentCriteria_Optimized',
    'BaselineMax_Purchase_Price',
  ],
  ranking: [
    'OptimizedADR',
    'MeetsInvestmentCriteria_Baseline',
    'MeetsInvestmentCriteria_Optimized',
    'StateAbbr',
    'City',
    'ZIPCode',
    'PercentileRank',
    'Revenue_Baseline',
    'Revenue_Optimized',
    'BaselineADR',
    'BaselineOccupancyPercent',
    'OptimizedOccupancyPercent',
    'BaselineROI',
    'OptimizedROI',
    'BaselineMax_Purchase_Price',
    'OptimizedMax_Purchase_Price',
  ],
  homesreview: [
    'ZIPCode',
    'Segment',
    'StateAbbr',
    'City',
    'AnnualRevenue',
    'HomeReviewMinimumADR',
    'BaselineOccupancyPercent',
    'Keywords',
    'Superhost',
    'CancellationPolicyText',
    'PetsAllowed',
    'OverallRating',
    'NumberofPhotos',
    'RelativeUnderperformance',
    'RelativeOverperformance',
  ],
  survey: [
    'MeetsInvestmentCriteria_Baseline',
    'MeetsInvestmentCriteria_Optimized',
    'StateAbbr',
    'City',
    'ZIPCode',
    'PercentileRank',
    'OptimizedProjectedRevenue',
    'BaselineMedianADR',
    'OptimizedADR',
    'BaselineOccupancyPercent',
    'OptimizedOccupancyPercent',
    'BaselineROI',
    'OptimizedROI',
    'BaselineMax_Purchase_Price',
    'OptimizedMax_Purchase_Price',
    'BaselineMedianAnnualRevenue',
  ],
  dataupdate: [
    'HomesCountInCity',
    'BaselineMedianADR',
    'OptimizedADR',
    'StateAbbr',
    'City',
    'ZIPCode',
    'OptimizedROI',
    'BaselineROI',
    'BaselineMedianAnnualRevenue',
    'OptimizedProjectedRevenue',
    'MeetsInvestmentCriteria_Baseline',
    'MeetsInvestmentCriteria_Optimized',
    'BaselineMax_Purchase_Price',
    'OptimizedMax_Purchase_Price',
    'HomesCountInZIP',
    'HomesCountInCity',
    'Revenue_Baseline',
    'Revenue_Optimized',
    'OptimizedADR',
  ],
};

const useFilters = (props = {}) => {
  const dispatch = useDispatch();
  const moduleName = props?.moduleName || null;
  const options = useSelector(state => state?.filters.selectOption);
  const selectedFilterOption = useSelector(
    state => state?.filters.selectedFilterOption
  );
  const [oldQS, setOldQS] = useState('');

  const selectOption = useMemo(() => {
    if (moduleName && Reflect.has(ALLOWED_FILTERS, moduleName)) {
      return options.filter(option =>
        ALLOWED_FILTERS[moduleName].includes(option)
      );
    }
    return options;
  }, [moduleName, options]);

  const newQueryString = objectsToQueryParams(
    selectedFilterOption,
    selectOption
  );

  const updateClickBackFilters = () => {
    const { result, optionKeys } = queryParamsToObject(window.location.search);
    dispatch(setListAndOptions({ list: optionKeys, options: result }));
  };

  useEffect(() => {
    if (oldQS !== newQueryString) {
      const newUrl =
        newQueryString.length > 0
          ? `${window.location.pathname}?${newQueryString}`
          : window.location.pathname;
      window.history.replaceState({}, '', newUrl);
      setOldQS(newQueryString);
    }
  }, [newQueryString]);

  return {
    selectOption,
    selectedFilterOption,
    setFilterOptions,
    setSelectedFilterOptions,
    updateClickBackFilters,
  };
};

export default useFilters;
