import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createOrUpdateSurvey } from '../../redux/survey/api';

const DataUpdateModal = props => {
  const { show, handleClose, geo } = props;
  const [checkbox, setCheckedBox] = useState(false);
  const [updateFrequency, setUpdateFrequecy] = useState(0);
  const [toggle, setIsToggle] = useState(false);
  const userInfo = useSelector(state => state.user.userInfo);
  const {
    MarketName,
    DataQuality,
    listing_update_frequency: listingUpdateFrequency,
    SurveyType,
    City,
    ZIPCode,
    StateAbbr,
    DataSurveyID,
    SurveyDate,
  } = props?.surveyDataForUpdate || {};
  const actionType = props?.surveyDataForUpdate?.type;
  useEffect(() => {
    setIsToggle(
      !!(DataQuality === 'Survey' || DataQuality === '' || DataQuality === 1)
    );
    setUpdateFrequecy(listingUpdateFrequency);
  }, [DataQuality, listingUpdateFrequency]);

  const changeCountryType = e => {
    if (e.target.value) {
      // setOption(e.target.value);
      setUpdateFrequecy(e.target.value);
    }
  };
  const handleGetData = async () => {
    handleClose();
    let data = {
      SurveyID: DataSurveyID || 0,
      UserID: userInfo.id,
      SurveyType: geo === 'City' ? 1 : 2,
      DataQuality: toggle ? 1 : 2,
      City: City || '',
      StateAbbr: StateAbbr || '',
      ZIPCodeList: ZIPCode || '',
      DataUpdateFrequency: updateFrequency ? parseInt(updateFrequency, 10) : 0,
      MarketName,
    };
    if (SurveyType === 1) {
      data = { ...data, updateAllZipForCity: checkbox };
    }

    props?.setOnClickUpdateBtn(prev => [...prev, data]);

    const res = await createOrUpdateSurvey(data);
    if (res.status === 200) {
      const { payload } = res.data;
      if (payload?.SurveyType === 1) {
        props?.setOnClickUpdateBtn(prev =>
          prev?.filter(element => element.MarketName !== payload.MarketName)
        );
      } else {
        props?.setOnClickUpdateBtn(prev =>
          prev?.filter(element => element.MarketName !== payload.MarketName)
        );
      }
    }
    //  await  props?.fetchDataFunction()
  };
  const DataQualityLabel = () => {
    const dataQualityLookup = {
      Survey: ['Survey', 0, 1],
      Full: [2],
    };

    const result = Object.entries(dataQualityLookup).find(([, values]) =>
      values.includes(DataQuality)
    );

    return result ? result[0] : '-';
  };

  return (
    <Container className="p-3">
      <Row className="mb-2">
        <Col xs={12}>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                {actionType === 'GET'
                  ? 'New Data Retrieval Request'
                  : 'Update Request'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="divFlex-model mb-1">
                {' '}
                <div className="flex-model-child">
                  <b>Geography: </b>
                </div>{' '}
                <div>{MarketName}</div>
              </div>
              {actionType === 'Update' && (
                <div>
                  <div className="divFlex-model mb-1">
                    {' '}
                    <div className="flex-model-child">
                      <b>Last Update Type: </b>
                    </div>{' '}
                    <div>{DataQualityLabel()}</div>
                  </div>
                  <div>
                    <div className="divFlex-model mb-1">
                      {' '}
                      <div className="flex-model-child">
                        <b>Last Update Date:</b>
                      </div>{' '}
                      <div> {moment(SurveyDate).format('L')}</div>
                    </div>
                  </div>
                </div>
              )}
              {DataQuality === 2 && (
                <div className="divFlex-model mb-1">
                  {' '}
                  <div className="flex-model-child">
                    <b>Update Frequency: </b>
                  </div>{' '}
                  <div>{updateFrequency || 'Full'}</div>
                </div>
              )}
              <div className="divFlex-model ">
                {DataQuality === 'Survey' ||
                DataQuality === 0 ||
                DataQuality === 1 ? (
                  <div className="divFlex-model mt-2">
                    {' '}
                    <div className="flex-model-child">
                      <b> Data Quality: </b>{' '}
                    </div>{' '}
                    <div>
                      {' '}
                      <button
                        type="button"
                        className={toggle ? 'active-survey' : 'deactive-full'}
                        onClick={() => setIsToggle(!toggle)}
                      >
                        <span className="activedeactive"> Survey</span>
                      </button>
                      <button
                        type="button"
                        className={toggle ? 'deactive-full' : 'active-survey'}
                        onClick={() => setIsToggle(!toggle)}
                      >
                        <span className="activedeactive">Full</span>
                      </button>
                    </div>{' '}
                  </div>
                ) : null}
              </div>
              {geo === 'Zip' && SurveyType === 1 && (
                <div className="pt-2">
                  <Form.Check
                    type="checkbox"
                    onChange={e => {
                      setCheckedBox(e.target.checked);
                    }}
                    className="update-checkbox"
                    label={`Update all ZIPs in ${City}`}
                  />
                </div>
              )}
              {DataQuality === 'Survey' ||
                (DataQuality === 1 && (
                  <div className="pt-1 auto-update-div">
                    <div className="divFlex-model mt-2">
                      <div className="flex-model-child">
                        <b> Auto Update: </b>
                      </div>
                      <div>
                        <Form.Select
                          onChange={changeCountryType}
                          na="true"
                          className="auto-update-select"
                          value={updateFrequency}
                        >
                          <option value="0">Never</option>
                          <option value="7">Weekly</option>
                          <option value="14">Every 14 days</option>
                          <option value="30">Monthly</option>
                          <option value="90">Quarterly</option>
                          <option value="365">Daily</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                ))}
              {!toggle && (
                <div className="mt-4">
                  <b>Update with a full data set?</b>
                </div>
              )}
              {/* <div className="mt-4">
                <b>{!toggle ? 'Update with a full data set?' : '' }</b>
              </div> */}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                className="allbutton"
                onClick={handleGetData}
                style={{ height: 40, fontSize: 14 }}
              >
                {actionType === 'GET' ? 'Get Data' : 'Update'}
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

DataUpdateModal.propTypes = {
  surveyDataForUpdate: propTypes.shape({
    MarketName: propTypes.string.isRequired,
    DataQuality: propTypes.string.isRequired,
    listingUpdateFrequency: propTypes.number.isRequired,
    SurveyType: propTypes.number.isRequired,
    City: propTypes.string,
    ZIPCode: propTypes.string,
    StateAbbr: propTypes.string,
    DataSurveyID: propTypes.number,
    UserID: propTypes.number,
    SurveyDate: propTypes.string.isRequired,
    UserId: propTypes.number.isRequired,
    type: propTypes.string.isRequired,
  }).isRequired,
  show: propTypes.bool.isRequired,
  handleClose: propTypes.func.isRequired,
  geo: propTypes.string.isRequired,
  setOnClickUpdateBtn: propTypes.func.isRequired,
};

export default DataUpdateModal;
