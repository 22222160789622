import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Check from '../../assets/images/check.png';
import Cross from '../../assets/images/cross.png';
import Logo from '../../assets/images/logo.svg';
import ModelPop from '../../component/ModelPop/ModelPop';

const Account = () => {
  const user = useSelector(state => state.user);
  const [show, setShow] = useState(false);
  const [modelType, setModelType] = useState('');
  const [modelHeading, setModelHeading] = useState('');

  return (
    <>
      <Container className="p-3">
        {user?.userInfo?.id ? (
          <>
            {' '}
            <section className="h-100 gradient-custom-2">
              <div className="container  h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="card-main-div">
                    <div className="user-card-div">
                      <div className="info-main-div">
                        <div className="acc-img-div">
                          <div className="img-container">
                            <img
                              src={Logo}
                              alt="Logo"
                              className="login-img-class"
                            />
                          </div>
                        </div>
                        <div className=" text-white  text-div">
                          <h5> {user?.userInfo?.userRoles?.name}</h5>
                          <p>{user?.userInfo?.userRoles?.description}</p>
                        </div>
                      </div>
                      <div className="p-3 text-black button-main-div">
                        <button
                          type="button"
                          className="btn btn-outline-dark"
                          data-mdb-ripple-color="dark"
                          style={{ zIndex: 1 }}
                          onClick={() => {
                            setModelType('updateProfile');
                            setModelHeading('Update Profile');
                            setShow(true);
                          }}
                        >
                          Edit profile
                        </button>

                        <button
                          type="button"
                          className="btn btn-outline-dark"
                          data-mdb-ripple-color="dark"
                          style={{ zIndex: 1, marginLeft: 20 }}
                          onClick={() => {
                            setModelType('updatePassword');
                            setModelHeading('Change Password');
                            setShow(true);
                          }}
                        >
                          Change Password
                        </button>
                      </div>
                      <div className="card-body p-4 text-black">
                        <div className="mb-5">
                          <p className="lead fw-normal mb-1">Roles</p>
                          <div
                            className="p-4"
                            style={{ background: '#f8f9fa', display: 'flex' }}
                          >
                            <div>
                              <p className="font-italic mb-0">Data Ingest </p>
                              <p className="font-italic mb-0">Data Update </p>
                              <p className="font-italic mb-0">Drilldown</p>
                              <p className="font-italic mb-0">Homes Review</p>
                              <p className="font-italic mb-0">Survey Full</p>
                              <p className="font-italic mb-0">
                                Survey Readonly
                              </p>
                              <p className="font-italic mb-0">Characteristic</p>
                            </div>
                            <div style={{ marginLeft: 30 }}>
                              <p className="font-italic mb-0">
                                {user?.userInfo?.userRoles?.data_ingest ? (
                                  <img src={Check} alt="" />
                                ) : (
                                  <img src={Cross} alt="" />
                                )}{' '}
                              </p>
                              <p className="font-italic mb-0">
                                {user?.userInfo?.userRoles?.data_update ? (
                                  <img src={Check} alt="" />
                                ) : (
                                  <img src={Cross} alt="" />
                                )}
                              </p>
                              <p className="font-italic mb-0">
                                {user?.userInfo?.userRoles?.drilldown ? (
                                  <img src={Check} alt="" />
                                ) : (
                                  <img src={Cross} alt="" />
                                )}
                              </p>
                              <p className="font-italic mb-0">
                                {user?.userInfo?.userRoles?.homes_review ? (
                                  <img src={Check} alt="" />
                                ) : (
                                  <img src={Cross} alt="" />
                                )}
                              </p>
                              <p className="font-italic mb-0">
                                {user?.userInfo?.userRoles?.surveys_full ? (
                                  <img src={Check} alt="" />
                                ) : (
                                  <img src={Cross} alt="" />
                                )}
                              </p>
                              <p className="font-italic mb-0">
                                {user?.userInfo?.userRoles?.surveys_readonly ? (
                                  <img src={Check} alt="" />
                                ) : (
                                  <img src={Cross} alt="" />
                                )}
                              </p>
                              <p className="font-italic mb-0">
                                {user?.userInfo?.userRoles?.characteristic ? (
                                  <img src={Check} alt="" />
                                ) : (
                                  <img src={Cross} alt="" />
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <h3 className="data-loading">Loading...</h3>
        )}
      </Container>
      <ModelPop
        show={show}
        setShow={setShow}
        type={modelType}
        modelHeading={modelHeading}
        profileInitialValues={{
          name: user?.userInfo?.userRoles?.name || '',
          description: user?.userInfo?.userRoles?.description || '',
        }}
      />
    </>
  );
};

export default Account;
