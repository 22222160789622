import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  clearTodaysListAsync,
  getShortListAsync,
  getTodaysListAsync,
  makeOffersAsync,
  replaceShortListAsync,
  rescindOffersAsync,
  updateRankBulkAsync,
  upsertTodaysListItemAsync,
  removeTodaysListItemsAsync,
} from '../../redux/fieldAnalysis/fieldAnalysisApi';
import { notify, notifyError } from '../Toaster';
import axiosConfig from '../../redux/axiosConfig';
import useUnsavedChanges from '../../hooks/useUnsavedChanges';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  bgcolor: 'background.paper',
  border: '0 solid #000',
  borderRadius: '8px',
  boxShadow: 4,
  p: 2,
};

const FATTable = ({
  properties,
  title = '',
  columns = [],
  hideCheckbox,
  loading = false,
  context = '',
  onRowClick = () => {},
  onAction = () => {},
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [propertiesPerPage] = useState(10);

  const firstIndex = (currentPage - 1) * propertiesPerPage;
  const lastIndex = Math.min(firstIndex + propertiesPerPage, properties.length);
  const currentProperties = properties.slice(firstIndex, lastIndex);

  const [selectedProperties, setSelectedProperties] = useState([]);

  const [action, setAction] = useState('');

  const user = useSelector(state => state.user);

  const [open, setOpen] = React.useState(false);

  // Today's List
  const [clearList, setClearList] = useState(false);
  const [addToList, setAddToList] = useState(false);
  const [removeFromList, setRemoveFromList] = useState(false);
  const [sortList, setSortList] = useState(false);

  // Current offers
  const [offeredProperties, setOfferedProperties] = useState([]);

  const { handleUnsavedChanges } = useUnsavedChanges();

  // const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (action !== 'print') {
      setSelectedProperties([]);
      setOpen(false);
      handleUnsavedChanges(false);
    }
  };

  const generatePDF = (propertyList, pdfTitle) => {
    setOpen(true);
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    axiosConfig
      .post('/fat/print', {
        selectedProperties: propertyList,
        title: pdfTitle,
        preparedBy: user.userInfo.user_name,
        datePreparedString: new Date().toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        }),
      })
      .then(res => {
        const { data: { Location } = {} } = res;
        const newWin = window.open(Location, '_blank');
        if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
          notifyError('Please allow pop-ups for this website');
        }
      })
      .catch(err => {
        notifyError(err.message);
      })
      .finally(() => {
        setSelectedProperties([]);
        setOpen(false);
      });
  };

  const handleAction = e => {
    const selectedAction = e.target.value;
    setAction(selectedAction);
    if (
      ['today', 'shortlist', 'rank', 'make_offer', 'rescind_offer'].includes(
        selectedAction
      )
    ) {
      setOpen(true);
    }
    if (selectedAction === 'send') {
      navigate('/fat/send', { state: { selectedProperties, title } });
    }
    if (selectedAction === 'print') {
      // navigate('/fat/print', { state: { selectedProperties, title } });
      generatePDF(selectedProperties, title);
    }
  };

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
    setSelectedProperties([]);
  };

  const renderPageNumbers = () => {
    const items = [];
    for (
      let i = 1;
      i <= Math.ceil(properties.length / propertiesPerPage);
      i += 1
    ) {
      items.push(i);
    }
    return items.map(number => (
      <li className="page-item" key={number}>
        <button
          type="button"
          className="page-link"
          onClick={() => paginate(number)}
          disabled={number === currentPage}
        >
          {number}
        </button>
      </li>
    ));
  };

  useEffect(() => {
    if (selectedProperties.length === 0) {
      setAction('');
    }
  }, [selectedProperties]);

  const [todaysList, setTodaysList] = useState([]);
  const [shortList, setShortList] = useState([]);

  useEffect(() => {
    getTodaysListAsync()
      .then(setTodaysList)
      .catch(err => notifyError(err.message));
    getShortListAsync()
      .then(setShortList)
      .catch(err => notifyError(err.message));
  }, []);

  const renderTodayModal = () => {
    const handleTodaysListAction = async () => {
      try {
        if (clearList) {
          await clearTodaysListAsync();
          notify('The list has been cleared');
          if (onAction) {
            onAction('clearList');
          }
        }
        if (addToList) {
          await upsertTodaysListItemAsync(selectedProperties);
          notify('The list has been updated');
        }
        if (removeFromList) {
          await removeTodaysListItemsAsync(selectedProperties);
          notify('The list has been updated');
          if (onAction) {
            onAction('clearList');
          }
        }
        if (sortList) {
          await navigate('/fat/todayslist', { state: { sortList } });
          if (onAction) {
            onAction('sortList');
          }
        }
      } catch (error) {
        notifyError(error.message);
      }
      handleClose();
    };

    return (
      <>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ color: 'rgba(0, 111, 255, 1)', fontSize: '18px' }}
        >
          Today&apos;s list of homes to visit
        </Typography>
        <small>
          You have {todaysList?.length} homes in today&apos;s list. Your
          options:
        </small>
        <Stack>
          <FormControlLabel
            sx={{ fontSize: '12px' }}
            control={
              <Checkbox onChange={e => setClearList(e.target.checked)} />
            }
            label="Clear the existing list"
          />
          {context !== 'todays_list' && (
            <FormControlLabel
              sx={{ fontSize: '12px' }}
              control={
                <Checkbox onChange={e => setAddToList(e.target.checked)} />
              }
              label={`Add the selected ${selectedProperties.length} number of homes to today’s list`}
            />
          )}
          {context === 'todays_list' && (
            <FormControlLabel
              sx={{ fontSize: '12px' }}
              control={
                <Checkbox onChange={e => setRemoveFromList(e.target.checked)} />
              }
              label={`Remove the selected ${selectedProperties.length} number of homes from today’s list`}
            />
          )}
          <FormControlLabel
            control={<Checkbox onChange={e => setSortList(e.target.checked)} />}
            label="Sort the list"
          />
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleTodaysListAction}
            >
              OK
            </Button>
          </Stack>
        </Stack>
      </>
    );
  };

  const [rank, setRank] = useState('');

  const renderShortlistModal = () => {
    const handleShortlistAction = async () => {
      try {
        await replaceShortListAsync(selectedProperties);
        notify('The list has been updated');
      } catch (error) {
        notifyError(error.message);
      }
      if (context === 'shortlist' && onAction) {
        onAction('refresh');
      } else {
        getShortListAsync()
          .then(setShortList)
          .catch(err => notifyError(err.message));
      }
      handleClose();
    };
    const shortListIDs = shortList?.map(s => s.ID);
    return (
      <>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ color: 'rgba(0, 111, 255, 1)', fontSize: '18px' }}
        >
          Shortlist On/Off
        </Typography>
        <small>
          This will add{' '}
          {selectedProperties?.filter(p => !shortListIDs?.includes(p)).length}{' '}
          to the shortlist
        </small>
        <br />
        <small>
          This will remove{' '}
          {shortList?.filter(s => selectedProperties?.includes(s.ID)).length}{' '}
          from the shortlist
        </small>
        <Stack>
          <div className="text-center pt-4 pb-4">Do you want to proceed?</div>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              onClick={handleShortlistAction}
            >
              OK
            </Button>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </>
    );
  };

  const renderRankModal = () => {
    const handleAddRankAction = async () => {
      try {
        await updateRankBulkAsync(selectedProperties, rank);
        setRank('');
        notify('The rank has been updated');
        handleClose();
        if (onAction) {
          onAction('refresh');
        }
      } catch (error) {
        notifyError(error.message);
      }
    };

    return (
      <>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ color: 'rgba(0, 111, 255, 1)', fontSize: '18px' }}
        >
          Add Rank
        </Typography>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>For the selected properties:</Typography>
            <FormControl sx={{ minWidth: 120, mb: 1 }} size="small">
              <InputLabel id="rank-select-small">Select</InputLabel>
              <Select
                labelId="rank-select-small"
                value={rank}
                label="Select"
                onChange={e => {
                  setRank(e.target.value);
                  handleUnsavedChanges(true);
                }}
              >
                <MenuItem value="1">1 (worst)</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="7">7</MenuItem>
                <MenuItem value="8">8</MenuItem>
                <MenuItem value="9">9</MenuItem>
                <MenuItem value="10">10 (best)</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              disabled={rank === ''}
              onClick={handleAddRankAction}
            >
              OK
            </Button>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </>
    );
  };

  const renderMakeOfferModal = () => {
    const offerProperties = [];
    currentProperties.forEach(property => {
      if (selectedProperties.includes(property.ID)) {
        offerProperties.push(property);
      }
    });

    const handleMakeOfferAction = async () => {
      try {
        if (offeredProperties.length > 0) {
          await makeOffersAsync(selectedProperties);
          setOfferedProperties([]);
          notify(
            selectedProperties.length === 1
              ? 'Offer for the property has been recorded'
              : 'Offers for the properties have been recorded'
          );
          handleClose();
        }
      } catch (error) {
        notifyError(error.message);
      }
    };

    return (
      <>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ color: 'rgba(0, 111, 255, 1)', fontSize: '18px' }}
        >
          Make Offer
        </Typography>
        <Typography variant="caption">
          These are the currently selected homes. Check or uncheck as needed and
          provide any notes in the space below.
        </Typography>
        <Stack spacing={2}>
          {offerProperties.map(property => (
            <Stack key={property.ID}>
              <FormControlLabel
                key={property.ID}
                sx={{ fontSize: '12px' }}
                control={<Checkbox />}
                label={property.Address}
                checked={offeredProperties.includes(property.ID)}
                onChange={e => {
                  if (e.target.checked) {
                    setOfferedProperties([...offeredProperties, property.ID]);
                  } else {
                    setOfferedProperties(
                      offeredProperties.filter(id => id !== property.ID)
                    );
                  }
                  handleUnsavedChanges(true);
                }}
              />
              <TextField
                key={`note-${property.ID}`}
                label="Notes"
                variant="filled"
                size="small"
                onChange={() => {
                  handleUnsavedChanges(true);
                }}
              />
            </Stack>
          ))}
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button variant="contained" color="primary">
              Print New Offers
            </Button>
            <Button variant="contained" color="primary">
              Print Current Offers
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleMakeOfferAction}
              disabled={offeredProperties.length === 0}
            >
              Save &amp; Close
            </Button>
          </Stack>
        </Stack>
      </>
    );
  };

  const renderRescindOfferModal = () => {
    const handleRescindOfferAction = async () => {
      try {
        await rescindOffersAsync(selectedProperties);
        notify(
          selectedProperties.length === 1
            ? 'The offer has been rescinded'
            : 'The offers have been rescinded'
        );
        handleClose();
        if (onAction) {
          onAction('rescindOffer');
        }
      } catch (error) {
        notifyError(error.message);
      }
    };

    return (
      <>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ color: 'rgba(0, 111, 255, 1)', fontSize: '18px' }}
        >
          Rescind Offers
        </Typography>
        <Stack spacing={2} className="pt-2">
          <Typography variant="body1">
            Are you sure you want to rescind offers for the selected addresses?
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              onClick={handleRescindOfferAction}
            >
              OK
            </Button>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </>
    );
  };

  const renderPrintModal = () => {
    return (
      <>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ color: 'rgba(0, 111, 255, 1)', fontSize: '18px' }}
        >
          Generating PDF
        </Typography>
        <Stack spacing={2} className="pt-2">
          <Typography variant="body1">
            Your download will begin soon. Please wait...
          </Typography>
        </Stack>
      </>
    );
  };

  const renderModalBody = () => {
    switch (action) {
      case 'today':
        return renderTodayModal();
      case 'shortlist':
        return renderShortlistModal();
      case 'rank':
        return renderRankModal();
      case 'make_offer':
        return renderMakeOfferModal();
      case 'rescind_offer':
        return renderRescindOfferModal();
      case 'print':
        return renderPrintModal();
      default:
        return null;
    }
  };

  return (
    <>
      {selectedProperties.length > 0 && (
        <div className="row">
          <div className="col-6">
            <FormControl sx={{ minWidth: 120, mb: 1 }} size="small">
              <InputLabel id="action-select-small">Action</InputLabel>
              <Select
                labelId="action-select-small"
                value={action}
                label="Action"
                onChange={handleAction}
              >
                <MenuItem value="today">Today&apos;s List</MenuItem>
                <MenuItem value="shortlist">Shortlist on/off</MenuItem>
                <MenuItem value="send">Send</MenuItem>
                <MenuItem value="print">Print PDFs</MenuItem>
                <MenuItem value="rank">Add Rank</MenuItem>
                <MenuItem value="make_offer">Make Offer</MenuItem>
                <MenuItem value="rescind_offer">Rescind Offer</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-6" />
        </div>
      )}
      {currentProperties && (
        <>
          {loading && (
            <div className="loading-screen">
              <div className="loading-spinner" />
            </div>
          )}{' '}
          <table id="recent-properties" className="table">
            <thead>
              <tr>
                {!hideCheckbox && (
                  <th>
                    <input
                      type="checkbox"
                      checked={
                        currentProperties.length > 0 &&
                        selectedProperties.length === currentProperties.length
                      }
                      onChange={e => {
                        if (e.target.checked) {
                          setSelectedProperties(
                            currentProperties.map(property => property.ID)
                          );
                        } else {
                          setSelectedProperties([]);
                        }
                      }}
                    />
                  </th>
                )}
                {columns.map(column => (
                  <th key={column.id}>{column.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentProperties.map(property => (
                <tr key={property.ID}>
                  {!hideCheckbox && (
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedProperties.includes(property.ID)}
                        onChange={e => {
                          if (e.target.checked) {
                            setSelectedProperties([
                              ...selectedProperties,
                              property.ID,
                            ]);
                          } else {
                            setSelectedProperties(
                              selectedProperties.filter(
                                id => id !== property.ID
                              )
                            );
                          }
                        }}
                      />
                    </td>
                  )}
                  {columns.map(column => (
                    <td
                      key={column.id}
                      onClick={() => onRowClick(property)}
                      className="cursor-pointer"
                    >
                      {column.format
                        ? column.format(
                            property[column.id] || column.default || ''
                          )
                        : property[column.id] || column.default || ''}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      <div className="row">
        <div className="col-6">
          <small>
            Showing {properties?.length ? firstIndex + 1 : 0} to {lastIndex} of{' '}
            {properties?.length}
          </small>
        </div>
        <div className="col-6">
          <nav aria-label="Page navigation">
            <ul className="pagination" style={{ float: 'right', margin: 0 }}>
              <li className="page-item">
                <button
                  type="button"
                  className="page-link"
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Back
                </button>
              </li>
              {renderPageNumbers()}
              <li className="page-item">
                <button
                  type="button"
                  className="page-link"
                  onClick={() => paginate(currentPage + 1)}
                  disabled={
                    currentPage ===
                    Math.ceil(properties.length / propertiesPerPage)
                  }
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{renderModalBody()}</Box>
      </Modal>
    </>
  );
};

FATTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  properties: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideCheckbox: PropTypes.bool,
  title: PropTypes.string,
  loading: PropTypes.bool,
  onRowClick: PropTypes.func,
  context: PropTypes.string,
  onAction: PropTypes.func,
};

FATTable.defaultProps = {
  hideCheckbox: false,
  title: '',
  loading: false,
  onRowClick: () => {},
  context: '',
  onAction: () => {},
};

export default FATTable;
