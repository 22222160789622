// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.segment-btn {
  padding: 0;
  font-size: 15px;
}

.segment-chip {
  font-size: 15px;
}

#notification-flash {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  text-decoration: none;
  background-color: red;
  color: white;
  width: 10px;
  height: 10px;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/component/FATHeader/fatHeader.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,YAAY;EACZ,qBAAqB;EACrB,qBAAqB;EACrB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".segment-btn {\n  padding: 0;\n  font-size: 15px;\n}\n\n.segment-chip {\n  font-size: 15px;\n}\n\n#notification-flash {\n  position: absolute;\n  top: 0;\n  right: 0;\n  z-index: 999;\n  text-decoration: none;\n  background-color: red;\n  color: white;\n  width: 10px;\n  height: 10px;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
