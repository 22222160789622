import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { useDispatch } from 'react-redux';
import { notify, notifyError } from '../component/Toaster';
import { login } from '../redux/user/api';
import LoginLogo from '../assets/images/loginLogo.png';
import { setIsUserLoggedIn } from '../redux/user/userSlice';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email Required'),
    password: Yup.string()
      .min(8, 'Too Short!')
      .max(20, 'Too Long!')
      .required('Password Required'),
  });

  return (
    <div>
      <div
        // component="form"
        // sx={{
        //   '& > :not(style)': { m: 1, width: '25ch' },
        // }}
        noValidate
        autoComplete="off"
        className="bg-img"
      >
        <div className="form-center">
          <div className="panel">
            <div className="panel__form-wrapper">
              <div className="panel__forms">
                <div className="field-logo">
                  <div className="form-side">
                    <Formik
                      initialValues={{ email: '', password: '' }}
                      validationSchema={SignupSchema}
                      onSubmit={async values => {
                        const ciphertext = await CryptoJS.AES.encrypt(
                          values.password,
                          process.env.REACT_APP_CryptoJS_SECRET
                        ).toString();
                        const res = await dispatch(
                          login({ ...values, password: ciphertext })
                        );
                        if (res?.payload?.token) {
                          dispatch(setIsUserLoggedIn(true));
                          localStorage.setItem('token', res?.payload?.token);
                          notify('Logged in Successfully!');
                          navigate('/dashboard');
                        } else {
                          notifyError('Login Failed!');
                        }
                      }}
                    >
                      {({ isSubmitting }) => (
                        <Form>
                          <div className="form-group-login">
                            <Field
                              name="email"
                              className="form-control form-field"
                              type="email"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="errorClass"
                            />
                          </div>
                          <div className="form-group-login panel__forms ">
                            <Field
                              name="password"
                              className="form-control form-field"
                              type="password"
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="errorClass"
                            />
                          </div>
                          <div className="mt-2">
                            <p className="text-white">Forgot Password?</p>
                          </div>
                          <div className="form-group-login panel__forms ">
                            <button
                              type="submit"
                              className="btn btn-primary submit-btn"
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? 'Please wait...' : 'Login'}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <div className="login-image-div">
                    <img className="login_logo" src={LoginLogo} alt="Logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
