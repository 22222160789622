// TODO: investigate this
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  getZipCodes,
  ranking,
  stateList,
  cityList,
  getSurveyDataUpdateByZips,
  getSurveyDataCities,
  getSurveyDataZips,
  getSurveyDataUpdateByCities,
  getSurveyMapCities,
  getSurveyMapZips,
  getSurveyMapHistory,
  getTrendsOccupancyROICities,
  getTrendsHomePriceCities,
  getTrendsOccupancyROIZips,
  getTrendsHomePriceZips,
  cityListRanking,
  cityListSurvey,
  cityListSurveyUpdate,
  zipListRanking,
  zipListSurvey,
  zipListSurveyUpdate,
  cityListRevADROccupancyROI,
  zipsListRevADROccupancyROI,
} from './api';

const initialState = {
  zipcodes: [],
  filters: {},
  sortings: {},
  ranking: [],
  rankingCount: 0,
  topTen: [],
  states: [],
  cityListRevADROccupancyROI: [],
  zipsListRevADROccupancyROI: [],
  cityListRanking: [],
  cityListSurvey: [],
  cityListDataUpdate: [],
  zipListRanking: [],
  zipListSurvey: [],
  zipListSurveyUpdate: [],
  surveyDataUpdateByZips: [],
  surveyDataUpdateByCities: [],
  surveyDataUpdateByCitiesCount: 0,
  surveyDataUpdateByZipsCount: 0,
  surveyMapCities: [],
  surveyMapCitiesLoader: false,
  surveyMapZips: [],
  surveyMapZipsLoader: false,
  surveyDataCities: [],
  surveyDataCitiesLoader: false,
  surveyDataCitiesCount: 0,
  surveyDataZips: [],
  surveyDataZipsLoader: false,
  surveyDataZipsCount: 0,
  surveyMapHistory: [],
  rankingLoader: false,
  surveyDataUpdateByZipLoader: false,
  surveyDataUpdateByCityLoader: false,
  trendsOccupancyROICities: [],
  trendsHomePriceCities: [],
  trendsHomePriceZips: [],
  trendsOccupancyROIZips: [],
  trendsOccupancyROIZipsLoader: false,
  currentSurveyTab: 'Ranking',
  trendsOccupancyROICitiesLoader: false,
};
export const rankingSlice = createSlice({
  name: 'ranking',
  initialState,
  reducers: {
    // toggleSurveyDataForLoading: (state, action) => {
    //   if (action.payload.SurveyType === 1) {
    //     const index = state.surveyDataCities.findIndex(
    //       item => item.id === action.payload.rowId
    //     );
    //     if (index !== -1) {
    //       state.surveyDataCities[index].isLoading =
    //         state.surveyDataCities[index].isLoading === undefined;
    //     }
    //   } else {
    //     const index = state.surveyDataZips.findIndex(
    //       item => item.id === action.payload.rowId
    //     );
    //     if (index !== -1) {
    //       state.surveyDataZips[index].isLoading =
    //         state.surveyDataZips[index].isLoading === undefined;
    //     }
    //   }
    // },
    updateCurrentSurvey: (state, action) => {
      state.currentSurveyTab = action.payload;
    },
  },
  extraReducers(builders) {
    builders.addCase(getZipCodes.fulfilled, (state, action) => {
      state.zipcodes = action.payload;
    });
    builders.addCase(ranking.fulfilled, (state, action) => {
      state.ranking = action.payload?.data;
      state.rankingLoader = false;
      state.rankingCount = action.payload.count;
      state.topTen = action.payload.topTen;
    });
    builders.addCase(ranking.pending, state => {
      state.rankingLoader = true;
      state.ranking = [];
      state.rankingCount = 0;
      state.topTen = [];
    });
    builders.addCase(ranking.rejected, state => {
      state.rankingLoader = true;
      state.ranking = [];
      state.rankingCount = 0;
      state.topTen = [];
    });
    builders.addCase(stateList.fulfilled, (state, action) => {
      state.states = action.payload;
    });
    builders.addCase(cityList.fulfilled, (state, action) => {
      state.city = action.payload;
    });
    // ----

    builders.addCase(cityListRanking.fulfilled, (state, action) => {
      state.cityListRanking = action.payload;
    });

    builders.addCase(cityListSurvey.fulfilled, (state, action) => {
      state.cityListSurvey = action.payload;
    });

    builders.addCase(cityListSurveyUpdate.fulfilled, (state, action) => {
      state.cityListDataUpdate = action.payload;
    });

    builders.addCase(cityListRevADROccupancyROI.fulfilled, (state, action) => {
      state.cityListRevADROccupancyROI = action.payload;
    });

    builders.addCase(zipsListRevADROccupancyROI.fulfilled, (state, action) => {
      state.zipsListRevADROccupancyROI = action.payload;
    });

    builders.addCase(zipListRanking.fulfilled, (state, action) => {
      state.zipListRanking = action.payload;
    });

    builders.addCase(zipListSurvey.fulfilled, (state, action) => {
      state.zipListSurvey = action.payload;
    });

    builders.addCase(zipListSurveyUpdate.fulfilled, (state, action) => {
      state.zipListSurveyUpdate = action.payload;
    });

    builders.addCase(getSurveyDataUpdateByZips.fulfilled, (state, action) => {
      state.surveyDataUpdateByZips = action.payload.data;
      state.surveyDataUpdateByZipLoader = false;
      state.surveyDataUpdateByZipsCount = action.payload.count;
    });
    builders.addCase(getSurveyDataUpdateByZips.pending, state => {
      state.surveyDataUpdateByZipLoader = true;
      state.surveyDataUpdateByZipsCount = 0;
      state.surveyDataUpdateByZips = [];
    });
    builders.addCase(getSurveyDataUpdateByZips.rejected, state => {
      state.surveyDataUpdateByZipLoader = true;
      state.surveyDataUpdateByZipsCount = 0;
      state.surveyDataUpdateByZips = [];
    });
    builders.addCase(getSurveyDataCities.fulfilled, (state, action) => {
      state.surveyDataCities = action.payload.data;
      state.surveyDataCitiesLoader = false;
      state.surveyDataCitiesCount = action.payload.count;
    });

    builders.addCase(getSurveyDataCities.pending, state => {
      state.surveyDataCitiesLoader = true;
      state.surveyDataCities = [];
      state.surveyDataCitiesCount = 0;
    });
    builders.addCase(getSurveyDataCities.rejected, state => {
      state.surveyDataCitiesLoader = false;
      state.surveyDataCities = [];
      state.surveyDataCitiesCount = 0;
    });

    builders.addCase(getSurveyDataZips.fulfilled, (state, action) => {
      state.surveyDataZips = action.payload.data;
      state.surveyDataZipsLoader = false;
      state.surveyDataZipsCount = action.payload.count;
    });

    builders.addCase(getSurveyDataZips.pending, state => {
      state.surveyDataZipsLoader = true;
      state.surveyDataZips = [];
      state.surveyDataZipsCount = 0;
    });
    builders.addCase(getSurveyDataZips.rejected, state => {
      state.surveyDataZipsLoader = false;
      state.surveyDataZips = [];
      state.surveyDataZipsCount = 0;
    });

    builders.addCase(getSurveyDataUpdateByCities.fulfilled, (state, action) => {
      state.surveyDataUpdateByCities = action.payload.data;
      state.surveyDataUpdateByCityLoader = false;
      state.surveyDataUpdateByCitiesCount = action.payload.count;
    });
    builders.addCase(getSurveyDataUpdateByCities.pending, state => {
      state.surveyDataUpdateByCityLoader = true;
      state.surveyDataUpdateByCities = [];
      state.surveyDataUpdateByCitiesCount = 0;
    });
    builders.addCase(getSurveyDataUpdateByCities.rejected, state => {
      state.surveyDataUpdateByCityLoader = true;
      state.surveyDataUpdateByCities = [];
      state.surveyDataUpdateByCitiesCount = 0;
    });
    builders.addCase(getSurveyMapCities.pending, state => {
      state.surveyMapCities = [];
      state.surveyMapCitiesLoader = true;
    });
    builders.addCase(getSurveyMapCities.fulfilled, (state, action) => {
      state.surveyMapCities = action.payload;
      state.surveyMapCitiesLoader = false;
    });
    builders.addCase(getSurveyMapZips.pending, (state, action) => {
      state.surveyMapZips = action.payload;
      state.surveyMapZipsLoader = true;
    });
    builders.addCase(getSurveyMapZips.fulfilled, (state, action) => {
      state.surveyMapZips = action.payload;
      state.surveyMapZipsLoader = false;
    });
    builders.addCase(getSurveyMapHistory.fulfilled, (state, action) => {
      state.surveyMapHistory = action.payload;
    });
    builders.addCase(getTrendsOccupancyROICities.fulfilled, (state, action) => {
      state.trendsOccupancyROICities = action.payload;
      state.trendsOccupancyROICitiesLoader = false;
    });
    builders.addCase(getTrendsOccupancyROICities.pending, state => {
      state.trendsOccupancyROICitiesLoader = true;
    });

    builders.addCase(getTrendsHomePriceCities.fulfilled, (state, action) => {
      state.trendsHomePriceCities = action.payload;
    });

    builders.addCase(getTrendsHomePriceZips.fulfilled, (state, action) => {
      state.trendsHomePriceZips = action.payload;
    });

    builders.addCase(getTrendsOccupancyROIZips.fulfilled, (state, action) => {
      state.trendsOccupancyROIZips = action.payload;
      state.trendsOccupancyROIZipsLoader = false;
    });
    builders.addCase(getTrendsOccupancyROIZips.pending, state => {
      state.trendsOccupancyROIZipsLoader = true;
    });
  },
});

export const { updateCurrentSurvey } = rankingSlice.actions;

export default rankingSlice.reducer;
