import { useState, useEffect } from 'react';

const useUnsavedChanges = () => {
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = event => {
      if (unsavedChanges) {
        const message =
          'You have unsaved changes. Are you sure you want to leave?';
        // eslint-disable-next-line no-param-reassign
        (event || window.event).returnValue = message;
        return message;
      }
      return null;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);

  const handleUnsavedChanges = flag => {
    setUnsavedChanges(flag);
  };

  return { unsavedChanges, handleUnsavedChanges };
};

export default useUnsavedChanges;
