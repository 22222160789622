import { loadMessages } from '../../constants/loadMessages';

const getLoadProcessHandler = context => {
  const { setPercentage, setMessage, setLoadMessage } = context;
  const loadChange = ({ percentage, type, loadMessage }) => {
    if (loadMessage) {
      setPercentage(null);
      setLoadMessage(loadMessage);
    } else {
      setLoadMessage(null);
      setPercentage(percentage);
    }
    setMessage(loadMessages[type]);
  };
  return loadChange;
};

export default getLoadProcessHandler;
