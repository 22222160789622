import { Navigate } from 'react-router-dom';
import React from 'react';
import propTypes from 'prop-types';

const Protected = ({ isLoggedIn, children }) => {
  // const navigate = useNavigate();
  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }
  return children;
};

Protected.propTypes = {
  isLoggedIn: propTypes.bool.isRequired,
  children: propTypes.node.isRequired,
};

export default Protected;
