import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  InputAdornment,
  TextField,
  IconButton,
  Chip,
  Stack,
  Typography,
} from '@mui/material';
import images from '../../assets/images';
import {
  searchPropertyAsync,
  getSavedSearchesAsync,
} from '../../redux/fieldAnalysis/fieldAnalysisApi';
import {
  storeSavedSearch,
  deleteSavedSearch,
} from '../../redux/fieldAnalysis/fieldAnalysisSlice';
import { notifyError } from '../../component/Toaster';
import './newSearch.css';
import FATTable from '../../component/FATTable/FATTable';

const NewSearch = () => {
  const {
    location,
    loading,
    masterData: { propertyTypeGroupings },
  } = useSelector(state => state.fieldAnalysis);
  const [query, setQuery] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [showResult, setShowResult] = useState(false);
  const [showNotFound, setShowNotFound] = useState(false);
  const [properties, setProperties] = useState([]);
  const [searching, setSearching] = useState(false);
  const [savedSearches, setSavedSearches] = useState([]);
  const [selectedHomeType, setSelectedHomeType] = useState([]);
  const [homeTypeOptions, setHomeTypeOptions] = useState([]);
  const [savedSearchFound, setSavedSearchFound] = useState(false);
  const [showHomeType, setShowHomeType] = useState(false);
  const [searchParamUsed, setSearchParamUsed] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (savedSearches?.length === 0) {
      getSavedSearchesAsync()
        .then(setSavedSearches)
        .catch(error => {
          notifyError(error);
        });
    }
  }, []);

  useEffect(() => {
    if (propertyTypeGroupings.length > 0) {
      setHomeTypeOptions(
        propertyTypeGroupings.map(grouping => ({
          id: grouping.GroupIds,
          label: grouping.PropertyTypeGrouping,
        }))
      );
    }
  }, [propertyTypeGroupings]);

  useEffect(() => {
    if (query && query.length > 0) {
      setSavedSearchFound(
        savedSearches.some(search => {
          return search.SearchQuery?.toLowerCase() === query.toLowerCase();
        })
      );
    }
  }, [query, savedSearches]);

  const searchProperty = async (queryString, backNav = false) => {
    setSearching(true);
    setShowResult(false);
    setShowNotFound(false);
    setProperties([]);
    setShowHomeType(false);
    const strPropertyTypeStrings = homeTypeOptions
      .filter(homeType => {
        return selectedHomeType.includes(homeType.id);
      })
      .map(homeType => homeType.label)
      .join(',');
    try {
      const response = await searchPropertyAsync(
        queryString,
        location.geographyId,
        selectedHomeType.join(','),
        strPropertyTypeStrings,
        location.zipCodes.join(',')
      );
      if (response.propertyID === 0) {
        setStreetAddress(queryString);
        setShowNotFound(true);
      } else if (response.propertyID > 0 && !backNav) {
        navigate(`/fat/survey/${response.propertyID}`);
      } else if (Array.isArray(response)) {
        setProperties(response);
        setShowResult(true);
      }
    } catch (error) {
      notifyError(error.message);
    } finally {
      setSearching(false);
    }
  };

  const setSearchParamsAndSearch = queryString => {
    setSearchParams({ q: queryString });
    searchProperty(queryString);
  };

  useEffect(() => {
    if (
      searchParams.get('q') &&
      homeTypeOptions?.length > 0 &&
      selectedHomeType?.length > 0 &&
      !searchParamUsed
    ) {
      setSearchParamUsed(true);
      setQuery(searchParams.get('q'));
      searchProperty(searchParams.get('q'), true);
    }
  }, [searchParams, homeTypeOptions, selectedHomeType]);

  useEffect(() => {
    if (homeTypeOptions.length > 0) {
      const savedHomeTypes = localStorage.getItem('homeTypes');
      if (!savedHomeTypes || JSON.parse(savedHomeTypes).length === 0) {
        const singleFamily = homeTypeOptions.filter(
          ptg => ptg.label === 'Single Family'
        );
        if (singleFamily.length > 0) {
          localStorage.setItem(
            'homeTypes',
            JSON.stringify([singleFamily[0].id])
          );
          setSelectedHomeType([singleFamily[0].id]);
        }
      } else {
        setSelectedHomeType(JSON.parse(savedHomeTypes));
      }
    }
  }, [homeTypeOptions]);

  useEffect(() => {
    if (selectedHomeType?.length > 0) {
      localStorage.setItem('homeTypes', JSON.stringify(selectedHomeType));
    }
  }, [selectedHomeType]);

  const saveSearch = () => {
    dispatch(
      storeSavedSearch({
        SearchQuery: query,
        GeographyID: location.geographyId,
        PropertyTypeGroupIDs: selectedHomeType.join(','),
      })
    ).then(action => {
      if (action.payload) {
        getSavedSearchesAsync()
          .then(data => {
            setSavedSearches(data);
            setSavedSearchFound(true);
          })
          .catch(error => {
            notifyError(error.message);
          });
      }
      if (action.error) {
        notifyError(action.error.message);
      }
    });
  };

  const deleteSearch = id => {
    dispatch(deleteSavedSearch(id)).then(action => {
      if (action.payload) {
        setSavedSearches(savedSearches.filter(s => s.id !== id));
        setSavedSearchFound(false);
      }
      if (action.error) {
        notifyError(action.error.message);
      }
    });
  };

  const runSavedSearch = search => {
    if (selectedHomeType.length < 1)
      notifyError('Please select at least one Home Type');
    else if (search) {
      setQuery(search.SearchQuery);
      setSearchParamsAndSearch(search.SearchQuery);
    }
  };

  return (
    <div>
      {!loading && location.found && (
        <div className="container-flex p-4">
          <div id="new-search" className="row">
            <div className="col-md-6 col-sm-12">
              <div className="row">
                <div className="col">
                  <TextField
                    label="Property Search"
                    id="query"
                    type="text"
                    className="form-control mb-1"
                    style={{ borderRadius: '10px', backgroundColor: '#D9D9D9' }}
                    value={query}
                    onFocus={() => {
                      setShowHomeType(true);
                    }}
                    onChange={e => setQuery(e.target.value)}
                    onKeyUp={e => {
                      if (e.key === 'Enter') {
                        if (
                          !query ||
                          searching ||
                          selectedHomeType?.length === 0
                        ) {
                          return;
                        }
                        setSearchParamsAndSearch(query);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setSearchParamsAndSearch(query)}
                            disabled={
                              !query ||
                              searching ||
                              selectedHomeType?.length === 0
                            }
                          >
                            <img
                              className="cursor-pointer"
                              src={images.search}
                              alt="Search"
                              style={{ margin: '.5rem', height: '36px' }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <small>
                    Property Type:{' '}
                    <em>
                      {selectedHomeType.length === homeTypeOptions.length
                        ? 'All'
                        : homeTypeOptions
                            .filter(homeType =>
                              selectedHomeType.includes(homeType.id)
                            )
                            .map(homeType => homeType.label)
                            .join(', ')}
                    </em>
                  </small>
                </div>
              </div>
              {showHomeType && (
                <div className="row">
                  <div className="col">
                    <div
                      className="mt-4 py-2"
                      style={{
                        backgroundColor: 'rgba(217, 217, 217, .5)',
                        borderRadius: '5px',
                      }}
                    >
                      <h6
                        className="px-2 pb-2"
                        style={{ borderBottom: '1px solid rgba(0, 0, 0, .31)' }}
                      >
                        Home Type{' '}
                        {!selectedHomeType?.length && (
                          <sub className="text-danger">
                            Please select at least one
                          </sub>
                        )}
                      </h6>
                      <div className="container mb-4">
                        <div className="row">
                          {homeTypeOptions.map(option => (
                            <div key={option.id} className="col-4">
                              <label htmlFor={option.id}>
                                <input
                                  type="checkbox"
                                  id={option.id}
                                  checked={selectedHomeType.includes(option.id)}
                                  onChange={e => {
                                    if (e.target.checked) {
                                      setSelectedHomeType([
                                        ...selectedHomeType,
                                        option.id,
                                      ]);
                                    } else {
                                      setSelectedHomeType(
                                        selectedHomeType.filter(
                                          id => id !== option.id
                                        )
                                      );
                                    }
                                  }}
                                  name="homeType"
                                />
                                <span className="p-2">{option.label}</span>
                              </label>
                            </div>
                          ))}
                          <div className="col-4">
                            <label htmlFor="all">
                              <input
                                type="checkbox"
                                id="all"
                                name="homeType"
                                checked={
                                  selectedHomeType.length ===
                                  homeTypeOptions.length
                                }
                                onChange={e => {
                                  if (e.target.checked) {
                                    setSelectedHomeType(
                                      homeTypeOptions.map(option => option.id)
                                    );
                                  }
                                }}
                              />
                              <span className="p-2">All</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <h6
                        className="px-2 pb-2"
                        style={{ borderBottom: '1px solid rgba(0, 0, 0, .31)' }}
                      >
                        Saved Searches
                      </h6>
                      {savedSearches && (
                        <Stack
                          direction="row"
                          spacing={1}
                          useFlexGap
                          flexWrap="wrap"
                          padding="1rem"
                        >
                          {savedSearches.map(search => (
                            <Chip
                              key={search.id}
                              label={search.SearchQuery}
                              size="medium"
                              onClick={() => runSavedSearch(search)}
                              onDelete={() => deleteSearch(search.id)}
                            />
                          ))}
                        </Stack>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-6 col-sm-12">
              {query && !savedSearchFound && (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={saveSearch}
                >
                  Save This Search
                </button>
              )}
              {query && savedSearchFound && (
                <span
                  style={{
                    padding: '7px 12px',
                    backgroundColor: '#d9d9d9',
                    fontSize: 'var(--bs-btn-font-weight)',
                    display: 'inline-block',
                    marginTop: '4px',
                  }}
                >
                  Saved Search ✅
                </span>
              )}
            </div>
          </div>
          {showNotFound && (
            <div className="container-flex">
              <div
                id="porpery-not-found"
                className="p-4 mt-4"
                style={{ backgroundColor: 'rgba(217, 217, 217, .5)' }}
              >
                <h3>{streetAddress} Real Estate &amp; Homes Results</h3>
                <h5>
                  No homes found.{' '}
                  <button
                    className="btn btn-link"
                    type="button"
                    onClick={() =>
                      navigate('/fat/newHome', { state: { streetAddress } })
                    }
                  >
                    Create Home for Analysis
                  </button>
                </h5>
              </div>
            </div>
          )}
          {showResult && (
            <div className="container-flex mt-4">
              <h5>Search Results</h5>
              <FATTable
                properties={properties}
                title="Search Results"
                columns={[
                  { id: 'PropertySize', label: 'Property Size' },
                  { id: 'Address', label: 'Property Address' },
                  {
                    id: 'WHRank',
                    label: 'WH Rank',
                    format: v => (v ? `${v}/10` : 'NA'),
                  },
                  {
                    id: 'AlgorithmicRating',
                    label: 'Algorithmic Rating',
                    format: v => (v ? `${v}/10` : 'NA'),
                  },
                  { id: 'Status', label: 'Status', default: 'Active' },
                ]}
                onRowClick={row => navigate(`/fat/survey/${row.ID}`)}
              />
            </div>
          )}
        </div>
      )}
      {!loading && !location.zipCodes?.length && (
        <Stack
          direction="row"
          height="50vh"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h5">Please Select Location</Typography>
        </Stack>
      )}
      {searching && (
        <div className="loading-screen">
          <div className="loading-spinner" />
        </div>
      )}{' '}
    </div>
  );
};

export default NewSearch;
