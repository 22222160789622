import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import { notify, notifyError } from '../../component/Toaster';
// import { createPropertyAsync } from '../../redux/fieldAnalysis/fieldAnalysisApi';
import { createProperty } from '../../redux/fieldAnalysis/fieldAnalysisSlice';
import useUnsavedChanges from '../../hooks/useUnsavedChanges';

const PropertySchema = Yup.object({
  Address: Yup.string().required('Address is required'),
  ZIPCode: Yup.string().required('ZIP Code is required'),
  City: Yup.string().required('City is required'),
  Province: Yup.string().required('State is required'),
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0 solid #000',
  borderRadius: '8px',
  boxShadow: 4,
  p: 2,
};

const CreateNewHome = () => {
  const {
    masterData: { states },
  } = useSelector(state => state.fieldAnalysis);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [property, setProperty] = React.useState(null);
  const handleClose = () => {
    setOpen(false);
    navigate(`/fat/survey/${property}`, { state: { isNew: true } });
  };
  const location = useLocation();
  const dispatch = useDispatch();
  const { handleUnsavedChanges } = useUnsavedChanges();

  const handleSubmit = async values => {
    dispatch(
      createProperty({
        ...values,
      })
    ).then(action => {
      if (action.error) {
        notifyError(action.error.message);
        return;
      }
      const { resultCode, propertyID } = action.payload;
      if (resultCode < 0) {
        notifyError('Failed to create property');
        return;
      }
      notify('Property created successfully!');
      setProperty(propertyID);
      setOpen(true);
      handleUnsavedChanges(false);
    });
  };

  return (
    <div id="create-new-property" className="row">
      <Typography variant="h5">Create Home for Analysis</Typography>
      <div className="col-md-6 col-sm-12">
        <Formik
          initialValues={{
            Address: location.state?.streetAddress || '',
            ZIPCode: '',
            City: '',
            Province: '',
          }}
          validationSchema={PropertySchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, handleChange }) => (
            <Form>
              <div className="container-flex">
                <div
                  className="mt-4 py-2"
                  style={{
                    backgroundColor: 'rgba(217, 217, 217, .39)',
                    borderRadius: '5px',
                  }}
                >
                  <h5 className="text-center" style={{ color: '#1877F2' }}>
                    Create Home for Analysis
                  </h5>
                  <div className="container p-3">
                    <div className="row pt-3">
                      <div className="col-2">
                        <span>Address</span>
                      </div>
                      <div className="col-10">
                        <Field
                          id="Address"
                          name="Address"
                          type="text"
                          className="form-control"
                          placeholder="Enter Address"
                          onChange={e => {
                            handleChange(e);
                            handleUnsavedChanges(true);
                          }}
                        />
                        {touched.Address && errors.Address ? (
                          <div className="text-danger">{errors.Address}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-2">
                        <span>ZIP Code</span>
                      </div>
                      <div className="col-5">
                        <Field
                          id="ZIPCode"
                          name="ZIPCode"
                          type="text"
                          className="form-control"
                          placeholder="Enter ZIP Code"
                          onChange={e => {
                            handleChange(e);
                            handleUnsavedChanges(true);
                          }}
                        />
                        {touched.ZIPCode && errors.ZIPCode ? (
                          <div className="text-danger">{errors.ZIPCode}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-2">
                        <span>City</span>
                      </div>
                      <div className="col-5">
                        <Field
                          id="City"
                          name="City"
                          type="text"
                          className="form-control"
                          placeholder="Enter City"
                          onChange={e => {
                            handleChange(e);
                            handleUnsavedChanges(true);
                          }}
                        />
                        {touched.City && errors.City ? (
                          <div className="text-danger">{errors.City}</div>
                        ) : null}
                      </div>
                      <div className="col-2">
                        <span>State</span>
                      </div>
                      <div className="col-3">
                        <Field
                          as="select"
                          id="Province"
                          name="Province"
                          className="form-select"
                          style={{ backgroundColor: 'rgba(217, 217, 217, .5)' }}
                          onChange={e => {
                            handleChange(e);
                            handleUnsavedChanges(true);
                          }}
                        >
                          <option>Select</option>
                          {states &&
                            states.map(state => (
                              <option key={state} value={state}>
                                {state}
                              </option>
                            ))}
                        </Field>
                        {touched.Province && errors.Province ? (
                          <div className="text-danger">{errors.Province}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col" style={{ textAlign: 'right' }}>
                    <button type="submit" className="btn btn-secondary mt-3">
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              color: 'rgba(0, 111, 255, 1)',
              fontSize: '18px',
              textAlign: 'center',
            }}
          >
            New home has been created.
          </Typography>
          <Typography
            variant="body1"
            sx={{ padding: '1rem', textAlign: 'center' }}
          >
            You can begin entering characteristics and amenity data while any
            listing data loads in the background.
          </Typography>
          <Stack>
            <Stack direction="row" justifyContent="center">
              <Button variant="contained" color="primary" onClick={handleClose}>
                Go
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateNewHome;
