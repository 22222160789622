import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

// TODO: add propTypes
// eslint-disable-next-line react/prop-types
const HookChart = ({ selectedChart = [] }) => {
  const [chartType, setChartType] = useState('line');

  const options = {
    chart: {
      height: 350,
      type: chartType,
      id: 'areachart-2',
      toolbar: {
        show: true,
        tools: {
          download: true,
          customIcons: [
            {
              icon: '<img src="https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/256/external-line-chart-advertising-kiranshastry-lineal-kiranshastry.png" width="20"/>',
              title: 'Line Chart',
              class: 'custom-icon',
              click: () => {
                setChartType('line');
              },
            },
            {
              icon: '<img src="https://img.icons8.com/material-two-tone/256/area-chart.png" width="20"/>',
              title: 'Area Chart',
              class: 'custom-icon',
              click: () => {
                setChartType('area');
              },
            },
            {
              icon: '<img src="https://img.icons8.com/plumpy/256/bar-chart.png" width="20"/>',
              //  index:2,
              title: 'Bar Chart',
              class: 'custom-icon',
              click: () => {
                setChartType('column');
              },
            },
          ],
        },
      },
    },
    colors:
      selectedChart.length > 0 ? selectedChart?.map(d => d.color) : ['#008FFB'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      width: 3,
    },
    grid: {
      padding: {
        right: 15,
        left: 18,
      },
    },
    markers: {
      size: 0,
      style: 'hollow',
    },
    xaxis: {
      type: 'datetime',

      tickPlacement: 'on',
      tickAmount: 10,
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy',
      },
      theme: 'dark',
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    },

    // yaxis: selectedChart?.map((y, index) => {
    //   return {
    //     opposite: index % 2 !== 0,
    //     seriesName: y?.title || '',
    //     axisTicks: {
    //       show: true,
    //     },
    //     axisBorder: {
    //       show: true,
    //       color: y.color || '#00E396',
    //     },
    //     labels: {
    //       style: {
    //         colors: y.color || '#00E396',
    //       },
    //       formatter: value => {
    //         return value?.toFixed(0);
    //       },
    //     },
    //     title: {
    //       text: y?.title || '',
    //       style: {
    //         color: y.color || '#00E396',
    //       },
    //     },
    //     tooltip: {
    //       enabled: true,
    //     },
    //   };
    // }),
    fill: {
      type: chartType === 'area' ? 'gradient' : 'solid',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.1,
        opacityFrom: 0.2,
        opacityTo: 0.8,
        stops: [0, 90, 100],
        inverseColors: true,
      },
    },
  };

  return (
    <div id="chart">
      <div id="chart-timeline">
        <ReactApexChart
          options={options}
          series={selectedChart}
          type="line"
          height={450}
        />
      </div>
    </div>
  );
};

export default HookChart;
