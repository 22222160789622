import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../axiosConfig';

export const getDrilldownZipsList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/drilldown/getDrilldownZipsList`,
      thunkAPI
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const getDrilldownBaseline = createAsyncThunk(
  'drilldown/getDrilldownBaseline',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/drilldown/getDrilldownBaseline`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getDrilldownOptimized = createAsyncThunk(
  'drilldown/getDrilldownOptimized',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/drilldown/getDrilldownOptimized`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);
// citylist and ziplist cityListDrilldown

export const cityListDrilldown = createAsyncThunk(
  'drilldown/cityListDrilldown',
  async () => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(`/city/cityListDrilldown`);
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const zipListDrilldown = createAsyncThunk(
  'drilldown/zipListDrilldown',
  async () => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(`/zipcode/zipListDrilldown`);
      return response.data;
    } catch (error) {
      return null;
    }
  }
);
