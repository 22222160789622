/* eslint-disable react/no-array-index-key */
import lodash from 'lodash';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../component/FilterDropdown';
import NextGenDataTable from '../../component/NextGenBsTable';
import { downloadPdf, ranking } from '../../redux/survey/api';
import { rankingColumn } from '../../utils/columns';
import data from '../../utils/data.json';
import {
  removeFilter,
  renderFiltersInputType,
  setSelectFilterOption,
  checkedApiCall,
} from '../../utils/helperInput';
import { notify } from '../../component/Toaster';
import {
  updateCurrentSurveyTab,
  updateSurveyTabNavigationData,
  updateUpdateTabNavigationData,
} from '../../redux/navigation/navigationSlice';
import useFilters from '../../hooks/useFilters';
import PdfModal from '../../component/ModelPop/PdfDownloadingModal';

const TabRanking = () => {
  const {
    selectOption,
    selectedFilterOption,
    setFilterOptions,
    setSelectedFilterOptions,
  } = useFilters({ moduleName: 'ranking' });
  const { cloneDeep } = lodash;
  const dispatch = useDispatch();
  const [seletedFilter, setSelectedFilter] = useState('');
  const [selectedSortingOption, setSelectedSortingOption] = useState({});
  const rankingData = useSelector(state => state.ranking);
  const stateData = useSelector(state => state?.ranking?.states);
  const cityData = useSelector(state => state?.ranking?.cityListRanking);
  const zipCodeData = useSelector(state => state?.ranking?.zipListRanking);
  const [, setLoading] = useState(false);
  const [isDataSet, setIsDataSet] = useState(true);
  const [filterByData, setFilterByData] = useState(data?.filterBy);
  const [, setTopTenData] = useState([]);
  const [resetPage, setResetPage] = useState(1);
  const [showPdfModal, setShowPdfModel] = useState(false);
  const isPdfDownloading = useSelector(state => state?.pdf?.isPdfDownloading);

  const currentSurveyTab = useSelector(
    state => state.navigation.currentSurveyTab
  );

  const setFilterOption = value => {
    if (currentSurveyTab.toLowerCase() === 'ranking') {
      dispatch(setFilterOptions(value));
    }
  };

  const setSelectedFilterOption = option => {
    if (currentSurveyTab.toLowerCase() === 'ranking') {
      dispatch(setSelectedFilterOptions(option));
    }
  };

  useEffect(() => {
    if (rankingData?.topTen?.length > 0 && isDataSet) {
      setTopTenData(rankingData?.topTen);
      setIsDataSet(false);
    }
  }, [rankingData]);

  const onSelectDropDown = async (e, row) => {
    if (e === 'print') {
      notify('Download pdf is in process');
      dispatch(
        downloadPdf({
          SurveyType: row?.SurveyType,
          ZIPCode: row?.ZIPCode,
          City: row?.City,
          StateAbbr: row?.StateAbbr,
          MarketName: row?.MarketName,
        })
      );
    }
    if (e === 'update') {
      dispatch(updateCurrentSurveyTab('Dataupdate'));
      dispatch(updateUpdateTabNavigationData(row));
    }
  };
  const onClickRankingRow = (e, row) => {
    dispatch(updateSurveyTabNavigationData(row));
    dispatch(updateCurrentSurveyTab('Survey'));
  };
  const columns = rankingColumn(onSelectDropDown, onClickRankingRow);

  const getRankingData = (start = 0, limit = 10, columnSort = {}) => {
    const sorts = { ...selectedSortingOption, ...columnSort };
    setLoading(true);
    dispatch(
      ranking({
        filters: { isStateCityFilter: true, ...selectedFilterOption },
        sortings: sorts,
        start,
        limit,
      })
    );
  };

  useEffect(() => {
    if (currentSurveyTab.toLowerCase() === 'ranking') {
      const isCallApi = checkedApiCall(selectedFilterOption);
      if (isCallApi || Object.keys(selectedSortingOption).length > 0) {
        getRankingData();
      }
    }
  }, [selectedFilterOption, selectedSortingOption, currentSurveyTab]);

  useEffect(() => {
    setLoading(false);
  }, [rankingData?.ranking]);

  const setSelectFilterOptionFunction = value => {
    setSelectFilterOption(
      value,
      data,
      selectOption,
      setFilterOption,
      setSelectedFilter,
      selectedFilterOption,
      setSelectedFilterOption,
      filterByData,
      setFilterByData
    );
  };

  const removeFilterFunction = id => {
    removeFilter(
      id,
      data.filterBy,
      selectOption,
      selectedFilterOption,
      setFilterOption,
      setSelectedFilter,
      setSelectedFilterOption,
      setFilterByData,
      filterByData
    );
  };

  const resetFilters = () => {
    setFilterByData(data?.filterBy);
    setFilterOption([]);
    setSelectedFilter('');
    setSelectedSortingOption({});
    setSelectedFilterOption({});
  };

  const setSortingOption = option => {
    const tempOption = {};
    if (option !== 'default') {
      if (option === 'monthly_revenue') {
        tempOption.Revenue_Baseline = true;
      } else {
        tempOption[option] = true;
      }
    }

    setSelectedSortingOption(tempOption);
    setResetPage(1);
  };

  const inputOnChangeFunction = (type, value) => {
    const tempSelectedFilterOption = cloneDeep(selectedFilterOption);
    tempSelectedFilterOption[type] = value;
    setSelectedFilterOption(tempSelectedFilterOption);
  };

  useEffect(() => {
    if (currentSurveyTab.toLowerCase() === 'ranking')
      setShowPdfModel(isPdfDownloading);
  }, [isPdfDownloading]);

  const handleClosePdfModel = () => {
    setShowPdfModel(false);
  };

  // useEffect(() => {
  //   if (selectedRow !== null) {
  //     setSelectFilterOptionFunction('ZIPCode');
  //     inputOnChangeFunction('ZIPCode', filteredZipData);
  //     const tempFilterByData = cloneDeep(filterByData);
  //     tempFilterByData.splice(
  //       filterByData.findIndex(res => res.optionValue === 'ZIPCode'),
  //       1
  //     );
  //     setFilterByData(tempFilterByData);
  //   }
  // }, [selectedRow]);

  useEffect(() => {
    if (selectOption.length > 0) {
      const filteredData = filterByData.filter(
        filter => filter.optionValue !== selectOption[selectOption.length - 1]
      );
      setFilterByData(filteredData);
    } else {
      setSelectedFilter('');
      setFilterByData(data?.filterBy);
    }
  }, [selectOption]);

  const renderFilterOptions = () => {
    return (
      <Row className="mb-2">
        {selectOption?.map((res, i) => {
          return (
            <Col xs={3} key={`filters-${i}`}>
              {renderFiltersInputType(
                res,
                i,
                data,
                data.filterBy,
                inputOnChangeFunction,
                removeFilterFunction,
                stateData,
                cityData,
                zipCodeData,
                selectedFilterOption
              )}
            </Col>
          );
        })}
      </Row>
    );
  };

  return (
    <div>
      <Container className="px-0">
        <Row className="mb-2">
          <Col xs={3}>
            <Filter
              setOption={setSelectFilterOptionFunction}
              options={filterByData}
              title="Filter By"
              value={seletedFilter}
            />
          </Col>
          <Col xs={3}>
            <Filter
              setOption={setSortingOption}
              options={data?.sortBy}
              title="Sort By"
            />
          </Col>

          <Col xs={3} className="reset-top">
            <Row>
              {selectOption?.length ? (
                <Col>
                  <button
                    type="button"
                    // eslint-disable-next-line react/no-unknown-property
                    variant="primary"
                    className="allbutton"
                    onClick={() => {
                      resetFilters();
                    }}
                  >
                    Reset Filter
                  </button>
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
        {renderFilterOptions()}

        <div className="toptenDiv">
          <h3 className="h3-heading mb-2">
            Top {rankingData?.topTen.length}{' '}
            {rankingData?.topTen.length === 1 ||
            rankingData?.topTen.length === 0
              ? 'Market'
              : 'Markets'}{' '}
          </h3>
          <Row className="mt-2">
            <Col xs={10}>
              <div className="geo-table-div">
                <NextGenDataTable
                  columns={columns}
                  data={rankingData?.topTen}
                  keyField="id"
                  loading={rankingData?.rankingLoader}
                  hideSizePerPage
                  enableSearching={false}
                  // onClickRow={onClickRankingRow}
                  striped
                  showRowText
                />
              </div>
            </Col>
          </Row>
          <div>
            <h3 className="h3-heading">Other Markets </h3>
            <Row className="mt-2">
              <Col xs={10}>
                <div className="geo-table-div">
                  <NextGenDataTable
                    columns={columns}
                    data={rankingData?.ranking}
                    keyField="id"
                    loading={rankingData?.rankingLoader}
                    hideSizePerPage
                    enableSearching={false}
                    striped
                    // onClickRow={onClickRankingRow}
                    count={
                      rankingData?.ranking.length > 0
                        ? rankingData?.rankingCount
                        : 0
                    }
                    fetchData={getRankingData}
                    remote
                    resetPage={resetPage}
                    setResetPage={setResetPage}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {showPdfModal && (
          <PdfModal show={showPdfModal} onClose={handleClosePdfModel} />
        )}
      </Container>
    </div>
  );
};

// TabRanking.propTypes = {
//   // eslint-disable-next-line react/forbid-prop-types
//   selectedRow: PropTypes.object,
//   // eslint-disable-next-line react/forbid-prop-types
//   filteredZipData: PropTypes.array,
// };

// TabRanking.defaultProps = {
//   selectedRow: null,
//   filteredZipData: null,
// };

export default TabRanking;
