import React, {
  createContext,
  useContext,
  // useEffect,
  useState,
  useMemo,
} from 'react';
import { Spinner } from 'react-bootstrap';
import propTypes from 'prop-types';

const LoadContext = createContext();

const LoadStateProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(null);
  const [message, setMessage] = useState('Loading...');
  const [loadMessage, setLoadMessage] = useState(null);

  // useEffect(() => {
  //   const listener = e => {
  //     e.preventDefault();
  //     e.returnValue = 'Are you sure you want to close?';
  //   };
  //   if (loading) {
  //     window.addEventListener('beforeunload', listener);
  //   }
  //   return () => window.removeEventListener('beforeunload', listener);
  // }, [loading]);

  const value = useMemo(() => {
    return {
      loading,
      setLoading,
      setMessage,
      setPercentage,
      setLoadMessage,
    };
  }, [loading, setLoading, setMessage, setPercentage, setLoadMessage]);

  return (
    <LoadContext.Provider value={value}>
      {children}
      {loading && (
        <>
          {typeof percentage === 'number' || typeof percentage === 'string' ? (
            <>
              {/* <Spinner className="mt-4" animation="border" role="status"> */}
              {/*  <p className="sr-only">Loading...</p> */}
              {/* </Spinner> */}
              <div className="bars">
                <p className="mt-3">{message}</p>
                <progress max={100} value={percentage} />
                <p className="mt-2">{percentage}%</p>
              </div>
            </>
          ) : (
            <>
              {/* <h6>Loading....</h6> */}
              <div className="bars">
                <Spinner className="mt-0" animation="border" role="status" />
                <p className="mt-4 p-3">{message}</p>
              </div>
            </>
          )}
          {loadMessage && <p className="mt-3">{loadMessage}</p>}
        </>
      )}
    </LoadContext.Provider>
  );
};

LoadStateProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export const useLoadContext = () => useContext(LoadContext);

export const withLoadProvider = Component => () =>
  (
    <LoadStateProvider>
      <LoadContext.Consumer>
        {value => <Component {...value} />}
      </LoadContext.Consumer>
    </LoadStateProvider>
  );
