/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
// import { useDispatch, useSelector } from 'react-redux';
// import debounce from 'debounce-promise';
import propTypes from 'prop-types';
// import {
//   cityListRanking,
//   cityListSurvey,
//   cityListSurveyUpdate,
//   zipListRanking,
//   zipListSurvey,
//   zipListSurveyUpdate,
// } from '../../redux/survey/api';

const MultiSelect = props => {
  const {
    optionHeading,
    option,
    name,
    removeFilter,
    setOption,
    type,
    toBeDeleted,
    listSize = 50,
  } = props;

  const [options, setOptions] = useState([]);
  // const dispatch = useDispatch();
  // const currentSurveyTab = useSelector(
  //   state => state.navigation.currentSurveyTab
  // );
  const setSelectedOption = data => {
    setOption(name, data);
  };

  // const debounceFunc = debounce(async inputValue => {
  //   let data;
  //   if (inputValue.length <= 2) {
  //     return [];
  //   }
  //   if (type === 'City') {
  //     if (currentSurveyTab === 'Ranking')
  //       data = await dispatch(cityListRanking({ searchQuery: inputValue }));
  //     if (currentSurveyTab === 'Survey')
  //       data = await dispatch(cityListSurvey({ searchQuery: inputValue }));
  //     if (currentSurveyTab === 'Dataupdate')
  //       data = await dispatch(
  //         cityListSurveyUpdate({ searchQuery: inputValue })
  //       );
  //   } else {
  //     if (currentSurveyTab === 'Ranking')
  //       data = await dispatch(zipListRanking({ searchQuery: inputValue }));
  //     if (currentSurveyTab === 'Survey')
  //       data = await dispatch(zipListSurvey({ searchQuery: inputValue }));
  //     if (currentSurveyTab === 'Dataupdate')
  //       data = await dispatch(zipListSurveyUpdate({ searchQuery: inputValue }));
  //   }

  //   return data?.payload;
  // }, 2000);

  useEffect(() => {
    let data = option;
    if (data?.length > listSize) {
      data = data.slice(0, listSize);
      setOptions(data);
    } else {
      setOptions(option);
    }
  }, [option]);

  // const inputDebounceFunc = debounce(async inputValue => {
  //   let data = option;
  //   if (inputValue.length <= 2) {
  //     if (data?.length > 50) {
  //       data = data.slice(0, 50);
  //       setOptions(data);
  //       return data;
  //     }
  //     setOptions(option);
  //     return option;
  //   }
  //   const newData = option?.filter(d => {
  //     return d?.label?.includes(inputValue);
  //   });
  //   setOptions(newData);
  //   return newData;
  // });

  const handleInputChange = inputValue => {
    let data = option;
    if (inputValue.length <= 2) {
      if (data?.length > listSize) {
        data = data.slice(0, listSize);
        setOptions(data);
        // return data
      } else {
        setOptions(option);
        // return  option
      }
    } else {
      const newData = option?.filter(d => {
        return d?.label?.toLowerCase()?.includes(inputValue.toLowerCase());
      });
      setOptions(newData);
      // return newData
    }
  };
  return (
    <div>
      {type !== undefined ? (
        <div className="position-relative mb-2">
          <span className="label-heading">{optionHeading}</span>
          <Row className="row-width">
            <Col>
              <Select
                isMulti
                name={name}
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={setSelectedOption}
                cacheOptions
                onInputChange={handleInputChange}
                value={props?.defaultValue}
                getOptionLabel={item =>
                  type === 'City' ? item.label : item.value
                }
              />
            </Col>
            <Col xs={1}>
              <div className="add-remove">
                <button
                  type="button"
                  // eslint-disable-next-line react/no-unknown-property
                  variant="primary"
                  className="close"
                  onClick={() => removeFilter(toBeDeleted)}
                >
                  <FontAwesomeIcon icon={faClose} />
                </button>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="position-relative mb-2">
          <span className="label-heading">{optionHeading}</span>
          <Row className="row-width">
            <Col>
              <Select
                isMulti
                name={name}
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={setSelectedOption}
                cacheOptions
                onInputChange={handleInputChange}
                value={props?.defaultValue}
                getOptionLabel={item =>
                  type === 'City' ? item.label : item.value
                }
              />
            </Col>
            <Col xs={1}>
              <div className="add-remove">
                <button
                  type="button"
                  // eslint-disable-next-line react/no-unknown-property
                  variant="primary"
                  className="close"
                  onClick={() => removeFilter(toBeDeleted)}
                >
                  <FontAwesomeIcon icon={faClose} />
                </button>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

MultiSelect.propTypes = {
  optionHeading: propTypes.string.isRequired,
  option: propTypes.arrayOf(
    propTypes.shape({
      optionName: propTypes.string,
      optionValue: propTypes.string,
    })
  ).isRequired,
  name: propTypes.string.isRequired,
  removeFilter: propTypes.func.isRequired,
  setOption: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  toBeDeleted: propTypes.string.isRequired,
  defaultValue: propTypes.string.isRequired,
};

export default MultiSelect;
