import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getShortListAsync } from '../../redux/fieldAnalysis/fieldAnalysisApi';
import { notifyError } from '../../component/Toaster';
import FATTable from '../../component/FATTable/FATTable';

const Shortlist = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const loadShortList = () => {
    setLoading(true);
    getShortListAsync()
      .then(props => {
        setProperties(props);
        setLoading(false);
      })
      .catch(err => notifyError(err.message))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadShortList();
  }, []);

  return (
    <div className="container-flex p-4">
      <h5>Shortlist</h5>
      <FATTable
        properties={properties}
        loading={loading}
        title="Shortlist"
        context="shortlist"
        columns={[
          { id: 'PropertySize', label: 'Property Size' },
          { id: 'Address', label: 'Property Address' },
          {
            id: 'WHRank',
            label: 'WH Rank',
            format: v => (v ? `${v}/10` : 'NA'),
          },
          {
            id: 'AlgorithmicRating',
            label: 'Algorithmic Rating',
            format: v => (v ? `${v}/10` : 'NA'),
          },
          { id: 'Status', label: 'Status', default: 'Active' },
        ]}
        onRowClick={row => navigate(`/fat/survey/${row.ID}`)}
        onAction={loadShortList}
      />
    </div>
  );
};

export default Shortlist;
