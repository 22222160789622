import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import rankingReducer from './survey/rankingSlice';
import drilldownReducer from './drilldown/drilldownSlice';
import homeReviewReducer from './homeReview/homeReviewSlice';
import userReducer from './user/userSlice';
import characteristicsReducer from './characteristics/characteristicsSlice';
import navigation from './navigation/navigationSlice';
import fieldAnalysisReducer from './fieldAnalysis/fieldAnalysisSlice';
import filterReducer from './filters/filterSlice';
import pdfReducer from './survey/pdfSlice';

const rootReducers = combineReducers({
  ranking: rankingReducer,
  user: userReducer,
  drilldown: drilldownReducer,
  homeReview: homeReviewReducer,
  characteristics: characteristicsReducer,
  navigation,
  filters: filterReducer,
  fieldAnalysis: fieldAnalysisReducer,
  pdf: pdfReducer,
});
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['navigation', 'user'],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
