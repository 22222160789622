/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import moment from 'moment';
// import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
// import DropdownButton from 'react-bootstrap/DropdownButton';
// import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
import ClipLoader from 'react-spinners/ClipLoader';
import { formatNumber, formatNumberWithSuffix } from './numberFormatter';
import { headerFormatter, cellFormatter } from './tableHelpers';

const calculateOnlyShowUpdate = row => {
  return (
    (row?.DataSurvey_ID === null || row?.SurveyDate === null) &&
    row?.visibility_status === null
  );
};

// const actionButton = (cell, row, rowIndex, formatExtraData) => {
//   return (
//     <InputGroup className="mb-3">
//       <Form.Control aria-label="Text input with dropdown button" />

//       <DropdownButton
//         variant="outline-secondary"
//         title="Dropdown"
//         id="input-group-dropdown-2"
//         align="end"
//       >
//         <Dropdown.Item href="#">Action</Dropdown.Item>
//         <Dropdown.Item href="#">Another action</Dropdown.Item>
//         <Dropdown.Item href="#">Something else here</Dropdown.Item>
//         <Dropdown.Divider />
//         <Dropdown.Item href="#">Separated link</Dropdown.Item>
//       </DropdownButton>
//     </InputGroup>
//   );
// };
const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 2,
});
const formatAsPercent = num => {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    maximumSignificantDigits: 2,
  }).format(num > 1 ? num / 100 : Math.floor(num));
};

// const formatPrevalencePercentage = value => {
//   const floatValue = parseFloat(value);
//   if (!Number.isNaN(floatValue)) {
//     const formattedPercentage = `${floatValue.toFixed(1)}%`;
//     return formattedPercentage;
//   }
//   return '0%';
// };

const headerStyle = {
  align: 'center',
  textAlign: 'center',
};

const headerStyleForWrappedText = {
  whiteSpace: 'pre-line',
  textAlign: 'center',
};

const priceFormatter = cell => {
  if (cell === null || cell === '-1.00') {
    return <div className="singleTextDiv">n/a</div>;
  }
  return (
    <div className="singleTextDiv">{USDollar.format(Math.floor(cell))}</div>
  );
};

const NightlyValueOfSuperhost = (cell, row) => {
  const { NightlyValueOfSuperhost: value } = row;
  return <div className="singleTextDiv">{value !== -1 ? cell : 'n/a'}</div>;
};

const NightlyValueOf5StarReviews = (cell, row) => {
  const { NightlyValueOf5StarReviews: value } = row;
  return <div className="singleTextDiv">{value !== -1 ? cell : 'n/a'}</div>;
};

const ValuableDescriptiveKeywords = (cell, row) => {
  const { ValuableDescriptiveKeywords: value } = row;
  return (
    <div className="ValuableDescriptiveKeywords">
      {value !== -1 ? value : 'n/a'}
    </div>
  );
};
const OptimalCancellationPolicy = (cell, row) => {
  const { OptimalCancellationPolicy: value } = row;
  return (
    <div className="ValuableDescriptiveKeywords">
      {value !== -1 ? value : 'n/a'}
    </div>
  );
};

const OptimalPetsPolicy = (cell, row) => {
  const { OptimalPetsPolicy: value } = row;
  return (
    <div className="ValuableDescriptiveKeywords">
      {value !== -1 ? value : 'n/a'}
    </div>
  );
};

const singleTextCellFormater = cell => {
  return <div className="singleTextDiv homeCursorCell">{cell}</div>;
};

const singleNumericCellFormatter = cell => {
  return (
    <div className="singleTextDiv homeCursorCell">{formatNumber(cell)}</div>
  );
};

const nameCellFormater = cell => {
  return <div className="nameclass homeCursorCell">{cell}</div>;
};

const singleTextPerCellFormater = cell => {
  return <div className="singleTextDiv">{formatAsPercent(cell)}</div>;
};

const percentileRankFormatter = cell => {
  if (cell !== null) return `${Math.floor(cell)}%`;
  return 'n/a';
};

const dateFormatter = cell => {
  return cell ? (
    moment(cell).utc().format('L')
  ) : (
    <div style={{ textAlign: 'center' }}>-</div>
  );
};

// const prevalenceCountPerCellFormatter = (cell, row) => {
//   const { PrevalenceCount: value, PrevalencePercent } = row;
//   return (
//     <div className="season-prevalenceCountPerCell homeCursorCell">
//       <div className="season-prev-cell">{value}</div>
//       <div className="season-prev-cell">
//         {formatPrevalencePercentage(PrevalencePercent)}
//       </div>
//     </div>
//   );
// };

// const profitCountPerCellFormatter = (cell, row) => {
//   const { PrevalenceCount, PrevalencePercent } = row;
//   return (
//     <div className="profit-prevalenceCountPerCell homeCursorCell">
//       <div className="profit-cell-per">{PrevalenceCount}</div>
//       <div className="profit-cell-per">
//         {formatAsPercent(PrevalencePercent)}
//       </div>
//     </div>
//   );
// };

const homeCountPerCellFormatter = (cell, row) => {
  const { PrevalenceCount, PrevalencePercent } = row;
  return (
    <div className="homeCountPerCellCountPerCell homeCursorCell">
      <div className="home-cellcountper">{PrevalenceCount}</div>
      <div className="home-cellcountper">
        {formatAsPercent(PrevalencePercent)}
      </div>
    </div>
  );
};

// const seasonCalenderText = (cell, row) => {
//   const { CalendarText: value } = row;
//   return <div className="cal-text-row">{value}</div>;
// };

// const seasonCharterText = (cell, row) => {
//   const { CharacteristicName: value } = row;
//   return <div className="cal-text-row">{value}</div>;
// };

// const annualRevenueCellFormatter = (cell, row) => {
//   const { AnnualRevenue_Min: min, AnnualRevenue_Max: max } = row;
//   return (
//     <div className="prevalenceCountPerCell-annualRevenueCell homeCursorCell">
//       <div className="prev-annualRevenueCell">
//         {row?.DataQuality === 0 ? 'n/a' : USDollar.format(min)}
//       </div>
//       <div className="prev-annualRevenueCell">
//         {row?.DataQuality === 0 ? 'n/a' : USDollar.format(max)}
//       </div>
//     </div>
//   );
// };

const singleTextFormater = column => {
  return <div className="singleTextDiv">{column.text}</div>;
};

// const multiTextFormater = column => {
//   return (
//     <div className="multiTextFormater-header">
//       {column.text.split(',')?.map((dt, id) => (
//         <div key={id}>{dt}</div>
//       ))}
//     </div>
//   );
// };

// const seasonCalenderHeader = () => {
//   return <div className="cal-header-div">Season</div>;
// };

const costTextFormater = () => {
  return (
    <div className="price-text-formater">
      <div className="price-text-head">Median Home Price</div>
      <div className="price-text-head">ROI at Median Price</div>
      <div className="price-text-head">Max Home Price</div>
      <div className="price-text-head">Max Retrofit Budget</div>
      <div className="price-text-head">Max Total Cost</div>
    </div>
  );
};

const ROIPercentile = column => {
  const { text } = column;
  return (
    <div className="percentile-main-div">
      <div className="roi-percentileRank">Percentile Rank</div>
      <div className="roi-percentileRank-header">
        {text.split(',')?.map((dt, id) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="roi-revroi" key={id}>
            {dt}
          </div>
        ))}
      </div>
    </div>
  );
};
// const annualRevenue = column => {
//   return (
//     <div className="">
//       <div className="prevlenceCount">Annual Revenue Value</div>
//       <div className="percentileRank-header">
//         {column.text.split(',')?.map((dt, id) => (
//           <div key={id}>{dt}</div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const annualRevenueProfit = (column, colIndex, { sortElement }) => {
//   return (
//     <div className="annual-profit-div">
//       <div
//         className="profit-prevlenceCount"
//         style={
//           sortElement?.props?.order ? { padding: '16px 8px 11px 8px' } : {}
//         }
//       >
//         Annual Revenue Value
//         <span
//           className={`react-bootstrap-table-sort-order ${
//             sortElement?.props?.order === 'asc' ? ' dropup' : ''
//           }`}
//         >
//           {!sortElement?.props?.order ? (
//             <span className="order">
//               <span className="dropdown">
//                 <span className="caret" />
//               </span>
//               <span className="dropup">
//                 <span className="caret" />
//               </span>
//             </span>
//           ) : (
//             <span className="caret" />
//           )}
//         </span>
//       </div>
//       <div className="profit-percentileRank-header-annual">
//         {column.text.split(',')?.map((dt, id) => (
//           // eslint-disable-next-line react/no-array-index-key
//           <div className="profit-annual-header" key={id}>
//             {dt}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const geographyAnnualRevenue = (column, colIndex, { sortElement }) => {
//   return (
//     <div className="geo-prev-annual-main">
//       <div
//         className="prevlenceCount underline-class"
//         style={sortElement?.props?.order ? { padding: '11px 0 0px' } : {}}
//       >
//         Seasonal Annual Revenue Value
//         <span
//           className={`react-bootstrap-table-sort-order ${
//             sortElement?.props?.order === 'asc' ? ' dropup' : ''
//           }`}
//         >
//           {!sortElement?.props?.order ? (
//             <span className="order">
//               <span className="dropdown">
//                 <span className="caret" />
//               </span>
//               <span className="dropup">
//                 <span className="caret" />
//               </span>
//             </span>
//           ) : (
//             <span className="caret" />
//           )}
//         </span>
//       </div>
//       <div className="geo-percentileRank-header">
//         {column.text.split(',')?.map((dt, id) => (
//           // eslint-disable-next-line react/no-array-index-key
//           <div className="geo-header-subdiv" key={id}>
//             {dt}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const profibilityCountPercent = (column, colIndex, { sortElement }) => {
//   return (
//     <div>
//       <div
//         className="profitibilityPrevlenceCount"
//         style={sortElement?.props?.order ? { padding: '23px 0' } : {}}
//       >
//         Prevalence
//         <span
//           className={`react-bootstrap-table-sort-order ${
//             sortElement?.props?.order === 'asc' ? ' dropup' : ''
//           }`}
//         >
//           {!sortElement?.props?.order ? (
//             <span className="order">
//               <span className="dropdown">
//                 <span className="caret" />
//               </span>
//               <span className="dropup">
//                 <span className="caret" />
//               </span>
//             </span>
//           ) : (
//             <span className="caret" />
//           )}
//         </span>
//       </div>
//       <div className="profit-percentileRank-header">
//         {column.text.split(',')?.map((dt, id) => (
//           <div className="profit-countper" key={id}>
//             {dt}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };
// const countPercent2 = (column, colIndex, { sortElement }) => {
//   return (
//     <div className="homesize-prev">
//       <div className="prevlenceCount-2">
//         Prevalence
//         <span
//           className={`react-bootstrap-table-sort-order ${
//             sortElement?.props?.order === 'asc' ? ' dropup' : ''
//           }`}
//         >
//           {!sortElement?.props?.order ? (
//             <span className="order">
//               <span className="dropdown">
//                 <span className="caret" />
//               </span>
//               <span className="dropup">
//                 <span className="caret" />
//               </span>
//             </span>
//           ) : (
//             <span className="caret" />
//           )}
//         </span>
//       </div>
//       <div className="home-percentileRank-header">
//         {column.text.split(',')?.map((dt, id) => (
//           <div className="home-countper" key={id}>
//             {dt}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };
// const countPercent3 = (column, colIndex, { sortElement }) => {
//   return (
//     <div className="col-prevalence">
//       <div
//         className={`prevlenceCount-4 ${
//           !sortElement?.props?.order ? 'padding-up-down' : ''
//         }`}
//       >
//         Prevalence
//         <span
//           className={`react-bootstrap-table-sort-order ${
//             sortElement?.props?.order === 'asc' ? ' dropup' : ''
//           }`}
//         >
//           {!sortElement?.props?.order ? (
//             <span className="order">
//               <span className="dropdown">
//                 <span className="caret" />
//               </span>
//               <span className="dropup">
//                 <span className="caret" />
//               </span>
//             </span>
//           ) : (
//             <span className="caret" />
//           )}
//         </span>
//       </div>
//       <div className="season-percentileRank-header">
//         {column.text.split(',')?.map((dt, id) => (
//           <div className="season-prev-header" key={id}>
//             {dt}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const propertyProfibility = (column, colIndex, { sortElement }) => {
//   return (
//     <div className="profit-property-div">
//       <div
//         className="profit-property-percentileRank"
//         style={sortElement?.props?.order ? { padding: '13px 0 14px' } : {}}
//       >
//         Maximum Estimated Property Profitability
//         <span
//           className={`react-bootstrap-table-sort-order ${
//             sortElement?.props?.order === 'asc' ? ' dropup' : ''
//           }`}
//         >
//           {!sortElement?.props?.order ? (
//             <span className="order">
//               <span className="dropdown">
//                 <span className="caret" />
//               </span>
//               <span className="dropup">
//                 <span className="caret" />
//               </span>
//             </span>
//           ) : (
//             <span className="caret" />
//           )}
//         </span>
//       </div>
//       <div className="profit-property-percentileRank-header">
//         {column.text.split(',')?.map((dt, id) => (
//           <div className="profit-property-sml" key={id}>
//             {dt}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const financialDetails = column => {
//   return (
//     <div>
//       <div className="financialDetails">Median Financial Details</div>
//       <div className="percentileRank-header">
//         {column.text.split(',')?.map((dt, id) => (
//           <div key={id}>{dt}</div>
//         ))}
//       </div>
//     </div>
//   );
// };

const drilldownFinancialDetails = column => {
  return (
    <div>
      <div className="drill-financialDetails">Median Financial Details</div>
      <div className="drill-percentileRank-header">
        {column.text.split(',')?.map((dt, id) => (
          <div className="drill-rev-adr" key={id}>
            {dt}
          </div>
        ))}
      </div>
    </div>
  );
};

const HomecountMaxSegments = () => {
  return (
    <div className="drill-header-segments">
      <div className="drill-homecount-head">Home Count</div>
      <div className="drill-homeCountSegement">
        <div className="drilldown-homecount-head">Max Sleeps/</div>
        <div className="drilldown-homecount-head">Beds/Bath</div>
      </div>
    </div>
  );
};

// const HomeSize = () => {
//   return (
//     <div className="header-segments">
//       <div className="homeCountSegement">
//         <div className="homecount-head">Home Size </div>
//         <div className="homecount-head">Sleeps/Beds/Bath</div>
//       </div>
//       <div className="homecount-head">
//         <div className="homesize-headCount">Home Count</div>
//         <div className="homesize-homecount">
//           <div>Count</div>
//           <div>%</div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const GeographyZipcode = (column, colIndex, { sortElement }) => {
//   return (
//     <div className="geography-header-segments">
//       <div className="geo-homecount-head">MarketName </div>
//       <div className="geoCount-homecount-head">
//         <div
//           className="prevlenceCount-3"
//           style={sortElement?.props?.order ? { padding: '24px 0' } : {}}
//         >
//           Prevalence
//           <span
//             className={`react-bootstrap-table-sort-order ${
//               sortElement?.props?.order === 'asc' ? ' dropup' : ''
//             }`}
//           >
//             {!sortElement?.props?.order ? (
//               <span className="order">
//                 <span className="dropdown">
//                   <span className="caret" />
//                 </span>
//                 <span className="dropup">
//                   <span className="caret" />
//                 </span>
//               </span>
//             ) : (
//               <span className="caret" />
//             )}
//           </span>
//         </div>
//         <div className="geo-homesize-homecount">
//           <div className="profit-cell-per">Count</div>
//           <div className="profit-cell-per">%</div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const HomeSizeProfitibility = (column, colIndex, { sortElement }) => {
//   return (
//     <div className="profit-header-segments">
//       <div className="homeCountSegement">
//         <div className="homecount-head">Home Size </div>
//         <div className="homecount-head">Sleeps/Beds/Bath</div>
//       </div>
//       <div className="profit-homecount-head">
//         Characteristic
//         <span
//           className={`react-bootstrap-table-sort-order ${
//             sortElement?.props?.order === 'asc' ? ' dropup' : ''
//           }`}
//         >
//           {!sortElement?.props?.order ? (
//             <span className="order">
//               <span className="dropdown">
//                 <span className="caret" />
//               </span>
//               <span className="dropup">
//                 <span className="caret" />
//               </span>
//             </span>
//           ) : (
//             <span className="caret" />
//           )}
//         </span>
//       </div>
//     </div>
//   );
// };

const HomeSizeSeason = () => {
  return (
    <div className="homeSizeSeason">
      <div>Seasonal Nightly Value</div>
      <div className="homeSizeSeason-main-child">
        <div className="homesize-season">
          <div>Season</div>
        </div>
        <div className="annual-child">
          <div className="underline-class-child">Revenue Value</div>
          <div className="annual-child-sub">
            <div>Max</div>
            <div>Min</div>
          </div>
        </div>
        <div className="annual-child">
          <div className="underline-class-child">ADR</div>
          <div className="annual-child-sub">
            <div>Max</div>
            <div>Min</div>
          </div>
        </div>
        <div className="annual-child">
          <div className="underline-class-child">Occup. %</div>
          <div className="annual-child-sub">
            <div>Max</div>
            <div>Min</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getNumberWithOrdinal = n => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

const percentileRankCellFormatter = (cell, row) => {
  return (
    <div className="percentile-cell-div">
      <div className="roi-cell-rev">
        {row.Baseline_Percentile_Rank_Revenue !== null &&
          getNumberWithOrdinal(row.Baseline_Percentile_Rank_Revenue)}
      </div>
      <div className="roi-cell-rev">
        {row.Baseline_Percentile_Rank_ROI !== null &&
          getNumberWithOrdinal(row.Baseline_Percentile_Rank_ROI)}
      </div>
    </div>
  );
};
const percentileOptimizedRankCellFormatter = (cell, row) => {
  return (
    <div className="percentile-cell-div">
      <div className="roi-cell-rev">
        {row.Optimized_Percentile_Rank_Revenue !== null &&
          getNumberWithOrdinal(row.Optimized_Percentile_Rank_Revenue)}
      </div>
      <div className="roi-cell-rev">
        {row.Optimized_Percentile_Rank_ROI !== null &&
          getNumberWithOrdinal(row.Optimized_Percentile_Rank_ROI)}
      </div>
    </div>
  );
};

const homecountSegemtCellFormatter = (cell, row) => {
  return (
    <div className="cell-div" style={{ padding: '0px 16px 0px 4px' }}>
      <div className="home-count-zip">
        {formatNumberWithSuffix(row.HomesCountInZIP)}
      </div>
      <div className="homecount-cell">
        {row?.Sleeps} {row?.Sleeps && '/'} {row?.Beds} {row?.Beds && '/'}{' '}
        {row?.Baths}
      </div>
    </div>
  );
};

const homeSizeSeasonCellFormater = (cell, row) => {
  return (
    <div className="homeSizeSeason">
      <div className="homeSizeSeason-main-child">
        <div className="homesize-season">
          <div className="home-name">{row?.CalendarText}</div>
        </div>
        <div className="annual-child">
          <div className="annual-child-sub">
            <div>{USDollar.format(row?.AnnualRevenue_Min)} </div>
            <div>{USDollar.format(row?.AnnualRevenue_Max)}</div>
          </div>
        </div>
        <div className="annual-child">
          <div className="annual-child-sub">
            <div>{row?.AnnualADR_Min}</div>
            <div>{row?.AnnualADR_Max}</div>
          </div>
        </div>
        <div className="annual-child">
          <div className="annual-child-sub">
            <div>{formatAsPercent(row?.AnnualOccupancyPercent_Max)}</div>
            <div>{formatAsPercent(row?.AnnualOccupancyPercent_Min)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const homeSizeCellFormater = (cell, row) => {
  return (
    <div className="homeSizeCellFormater-class">
      <div className="homeSizeCellFormater-segment">
        {row?.Sleeps} {row?.Sleeps && '/'} {row?.Beds} {row?.Beds && '/'}{' '}
        {row?.Baths}
      </div>
      <div className="homeSizeCellFormater-count">
        <div>{row?.HomeCount}</div>
        <div>{formatAsPercent(row?.HomeCountPercentOfHomesInSegment)}</div>
      </div>
    </div>
  );
};

const profibilityCellFormater = (cell, row) => {
  return (
    <div className="profit-homeSizeCellFormater-class">
      <div className="profit-homeSizeCellFormater-segment">{row.HomeSize}</div>
      <div className="profitibilityCellFormater-count ">
        {row?.CharacteristicName}
      </div>
    </div>
  );
};

// const GeographyZipcodeCellFormater = (cell, row) => {
//   return (
//     <div className=" geo-homeSizeCellFormater-class">
//       <div className="geo-zip-cell">{row?.MarketName}</div>
//       <div className="geo-prevalenceCountPerCell homeCursorCell">
//         <div className="prevalenceCountCell">{row?.PrevalenceCount}</div>
//         <div className="prevalenceCountCell">
//           {' '}
//           {formatAsPercent(row?.PrevalencePercent)}
//         </div>
//       </div>
//     </div>
//   );
// };

// const financialDetailsCellFormatter = (cell, row) => {
//   return (
//     <div className="cell-div">
//       <div>{USDollar.format(row?.BaselineMedianAnnualRevenue)}</div>
//       <div>{USDollar.format(row?.BaselineMedianADR)}</div>
//       <div>{formatAsPercent(row?.BaselineOccupancyPercent)}</div>
//     </div>
//   );
// };

const drillbaselineAdr = (cell, row) => {
  return (
    <div className="drill-base-cell-div">
      <div className="drill-base-annual">
        {formatNumberWithSuffix(row?.BaselineMedianAnnualRevenue, 'USD')}
      </div>
      <div className="drill-base-annual">
        {USDollar.format(row?.BaselineMedianADR)}
      </div>
      <div className="drill-base-annual">
        {formatAsPercent(row?.BaselineOccupancyPercent)}
      </div>
    </div>
  );
};

const drillOptimseADR = (cell, row) => {
  return (
    <div className="drill-base-cell-div">
      <div className="drill-base-annual">
        {formatNumberWithSuffix(row?.OptimizedProjectedRevenue, 'USD')}
      </div>
      <div className="drill-base-annual">
        {formatNumberWithSuffix(row?.OptimizedADR, 'USD')}
      </div>
      <div className="drill-base-annual">
        {formatAsPercent(row?.OptimizedOccupancyPercent)}
      </div>
    </div>
  );
};

const singleCellFormater = cell => {
  return <div className="singlecell-div">{cell}</div>;
};

const costTextCellFormatter = (cell, row) => {
  return (
    <div className="price-text-formater-cell">
      <div className="price-text-head">
        {row.HomePurchasePrice !== '-1.00'
          ? formatNumberWithSuffix(row.HomePurchasePrice, 'USD')
          : 'n/a'}
      </div>
      <div className="price-text-head">{formatAsPercent(row.BaselineROI)}</div>
      {/* <div className="price-text-head">
        {USDollar.format(row.BaselineMedianAnnualRevenue)}
      </div> */}
      <div className="price-text-head">
        {formatNumberWithSuffix(row.BaselineMax_Purchase_Price, 'USD')}
      </div>
      <div className="price-text-head">
        {formatNumberWithSuffix(row.RetrofitBudget, 'USD')}
      </div>
      <div className="price-text-head">
        {formatNumberWithSuffix(row.MaxTotalCost, 'USD')}
      </div>
    </div>
  );
};

const costTextOptimizedCellFormatter = (cell, row) => {
  return (
    <div className="price-text-formater-cell">
      <div className="price-text-head">
        {row.HomePurchasePrice !== '-1.00'
          ? formatNumberWithSuffix(row.HomePurchasePrice, 'USD')
          : 'n/a'}
      </div>
      <div className="price-text-head">{formatAsPercent(row.OptimizedROI)}</div>
      <div className="price-text-head">
        {formatNumberWithSuffix(row.OptimizedMax_Purchase_Price, 'USD')}
      </div>
      <div className="price-text-head">
        {formatNumberWithSuffix(row.RetrofitBudget, 'USD')}
      </div>
      <div className="price-text-head">
        {formatNumberWithSuffix(row.MaxTotalCost, 'USD')}
      </div>
    </div>
  );
};

const dataQualityFormatter = (cell, row) => {
  if (row.DataQuality === '') {
    return '-';
  }
  if (
    row.DataQuality === 0 ||
    row.DataQuality === 1 ||
    row.DataQuality === 'Survey'
  ) {
    return 'Survey';
  }
  return 'Full';
};

const updateFrequencyFormatter = (cell, row) => {
  switch (row?.listing_update_frequency) {
    case 0:
      return 'Never';
    case 7:
      return 'Weekly';
    case 14:
      return 'Every 14 days';
    case 30:
      return 'Monthly';
    case 90:
      return 'Quarterly';
    case 365:
      return 'Daily';
    default:
      return '-';
  }
};

const bedSegmentsInZip = column => {
  return (
    <div>
      <div className="bedSegmentsInZip">Best Segments in Best ZIP&apos;s </div>
      <div className="bedSegmentsInZip-percentileRank-header">
        {column.text.split(',')?.map((dt, id) => (
          <div className="bedSegmentsInZip-segment" key={id}>
            {dt}
          </div>
        ))}
      </div>
    </div>
  );
};

const cellFormatterForBestSegmentInZip = (cell, row) => {
  return (
    <div className="cell-div-BestSegmentInZip">
      <div className="bestin-segment-sleep">{row?.Sleeps}</div>
      <div className="bestin-segment-sleep">{row?.Beds}</div>
      <div className="bestin-segment-sleep">{row?.Baths}</div>
    </div>
  );
};

// const multiTextpropertyProfibilityCellFormater = (cell, row) => {
//   return (
//     <div className="multicell-formatter">
//       <div className="profit-mult-cell">
//         {row?.BaselineROI !== null && formatAsPercent(row?.BaselineROI)}
//       </div>
//       <div className="profit-mult-cell">
//         {row?.OptimizedROI !== null && formatAsPercent(row?.OptimizedROI)}
//       </div>
//     </div>
//   );
// };

export const headerForServiceLevelByCity = onClickRankingRow => {
  return [
    {
      dataField: 'MarketName',
      text: 'Cities',
      sort: true,
      headerStyle: { ...headerStyle, width: '16%' },
      style: {
        padding: '0px 10px',
        textWrap: 'wrap',
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'NightlyValueOfSuperhost',
      text: 'Value of\nSuperhost',
      sort: true,
      formatter: NightlyValueOfSuperhost,
      headerStyle: { ...headerStyleForWrappedText, width: '14%' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'NightlyValueOf5StarReviews',
      text: 'Value of\n5-Star\nReviews',
      sort: true,
      formatter: NightlyValueOf5StarReviews,
      headerStyle: { ...headerStyleForWrappedText, width: '14%' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'OptimalCancellationPolicy',
      text: 'Optimal\nCancellation\nPolicy',
      sort: true,
      headerStyle: { ...headerStyleForWrappedText, width: '14%' },
      formatter: OptimalCancellationPolicy,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'OptimalPetsPolicy',
      text: 'Optimal\nPets\nPolicy',
      sort: true,
      headerStyle: { ...headerStyleForWrappedText, width: '14%' },
      formatter: OptimalPetsPolicy,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'ValuableDescriptiveKeywords',
      text: 'Valuable\nDescriptive\nKeywords',
      sort: true,
      headerStyle: headerStyleForWrappedText,
      formatter: ValuableDescriptiveKeywords,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
  ];
};

export const headerForServiceLevelByZip = onClickRankingRow => {
  return [
    {
      dataField: 'MarketName',
      text: 'Zip',
      sort: true,
      headerStyle,
      style: {
        padding: '0px 10px',
        textWrap: 'wrap',
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'NightlyValueOfSuperhost',
      text: 'Value of Superhost',
      sort: true,
      formatter: NightlyValueOfSuperhost,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'NightlyValueOf5StarReviews',
      text: 'Value of 5-Star Reviews',
      sort: true,
      formatter: NightlyValueOf5StarReviews,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'OptimalCancellationPolicy',
      text: 'Optimal Cancellation Policy',
      sort: true,
      headerStyle,
      formatter: OptimalCancellationPolicy,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'OptimalPetsPolicy',
      text: 'Optimal Pets Policy',
      sort: true,
      headerStyle,
      formatter: OptimalPetsPolicy,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'ValuableDescriptiveKeywords',
      text: 'Valuable Descriptive Keywords',
      sort: true,
      headerStyle,
      formatter: ValuableDescriptiveKeywords,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
  ];
};

export const headerForDataDetailsByCity = (
  onSelectDropDown,
  onClickRankingRow,
  surveysReadonly
) => {
  const linkFollow = (cell, row) => {
    return (
      <Dropdown
        // TODO: investigate this
        // eslint-disable-next-line no-bitwise
        align={{ sm: 'start' | 'end' }}
        size="sm"
        drop="end"
        // menuVariant="dark"
        variant="secondary"
        onSelect={e => onSelectDropDown(e, row)}
      >
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-basic"
          size="sm"
        >
          Action
        </Dropdown.Toggle>

        <Dropdown.Menu bsPrefix="  dropdown-menu dropdown__style ">
          {surveysReadonly !== 1 && (
            <Dropdown.Item eventKey="update">Update</Dropdown.Item>
          )}
          <Dropdown.Item eventKey="print">Print</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  return [
    {
      dataField: 'MarketName',
      text: 'Cities',
      sort: true,
      headerStyle,
      style: {
        padding: '0px 10px',
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'SurveyDate',
      text: 'Update Date',
      sort: true,
      formatter: dateFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'DataQuality',
      text: 'Data Quality',
      sort: true,
      headerStyle,
      formatter: dataQualityFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'UserName',
      text: 'User',
      sort: true,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: '',
      text: 'Action',
      formatter: linkFollow,
      sort: false,
      headerStyle: {
        fontSize: '13px',
        fontWeight: 'normal',
        ...headerStyle,
      },
    },
  ];
};

export const headerForDataDetailsByZip = (
  onSelectDropDown,
  onClickRankingRow,
  surveysReadonly
) => {
  const linkFollow = (cell, row) => {
    return (
      <Dropdown
        // TODO: investigate bitwise op
        // eslint-disable-next-line no-bitwise
        align={{ sm: 'start' | 'end' }}
        size="sm"
        drop="end"
        // menuVariant="dark"
        variant="secondary"
        onSelect={e => onSelectDropDown(e, row)}
      >
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-basic"
          size="sm"
        >
          Action
        </Dropdown.Toggle>

        <Dropdown.Menu bsPrefix="  dropdown-menu dropdown__style ">
          {surveysReadonly !== 1 && (
            <Dropdown.Item eventKey="update">Update</Dropdown.Item>
          )}
          <Dropdown.Item eventKey="print">Print</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  return [
    {
      dataField: 'MarketName',
      text: 'Zipcode',
      sort: true,
      headerStyle: { ...headerStyle, width: '26%' },
      style: {
        padding: '0px 10px',
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'HomesInTargetZIPs',
      text: 'Update Date',
      sort: true,
      formatter: dateFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'DataQuality',
      text: 'Data Quality',
      sort: true,
      formatter: dataQualityFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'UserName',
      text: 'User',
      sort: true,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: '',
      text: 'Action',
      formatter: linkFollow,
      sort: false,
      headerStyle: {
        fontSize: '13px',
        fontWeight: 'normal',
        ...headerStyle,
      },
    },
  ];
};

export const surveyDataUpdateByZipColumn = (
  onSelectDropDown,
  getData,
  user
) => {
  const linkFollow = (cell, row) => {
    if (
      row?.DataSurvey_ID === null &&
      row?.SurveyDate === null &&
      row?.DataQuality === '' &&
      row?.isLoading === undefined &&
      row.isLoading === !true
    ) {
      return (
        <div className="get-button-div">
          <button
            type="button"
            disabled={!user?.userInfo?.userRoles?.data_update}
            className="getButton"
            onClick={() => {
              getData(row);
            }}
          >
            Get
          </button>
        </div>
      );
    }
    if (row?.isLoading !== undefined && row?.isLoading === true) {
      return <ClipLoader color="#1470af" loading size={20} />;
    }

    const onlyShowUpdate =
      (row?.DataSurvey_ID === null || row?.SurveyDate === null) &&
      row?.visibility_status === null;
    return (
      <Dropdown
        // TODO: investigate bitwise op
        // eslint-disable-next-line no-bitwise
        align={{ sm: 'start' | 'end' }}
        size="sm"
        drop="end"
        // menuVariant="dark"
        variant="secondary"
        onSelect={e => onSelectDropDown(e, row)}
      >
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-basic"
          size="sm"
        >
          {onlyShowUpdate ? 'Get' : 'Action'}
        </Dropdown.Toggle>
        <Dropdown.Menu bsPrefix="  dropdown-menu dropdown__style ">
          <Dropdown.Item
            disabled={!user?.userInfo?.userRoles?.data_update}
            eventKey="update"
          >
            Update
          </Dropdown.Item>
          {row?.DataSurvey_ID !== null && row?.SurveyDate !== null ? (
            <Dropdown.Item eventKey="print">Print</Dropdown.Item>
          ) : null}
          {row?.visibility_status === null ? null : (
            <Dropdown.Item
              disabled={!user?.userInfo?.userRoles?.data_update}
              eventKey={row?.visibility_status === 1 ? 'Hide' : 'Unhide'}
            >
              {row?.visibility_status === 1 ? 'Hide' : 'Unhide'}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  return [
    {
      dataField: 'ZIPCode',
      text: 'ZIP Code',
      sort: true,
      headerFormatter,
    },
    {
      dataField: 'SurveyDate',
      text: 'Update Date',
      sort: true,
      headerFormatter,
      headerStyle: { width: '18%' },
      formatter: (cell, row) => {
        const onlyShowUpdate = calculateOnlyShowUpdate(row);
        return onlyShowUpdate ? (
          <div
            style={{
              width: '30rem',
            }}
          >
            No Data Survey yet
          </div>
        ) : (
          dateFormatter(cell)
        );
      },
      style: (cell, row) => {
        const onlyShowUpdate = calculateOnlyShowUpdate(row);
        return onlyShowUpdate ? { borderRight: 0 } : {};
      },
    },
    {
      dataField: 'DataQuality',
      text: 'Data Quality',
      sort: true,
      headerFormatter,
      headerStyle: { width: '18%' },
      formatter: (cell, row) => {
        const onlyShowUpdate = calculateOnlyShowUpdate(row);
        return onlyShowUpdate ? null : dataQualityFormatter(cell, row);
      },
      style: (cell, row) => {
        const onlyShowUpdate = calculateOnlyShowUpdate(row);
        return onlyShowUpdate ? { border: 'none' } : {};
      },
    },

    {
      dataField: 'listing_update_frequency',
      text: 'Auto-Update Frequency',
      sort: true,
      headerFormatter,
      headerStyle: { width: '28%' },
      formatter: (cell, row) => {
        const onlyShowUpdate = calculateOnlyShowUpdate(row);
        return onlyShowUpdate ? null : updateFrequencyFormatter(cell, row);
      },
      style: (cell, row) => {
        const onlyShowUpdate = calculateOnlyShowUpdate(row);
        return onlyShowUpdate ? { border: 'none' } : {};
      },
    },
    {
      dataField: 'BaselineMax_Purchase_Price',
      text: 'Action',
      headerFormatter,
      formatter: linkFollow,
      headerStyle: { width: '10%' },
    },
  ];
};

export const surveyDataUpdateByCityColumn = (
  onSelectDropDown,
  getData,
  user
) => {
  const linkFollow = (cell, row) => {
    if (
      row?.DataSurvey_ID === null &&
      row?.SurveyDate === null &&
      row?.DataQuality === 0 &&
      row?.isLoading === undefined &&
      row.isLoading !== true
    ) {
      return (
        <div className="get-button-div">
          <button
            type="button"
            disabled={!user?.userInfo?.userRoles?.data_update}
            className="getButton"
            onClick={() => {
              getData(row);
            }}
          >
            Get
          </button>
        </div>
      );
    }
    if (row?.isLoading !== undefined && row?.isLoading === true) {
      return <ClipLoader color="#1470af" loading size={20} />;
    }
    const onlyShowUpdate =
      (row?.DataSurvey_ID === null || row?.SurveyDate === null) &&
      row?.visibility_status === null;
    return (
      <Dropdown
        // eslint-disable-next-line no-bitwise
        align={{ sm: 'start' | 'end' }}
        size="sm"
        drop="end"
        // menuVariant="dark"
        variant="secondary"
        onSelect={e => onSelectDropDown(e, row)}
      >
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-basic"
          size="sm"
        >
          {onlyShowUpdate ? 'Get' : 'Action'}
        </Dropdown.Toggle>
        <Dropdown.Menu bsPrefix="  dropdown-menu dropdown__style ">
          <Dropdown.Item
            disabled={!user?.userInfo?.userRoles?.data_update}
            eventKey="update"
          >
            Update
          </Dropdown.Item>
          {row?.DataSurvey_ID !== null && row?.SurveyDate !== null ? (
            <Dropdown.Item eventKey="print">Print</Dropdown.Item>
          ) : null}
          {row?.visibility_status === null ? null : (
            <Dropdown.Item
              disabled={!user?.userInfo?.userRoles?.data_update}
              eventKey={row?.visibility_status === 1 ? 'Hide' : 'Unhide'}
            >
              {row?.visibility_status === 1 ? 'Hide' : 'Unhide'}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  return [
    {
      dataField: 'MarketName',
      text: 'City',
      sort: true,
      headerFormatter,
    },
    {
      dataField: 'SurveyDate',
      text: 'Update Date',
      sort: true,
      headerFormatter,
      headerStyle: { width: '18%' },
      formatter: (cell, row) => {
        const onlyShowUpdate = calculateOnlyShowUpdate(row);
        return onlyShowUpdate ? (
          <div
            style={{
              width: '30rem',
            }}
          >
            No Data Survey yet
          </div>
        ) : (
          dateFormatter(cell)
        );
      },
      style: (cell, row) => {
        const onlyShowUpdate = calculateOnlyShowUpdate(row);
        return onlyShowUpdate ? { borderRight: 0 } : {};
      },
    },
    {
      dataField: 'DataQuality',
      text: 'Data Quality',
      sort: true,
      headerFormatter,
      headerStyle: { width: '18%' },
      formatter: (cell, row) => {
        const onlyShowUpdate = calculateOnlyShowUpdate(row);
        return onlyShowUpdate ? null : dataQualityFormatter(cell, row);
      },
      style: (cell, row) => {
        const onlyShowUpdate = calculateOnlyShowUpdate(row);
        return onlyShowUpdate ? { border: 'none' } : {};
      },
    },
    {
      dataField: 'listing_update_frequency',
      text: 'Auto-Update Frequency',
      sort: true,
      headerFormatter,
      headerStyle: { width: '28%' },
      formatter: (cell, row) => {
        const onlyShowUpdate = calculateOnlyShowUpdate(row);
        return onlyShowUpdate ? null : updateFrequencyFormatter(cell, row);
      },
      style: (cell, row) => {
        const onlyShowUpdate = calculateOnlyShowUpdate(row);
        return onlyShowUpdate ? { border: 'none' } : {};
      },
    },
    {
      dataField: 'BaselineMax_Purchase_Price',
      text: 'Action',
      headerFormatter,
      formatter: linkFollow,
      headerStyle: { width: '10%' },
    },
  ];
};

export const rankingColumn = (onSelectDropDown, onClickRankingRow) => {
  const linkFollow = (cell, row) => {
    return (
      <Dropdown
        // eslint-disable-next-line no-bitwise
        align={{ sm: 'start' | 'end' }}
        size="sm"
        drop="end"
        // menuVariant="dark"
        variant="secondary"
        onSelect={e => onSelectDropDown(e, row)}
      >
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-basic"
          size="sm"
        >
          Action
        </Dropdown.Toggle>

        <Dropdown.Menu bsPrefix="  dropdown-menu dropdown__style ">
          <Dropdown.Item eventKey="update">Update</Dropdown.Item>
          <Dropdown.Item eventKey="print">Print</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  return [
    {
      dataField: 'MarketName',
      text: 'Market Name',
      sort: true,
      headerFormatter,
      formatter: (cell, row) => cellFormatter(cell, row),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'SurveyDate',
      text: 'Survey Date',
      sort: true,
      headerFormatter,
      formatter: (cell, row) =>
        cellFormatter(cell, row, [], [], ['SurveyDate'], ['SurveyDate']),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'DataQuality',
      text: 'Data Quality',
      sort: true,
      headerFormatter,
      formatter: dataQualityFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'SurveyType',
      text: 'Action',
      formatter: linkFollow,
      headerFormatter,
    },
  ];
};

export const mapHistoryColumns = [
  {
    dataField: 'market_name',
    text: 'Market',
    sort: true,
    headerFormatter: (column, colIndex, sortElement) =>
      headerFormatter(column, colIndex, sortElement, true),
    formatter: (cell, row) => cellFormatter(cell, row),
  },
  {
    dataField: 'view_date',
    text: 'Last visit',
    sort: true,
    headerFormatter: (column, colIndex, sortElement) =>
      headerFormatter(column, colIndex, sortElement, true),
    formatter: (cell, row) =>
      cellFormatter(cell, row, [], [], ['view_date'], ['view_date']),
    defaultSortOrder: 'desc',
    headerStyle: { width: '22%' },
  },
];

function drilldownHomeCountHeader(column) {
  return (
    <div
      style={{
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div style={{ width: '100%' }}>
        {column.headerPrimaryText && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              ...(column.text && { borderTop: '1px solid #878282' }),
            }}
          >
            {column.headerPrimaryText.split(',').map((dt, idx) => (
              <div key={dt} style={{ width: idx === 0 ? '100%' : '60%' }}>
                <div
                  style={{
                    ...(idx !== 0 && {
                      padding: '2px 10px',
                      wordWrap: 'break-word',
                    }),
                  }}
                >
                  {dt}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export const drilldownBaselineColumn = onSelectDropDown => {
  const linkFollow = (cell, row) => {
    return (
      <Dropdown
        // eslint-disable-next-line no-bitwise
        align={{ sm: 'start' | 'end' }}
        size="sm"
        drop="down-centered"
        // menuVariant="dark"
        variant="secondary"
        onSelect={e => onSelectDropDown(e, row)}
      >
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-basic"
          size="sm"
        >
          Action
        </Dropdown.Toggle>

        <Dropdown.Menu bsPrefix="  dropdown-menu dropdown__style ">
          <Dropdown.Item eventKey="update">Update</Dropdown.Item>
          <Dropdown.Item eventKey="print">Print</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  return [
    {
      dataField: 'ZIPCode',
      text: 'ZIP Code',
      headerFormatter,
      formatter: (cell, row) => cellFormatter(cell, row),
      headerStyle: { ...headerStyle, width: '8%' },
    },
    {
      dataField: 'Baseline_ROI_Numerical_Rank',
      text: 'Baseline ROI Rank In City',
      headerFormatter,
      formatter: (cell, row) => cellFormatter(cell, row),
      headerStyle: { ...headerStyle, width: '8%' },
    },
    {
      dataField: 'BaselineOccupancyPercent',
      text: 'Percentile Rank',
      headerPrimaryText: 'Rev,ROI',
      headerFormatter,
      formatter: percentileRankCellFormatter,
      headerStyle: { ...headerStyle, width: '10%' },
    },
    {
      dataField: 'BaselineMedianADR',
      text: '',
      headerPrimaryText: 'Homes Count,Max Sleeps/Beds/Bath',
      headerFormatter: drilldownHomeCountHeader,
      formatter: homecountSegemtCellFormatter,
      headerStyle: { ...headerStyle, width: '14%' },
    },
    {
      dataField: 'OptimalPetsPolicy',
      text: 'Median Financial Details',
      headerPrimaryText: 'Revenue,ADR,Occup. %',
      headerFormatter,
      formatter: drillbaselineAdr,
      headerStyle: { ...headerStyle, width: '16%' },
    },
    {
      dataField: 'MaxTotalCost',
      text: '',
      headerPrimaryText:
        'Median Home Price,ROI at Median Price,Max Purch. Price,Max Retrofit Budget,Max Total Cost',
      headerFormatter,
      formatter: costTextCellFormatter,
    },
    {
      dataField: 'Status',
      text: 'Action',
      headerFormatter: singleTextFormater,
      headerStyle: { ...headerStyle, width: '8%' },
      formatter: linkFollow,
    },
  ];
};

export const drilldownOptimizedColumn = onSelectDropDown => {
  const linkFollow = (cell, row) => {
    return (
      <Dropdown
        // eslint-disable-next-line no-bitwise
        align={{ sm: 'start' | 'end' }}
        size="sm"
        drop="down-centered"
        // menuVariant="dark"
        variant="secondary"
        onSelect={e => onSelectDropDown(e, row)}
      >
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-basic"
          size="sm"
        >
          Action
        </Dropdown.Toggle>

        <Dropdown.Menu bsPrefix="  dropdown-menu dropdown__style ">
          <Dropdown.Item eventKey="update">Update</Dropdown.Item>
          <Dropdown.Item eventKey="print">Print</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  return [
    {
      dataField: 'ZIPCode',
      text: 'ZIP Code',
      headerFormatter: singleTextFormater,
      formatter: singleCellFormater,
      headerStyle: { ...headerStyle, width: '8%' },
    },
    {
      dataField: 'Optimized_ROI_Numerical_Rank',
      text: 'Optimized ROI Rank In City',
      headerFormatter: singleTextFormater,
      formatter: singleCellFormater,
      headerStyle: { ...headerStyle, width: '8%' },
    },
    {
      dataField: 'MarketName',
      text: 'Rev,ROI',
      headerFormatter: ROIPercentile,
      formatter: percentileOptimizedRankCellFormatter,
      headerStyle: { ...headerStyle, width: '10%' },
    },
    {
      dataField: 'HomesCountInZIP',
      text: '',
      headerFormatter: HomecountMaxSegments,
      formatter: homecountSegemtCellFormatter,
      headerStyle: { ...headerStyle, width: '12%' },
    },
    {
      dataField: 'StateAbbr',
      text: 'Revenue , ADR  ,Occup. %',
      headerFormatter: drilldownFinancialDetails,
      formatter: drillOptimseADR,
      headerStyle: { ...headerStyle, width: '16%' },
    },
    {
      dataField: 'OptimalPetsPolicy',
      text: 'Median Home Price ,ROI at Median Price ,Max Purch. Price,Max Retrofit Budget,Max Total Cost',
      headerFormatter: costTextFormater,
      formatter: costTextOptimizedCellFormatter,
      headerStyle,
    },
    {
      dataField: 'Status',
      text: 'Action',
      headerFormatter: singleTextFormater,
      headerStyle: { ...headerStyle, width: '8%' },
      formatter: linkFollow,
    },
  ];
};

export const headerForbaseLineFinancialDetailsByCity = onClickRankingRow => {
  return [
    {
      dataField: 'MarketName',
      text: 'Cities',
      sort: true,
      formatter: singleTextCellFormater,
      headerStyle: { ...headerStyle, width: '16%' },
      style: { padding: '0px 10px' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'BaselineMedianAnnualRevenue',
      text: 'Median Revenue',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'BaselineMedianADR',
      text: 'Median ADR',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'BaselineOccupancyPercent',
      text: 'Median Occup. %',
      sort: true,
      formatter: singleTextPerCellFormater,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'HomePurchasePrice',
      text: 'Median Home Price',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'BaselineROI',
      text: 'Proj. ROI at Baseline',
      sort: true,
      formatter: percentileRankFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'TargetROI',
      text: 'Target ROI',
      sort: true,
      formatter: percentileRankFormatter,
      headerStyle: { ...headerStyle, width: '8%' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'BaselineMax_Purchase_Price',
      text: 'Max Home Price',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'RetrofitBudget',
      text: 'Retrofit Budget',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'MaxTotalCost',
      text: 'Max Total Cost',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
  ];
};

export const headerForHousingDetailsByCity = onClickRankingRow => {
  return [
    {
      dataField: 'MarketName',
      text: 'Cities',
      sort: true,
      formatter: singleTextCellFormater,
      headerStyle,
      style: { padding: '0px 10px' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'HomesCountInCity',
      text: 'Count in City',
      sort: true,
      formatter: singleNumericCellFormatter,
      headerStyle: { ...headerStyle, width: '8%' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'ZIPsMeetingCriteria',
      text: 'ZIPs Meeting Criteria',
      sort: true,
      formatter: singleNumericCellFormatter,
      headerStyle: { ...headerStyle, width: '9%', padding: '0 2px' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'HomesInTargetZIPs',
      text: 'Homes in Targets ZIPs',
      sort: true,
      formatter: singleNumericCellFormatter,
      headerStyle: { ...headerStyle, width: '12%' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'BestSegmentsInBestZIPs',
      text: 'Best Segment in Best ZIPs',
      sort: true,
      formatter: singleTextCellFormater,
      headerStyle: { ...headerStyle, width: '10%' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'BaselineMedianAnnualRevenue',
      text: 'Median Revenue Baseline',
      sort: true,
      formatter: priceFormatter,
      headerStyle: { ...headerStyle, width: '12%' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'OptimizedProjectedRevenue',
      text: 'Revenue (Optimized)',
      sort: true,
      formatter: priceFormatter,
      headerStyle: { ...headerStyle, width: '12%' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'HomePurchasePrice',
      text: 'Purchase Cost',
      sort: true,
      formatter: priceFormatter,
      headerStyle: { ...headerStyle, width: '8%' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'TargetROI',
      text: 'Target ROI',
      sort: true,
      formatter: singleTextPerCellFormater,
      headerStyle: { ...headerStyle, width: '8%' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
  ];
};

export const headerForHousingDetailsByZip = onClickRankingRow => {
  return [
    {
      dataField: 'ZIPCode',
      text: 'ZIP Codes',
      sort: false,
      formatter: singleTextCellFormater,
      headerStyle: {
        ...headerStyle,
        padding: '8px',
        fontSize: '13px',
        fontWeight: 'normal',
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'HomesCountInZIP',
      text: 'Homes in Targets ZIPs',
      sort: true,
      formatter: singleTextCellFormater,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'BestSegmentsInBestZIPs',
      text: 'Sleep,Bed,Bath',
      sort: true,
      // formatter:singleTextCellFormater
      headerFormatter: bedSegmentsInZip,
      formatter: cellFormatterForBestSegmentInZip,
      headerStyle: { ...headerStyle, width: '18%' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'BaselineMedianAnnualRevenue',
      text: 'Median Revenue Baseline',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'OptimizedProjectedRevenue',
      text: 'Projected Revenue (Optimized)',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'HomePurchasePrice',
      text: 'Purchase Cost',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'TargetROI',
      text: 'Target ROI',
      sort: true,
      formatter: singleTextPerCellFormater,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
  ];
};

export const headerForoptimizedFinancialDetailsByCity = onClickRankingRow => {
  return [
    {
      dataField: 'MarketName',
      text: 'Cities',
      sort: true,
      formatter: singleTextCellFormater,
      headerStyle: { ...headerStyle, width: '18%' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'OptimizedProjectedRevenue',
      text: 'Projected Revenue',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'OptimizedADR',
      text: 'Incr. ADR',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'OptimizedOccupancyPercent',
      text: 'Incr. Occup. %',
      sort: true,
      formatter: singleTextPerCellFormater,
      headerStyle: { ...headerStyle, width: '10%' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'HomePurchasePrice',
      text: 'Median Home Price',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'OptimizedROI',
      text: 'Projected Optim. ROI',
      sort: true,
      formatter: percentileRankFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'TargetROI',
      text: 'Target ROI',
      sort: true,
      formatter: percentileRankFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'OptimizedMax_Purchase_Price',
      text: 'Max Home Price',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'RetrofitBudget',
      text: 'Retrofit Budget',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'MaxTotalCost',
      text: 'Max Total Cost',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
  ];
};

export const headerForbaseLineFinancialDetailsByZip = onClickRankingRow => {
  return [
    {
      dataField: 'MarketName',
      text: 'ZIP Codes',
      sort: true,
      formatter: singleTextCellFormater,
      headerStyle: { ...headerStyle, width: '22%' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'BaselineMedianAnnualRevenue',
      text: 'Median Revenue',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'BaselineMedianADR',
      text: 'Median ADR',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'BaselineOccupancyPercent',
      text: 'Median Occup. %',
      sort: true,
      formatter: singleTextPerCellFormater,
      headerStyle: { ...headerStyle, width: '6%' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'HomePurchasePrice',
      text: 'Median Home Price',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'BaselineROI',
      text: 'Proj. Opt. ROI',
      sort: true,
      formatter: percentileRankFormatter,
      headerStyle: { ...headerStyle, width: '6%' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'TargetROI',
      text: 'Target ROI',
      sort: true,
      headerStyle: { ...headerStyle, width: '6%' },
      formatter: percentileRankFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'BaselineMax_Purchase_Price',
      text: 'Max Home Price',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'RetrofitBudget',
      text: 'Retrofit Budget',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'MaxTotalCost',
      text: 'Max Total Cost',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
  ];
};

export const headerForoptimizedFinancialDetailsByZip = onClickRankingRow => {
  return [
    {
      dataField: 'MarketName',
      text: 'ZIP Codes',
      sort: true,
      formatter: singleTextCellFormater,
      headerStyle: { ...headerStyle, width: '22%' },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'OptimizedProjectedRevenue',
      text: 'Projected Revenue',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'OptimizedADR',
      text: 'Incr. ADR',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'OptimizedOccupancyPercent',
      text: 'Incr Occup. %',
      sort: true,
      formatter: singleTextPerCellFormater,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'HomePurchasePrice',
      text: 'Median Home Price',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'OptimizedROI',
      text: 'Projected Optim. ROI',
      sort: true,
      formatter: percentileRankFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'TargetROI',
      text: 'Target ROI',
      sort: true,
      formatter: percentileRankFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'OptimizedMax_Purchase_Price',
      text: 'Max Home Price',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'RetrofitBudget',
      text: 'Retrofit Budget',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
    {
      dataField: 'MaxTotalCost',
      text: 'Max Total Cost',
      sort: true,
      formatter: priceFormatter,
      headerStyle,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          onClickRankingRow(e, row, rowIndex, column);
        },
      },
    },
  ];
};
export const homeReviewColumns = [
  {
    dataField: 'PropertyID',
    text: 'PropertyID',
    sort: true,
    formatter: singleTextCellFormater,
    headerStyle,
  },
  {
    dataField: 'ZIPCode',
    text: 'ZIP Code',
    sort: true,
    formatter: singleTextCellFormater,
    headerStyle,
  },
  {
    dataField: 'Name',
    text: 'Name',
    sort: true,
    formatter: nameCellFormater,
    headerStyle: { ...headerStyle, width: '60%' },
  },
];

export const prevalenceColumns = (navigateToHome, navigateToSeasonal) => {
  return [
    {
      dataField: 'PrevalencePercent',
      firstColumnText: 'Characteristics',
      text: 'Prevalence',
      headerPrimaryText: 'Count,%',
      textStyle: { paddingTop: '26px' },
      sort: true,
      headerFormatter,
      events: {
        onClick: (e, column, columnIndex, row) => {
          navigateToHome(row);
        },
      },
      formatter: (cell, row) =>
        cellFormatter(
          cell,
          row,
          ['CharacteristicName', 'PrevalenceCount, PrevalencePercent'],
          [],
          ['PrevalencePercent']
        ),
      // defaultSortOrder: 'desc',
    },
    {
      dataField: 'AnnualRevenue_Max',
      text: 'Annual Revenue Value',
      headerPrimaryText: 'Min,Max',
      textStyle: { paddingTop: '26px' },
      headerStyle: { width: '18%' },
      sort: true,
      headerFormatter,
      formatter: (cell, row) =>
        cellFormatter(cell, row, ['AnnualRevenue_Min, AnnualRevenue_Max']),
      events: {
        onClick: (e, column, columnIndex, row) => {
          navigateToSeasonal(row);
        },
      },
    },
    {
      dataField: 'AnnualADR_Max',
      text: 'Annualized Nightly Value',
      headerPrimaryText: 'ADR,Occup. %',
      headerSecondaryText: 'Min,Max',
      preventTextSpacing: true,
      headerStyle: { width: '24%' },
      headerFormatter,
      formatter: (cell, row) =>
        cellFormatter(
          cell,
          row,
          [
            'AnnualADR_Min, AnnualADR_Max',
            'AnnualOccupancyPercent_Min, AnnualOccupancyPercent_Max',
          ],
          [],
          ['AnnualOccupancyPercent_Min', 'AnnualOccupancyPercent_Max']
        ),
      events: {
        onClick: (e, column, columnIndex, row) => {
          navigateToSeasonal(row);
        },
      },
    },
    {
      dataField: 'MarginOfError_ADR',
      text: 'Margin of Error',
      headerPrimaryText: 'ADR,Occup. %',
      textStyle: { paddingTop: '26px' },
      headerStyle: { width: '18%' },
      headerFormatter,
      formatter: (cell, row) =>
        cellFormatter(
          cell,
          row,
          ['MarginOfError_ADR', 'MarginOfError_OccupancyPercent'],
          ['MarginOfError_ADR'],
          ['MarginOfError_OccupancyPercent']
        ),
      events: {
        onClick: (e, column, columnIndex, row) => {
          navigateToSeasonal(row);
        },
      },
    },
  ];
};

export const homeColumns = [
  {
    dataField: 'PrevalenceCount',
    firstColumnText: (
      <>
        <div>Home Size</div>
        <div>Sleeps/Beds/Bath</div>
      </>
    ),
    text: 'Home Count',
    headerPrimaryText: 'Count,%',
    headerFormatter,
    formatter: homeSizeCellFormater,
    headerStyle: { width: '65%' },
  },
  {
    dataField: 'PrevalencePercent',
    text: 'Prevalence',
    headerPrimaryText: ' Count,%',
    sort: true,
    headerFormatter,
    formatter: homeCountPerCellFormatter,
  },
];

export const homeColumnsSeason = [
  {
    dataField: 'SeasonNumber',
    text: 'SeasonNumber',
    headerFormatter: HomeSizeSeason,
    formatter: homeSizeSeasonCellFormater,
    headerStyle: { width: '35%' },
  },
];

export const profitbilityColumn = [
  {
    dataField: 'CharacteristicName',
    firstColumnText: (
      <>
        <div>Home Size</div>
        <div>Sleeps/Beds/Bath</div>
      </>
    ),
    text: 'Characteristic',
    sort: true,
    headerFormatter,
    formatter: profibilityCellFormater,
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      }
      if (a < b) return 1;
      if (a > b) return -1;
      return 0;
    },
  },
  {
    dataField: 'PrevalenceCount',
    text: 'Prevalence',
    headerPrimaryText: 'Count,%',
    lineHeight: '42px',
    sort: true,
    headerFormatter,
    formatter: (cell, row) =>
      cellFormatter(
        cell,
        row,
        ['PrevalenceCount', 'PrevalencePercent'],
        [],
        ['PrevalencePercent']
      ),
    headerStyle: { width: '20%' },
  },
  {
    dataField: 'AnnualRevenue_Max',
    text: 'Annual Revenue Value',
    headerPrimaryText: 'Min,Max',
    sort: true,
    headerFormatter,
    formatter: (cell, row) =>
      cellFormatter(
        cell,
        row,
        ['AnnualRevenue_Min', 'AnnualRevenue_Max'],
        ['AnnualRevenue_Min', 'AnnualRevenue_Max']
      ),
    headerStyle: { width: '20%' },
    sortFunc: (a, b, order) => (order === 'asc' ? a - b : b - a),
  },

  {
    dataField: 'OptimizedROI',
    text: 'Maximum Estimated Property Profitability',
    headerPrimaryText: 'Baseline,Optimized',
    headerFormatter,
    formatter: (cell, row) =>
      cellFormatter(
        cell,
        row,
        ['BaselineROI', 'OptimizedROI'],
        [],
        ['BaselineROI', 'OptimizedROI']
      ),
    headerStyle: { width: '22%' },
  },
];

export const geographyColumn = () => {
  return [
    {
      dataField: 'MarketName',
      firstColumnText: 'MarketName',
      text: 'Prevalence',
      headerPrimaryText: 'Count,%',
      lineHeight: '42px',
      sort: true,
      headerFormatter,
      formatter: (cell, row) =>
        cellFormatter(
          cell,
          row,
          ['MarketName', 'PrevalenceCount, PrevalencePercent'],
          [],
          ['PrevalencePercent']
        ),
    },
    {
      dataField: 'AnnualRevenue_Max',
      text: 'Seasonal Annual Revenue Value',
      headerPrimaryText: 'Min,Max',
      sort: true,
      headerFormatter,
      formatter: (cell, row) =>
        cellFormatter(
          cell,
          row,
          ['AnnualRevenue_Min', 'AnnualRevenue_Max'],
          ['AnnualRevenue_Min', 'AnnualRevenue_Max']
        ),
      headerStyle: { width: '26%' },
    },
    {
      dataField: 'OptimizedROI',
      text: 'Maximum Estimated Property Profitability',
      headerPrimaryText: 'Baseline,Optimized',
      sort: true,
      headerFormatter,
      formatter: (cell, row) =>
        cellFormatter(
          cell,
          row,
          ['BaselineROI', 'OptimizedROI'],
          [],
          ['BaselineROI', 'OptimizedROI']
        ),
      headerStyle: { width: '30%' },
    },
  ];
};

// const seasonalADRImpactCellFormatter = (cell, row) => {
//   return (
//     <div className="season-annual-prevalenceCountPerCell homeCursorCell">
//       <div className="season-annual-value">
//         {USDollar.format(row?.AnnualADR_Min)}
//       </div>
//       <div className="season-annual-value">
//         {' '}
//         {USDollar.format(row?.AnnualADR_Max)}
//       </div>
//     </div>
//   );
// };

// const seasonalOccImpact = (column, colIndex, { sortElement }) => {
//   return (
//     <div className="season-max-min">
//       <div
//         className="seasonalImpactAdr"
//         style={sortElement?.props?.order ? { padding: '3px 0' } : {}}
//       >
//         <div>Impact on</div>
//         <div>Occup. %</div>
//         <span
//           className={`react-bootstrap-table-sort-order ${
//             sortElement?.props?.order === 'asc' ? ' dropup' : ''
//           }`}
//         >
//           {!sortElement?.props?.order ? (
//             <span className="order">
//               <span className="dropdown">
//                 <span className="caret" />
//               </span>
//               <span className="dropup">
//                 <span className="caret" />
//               </span>
//             </span>
//           ) : (
//             <span className="caret" />
//           )}
//         </span>
//       </div>
//       <div className="season-maxmin-div">
//         {column.text.split(',')?.map((dt, id) => (
//           <div className="season-min-max-space" key={id}>
//             {dt}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const seasonalOccImpactCellFormatter = (cell, row) => {
//   return (
//     <div className="season-annual-prevalenceCountPerCell homeCursorCell">
//       <div className="season-annual-value">
//         {USDollar.format(row?.AnnualOccupancyPercent_Min)}
//       </div>
//       <div className="season-annual-value">
//         {' '}
//         {USDollar.format(row?.AnnualOccupancyPercent_Max)}
//       </div>
//     </div>
//   );
// };

// const seasonalADRImpact = (column, colIndex, { sortElement }) => {
//   return (
//     <div className="season-max-min">
//       <div
//         className="seasonalImpactAdr"
//         style={sortElement?.props?.order ? { padding: '3px 0' } : {}}
//       >
//         <div>Seasonal</div>
//         <div>Impact on ADR</div>
//         <span
//           className={`react-bootstrap-table-sort-order ${
//             sortElement?.props?.order === 'asc' ? ' dropup' : ''
//           }`}
//         >
//           {!sortElement?.props?.order ? (
//             <span className="order">
//               <span className="dropdown">
//                 <span className="caret" />
//               </span>
//               <span className="dropup">
//                 <span className="caret" />
//               </span>
//             </span>
//           ) : (
//             <span className="caret" />
//           )}
//         </span>
//       </div>
//       <div className="season-maxmin-div">
//         {column.text.split(',')?.map((dt, id) => (
//           <div className="season-min-max-space" key={id}>
//             {dt}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const seasonalAnnualRevVal = (column, colIndex, { sortElement }) => {
//   return (
//     <div className="season-max-min">
//       <div
//         className="seasonalImpactAdr2"
//         style={sortElement?.props?.order ? { padding: '3px 0' } : {}}
//       >
//         <div>Seasonal Annual</div>
//         <div>Revenue Value</div>
//         <span
//           className={`react-bootstrap-table-sort-order ${
//             sortElement?.props?.order === 'asc' ? ' dropup' : ''
//           }`}
//         >
//           {!sortElement?.props?.order ? (
//             <span className="order">
//               <span className="dropdown">
//                 <span className="caret" />
//               </span>
//               <span className="dropup">
//                 <span className="caret" />
//               </span>
//             </span>
//           ) : (
//             <span className="caret" />
//           )}
//         </span>
//       </div>
//       <div className="season-maxmin-div">
//         {column.text.split(',')?.map((dt, id) => (
//           <div className="season-min-max-space" key={id}>
//             {dt}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const seasonalAnnualRevValFormatter = (cell, row) => {
//   return (
//     <div className="season-annual-prevalenceCountPerCell homeCursorCell">
//       <div className="season-annual-value">
//         {USDollar.format(row?.AnnualRevenue_Min)}
//       </div>
//       <div className="season-annual-value">
//         {' '}
//         {USDollar.format(row?.AnnualRevenue_Max)}
//       </div>
//     </div>
//   );
// };
export const seasonalByCharcterstics = [
  {
    dataField: 'CharacteristicName',
    text: 'Characteristic ',
    sort: true,
    headerFormatter,
    formatter: (cell, row) => cellFormatter(cell, row),
  },
  {
    dataField: 'PrevalencePercent',
    text: 'Prevalence',
    headerPrimaryText: 'Count,%',
    lineHeight: '42px',
    sort: true,
    headerFormatter,
    formatter: (cell, row) =>
      cellFormatter(
        cell,
        row,
        ['PrevalenceCount', 'PrevalencePercent'],
        [],
        ['PrevalencePercent']
      ),
    headerStyle: { width: '16%' },
  },
  {
    dataField: 'AnnualRevenue_Max',
    text: 'Seasonal Annual Revenue Value',
    headerPrimaryText: 'Min,Max',
    sort: true,
    headerFormatter,
    formatter: (cell, row) =>
      cellFormatter(
        cell,
        row,
        ['AnnualRevenue_Min', 'AnnualRevenue_Max'],
        ['AnnualRevenue_Min', 'AnnualRevenue_Max']
      ),
    headerStyle: { width: '18%' },
    defaultSortOrder: 'desc',
  },
  {
    dataField: 'AnnualADR_Max',
    text: 'Seasonal Impact on ADR',
    headerPrimaryText: 'Min,Max',
    sort: true,
    headerFormatter,
    formatter: (cell, row) =>
      cellFormatter(
        cell,
        row,
        ['AnnualADR_Min', 'AnnualADR_Max'],
        ['AnnualADR_Min', 'AnnualADR_Max']
      ),
    headerStyle: { width: '16%' },
    sortFunc: (a, b, order) => (order === 'asc' ? a - b : b - a),
  },
  {
    dataField: 'AnnualOccupancyPercent_Max',
    text: 'Impact on Occup. %',
    headerPrimaryText: 'Min,Max',
    sort: true,
    headerFormatter,
    formatter: (cell, row) =>
      cellFormatter(
        cell,
        row,
        ['AnnualOccupancyPercent_Min', 'AnnualOccupancyPercent_Max'],
        [],
        ['AnnualOccupancyPercent_Min', 'AnnualOccupancyPercent_Max']
      ),
    headerStyle: { width: '16%' },
  },
];

export const seasonalByTopCharSeason = [
  {
    dataField: 'CalendarText',
    text: 'Season',
    sort: true,
    headerFormatter,
    formatter: (cell, row) => cellFormatter(cell, row),
  },
  {
    dataField: 'PrevalencePercent',
    text: 'Prevalence',
    headerPrimaryText: 'Count,%',
    lineHeight: '42px',
    sort: true,
    headerFormatter,
    formatter: (cell, row) =>
      cellFormatter(
        cell,
        row,
        ['PrevalenceCount', 'PrevalencePercent'],
        [],
        ['PrevalencePercent']
      ),
    headerStyle: { width: '16%' },
  },
  {
    dataField: 'AnnualRevenue_Max',
    text: 'Seasonal Annual Revenue Value',
    headerPrimaryText: 'Min,Max',
    sort: true,
    headerFormatter,
    formatter: (cell, row) =>
      cellFormatter(
        cell,
        row,
        ['AnnualRevenue_Min', 'AnnualRevenue_Max'],
        ['AnnualRevenue_Min', 'AnnualRevenue_Max']
      ),
    headerStyle: { width: '18%' },
  },
  {
    dataField: 'SeasonNumber',
    text: 'Seasonal Impact on ADR',
    headerPrimaryText: 'Min,Max',
    sort: true,
    headerFormatter,
    formatter: (cell, row) =>
      cellFormatter(
        cell,
        row,
        ['AnnualADR_Min', 'AnnualADR_Max'],
        ['AnnualADR_Min', 'AnnualADR_Max']
      ),
    headerStyle: { width: '16%' },
  },
  {
    dataField: 'BaselineMedianAnnualRevenue',
    text: 'Impact on Occup. %',
    headerPrimaryText: 'Min,Max',
    sort: true,
    headerFormatter,
    formatter: (cell, row) =>
      cellFormatter(
        cell,
        row,
        ['AnnualOccupancyPercent_Min', 'AnnualOccupancyPercent_Max'],
        [],
        ['AnnualOccupancyPercent_Min', 'AnnualOccupancyPercent_Max']
      ),
    headerStyle: { width: '16%' },
  },
];
