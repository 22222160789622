const getLoadState = async id => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASEURL}/fileLoad/${id}`,
    {
      method: 'GET',
    }
  );
  return response.json();
};

export default getLoadState;
