import axiosConfig from '../axiosConfig';

const fetchAllSegmentsAsync = async zipCodes => {
  const response = await axiosConfig.get('/fat/segment', {
    params: { zipCodes },
  });
  return response.data;
};

const getCurrentLocationAsync = async () => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get('/fat/location');
  return response.data;
};

const saveCurrentLocationAsync = async location => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.post('/fat/location', {
    ZIPCodesList: location.zipCodes?.join(',') || '',
    SegmentIDsList: location.segmentIds?.join(',') || '',
    Segments: location.segmentType,
    InPerson: location.surveyType,
  });
  return response.data;
};

const getRecentPropertiesAsync = async () => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get('/fat/recent');
  return response.data;
};

const clearRecentPropertiesAsync = async () => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.delete('/fat/recent');
  return response.data;
};

const getSavedSearchesAsync = async () => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get('/fat/savedSearches');
  return response.data;
};

const storeSavedSearchAsync = async savedSearch => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.post('/fat/savedSearches', savedSearch);
  return response.data;
};

const deleteSavedSearchAsync = async id => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.delete(`/fat/savedSearches/${id}`);
  return response.data;
};

const getTodaysListAsync = async () => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get('/fat/todaysList');
  return response.data;
};

const clearTodaysListAsync = async () => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.delete('/fat/todaysList');
  return response.data;
};

const deleteTodaysListItemAsync = async id => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.delete(`/fat/todaysList/${id}`);
  return response.data;
};

const upsertTodaysListItemAsync = async properties => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.patch(`/fat/todaysList`, properties);
  return response.data;
};

const removeTodaysListItemsAsync = async properties => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.patch(
    `/fat/todaysList/remove`,
    properties
  );
  return response.data;
};

const getShortListAsync = async () => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get('/fat/shortlists');
  return response.data;
};

const replaceShortListAsync = async properties => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.patch('/fat/shortlists', properties);
  return response.data;
};

const getOffersAsync = async () => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get('/fat/offers');
  return response.data;
};

const makeOffersAsync = async properties => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.post('/fat/offers', properties);
  return response.data;
};

const rescindOffersAsync = async properties => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.delete(
    `/fat/offers/${properties.join(',')}`
  );
  return response.data;
};

const getNotificationsAsync = async () => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get('/fat/notifications');
  return response.data;
};

const markNotificationAsReadAsync = async id => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.patch(`/fat/notifications/${id}`);
  return response.data;
};

const clearNotificationsAsync = async () => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.delete('/fat/notifications');
  return response.data;
};

const getSegmentPriorityByZipCodeAsync = async zipCode => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get(`/fat/segmentPriority/${zipCode}`);
  return response.data;
};

const getZipCodesForAutoCompleteAsync = async (query, exclude = []) => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get(`/fat/zipCodes/${query}`, {
    params: { exclude: exclude.join(',') },
  });
  return response.data;
};

const getStateAbbrsAsync = async () => {
  const response = await axiosConfig.get(`/fat/states`);
  return response.data;
};

const updateRankBulkAsync = async (properties, rank) => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.put('/fat/propertyRating', {
    properties,
    UserRating: rank,
  });
  return response.data;
};

const createPropertyAsync = async newProperty => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.post('/fat/listedProperty', newProperty);
  return response.data;
};

const getPropertyByIdAsync = async id => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get(`/fat/listedProperty/${id}`);
  return response.data;
};

const getNotesAndRatingsAsync = async id => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get(`/fat/notesAndRatings/${id}`);
  return response.data;
};

const updateNotesAndRatingsAsync = async notesAndRatings => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.put(
    `/fat/notesAndRatings`,
    notesAndRatings
  );
  return response.data;
};

const calculateScoreAsync = async id => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get(`/fat/algorithmicScore/${id}`);
  return response.data;
};

const saveUserMediaAsync = async media => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.post('/fat/media', media);
  return response.data;
};

const updateSavedSearchUpdateFrequencyAsync = async ({
  ZIPCodeList,
  GeographyID,
}) => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.put('/fat/savedSearchesFrequency', {
    GeographyID,
    ZIPCodeList,
  });
  return response.data;
};

const searchPropertyAsync = async (
  strAddress,
  intGeographyID,
  strPropertyTypes,
  strPropertyTypeStrings,
  strZIPCodes
) => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.post('/fat/searchProperty', {
    strAddress,
    intGeographyID,
    strPropertyTypes,
    strPropertyTypeStrings,
    strZIPCodes,
  });
  return response.data;
};

const updatePropertyInfoAsync = async propertyInfo => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.put('/fat/listedProperty', propertyInfo);
  return response.data;
};

const getPropertyTypeGroupingsAsync = async () => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get('/fat/propertyTypeGroupings');
  return response.data;
};

const getPropertyPrintDetailsAsync = async propertyIds => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.post('/fat/propertyPrintDetails', {
    propertyIds,
  });
  return response.data;
};

const getMediaAsync = async ({ DatafinitiID, propertyId }) => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get(
    `/fat/media?DatafinitiID=${DatafinitiID}&ListedPropertiesID=${propertyId}`
  );
  return response.data;
};

const getMatchingSearchesAsync = async () => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get('/fat/matchingSearches');
  return response.data;
};

const clearMatchingSearchesAsync = async () => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.delete('/fat/matchingSearches');
  return response.data;
};

const getSurveyCharacteristicsAsync = async id => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get(`/fat/characteristics/${id}`);
  return response.data;
};

const parseCharacteristicsAsync = async id => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get(`/fat/characteristics/parse/${id}`);
  return response.data;
};

const addCharacteristicsAsync = async characteristics => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.post(
    '/fat/characteristics',
    characteristics
  );
  return response.data;
};

const removeCharacteristicsAsync = async id => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.delete(`/fat/characteristics/${id}`);
  return response.data;
};

const addCharacteristicNotesAsync = async notes => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.post('/fat/characteristics/notes', notes);
  return response.data;
};

const sendEmailAsync = async email => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.post('/fat/sendEmail', email);
  return response.data;
};

const getSurveyStatusesAsync = async () => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.get('/fat/surveyStatus');
  return response.data;
};

const reorderTodaysListAsync = async properties => {
  axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token'
  )}`;
  const response = await axiosConfig.patch(
    '/fat/todaysList/reorder',
    properties
  );
  return response.data;
};

export {
  calculateScoreAsync,
  clearMatchingSearchesAsync,
  clearNotificationsAsync,
  clearRecentPropertiesAsync,
  clearTodaysListAsync,
  createPropertyAsync,
  deleteSavedSearchAsync,
  deleteTodaysListItemAsync,
  fetchAllSegmentsAsync,
  getCurrentLocationAsync,
  getMatchingSearchesAsync,
  getMediaAsync,
  getNotesAndRatingsAsync,
  getNotificationsAsync,
  getOffersAsync,
  getPropertyByIdAsync,
  getPropertyPrintDetailsAsync,
  getPropertyTypeGroupingsAsync,
  getRecentPropertiesAsync,
  getSavedSearchesAsync,
  getSegmentPriorityByZipCodeAsync,
  getShortListAsync,
  getStateAbbrsAsync,
  getTodaysListAsync,
  getZipCodesForAutoCompleteAsync,
  makeOffersAsync,
  replaceShortListAsync,
  rescindOffersAsync,
  saveCurrentLocationAsync,
  saveUserMediaAsync,
  searchPropertyAsync,
  storeSavedSearchAsync,
  updateNotesAndRatingsAsync,
  updatePropertyInfoAsync,
  updateRankBulkAsync,
  updateSavedSearchUpdateFrequencyAsync,
  upsertTodaysListItemAsync,
  markNotificationAsReadAsync,
  getSurveyCharacteristicsAsync,
  parseCharacteristicsAsync,
  addCharacteristicsAsync,
  removeCharacteristicsAsync,
  addCharacteristicNotesAsync,
  sendEmailAsync,
  getSurveyStatusesAsync,
  reorderTodaysListAsync,
  removeTodaysListItemsAsync,
};
