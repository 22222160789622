import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../axiosConfig';
import { notifyError } from '../../component/Toaster';

export const login = createAsyncThunk('user/login', async thunkAPI => {
  try {
    const response = await axiosConfig.post(
      `${process.env.REACT_APP_API_BASEURL}/user/login`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return notifyError(error?.response?.data?.error);
  }
});

export const userInfo = createAsyncThunk('user/userInfo', async () => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.get(
      `${process.env.REACT_APP_API_BASEURL}/user/userInfo`
    );
    return response.data;
  } catch (error) {
    notifyError(error?.response?.data?.error);
    throw error;
  }
});

export const updateProfile = createAsyncThunk(
  'user/updateProfile',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `${process.env.REACT_APP_API_BASEURL}/user/updateProfile`,
        thunkAPI
      );
      return response;
    } catch (error) {
      return notifyError(error?.response?.data?.error);
    }
  }
);

export const updatePassword = async data => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `${process.env.REACT_APP_API_BASEURL}/user/updatePassword`,
      data
    );
    return response;
  } catch (error) {
    return notifyError(error?.response?.data?.error);
  }
};

export const createUser = createAsyncThunk(
  'user/createUser',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `${process.env.REACT_APP_API_BASEURL}/user`,
        thunkAPI
      );
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const usersList = createAsyncThunk('user/usersList', async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `${process.env.REACT_APP_API_BASEURL}/user/admin/users`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return notifyError(error?.response?.data?.error);
  }
});
export const deleteUser = async id => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.delete(
      `${process.env.REACT_APP_API_BASEURL}/user/${id}`
    );
    return response;
  } catch (error) {
    return notifyError(error?.response?.data?.error);
  }
};

export const updateUser = async (id, data) => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.patch(
      `${process.env.REACT_APP_API_BASEURL}/user/${id}`,
      data
    );
    return response;
  } catch (error) {
    return notifyError(error?.response?.data?.error);
  }
};

export const getEnv = async () => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.get(
      `${process.env.REACT_APP_API_BASEURL}/user/getEnv`
    );
    return response;
  } catch (error) {
    return notifyError(error?.response?.data?.error);
  }
};

export const changeEnv = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `${process.env.REACT_APP_API_BASEURL}/user/changeEnv`,
      thunkAPI
    );
    return response;
  } catch (error) {
    return notifyError(error?.response?.data?.error);
  }
};

export const getCronJobStatus = async () => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.get(
      `${process.env.REACT_APP_API_BASEURL}/user/getCronJobStatus`
    );
    return response;
  } catch (error) {
    return notifyError(error?.response?.data?.error);
  }
};

export const updateCronJobStatus = async body => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `${process.env.REACT_APP_API_BASEURL}/user/updateCronJobStatus`,
      body
    );
    return response;
  } catch (error) {
    return notifyError(error?.response?.data?.error);
  }
};
