import React from 'react';
import { toast, ToastContainer } from 'react-toastify';

export const notifyError = message =>
  toast.error(message, {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    onClose: () => {},
  });

export const notify = message =>
  toast.success(message, {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    onClose: () => {},
  });

const Toaster = () => {
  return <ToastContainer theme="dark" />;
};

export default Toaster;
