import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import { getTodaysListAsync } from '../../redux/fieldAnalysis/fieldAnalysisApi';
import FATTable from '../../component/FATTable/FATTable';
import OrderedTable from '../../component/FAT/OrderedTable';

const TodaysList = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortList, setSortList] = useState(false);
  const { state } = useLocation();

  const navigate = useNavigate();

  const loadTodaysList = () => {
    setLoading(true);
    getTodaysListAsync()
      .then(props => {
        setProperties(props);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadTodaysList();
  }, []);

  useEffect(() => {
    setSortList(state?.sortList ?? false);
  }, [state?.sortList]);

  const handleAction = action => {
    if (action === 'clearList') {
      loadTodaysList();
    }
    if (action === 'sortList') {
      setSortList(!sortList);
    }
  };

  return (
    <div className="container-flex p-4">
      <Stack direction="row" justifyContent="space-between">
        <h5>{sortList ? 'Sorting ' : ''}Today&apos;s List</h5>
        {sortList && (
          <Button
            variant="contained"
            size="medium"
            className="mb-3"
            onClick={() => {
              setSortList(!sortList);
              loadTodaysList();
            }}
          >
            Done
          </Button>
        )}
      </Stack>
      {!sortList ? (
        <FATTable
          properties={properties}
          loading={loading}
          title="Today's List"
          columns={[
            { id: 'PropertySize', label: 'Property Size' },
            { id: 'Address', label: 'Property Address' },
            {
              id: 'WHRank',
              label: 'WH Rank',
              format: v => (v ? `${v}/10` : 'NA'),
            },
            {
              id: 'AlgorithmicRating',
              label: 'Algorithmic Rating',
              format: v => (v ? `${v}/10` : 'NA'),
            },
            { id: 'Status', label: 'Status', default: 'Active' },
          ]}
          onRowClick={row => navigate(`/fat/survey/${row.ID}`)}
          context="todays_list"
          onAction={handleAction}
        />
      ) : (
        <OrderedTable
          properties={properties}
          columns={[
            { id: 'PropertySize', label: 'Property Size' },
            { id: 'Address', label: 'Property Address' },
            {
              id: 'WHRank',
              label: 'WH Rank',
              format: v => (v ? `${v}/10` : 'NA'),
            },
            {
              id: 'AlgorithmicRating',
              label: 'Algorithmic Rating',
              format: v => (v ? `${v}/10` : 'NA'),
            },
            { id: 'Status', label: 'Status', default: 'Active' },
          ]}
        />
      )}
    </div>
  );
};

export default TodaysList;
