/**
 * Formats a number with commas as thousands separators.
 * @param {number} number - The number to format.
 * @returns {string} - The formatted number as a string.
 */
export const formatNumber = number => {
  return number !== undefined
    ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : 0;
};

export const formatNumberWithSuffix = (value, currency = false) => {
  if (value !== undefined && value !== null) {
    const numericValue = parseFloat(String(value).replace(/[^0-9.-]+/g, ''));
    const suffixes = ['', 'k', 'M'];
    const suffixIndex = Math.max(
      0,
      Math.floor(Math.log10(Math.abs(numericValue)) / 3)
    );
    const roundedValue = (numericValue / 10 ** (suffixIndex * 3)).toFixed(0);
    const formattedNumber =
      currency === 'USD'
        ? new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumSignificantDigits: 2,
          }).format(roundedValue)
        : roundedValue;
    return `${formattedNumber}${suffixes[suffixIndex]}`;
  }
  return 0;
};
