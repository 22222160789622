import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import PropertyInfo from '../../component/FAT/PropertyInfo';

const Survey = () => {
  const { id: propertyId } = useParams();
  const { state } = useLocation();
  const isNew = state ? state.isNew : false;
  return <PropertyInfo listedPropertyId={propertyId} readOnly={!isNew} />;
};

export default Survey;
