/* eslint-disable import/no-cycle */
// TODO: invesitage no=console
/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../axiosConfig';

export const getZipCodes = createAsyncThunk(
  'ranking/getZipCodes',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.get(`/zipcode?search=${thunkAPI}`);
      return response.data;
    } catch (error) {
      console.log('--error----', error);
    }
    return null;
  }
);

export const ranking = createAsyncThunk(
  'ranking/getMarketRanking',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/survey/getMarketRanking`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log('--error----', error);
    }
    return null;
  }
);

export const stateList = createAsyncThunk('ranking/stateList', async () => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.get(`/zipcode/States`);
    return response.data;
  } catch (error) {
    console.log('error');
  }
  return null;
});

export const cityList = createAsyncThunk('ranking/cityList', async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.get(
      `/zipcode/Cities?search=${thunkAPI}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
  return null;
});

// -------------city--------------
export const cityListRanking = createAsyncThunk(
  'ranking/cityListRanking',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/city/cityListRanking`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);

export const cityListSurvey = createAsyncThunk(
  'ranking/cityListSurvey',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(`/city/cityListSurvey`, thunkAPI);
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);

export const cityListSurveyUpdate = createAsyncThunk(
  'ranking/cityListSurveyUpdate',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/city/cityListSurveyUpdate`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);

export const cityListRevADROccupancyROI = createAsyncThunk(
  'ranking/cityListRevADROccupancyROI',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/city/cityListRevADROccupancyROI`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);

// --zips----------------

export const zipListRanking = createAsyncThunk(
  'ranking/zipListRanking',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/zipcode/zipListRanking`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);
export const zipListSurvey = createAsyncThunk(
  'ranking/zipListSurvey',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/zipcode/zipListSurvey`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);
export const zipListSurveyUpdate = createAsyncThunk(
  'ranking/zipListSurveyUpdate',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/zipcode/zipListSurveyUpdate`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);

export const zipsListRevADROccupancyROI = createAsyncThunk(
  'ranking/zipsListRevADROccupancyROI',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/city/zipsListRevADROccupancyROI`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);

export const getSurveyDataCities = createAsyncThunk(
  'ranking/surveyDataCities',
  async thunkAPI => {
    try {
      const response = await axiosConfig.post(
        `/survey/getSurveyCities`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);

export const getSurveyDataZips = createAsyncThunk(
  'ranking/surveyDataZips',
  async thunkAPI => {
    try {
      const response = await axiosConfig.post(
        `/survey/getSurveyZips`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);

export const getSurveyDataUpdateByZips = createAsyncThunk(
  'ranking/getSurveyDataUpdateByZips',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/survey/getSurveyDataUpdateByZips`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);

export const getSurveyDataUpdateByCities = createAsyncThunk(
  'ranking/getSurveyDataUpdateByCities',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/survey/getSurveyDataUpdateByCities`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);

export const getSurveyMapCities = createAsyncThunk(
  'ranking/getSurveyMapCities',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/survey/getSurveyMapCities`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);

export const getSurveyMapZips = createAsyncThunk(
  'ranking/getSurveyMapZips',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/survey/getSurveyMapZips`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);

export const getSurveyMapHistory = createAsyncThunk(
  'ranking/getSurveyMapHistory',
  async () => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.get(`/survey/getSurveyMapHistory`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);

export const saveUserMapHistory = async data => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(`/survey/saveUserMapHistory`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getTrendsOccupancyROICities = createAsyncThunk(
  'ranking/getTrendsOccupancyROICities',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/survey/getTrendsOccupancyROICities`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);

export const getTrendsOccupancyROIZips = createAsyncThunk(
  'ranking/getTrendsOccupancyROIZips',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/survey/getTrendsOccupancyROIZips`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);

export const getTrendsHomePriceCities = createAsyncThunk(
  'ranking/getTrendsHomePriceCities',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/survey/getTrendsHomePriceCities`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);

export const getTrendsHomePriceZips = createAsyncThunk(
  'ranking/getTrendsHomePriceZips',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/survey/getTrendsHomePriceZips`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
);

export const dataSurveyVisibilityChange = async data => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/survey/dataSurveyVisibilityChange`,
      data
    );
    return response;
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const createOrUpdateSurvey = async data => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(`/survey`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const downloadPdf = createAsyncThunk('pdf/downloadPdf', async params => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;

    const response = await axiosConfig.post(`/survey/downloadPdf`, params, {
      responseType: 'blob',
    });

    // Get the filename from the Content-Disposition header
    const contentDisposition = response.headers['content-disposition'];
    const filename = contentDisposition.split('filename=')[1];

    // Create a Blob from the response data
    const fileBlob = new Blob([response.data], { type: 'application/pdf' });

    // Create a Blob URL and open it in a new tab for print preview
    const fileUrl = URL.createObjectURL(fileBlob);
    window.open(fileUrl, '_blank');

    // enabling direct download by creating a download link
    const alink = document.createElement('a');
    alink.download = filename;
    alink.href = fileUrl;
    alink.style.display = 'none'; // Hide the link
    alink.click();
    alink.remove();
    return false;
  } catch (error) {
    console.log(error);
  }
  return false;
});
