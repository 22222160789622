/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import images from '../../assets/images';
import { reorderTodaysList } from '../../redux/fieldAnalysis/fieldAnalysisSlice';
import { notifyError } from '../Toaster';

const OrderedTable = ({ properties: props, columns = [] }) => {
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    setProperties(props);
  }, [props]);

  const dispatch = useDispatch();

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const reorderedProperties = Array.from(properties);
    const [removed] = reorderedProperties.splice(result.source.index, 1);
    reorderedProperties.splice(result.destination.index, 0, removed);
    setProperties(reorderedProperties);
    dispatch(reorderTodaysList(reorderedProperties)).then(action => {
      if (action.error) {
        setProperties(props);
        notifyError(action.error);
      }
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <table
            id="recent-properties"
            className="table"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <thead>
              <tr>
                <th>&nbsp;</th>
                {columns.map(column => (
                  <th key={column.id}>{column.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {properties.map((property, index) => (
                <Draggable
                  key={property.ID}
                  draggableId={`''${property.ID}`}
                  index={index}
                >
                  {provided => (
                    <tr
                      id={`''${property.ID}`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <td
                        {...provided.dragHandleProps}
                        style={{ cursor: 'grab', width: '40px' }}
                      >
                        <img
                          className="icon"
                          src={images.dragHandle}
                          alt="Drag Handle"
                        />
                      </td>
                      {columns.map(column => (
                        <td key={column.id}>{property[column.id]}</td>
                      ))}
                    </tr>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </tbody>
          </table>
        )}
      </Droppable>
    </DragDropContext>
  );
};

OrderedTable.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.shape),
  columns: PropTypes.arrayOf(PropTypes.shape),
};

OrderedTable.defaultProps = {
  properties: [],
  columns: [],
};

export default OrderedTable;
