/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import lodash from 'lodash';
import AsyncSelect from 'react-select/async';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import debounce from 'debounce-promise';
import { useDispatch, useSelector } from 'react-redux';
import HookChart from '../../component/Charts';
import Filter from '../../component/FilterDropdown';
import {
  getTrendsHomePriceCities,
  getTrendsOccupancyROICities,
  cityListRevADROccupancyROI,
  zipsListRevADROccupancyROI,
  getTrendsOccupancyROIZips,
  getTrendsHomePriceZips,
} from '../../redux/survey/api';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const badgeStyle = {
  backgroundColor: 'gray',
  padding: '2px 4px',
  borderRadius: 5,
  fontSize: 12,
  color: 'white',
  marginLeft: 5,
};

const chartType = [
  {
    optionName: 'Select Chart',
    optionValue: '',
  },
  {
    optionName: 'Annualized Revenue Trend',
    optionValue: 'AnnualAveragePropertyRevenue',
  },
  {
    optionName: 'Monthly Revenue',
    optionValue: 'MonthlyRevenue',
  },
  {
    optionName: 'ADR Trend',
    optionValue: 'AverageADR',
  },
  {
    optionName: 'Occupancy Rate Trend',
    optionValue: 'AverageOccupancyPercent',
  },
  {
    optionName: 'Baseline Profitability',
    optionValue: 'BaselineROI',
  },
  {
    optionName: 'Optimized Profitability',
    optionValue: 'AverageOccupancyPercent',
  },
  {
    optionName: 'Annualized Total Market Revenue Trend',
    optionValue: 'HomePrice',
  },
  {
    optionName: 'Median Home Price Trend',
    optionValue: 'AnnualTotalMarketRevenue',
  },
];

const TabTrends = () => {
  const dispatch = useDispatch();
  const { isEmpty } = lodash;
  const cityData = useSelector(
    state => state?.ranking?.cityListRevADROccupancyROI
  );
  const trendsOccupancyROICities = useSelector(
    state => state.ranking.trendsOccupancyROICities
  );
  const trendsHomePriceCities = useSelector(
    state => state.ranking.trendsHomePriceCities
  );
  const trendsOccupancyROICitiesLoader = useSelector(
    state => state.ranking.trendsOccupancyROICitiesLoader
  );
  const zipData = useSelector(
    state => state?.ranking?.zipsListRevADROccupancyROI
  );
  const trendsOccupancyROIZips = useSelector(
    state => state.ranking.trendsOccupancyROIZips
  );
  const trendsHomePriceZips = useSelector(
    state => state.ranking.trendsHomePriceZips
  );
  const trendsOccupancyROIZipsLoader = useSelector(
    state => state.ranking.trendsOccupancyROIZipsLoader
  );

  const [selectedChartType, setSelectedChartType] = useState('');
  const [selectedOption, setSelectedOption] = useState([]);
  const [chartData, setChartData] = useState({
    MonthlyRevenue: [],
    AverageADR: [],
    AnnualAveragePropertyRevenue: [],
    AnnualTotalMarketRevenue: [],
    AverageOccupancyPercent: [],
    BaselineROI: [],
    OptimizedROI: [],
    HomePrice: [],
  });

  const getChartData = () => {
    let MonthlyRevenue = [];
    let AverageADR = [];
    let AnnualAveragePropertyRevenue = [];
    let AnnualTotalMarketRevenue = [];
    let AverageOccupancyPercent = [];
    let BaselineROI = [];
    let OptimizedROI = [];
    let HomePrice = [];

    selectedOption.forEach(item => {
      const color = getRandomColor();
      MonthlyRevenue = [
        ...MonthlyRevenue,
        {
          name: item.label,
          color,
          type: 'line',
          data:
            item.DataFrom === 'City'
              ? trendsOccupancyROICities
                  .filter(d => d.City === item.value)
                  .map(d => [
                    new Date(d.MonthYear).getTime(),
                    d.MonthlyRevenue || 0,
                  ])
              : trendsOccupancyROIZips
                  .filter(d => d.ZIPCode === item.value)
                  .map(d => [
                    new Date(d.MonthYear).getTime(),
                    d.MonthlyRevenue || 0,
                  ]),
        },
      ];

      AverageADR = [
        ...AverageADR,
        {
          name: item.label,
          color,
          type: 'line',
          data:
            item.DataFrom === 'City'
              ? trendsOccupancyROICities
                  .filter(d => d.City === item.value)
                  .map(d => [
                    new Date(d.MonthYear).getTime(),
                    d.AverageADR || 0,
                  ])
              : trendsOccupancyROIZips
                  .filter(d => d.ZIPCode === item.value)
                  .map(d => [
                    new Date(d.MonthYear).getTime(),
                    d.AverageADR || 0,
                  ]),
        },
      ];

      AnnualAveragePropertyRevenue = [
        ...AnnualAveragePropertyRevenue,
        {
          name: item.label,
          color,
          type: 'line',
          data:
            item.DataFrom === 'City'
              ? trendsOccupancyROICities
                  .filter(d => d.City === item.value)
                  .map(d => [
                    new Date(d.MonthYear).getTime(),
                    d.AnnualAveragePropertyRevenue || 0,
                  ])
              : trendsOccupancyROIZips
                  .filter(d => d.ZIPCode === item.value)
                  .map(d => [
                    new Date(d.MonthYear).getTime(),
                    d.AnnualAveragePropertyRevenue || 0,
                  ]),
        },
      ];

      AnnualTotalMarketRevenue = [
        ...AnnualTotalMarketRevenue,
        {
          name: item.label,
          color,
          type: 'line',
          data:
            item.DataFrom === 'City'
              ? trendsOccupancyROICities
                  .filter(d => d.City === item.value)
                  .map(d => [
                    new Date(d.MonthYear).getTime(),
                    d.AnnualTotalMarketRevenue || 0,
                  ])
              : trendsOccupancyROIZips
                  .filter(d => d.ZIPCode === item.value)
                  .map(d => [
                    new Date(d.MonthYear).getTime(),
                    d.AnnualTotalMarketRevenue || 0,
                  ]),
        },
      ];

      AverageOccupancyPercent = [
        ...AverageOccupancyPercent,
        {
          name: item.label,
          color,
          type: 'line',
          data:
            item.DataFrom === 'City'
              ? trendsOccupancyROICities
                  .filter(d => d.City === item.value)
                  .map(d => [
                    new Date(d.MonthYear).getTime(),
                    d.AverageOccupancyPercent || 0,
                  ])
              : trendsOccupancyROIZips
                  .filter(d => d.ZIPCode === item.value)
                  .map(d => [
                    new Date(d.MonthYear).getTime(),
                    d.AverageOccupancyPercent || 0,
                  ]),
        },
      ];

      BaselineROI = [
        ...BaselineROI,
        {
          name: item.label,
          color,
          type: 'line',
          data:
            item.DataFrom === 'City'
              ? trendsOccupancyROICities
                  .filter(d => d.City === item.value)
                  .map(d => [
                    new Date(d.MonthYear).getTime(),
                    d.BaselineROI || 0,
                  ])
              : trendsOccupancyROIZips
                  .filter(d => d.ZIPCode === item.value)
                  .map(d => [
                    new Date(d.MonthYear).getTime(),
                    d.BaselineROI || 0,
                  ]),
        },
      ];

      OptimizedROI = [
        ...OptimizedROI,
        {
          name: item.label,
          color,
          type: 'line',
          data:
            item.DataFrom === 'City'
              ? trendsOccupancyROICities
                  .filter(d => d.City === item.value)
                  .map(d => [
                    new Date(d.MonthYear).getTime(),
                    d.OptimizedROI || 0,
                  ])
              : trendsOccupancyROIZips
                  .filter(d => d.ZIPCode === item.value)
                  .map(d => [
                    new Date(d.MonthYear).getTime(),
                    d.OptimizedROI || 0,
                  ]),
        },
      ];

      HomePrice = [
        ...HomePrice,
        {
          name: item.label,
          color,
          type: 'line',
          data:
            item.DataFrom === 'City'
              ? trendsHomePriceCities
                  .filter(d => d.City === item.value)
                  .map(d => [new Date(d.MonthYear).getTime(), d.HomePrice || 0])
              : trendsHomePriceZips
                  .filter(d => d.ZIPCode === item.value)
                  .map(d => [
                    new Date(d.MonthYear).getTime(),
                    d.HomePrice || 0,
                  ]),
        },
      ];
    });

    setChartData({
      MonthlyRevenue,
      AverageADR,
      AnnualAveragePropertyRevenue,
      AnnualTotalMarketRevenue,
      AverageOccupancyPercent,
      BaselineROI,
      OptimizedROI,
      HomePrice,
    });
  };

  const getTrends = filter => {
    if (filter[filter.length - 1]?.DataFrom === 'City') {
      dispatch(
        getTrendsOccupancyROICities({
          filters: { City: filter },
        })
      );
      dispatch(
        getTrendsHomePriceCities({
          filters: { City: filter },
        })
      );
    }
    if (filter[filter.length - 1]?.DataFrom === 'Zip') {
      dispatch(
        getTrendsOccupancyROIZips({
          filters: { ZIPCode: filter },
        })
      );
      dispatch(
        getTrendsHomePriceZips({
          filters: { ZIPCode: filter },
        })
      );
    }
  };

  const isNumber = value => !Number.isNaN(Number(value));

  const onChange = value => {
    setSelectedOption(value);
    getTrends(value);
  };

  const handleSelectedChartType = chart => {
    setSelectedChartType(chart);
  };

  const loadOptions = debounce(async inputValue => {
    if (inputValue.length <= 2) {
      return [];
    }
    const value = inputValue.trim();
    const isNum = isNumber(value);
    if (isNum)
      return zipData.filter(
        i => i.value.toLowerCase().indexOf(value.toLowerCase()) >= 0
      );
    return cityData.filter(
      i => i.value.toLowerCase().indexOf(value.toLowerCase()) >= 0
    );
  }, 250);

  const renderChart = () => {
    if (trendsOccupancyROICitiesLoader || trendsOccupancyROIZipsLoader) {
      return (
        <Row className="mt-4">
          <b>Chart Loading</b>
        </Row>
      );
    }

    if (isEmpty(selectedOption) && isEmpty(chartData[selectedChartType])) {
      return (
        <Row className="mt-4">
          <b>Select city or ZIP Code to show chart.</b>
        </Row>
      );
    }

    return (
      <Row>
        <div className="mb-4 mt-3 d-flex">
          <b> Current Geography:</b>
          <div className="ml-3">
            {selectedOption.map(item => (
              <span key={item.value} style={badgeStyle}>
                {item.label}
              </span>
            ))}
          </div>
        </div>
        <HookChart selectedChart={chartData[selectedChartType]} />
      </Row>
    );
  };

  useEffect(() => {
    if (
      !isEmpty(trendsOccupancyROICities) ||
      !isEmpty(trendsOccupancyROIZips)
    ) {
      getChartData();
    }
  }, [trendsOccupancyROICities, trendsOccupancyROIZips]);

  useEffect(() => {
    dispatch(cityListRevADROccupancyROI());
    dispatch(zipsListRevADROccupancyROI());
  }, []);

  return (
    <div>
      <Row className="mb-2">
        <Col xs={4}>
          <Filter
            setOption={handleSelectedChartType}
            options={chartType}
            title="Chart Type"
            value={selectedChartType}
          />
        </Col>
        <Col xs={6}>
          <div>
            <h6 style={{ marginBottom: '0.4rem' }}>Quick Search</h6>
            <AsyncSelect
              isMulti
              defaultOptions={cityData}
              onChange={onChange}
              loadOptions={loadOptions}
              getOptionLabel={item => item.label}
              placeholder="Enter City or ZIP Code(s)"
            />
          </div>
        </Col>
      </Row>
      {renderChart()}
    </div>
  );
};

export default TabTrends;
