import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import propTypes from 'prop-types';
import { dataSurveyVisibilityChange } from '../../redux/survey/api';
import { notify } from '../Toaster';

const HideUnHideModal = ({ show, handleClose, row, hideRowFromData }) => {
  const onProceed = async () => {
    const payload = {
      SurveyID: row?.DataSurvey_ID,
      Status: row.visibility_status === 1 ? 2 : 1,
    };
    handleClose();
    const response = await dataSurveyVisibilityChange(payload);
    const index = response.data.findIndex(item => {
      return item['@intResultCode'] !== 0;
    });
    if (index === -1) {
      notify(
        `${
          row?.SurveyType === 1
            ? `City ${row?.City}`
            : `Zipcode ${row?.ZIPCode}`
        } is ${row?.visibility_status === 2 ? 'visible' : 'hidden'}`
      );
      hideRowFromData(row?.DataSurvey_ID);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Finished with this{' '}
          {row?.SurveyType === 1
            ? `city ${row?.City}`
            : `Zipcode ${row?.ZIPCode}`}{' '}
          analysis?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {row?.visibility_status === 1
          ? `Are you sure you wish to hide this ${
              row?.SurveyType === 1
                ? `city ${row?.City}`
                : `zipcode ${row?.ZIPCode}`
            } from the surveys? You can always Unhide later.`
          : `Are you sure you wish to Unhide this ${
              row?.SurveyType === 1
                ? `city ${row?.City}`
                : `zipcode ${row?.ZIPCode}`
            }  from the surveys? You can always hide later.`}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={onProceed}>
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

HideUnHideModal.propTypes = {
  show: propTypes.bool.isRequired,
  handleClose: propTypes.func.isRequired,
  hideRowFromData: propTypes.func.isRequired,
  row: propTypes.shape({
    DataSurvey_ID: propTypes.number.isRequired,
    visibility_status: propTypes.number.isRequired,
    SurveyType: propTypes.number.isRequired,
    City: propTypes.string,
    ZIPCode: propTypes.string,
    MarketName: propTypes.string,
  }).isRequired,
};

export default HideUnHideModal;
