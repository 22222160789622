/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import propTypes from 'prop-types';

const InputField = props => {
  const [inputValue, setInputValue] = useState('');
  const {
    onChange: onTextChange,
    name,
    optionHeading,
    removeFilter,
    toBeDeleted,
  } = props;

  const debounceTimeout = useRef(null);

  const textChange = e => {
    setInputValue(e.target.value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      onTextChange(name, e.target.value);
    }, 2000);
  };

  useEffect(() => {
    setInputValue(props?.defaultValue);

    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [props?.defaultValue]);

  return (
    <div>
      <div className="position-relative mb-2">
        <span className="label-heading">{optionHeading}</span>
        <Row className="row-width">
          <Col>
            {props?.inputType === 'number' ? (
              <>
                {' '}
                <Form.Control
                  name={name}
                  type="number"
                  placeholder={optionHeading}
                  onChange={e => textChange(e)}
                  min="0"
                  id="typeNumber"
                  value={inputValue}
                  // defaultValue={props.defaultValue}
                />
              </>
            ) : (
              <>
                {' '}
                <Form.Control
                  name={name}
                  type="text"
                  placeholder={optionHeading}
                  onChange={e => textChange(e)}
                  value={inputValue}
                />
              </>
            )}
          </Col>
          <Col xs={1}>
            <div className="add-remove">
              <button
                type="button"
                variant="primary"
                className="close"
                onClick={() => removeFilter(toBeDeleted)}
              >
                <FontAwesomeIcon icon={faClose} />
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

InputField.propTypes = {
  name: propTypes.string.isRequired,
  optionHeading: propTypes.string.isRequired,
  inputType: propTypes.string.isRequired,
  removeFilter: propTypes.func.isRequired,
  onChange: propTypes.func.isRequired,
  defaultValue: propTypes.string.isRequired,
  toBeDeleted: propTypes.string.isRequired,
};

export default InputField;
