import qs from 'qs';

export function objectsToQueryParams(data, allowedOptions) {
  if (!data) return '';
  const queryParams = {};
  Object.entries(data).forEach(([key, value]) => {
    if (allowedOptions.includes(key)) {
      if (Array.isArray(value)) {
        // If value is an array of objects
        queryParams[`${key}__in`] = value.map(obj => obj.value).join(',');
      } else {
        // If value is a string
        queryParams[key] = value;
      }
    }
  });
  return qs.stringify(queryParams);
}

export function queryParamsToObject(queryParams) {
  const parsedParams = qs.parse(queryParams, { ignoreQueryPrefix: true });
  const result = {};
  const optionKeys = [];

  Object.entries(parsedParams).forEach(([key, value]) => {
    const keyParts = key.split('__'); // split key if it has __in suffix

    if (keyParts.length === 2 && keyParts[1] === 'in') {
      // if key has __in suffix, convert comma-separated values to an array
      const items = value.split(',');
      const output = items.map(val => ({
        label: val,
        value: val,
        DataFrom: keyParts[0],
      }));
      result[keyParts[0]] = output || '';
      optionKeys.push(keyParts[0]);
    } else {
      // otherwise, set the value as is
      result[key] = value;
      optionKeys.push(keyParts[0]);
    }
  });

  return { result, optionKeys };
}

const getQueryParams = (skinned = true) => {
  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  if (skinned)
    return Object.keys(queryParams).map(param => param.split('__')[0]);
  return queryParams;
};

export const isParamExists = key => {
  const queryParams = getQueryParams();
  return queryParams.includes(key);
};
