/* eslint-disable no-underscore-dangle */
import * as tus from 'tus-js-client';
import invokeLoad from './invokeLoad';
import processFileParse from './processFileParse';
import { removeLoad, saveLoad } from './saveLoad';
// import checkConnection from './checkConntection';
// import clear from './clear';

export const uploadFile = (type, file, loadChange, confirmResume) =>
  new Promise((resolve, reject) => {
    let id;
    const loadProgress = [];
    const options = {
      endpoint: `${process.env.REACT_APP_API_BASEURL}/files/${type}`,
      // endpoint: `http://localhost:8081/api/files/${type}`,
      headers: {
        filetype: type,
      },
      fingerprint(f) {
        return Promise.resolve(`${f.name}-${type}`);
      },
      removeFingerprintOnSuccess: true,
      metadata: {
        // id: '123',
        filename: 'airdna-new.csv',
        filetype: 'text/csv',
      },
      onError(error) {
        return reject(error);
      },
      onProgress(bytesUploaded, bytesTotal) {
        const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        loadChange({ type: 'uploadServer', percentage });
        if (loadProgress.length < 2) {
          loadProgress.push(percentage);
        }
      },
      onAfterResponse(req, res) {
        // eslint-disable-next-line no-underscore-dangle
        if (
          res._xhr.statusText === 'No Content' ||
          res._xhr.statusText === 'OK'
        ) {
          // eslint-disable-next-line no-underscore-dangle
          id = res._xhr.responseURL.split('/').at(-1);
        }
      },
      async onSuccess() {
        // as soon as tus don't invoke any events we need this crutch
        if (loadProgress.length === 1) {
          await invokeLoad(id);
        }
        saveLoad(id);
        resolve(await processFileParse(id, loadChange));
        removeLoad();
      },
    };
    const uploader = new tus.Upload(file, options);
    uploader.findPreviousUploads().then(async previousUploads => {
      // Found previous uploads so we select the first one.
      let currentKey;
      if (previousUploads.length) {
        const shouldResume = await confirmResume();
        if (shouldResume) {
          const item = previousUploads.at(-1);
          currentKey = item.urlStorageKey;
          uploader.resumeFromPreviousUpload(item);
        } else {
          // await clear();
        }
      } else {
        // await clear();
      }

      Object.keys(localStorage).forEach(key => {
        if (key.startsWith('tus::') && currentKey && key !== currentKey) {
          localStorage.removeItem(key);
        }
      });

      uploader.start();
      // const { dbWorked, error } = await checkConnection();
      // if (dbWorked) {
      // } else {
      //   resolve({ result: error });
      // }
      // Start the upload
    });

    resolve({});
  });
