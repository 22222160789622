/* eslint-disable no-lonely-if */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import AsyncSelect from 'react-select/async';
import debounce from 'debounce-promise';
import cloneDeep from 'lodash/cloneDeep';
// import propTypes from 'prop-types';
import Filter from '../../component/FilterDropdown';
import NextGenBsTable from '../../component/NextGenBsTable';
import {
  getHomeSizeSearchZipsList,
  getHomeSizeSearchCitiesList,
  getHomeSizeSearchCitiesCharList,
  getHomeSizeAllCitiesCharList,
  getHomeSizeAllZipsCharList,
  getHomeSizeCities,
  getHomeSizeZips,
  getHomeSizeNightlyValueCities,
  getHomeSizeNightlyValueZips,
} from '../../redux/characteristics/api';
import { homeColumns, homeColumnsSeason } from '../../utils/columns';
import data from '../../utils/data.json';
import {
  removeFilter,
  renderFiltersInputType,
  setSelectFilterOption,
} from '../../utils/helperInput';
import { setSearchValue } from '../../redux/characteristics/characteristicsSlice';
import { updateCurrentCharacteristicsDrilldownTab } from '../../redux/navigation/navigationSlice';

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 2,
});

const TabHomeSize = ({ selectedPrevalenceRow, selectedTab }) => {
  const dispatch = useDispatch();
  const [selectOption, setFilterOption] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [selectedFilterOption, setSelectedFilterOption] = useState({});
  const [selectedSortingOption, setSelectedSortingOption] = useState({});
  const [selectedCharacteristic, setSelectedCharacteristic] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedZip, setSelectedZip] = useState('');
  const [rowClicked, setRowClicked] = useState({});
  const [filterByData, setFilterByData] = useState(
    data?.characteristicsFilters
  );
  const homeSizeAllCitiesCharList = useSelector(
    state => state.characteristics.homeSizeAllCitiesCharList
  );
  const homeSizeAllZipsCharList = useSelector(
    state => state.characteristics.homeSizeAllZipsCharList
  );
  const homeSizeCitiesLoader = useSelector(
    state => state.characteristics.homeSizeCitiesLoader
  );
  const homeSizeZipsLoader = useSelector(
    state => state.characteristics.homeSizeZipsLoader
  );
  const homeSizeCities = useSelector(
    state => state.characteristics.homeSizeCities
  );
  const homeSizeZips = useSelector(state => state.characteristics.homeSizeZips);
  const homeSizeNightlyValueCities = useSelector(
    state => state.characteristics.homeSizeNightlyValueCities
  );
  const homeSizeNightlyValueCitiesLoader = useSelector(
    state => state.characteristics.homeSizeNightlyValueCitiesLoader
  );
  const homeSizeNightlyValueZips = useSelector(
    state => state.characteristics.homeSizeNightlyValueZips
  );
  const homeSizeNightlyValueZipsLoader = useSelector(
    state => state.characteristics.homeSizeNightlyValueZipsLoader
  );
  const quickSearchValue = useSelector(
    state => state.characteristics.searchValue
  );

  const setSelectedOption = options => {
    if (!options || options.length === 0) {
      setSelectedCity('');
      setSelectedZip('');
      setSelectedCharacteristic('');
    }

    let newSelectedCity = '';
    let newSelectedZip = '';
    let newSelectedCharacteristics = '';

    options.forEach(o => {
      if (o?.DataFrom === 'City') newSelectedCity = o.value;
      if (o?.DataFrom === 'Zip') newSelectedZip = o.value;
      if (o?.DataFrom === 'Char') newSelectedCharacteristics = o.value;
    });

    setSelectedCity(newSelectedCity);
    setSelectedZip(newSelectedZip);
    setSelectedCharacteristic(newSelectedCharacteristics);

    dispatch(setSearchValue(options));
  };

  useEffect(() => {
    setSelectedOption(quickSearchValue);
  }, [selectedTab]);

  const handleSelectedCharacteristic = value => {
    if (quickSearchValue.length > 0) {
      setSelectedOption([
        ...quickSearchValue.filter(o => o?.DataFrom !== 'Char'),
        { label: value, value, DataFrom: 'Char' },
      ]);
    }
  };

  const setSelectFilterOptionFunction = value => {
    setSelectFilterOption(
      value,
      data,
      selectOption,
      setFilterOption,
      setSelectedFilter,
      selectedFilterOption,
      setSelectedFilterOption,
      filterByData,
      setFilterByData
    );
  };

  const setSortingOption = option => {
    const tempOption = {};
    if (option !== 'default') {
      setSelectedSortingOption(option);
      if (option === 'monthly_revenue') {
        tempOption.Revenue_Baseline = true;
      } else {
        tempOption[option] = true;
      }
    }

    setSelectedSortingOption(tempOption);
  };

  const inputOnChangeFunction = (type, value) => {
    const tempSelectedFilterOption = cloneDeep(selectedFilterOption);
    tempSelectedFilterOption[type] = value;
    setSelectedFilterOption(tempSelectedFilterOption);
  };

  const removeFilterFunction = id => {
    removeFilter(
      id,
      data.characteristicsFilters,
      selectOption,
      selectedFilterOption,
      setFilterOption,
      setSelectedFilter,
      setSelectedFilterOption,
      setFilterByData,
      filterByData
    );
  };

  const resetFilters = () => {
    setFilterOption([]);
    setSelectedFilter('');
    setSelectedSortingOption({});
    setSelectedFilterOption({});
  };

  const renderFilterOptions = () => {
    return (
      <Row className="mb-2 align-items-end">
        {selectOption?.map((res, i) => {
          return (
            // TODO: fix the key
            // eslint-disable-next-line react/no-array-index-key
            <Col xs={3} key={`filters-${i}`}>
              {renderFiltersInputType(
                res,
                i,
                data,
                data.characteristicsFilters,
                inputOnChangeFunction,
                removeFilterFunction,
                [],
                [],
                [],
                selectedFilterOption,
                [],
                'dropdown-select',
                'minimumOccupancyPercentage'
              )}
            </Col>
          );
        })}
        <Col xs={3}>
          <div className="mb-2">
            {selectOption?.length ? (
              <button
                type="button"
                className="allbutton"
                onClick={() => {
                  resetFilters();
                }}
              >
                Reset Filter
              </button>
            ) : null}
          </div>
        </Col>
      </Row>
    );
  };

  const getHomesize = (_, __, sort = {}) => {
    const sortings = { ...selectedSortingOption, ...sort };
    if (selectedCharacteristic) {
      if (selectedZip) {
        dispatch(
          getHomeSizeZips({
            searchQuery: selectedZip,
            filters: {
              ...selectedFilterOption,
              CharacteristicName: selectedCharacteristic,
            },
            sortings,
          })
        );
      }
      if (selectedCity) {
        dispatch(
          getHomeSizeCities({
            searchQuery: selectedCity,
            filters: {
              ...selectedFilterOption,
              CharacteristicName: selectedCharacteristic,
            },
            sortings,
          })
        );
      }
    }
  };

  useEffect(() => {
    setRowClicked({});
    if (selectedTab === 'Home Size') getHomesize();
  }, [
    selectedCity,
    selectedZip,
    selectedCharacteristic,
    selectedFilterOption,
    selectedSortingOption,
    selectedTab,
  ]);

  useEffect(() => {
    const newSelectedCharecteristic = quickSearchValue.find(
      item => item.DataFrom === 'Char'
    )?.value;
    if (selectedTab === 'Home Size' && newSelectedCharecteristic) {
      setSelectedCharacteristic(newSelectedCharecteristic);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab === 'Home Size') {
      if (selectedZip)
        dispatch(getHomeSizeAllZipsCharList({ ZIPCode: selectedZip }));
      if (selectedCity)
        dispatch(getHomeSizeAllCitiesCharList({ City: selectedCity }));
    }
  }, [selectedCity, selectedZip, selectedTab]);

  useEffect(() => {
    if (!isEmpty(selectedPrevalenceRow)) {
      dispatch(updateCurrentCharacteristicsDrilldownTab('Home Size'));
      setSelectedOption([
        ...quickSearchValue.filter(o => o?.DataFrom !== 'Char'),
        {
          label: selectedPrevalenceRow?.CharacteristicName,
          value: selectedPrevalenceRow?.CharacteristicName,
          DataFrom: 'Char',
        },
      ]);
    }
  }, [selectedPrevalenceRow]);

  const onClickRow = (e, row) => {
    setRowClicked(row);
    if (selectedZip) {
      dispatch(
        getHomeSizeNightlyValueZips({
          dataSurveyId: row?.DataSurvey_ID,
          characteristicId: row?.CharacteristicID,
        })
      );
    }
    if (selectedCity) {
      dispatch(
        getHomeSizeNightlyValueCities({
          dataSurveyId: row?.DataSurvey_ID,
          characteristicId: row?.CharacteristicID,
        })
      );
    }
  };

  const getData = () => {
    if (selectedCity) return homeSizeCities;
    if (selectedZip) return homeSizeZips;
    return [];
  };

  const getLoader = () => {
    if (selectedCity) return homeSizeCitiesLoader;
    if (selectedZip) return homeSizeZipsLoader;
    return false;
  };

  const getStructrualList = () => {
    let struct = [
      { optionValue: '', optionName: 'Structural Characteristics' },
    ];
    if (selectedZip) {
      if (homeSizeAllZipsCharList?.structural?.length > 0) {
        const structData = homeSizeAllZipsCharList?.structural?.map(d => ({
          optionValue: d?.CharacteristicName,
          optionName: d?.CharacteristicName,
        }));
        struct = [...struct, ...structData];
      }
    }
    if (selectedCity) {
      if (homeSizeAllCitiesCharList?.structural?.length > 0) {
        const structData = homeSizeAllCitiesCharList?.structural?.map(d => ({
          optionValue: d?.CharacteristicName,
          optionName: d?.CharacteristicName,
        }));
        struct = [...struct, ...structData];
      }
    }

    return struct;
  };

  const getAmenitiesList = () => {
    let amenit = [{ optionValue: '', optionName: 'Amenities ' }];
    if (selectedZip) {
      if (homeSizeAllZipsCharList?.amenities?.length > 0) {
        const amenitData = homeSizeAllZipsCharList?.amenities?.map(d => ({
          optionValue: d?.CharacteristicName,
          optionName: d?.CharacteristicName,
        }));
        amenit = [...amenit, ...amenitData];
      }
    }
    if (selectedCity) {
      if (homeSizeAllCitiesCharList?.amenities?.length > 0) {
        const amenitData = homeSizeAllCitiesCharList?.amenities?.map(d => ({
          optionValue: d?.CharacteristicName,
          optionName: d?.CharacteristicName,
        }));
        amenit = [...amenit, ...amenitData];
      }
    }

    return amenit;
  };

  const debounceFunc = debounce(async inputValue => {
    let options = [];
    if (inputValue.length <= 2) {
      return [];
    }

    const isNumber = !Number.isNaN(Number(inputValue));
    if (isNumber) {
      const zipsList = await getHomeSizeSearchZipsList({
        searchQuery: inputValue,
      });

      if (zipsList?.length)
        options = [
          ...options,
          { value: '', label: '----Zips----', isDisabled: true },
          ...zipsList,
        ];
      else
        options = [
          ...options,
          { value: '', label: '----Zips----', isDisabled: true },
          { value: '', label: 'No zips found', isDisabled: true },
        ];

      return options;
    }
    const citiesList = await getHomeSizeSearchCitiesList({
      searchQuery: inputValue,
    });

    if (citiesList?.length)
      options = [
        ...options,
        { value: '', label: '----Cities----', isDisabled: true },
        ...citiesList,
      ];
    else
      options = [
        ...options,
        { value: '', label: '----Cities----', isDisabled: true },
        { value: '', label: 'No cities found', isDisabled: true },
      ];

    const charList = await getHomeSizeSearchCitiesCharList({
      Char: inputValue,
    });
    if (charList?.length)
      options = [
        ...options,
        { value: '', label: '----Characteristics----', isDisabled: true },
        ...charList.map(d => ({
          value: d.CharacteristicName,
          label: d.CharacteristicName,
          DataFrom: d.DataFrom,
        })),
      ];
    else {
      options = [
        ...options,
        { value: '', label: '----Characteristics----', isDisabled: true },
        { value: '', label: 'No characteristics found', isDisabled: true },
      ];
    }

    return options;
  }, 1000);

  return (
    <div>
      <Row className="mb-2">
        <Col xs={6}>
          <h6>Quick Search</h6>
          <AsyncSelect
            isMulti
            isClearable
            loadOptions={debounceFunc}
            onChange={setSelectedOption}
            getOptionValue={option => option.label}
            placeholder={
              <div style={{ width: '100vh' }}>
                Enter City or ZIP Code(s) or charecteristic name separated by
                commas
              </div>
            }
            value={quickSearchValue}
          />
        </Col>
        <Col xs={2}>
          <Filter
            setOption={setSelectFilterOptionFunction}
            options={filterByData}
            title="Filter By"
            value={selectedFilter}
          />
        </Col>
        <Col xs={2}>
          <Filter
            setOption={setSortingOption}
            options={data?.characteristicsSorting}
            title="Sort By"
          />
        </Col>
      </Row>
      <Row>{renderFilterOptions()}</Row>
      {!selectedCity && !selectedZip && !selectedCharacteristic ? (
        <Row className="mt-2">
          <b>Enter City or a Characteristic name in the search bar.</b>
        </Row>
      ) : (
        <div>
          <Row>
            <Col xs={12} className="flex-style">
              <div>
                <b> Current Geography:</b> {selectedCity || selectedZip}
              </div>
            </Col>
          </Row>
          <Row className="mb-4 mt-2">
            <Col xs={3}>
              <Filter
                setOption={handleSelectedCharacteristic}
                options={getStructrualList()}
                value={selectedCharacteristic}
              />
            </Col>
            <Col xs={3}>
              <Filter
                setOption={handleSelectedCharacteristic}
                options={getAmenitiesList()}
                value={selectedCharacteristic}
              />
            </Col>
          </Row>
          {(selectedCity || selectedZip) && !selectedCharacteristic ? (
            <b>Select Structural Characteristic or Amenity First</b>
          ) : (
            <Row>
              <Col xs={4} className="home-size">
                <NextGenBsTable
                  showPagination={false}
                  keyField="id"
                  columns={homeColumns}
                  data={getData()}
                  loading={getLoader()}
                  hideSizePerPage
                  enableSearching={false}
                  onClickRow={onClickRow}
                  hoverClass="row-hoverclass"
                  striped
                  highlightActiveRow
                />
              </Col>
              <Col xs={5}>
                {rowClicked?.id && (
                  <div>
                    {rowClicked?.id && (
                      <div>
                        <div>
                          <span
                            className="span-bold"
                            style={{ paddingRight: '10px' }}
                          >
                            Home Size:
                          </span>
                          {`${rowClicked?.Sleeps} / ${rowClicked?.Beds} / ${rowClicked?.Baths}`}
                        </div>
                        <div>
                          <span className="span-bold">
                            Seasonal Annual Revenue Value
                          </span>
                          : <span className="span-bold">Min</span>:
                          {USDollar.format(
                            selectedZip
                              ? homeSizeNightlyValueZips?.[0]?.AnnualRevenue_Min
                              : homeSizeNightlyValueCities?.[0]
                                  ?.AnnualRevenue_Min || 0
                          )}
                          <span className="span-bold ml-5">Max</span>:
                          {USDollar.format(
                            selectedZip
                              ? homeSizeNightlyValueZips?.[0]?.AnnualRevenue_Max
                              : homeSizeNightlyValueCities?.[0]
                                  ?.AnnualRevenue_Max || 0
                          )}
                        </div>
                      </div>
                    )}
                    <NextGenBsTable
                      keyField="id"
                      columns={homeColumnsSeason}
                      data={
                        selectedZip
                          ? homeSizeNightlyValueZips || []
                          : homeSizeNightlyValueCities || []
                      }
                      loading={
                        selectedZip
                          ? homeSizeNightlyValueZipsLoader
                          : homeSizeNightlyValueCitiesLoader
                      }
                      enableSearching={false}
                      showPagination={false}
                      hideSizePerPage
                      striped
                    />
                  </div>
                )}
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  );
};

// TabHomeSize.propTypes = {
//   search: propTypes.shape({
//     value: propTypes.string,
//     DataFrom: propTypes.string,
//     label: propTypes.string,
//     DataQuality: propTypes.number,
//   }).isRequired,
//   selectedFilterOption: propTypes.string.isRequired,
//   selectedSortingOption: propTypes.string.isRequired,
//   // eslint-disable-next-line react/forbid-prop-types
//   characteristicsList: propTypes.any.isRequired,
// };

export default TabHomeSize;
