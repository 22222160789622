import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getOffersAsync } from '../../redux/fieldAnalysis/fieldAnalysisApi';
import FATTable from '../../component/FATTable/FATTable';

const CurrentOffers = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadCurrentOffers = () => {
    setLoading(true);
    getOffersAsync()
      .then(props => {
        setProperties(props);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadCurrentOffers();
  }, []);
  const navigate = useNavigate();

  const handleAction = action => {
    if (action === 'rescindOffer') {
      loadCurrentOffers();
    }
  };

  return (
    <div className="container-flex p-4">
      <h5>Current Offers</h5>
      <FATTable
        properties={properties}
        loading={loading}
        title="Current Offers"
        columns={[
          { id: 'PropertySize', label: 'Property Size' },
          { id: 'Address', label: 'Property Address' },
          {
            id: 'WHRank',
            label: 'WH Rank',
            format: v => (v ? `${v}/10` : 'NA'),
          },
          {
            id: 'AlgorithmicRating',
            label: 'Algorithmic Rating',
            format: v => (v ? `${v}/10` : 'NA'),
          },
          { id: 'Status', label: 'Status', default: 'Active' },
        ]}
        onRowClick={row => navigate(`/fat/survey/${row.ID}`)}
        onAction={handleAction}
      />
    </div>
  );
};

export default CurrentOffers;
