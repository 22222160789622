import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Paper, Stack, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosConfig from '../../redux/axiosConfig';
import { notify, notifyError } from '../../component/Toaster';
import { sendEmail } from '../../redux/fieldAnalysis/fieldAnalysisSlice';
import useUnsavedChanges from '../../hooks/useUnsavedChanges';

const EmailMessageSchema = Yup.object({
  RecipientName: Yup.string().required('Recipient name is required'),
  RecipientEmail: Yup.string()
    .email()
    .required('Recipient email must be valid'),
  // RecipientType: Yup.string().required('Recipient type is required'),
  Message: Yup.string().required('Message is required'),
  Subject: Yup.string().required('Subject is required'),
});

const SendPropertyList = () => {
  const user = useSelector(state => state.user);
  const [pdfURL, setPdfURL] = React.useState(null);
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { selectedProperties } = location.state;
  const navigate = useNavigate();
  const { handleUnsavedChanges } = useUnsavedChanges();

  useEffect(() => {
    if (selectedProperties) {
      // setLoading(true);
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      axiosConfig
        .post('/fat/print', {
          selectedProperties,
          preparedBy: user.userInfo.user_name,
          datePreparedString: new Date().toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }),
        })
        .then(res => {
          const { data: { Location } = {} } = res;
          setPdfURL(Location);
        })
        .catch(err => {
          notifyError(err.message);
        })
        .finally(() => {
          // setLoading(false);
        });
    }
  }, [selectedProperties]);

  return (
    <div>
      <h5>Send Property List</h5>
      <Typography variant="body1">
        Invite someone to view this list of properties
      </Typography>
      {pdfURL ? (
        <Paper
          elevation={0}
          sx={{ p: 2 }}
          style={{ backgroundColor: '#e9e9e9' }}
        >
          <Formik
            initialValues={{
              RecipientName: '',
              RecipientEmail: '',
              // RecipientType: '',
              Message: `Hi, I'm inviting you to preview this list of homes we are reviewing for analysis or purchase purpose.

${pdfURL}

Thank you!

${user?.userInfo?.user_name}`,
              Subject:
                'Wanderhome is inviting you to review this list of homes',
            }}
            validationSchema={EmailMessageSchema}
            onSubmit={values => {
              dispatch(sendEmail(values)).then(action => {
                if (action.error) {
                  notifyError(action.error.message);
                }
                if (action.payload) {
                  setPdfURL(null);
                  notify('Email sent successfully');
                  // eslint-disable-next-line no-restricted-globals
                  history.back();
                }
                handleUnsavedChanges(false);
              });
            }}
          >
            {({ errors, touched, handleChange }) => (
              <Form>
                <Stack direction="column" spacing={2}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    paddingX="5px"
                  >
                    <Typography variant="body1" fontWeight="600">
                      Name
                    </Typography>
                    <Stack direction="column" flex="1">
                      <Field
                        style={{ flex: 1 }}
                        as={Input}
                        name="RecipientName"
                        label="Recipient Name"
                        className="form-control"
                        onChange={e => {
                          handleChange(e);
                          handleUnsavedChanges(true);
                        }}
                      />
                      {touched.RecipientName && errors.RecipientName ? (
                        <div className="text-danger">
                          {errors.RecipientName}
                        </div>
                      ) : null}
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    paddingX="5px"
                  >
                    <Typography
                      variant="body1"
                      fontWeight="600"
                      style={{ whiteSpace: 'nowrap !important' }}
                    >
                      Email Address
                    </Typography>
                    <Stack direction="column" flex="1">
                      <Field
                        style={{ flex: 1 }}
                        as={Input}
                        name="RecipientEmail"
                        label="Recipient Email"
                        className="form-control"
                        onChange={e => {
                          handleChange(e);
                          handleUnsavedChanges(true);
                        }}
                      />
                      {touched.RecipientEmail && errors.RecipientEmail ? (
                        <div className="text-danger">
                          {errors.RecipientEmail}
                        </div>
                      ) : null}
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    paddingX="5px"
                  >
                    <Typography
                      variant="body1"
                      fontWeight="600"
                      style={{ whiteSpace: 'nowrap !important' }}
                    >
                      Subject Line
                    </Typography>
                    <Stack direction="column" flex="1">
                      <Field
                        style={{ flex: 1 }}
                        as={Input}
                        name="Subject"
                        label="Subject"
                        className="form-control"
                        onChange={e => {
                          handleChange(e);
                          handleUnsavedChanges(true);
                        }}
                      />
                      {touched.Subject && errors.Subject ? (
                        <div className="text-danger">{errors.Subject}</div>
                      ) : null}
                    </Stack>
                  </Stack>
                  <Stack direction="row" paddingX="5px">
                    <Typography
                      variant="body1"
                      fontWeight="600"
                      style={{ whiteSpace: 'nowrap !important' }}
                    >
                      Body
                    </Typography>
                  </Stack>
                  <Field
                    as="textarea"
                    rows="10"
                    name="Message"
                    label="Message"
                    className="form-control"
                    onChange={e => {
                      handleChange(e);
                      handleUnsavedChanges(true);
                    }}
                  />
                  {touched.Message && errors.Message ? (
                    <div className="text-danger">{errors.Message}</div>
                  ) : null}
                  <Stack direction="row" spacing={2}>
                    <button type="submit" className="btn btn-secondary mt-3">
                      Send
                    </button>
                    <button
                      type="button"
                      className="btn btn-tertiary mt-3"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                  </Stack>
                </Stack>
                {/* <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    as={Input}
                    name="RecipientType"
                    label="Recipient Type"
                    error={errors.RecipientType && touched.RecipientType}
                    helperText={errors.RecipientType && touched.RecipientType}
                  />
                </Grid>
              </Grid> */}
              </Form>
            )}
          </Formik>
        </Paper>
      ) : (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          height="200px"
        >
          <Typography variant="body1" textAlign="center">
            Generating PDF. Please wait...
          </Typography>
        </Stack>
      )}
    </div>
  );
};

export default SendPropertyList;
