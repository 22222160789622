import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './recentProperties.css';
import { Button, Stack } from '@mui/material';
import { getRecentPropertiesAsync } from '../../redux/fieldAnalysis/fieldAnalysisApi';
import { clearRecentProperties } from '../../redux/fieldAnalysis/fieldAnalysisSlice';
import FATTable from '../../component/FATTable/FATTable';

const RecentProperties = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clearList = () => {
    dispatch(clearRecentProperties()).then(() => {
      setProperties([]);
    });
  };

  useEffect(() => {
    setLoading(true);
    getRecentPropertiesAsync()
      .then(props => {
        setProperties(props);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="container-flex p-4">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <h5>Recently Viewed Properties</h5>
        <Button
          variant="contained"
          onClick={() => clearList()}
          disabled={properties.length === 0}
        >
          Clear List
        </Button>
      </Stack>
      <FATTable
        properties={properties}
        title="Property Details"
        loading={loading}
        columns={[
          { id: 'PropertySize', label: 'Property Size' },
          { id: 'Address', label: 'Property Address' },
          {
            id: 'WHRank',
            label: 'WH Rank',
            format: v => (v ? `${v}/10` : 'NA'),
          },
          {
            id: 'AlgorithmicRating',
            label: 'Algorithmic Rating',
            format: v => (v ? `${v}/10` : 'NA'),
          },
          { id: 'Status', label: 'Status', default: 'Active' },
        ]}
        onRowClick={row => navigate(`/fat/survey/${row.ID}`)}
      />
    </div>
  );
};

export default RecentProperties;
