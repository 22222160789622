import React, { useEffect, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axiosConfig from '../../redux/axiosConfig';
import { notifyError } from '../../component/Toaster';

const Print = () => {
  const location = useLocation();
  const { selectedProperties, title } = location.state;
  const [loading, setLoading] = React.useState(false);
  const [pdfURL, setPdfURL] = React.useState('');
  const user = useSelector(state => state.user);

  useEffect(() => {
    if (selectedProperties) {
      setLoading(true);
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      axiosConfig
        .post('/fat/print', {
          selectedProperties,
          title,
          preparedBy: user.userInfo.user_name,
          datePreparedString: new Date().toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }),
        })
        .then(res => {
          const { data: { Location } = {} } = res;
          const link = document.createElement('a');
          link.download = `${title}.pdf`;
          link.href = Location;
          link.target = '_blank';
          link.click();
          setPdfURL(Location);
        })
        .catch(err => {
          notifyError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedProperties]);

  return (
    <div className="container-flex p-4 text-center">
      {loading
        ? 'Please wait. Your download will start automatically...'
        : pdfURL && (
            <span>
              Your PDF is ready. Click{' '}
              <a href={pdfURL} target="_blank" rel="noreferrer">
                here
              </a>{' '}
              to download.
            </span>
          )}
    </div>
  );
};

export default memo(Print);
