/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Autocomplete,
  Button,
  Chip,
  Stack,
  Switch,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../../assets/images/logo.png';
import images from '../../assets/images';
import {
  // getCurrentLocation,
  // getStateAbbrs,
  saveCurrentLocation,
  // getPropertyTypeGroupings,
  clearNotifications,
} from '../../redux/fieldAnalysis/fieldAnalysisSlice';
import {
  getSegmentPriorityByZipCodeAsync,
  getZipCodesForAutoCompleteAsync,
  getNotificationsAsync,
  markNotificationAsReadAsync,
} from '../../redux/fieldAnalysis/fieldAnalysisApi';
import './fatHeader.css';
import { notifyError } from '../Toaster';

const IOSSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(14px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const FATHeader = () => {
  const dispatch = useDispatch();

  const { location: currentLocation } = useSelector(
    state => state.fieldAnalysis
  );
  const [showMenu, setShowMenu] = useState(false);
  const [segmentString, setSegmentString] = useState('');
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const [showNotifications, setShowNotifications] = useState(false);
  const toggleNotifications = () => {
    setShowNotifications(prev => !prev);
  };

  const [showLocations, setShowLocations] = useState(false);
  const toggleLocations = () => {
    setShowLocations(!showLocations);
  };

  const handleClickOutside = () => {
    setShowMenu(false);
    setShowNotifications(false);
  };

  const useOutsideClick = callback => {
    const ref = useRef();
    useEffect(() => {
      const handleEvent = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener('click', handleEvent, true);
      return () => {
        document.removeEventListener('click', handleEvent, true);
      };
    }, [ref]);
    return ref;
  };

  const menuRef = useOutsideClick(handleClickOutside);
  const notificationRef = useOutsideClick(handleClickOutside);
  const locationsRef = useOutsideClick(handleClickOutside);

  const [segmentType, setSegmentType] = useState(1);
  const [surveyType, setSurveyType] = useState(1);

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [selectedZipCodes, setSelectedZipCodes] = React.useState([]);

  const [segments, setSegments] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);

  const fetch = React.useMemo(
    () =>
      debounce((query, exclude, callback) => {
        getZipCodesForAutoCompleteAsync(query, exclude).then(callback);
      }, 400),
    []
  );

  useEffect(() => {
    if (currentLocation) {
      setSelectedZipCodes(currentLocation.zipCodes);
      setSelectedSegments(currentLocation.segmentIds);
      setSegmentType(currentLocation.isAllSegments);
      setSurveyType(currentLocation.isInPerson);
    }
  }, [currentLocation]);

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    // if (inputValue.length <= 2) {
    //   setOptions(value ? [value] : []);
    //   return undefined;
    // }

    fetch(inputValue, selectedZipCodes, results => {
      if (active) {
        let newOptions = [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  useEffect(() => {
    if (selectedZipCodes?.length > 0 && selectedZipCodes.join(',').length > 0) {
      getSegmentPriorityByZipCodeAsync(selectedZipCodes.join(',')).then(
        action => {
          setSegments(action);
          setSelectedSegments(
            action
              .filter(item =>
                currentLocation.segmentIds.includes(item.SegmentID.toString())
              )
              .map(item => item.SegmentID.toString())
          );
        }
      );
      // setSegmentType(2);
    } else {
      setSegments([]);
      setSelectedSegments([]);
      // setSegmentType(1);
    }
    return () => {};
  }, [selectedZipCodes]);

  useEffect(() => {
    if (segmentType && segments && selectedSegments) {
      if (segmentType === 1) {
        setSegmentString('All Segments');
      } else if (segmentType === 2) {
        if (selectedSegments.length === 1) {
          const [segment] = segments.filter(
            item => item.SegmentID === parseInt(selectedSegments[0], 10)
          );
          if (segment) {
            setSegmentString(`Sleeps ${segment.SegmentText}`);
          }
        } else if (selectedSegments.length === 0) {
          setSegmentString('');
        } else {
          setSegmentString('Multiple Segments');
        }
      }
    } else {
      setSegmentString('');
    }
  }, [segmentType, segments, selectedSegments]);

  const handleZipCodeKeyUp = e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      if (options.filter(item => item.ZIPCode === e.target.value).length > 0) {
        setSelectedZipCodes([...selectedZipCodes, e.target.value]);
        setValue(null);
        e.target.blur();
      }
    }
  };

  const [notifications, setNotifications] = useState([]);

  const updateNotifications = useCallback(() => {
    getNotificationsAsync()
      .then(response => {
        if (response) {
          setNotifications(response);
        }
      })
      .catch(error => notifyError(error.message));
  }, []);

  const handleClearNotifications = () => {
    dispatch(clearNotifications()).then(action => {
      if (action.error) {
        notifyError(action.error.message);
      }
      if (action.payload) {
        setNotifications([]);
      }
    });
  };

  const handleNotificationClick = async notification => {
    if (notification?.Unread === 1) {
      try {
        const response = await markNotificationAsReadAsync(notification.id);
        if (response) {
          await updateNotifications();
        }
      } catch {
        // Ignore
      }
    }
    setShowNotifications(false);
  };

  useEffect(() => {
    updateNotifications();
    const interval = setInterval(updateNotifications, 30000);
    return () => clearInterval(interval);
  }, [updateNotifications]);

  return (
    <header className="header">
      <div className="left-group">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarMenu"
          onClick={() => toggleMenu()}
        >
          <img className="cursor-pointer icon" src={images.menu} alt="Menu" />
        </button>
        <img className="logo" src={logo} alt="Logo" />
      </div>
      {showMenu && (
        <div className="popup-menu" ref={menuRef} id="popup-menu">
          <Link
            to="/fat/newSearch"
            className="popup-menu-item"
            onClick={() => toggleMenu()}
          >
            <img className="icon" src={images.search} alt="New Search" />
            <span>New Search</span>
          </Link>
          <Link
            to="/fat/recentProperties"
            className="popup-menu-item"
            onClick={() => toggleMenu()}
          >
            <img className="icon" src={images.recent} alt="Recent Properties" />
            <span>Recent Properties</span>
          </Link>
          {/* <Link
            to="/fat/savedSearches"
            className="popup-menu-item"
            onClick={() => toggleMenu()}
          >
            <img
              className="icon"
              src={images.savedSearches}
              alt="Saved Searches"
            />
            <span>Saved Searches</span>
          </Link> */}
          <Link
            to="/fat/newlyListed"
            className="popup-menu-item"
            onClick={() => toggleMenu()}
          >
            <img className="icon" src={images.newList} alt="Newly Listed" />
            <span>Newly Listed</span>
          </Link>
          <Link
            to="/fat/todaysList"
            className="popup-menu-item"
            onClick={() => toggleMenu()}
          >
            <img className="icon" src={images.calendar} alt="Today's List" />
            <span>Today&apos;s List</span>
          </Link>
          <Link
            to="/fat/shortlist"
            className="popup-menu-item"
            onClick={() => toggleMenu()}
          >
            <img className="icon" src={images.shortlist} alt="Shortlist" />
            <span>Shortlist</span>
          </Link>
          <Link
            to="/fat/offers"
            className="popup-menu-item"
            onClick={() => toggleMenu()}
          >
            <img className="icon" src={images.offers} alt="Current Offers" />
            <span>Current Offers</span>
          </Link>
          <Link
            to="/fat/newHome"
            className="popup-menu-item"
            onClick={() => toggleMenu()}
          >
            <img className="icon" src={images.home} alt="Create New Home" />
            <span>Create New Home</span>
          </Link>
        </div>
      )}
      <div className="right-group">
        <div style={{ position: 'relative' }}>
          <Stack direction="column" alignItems="flex-end">
            <button
              type="button"
              className="nav-link"
              onClick={() => toggleLocations()}
            >
              <span>{currentLocation.locationStr}</span>
            </button>
            <div>{segmentString}</div>
          </Stack>
          {showLocations && (
            <Container
              className="popup-menu p-4 text-center"
              ref={locationsRef}
              id="locations"
              style={{ minHeight: '200px' }}
            >
              <div className="flex flex-row w-100">
                <img
                  className="icon"
                  src={images.location}
                  alt="Location"
                  style={{ margin: '.5rem .5rem 0 0' }}
                />
                <div className="w-100">
                  <Autocomplete
                    id="zip-codes"
                    size="small"
                    sx={{ marginBottom: '1rem', width: '100%' }}
                    options={options}
                    getOptionLabel={option => option.ZIPCode}
                    autoComplete
                    filterSelectedOptions
                    filterOptions={x => x}
                    value={value}
                    getOptionDisabled={option =>
                      selectedZipCodes.includes(option)
                    }
                    noOptionsText="Search for a ZIP Code"
                    onChange={(event, newValue) => {
                      setOptions([]);
                      if (!selectedZipCodes.includes(newValue.ZIPCode)) {
                        setSelectedZipCodes([
                          newValue.ZIPCode,
                          ...selectedZipCodes,
                        ]);
                      }
                      setValue(null);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Enter ZIP Code"
                        fullWidth
                        onKeyUp={handleZipCodeKeyUp}
                      />
                    )}
                  />
                  <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                    {selectedZipCodes.map(option => (
                      <Chip
                        key={option}
                        label={option}
                        size="medium"
                        onDelete={() => {
                          setSelectedZipCodes(
                            selectedZipCodes.filter(item => item !== option)
                          );
                        }}
                      />
                    ))}
                  </Stack>
                </div>
              </div>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                className="mt-4"
              >
                <strong>Segments:</strong>
                <span>All</span>
                <IOSSwitch
                  color="success"
                  checked={selectedZipCodes.length > 0 && segmentType === 2}
                  onChange={event =>
                    setSegmentType(
                      selectedZipCodes.length > 0 && event.target.checked
                        ? 2
                        : 1
                    )
                  }
                  disabled={selectedZipCodes.length === 0}
                />
                <span>Some</span>
              </Stack>
              {segmentType === 2 && (
                <Stack paddingLeft="50px" position="relative">
                  <Row
                    className="mb-2 mt-2 p-2"
                    style={{ backgroundColor: '#dedede', borderRadius: '4px' }}
                  >
                    <Stack style={{ height: '150px', overflowY: 'scroll' }}>
                      <strong>Top Performing Segments</strong>
                      {segments && (
                        <Row>
                          {segments
                            .filter(segment => segment.Priority === 1)
                            .map(segment => (
                              <Col key={segment.SegmentID} className="col-3">
                                <Button
                                  variant="text"
                                  disabled={selectedSegments.includes(
                                    segment.SegmentID.toString()
                                  )}
                                  onClick={() =>
                                    setSelectedSegments([
                                      segment.SegmentID.toString(),
                                      ...selectedSegments,
                                    ])
                                  }
                                  className="segment-btn"
                                >
                                  {segment.SegmentText}
                                </Button>
                              </Col>
                            ))}
                        </Row>
                      )}
                      <strong>Other Segments</strong>
                      {segments && (
                        <Row>
                          {segments
                            .filter(segment => segment.Priority === 2)
                            .map(segment => (
                              <Col key={segment.SegmentID} className="col-3">
                                <Button
                                  variant="text"
                                  disabled={selectedSegments.includes(
                                    segment.SegmentID.toString()
                                  )}
                                  onClick={() =>
                                    setSelectedSegments([
                                      segment.SegmentID.toString(),
                                      ...selectedSegments,
                                    ])
                                  }
                                  className="segment-btn"
                                >
                                  {segment.SegmentText}
                                </Button>
                              </Col>
                            ))}
                        </Row>
                      )}
                    </Stack>
                  </Row>
                  <Stack direction="row" useFlexGap flexWrap="wrap">
                    {selectedSegments.map(segment => (
                      <Chip
                        key={segment}
                        label={`${
                          segments.filter(
                            s => s.SegmentID === parseInt(segment, 10)
                          )[0]?.SegmentText
                        }`}
                        size="medium"
                        onDelete={() => {
                          setSelectedSegments(
                            selectedSegments.filter(item => item !== segment)
                          );
                        }}
                        className="segment-chip"
                      />
                    ))}
                    {selectedSegments.length === 0 && (
                      <Typography variant="body" color="red" fontSize="12px">
                        Select at least one segment
                      </Typography>
                    )}
                  </Stack>
                  <img
                    className="icon"
                    src={images.home}
                    alt="Home Segments"
                    style={{ position: 'absolute', top: '15px', left: '0' }}
                  />
                </Stack>
              )}
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                className="mt-4"
              >
                <strong>Mode:</strong>
                <span>In-Person</span>
                <IOSSwitch
                  color="success"
                  checked={surveyType === 0}
                  onChange={event =>
                    setSurveyType(event.target.checked ? 0 : 1)
                  }
                />
                <span>Remote</span>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-end"
                className="mt-4"
              >
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    setSelectedZipCodes(currentLocation.zipCodes);
                    setSelectedSegments(currentLocation.segmentIds);
                    setSegmentType(currentLocation.isAllSegments);
                    setSurveyType(currentLocation.isInPerson);
                    setShowLocations(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={
                    selectedZipCodes.length === 0 ||
                    (segmentType === 2 && selectedSegments.length === 0)
                  }
                  onClick={() => {
                    const location = {
                      zipCodes: selectedZipCodes,
                      segmentIds: selectedSegments,
                      segmentType,
                      surveyType,
                    };
                    dispatch(saveCurrentLocation(location)).then(() =>
                      setShowLocations(false)
                    );
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Container>
          )}
        </div>
        <div style={{ position: 'relative' }}>
          <button
            type="button"
            className="nav-link"
            onClick={() => toggleNotifications()}
            style={{ position: 'relative' }}
          >
            <img
              className="icon"
              src={images.notification}
              width="40px"
              height="40px"
              alt="Notifications"
            />
            {notifications.some(n => n.Unread) > 0 && (
              <div id="notification-flash" className="notification-flash" />
            )}
          </button>
          {showNotifications && (
            <div
              className="popup-menu"
              ref={notificationRef}
              id="notifications"
            >
              <div className="notification-header">
                <strong>Notifications</strong>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={handleClearNotifications}
                  disabled={notifications.length === 0}
                >
                  Clear Notifications
                </button>
              </div>
              <ul className="notification-body">
                {notifications.map(notification => (
                  <li
                    className={
                      notification.Unread === 1
                        ? 'cursor-pointer notification-item unread'
                        : 'cursor-pointer notification-item'
                    }
                    key={notification.id}
                    onClick={() => {
                      handleNotificationClick(notification);
                    }}
                  >
                    <div className="notification-time">
                      <span className="text-nowrap">
                        {moment(notification.NotificationDateTime).fromNow()}
                      </span>
                      <span>
                        {moment(notification.NotificationDateTime).format(
                          'h:mma'
                        )}
                      </span>
                    </div>
                    <div className="notification-text">
                      <p>{notification.NotificationTypeText}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <Link className="nav-link" to="/dashboard">
          <span>Exit </span>
          <img
            className="pr-1 icon"
            src={images.exit}
            alt="Exit"
            style={{ rotate: '180deg' }}
          />
        </Link>
      </div>
    </header>
  );
};

export default React.memo(FATHeader);
