import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../axiosConfig';

// PREVALENCE
export const getPrevalenceSearchCitiesList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/characteristics/getPrevalenceSearchCitiesList`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getPrevalenceSearchZipsList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/characteristics/getPrevalenceSearchZipsList`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getPrevalenceSearchCitiesCharList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/characteristics/getPrevalenceSearchCitiesCharList`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getPrevalenceSearchZipsCharList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/characteristics/getPrevalenceSearchZipsCharList`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getPrevalenceCities = createAsyncThunk(
  'characteristics/getPrevalenceCities',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/characteristics/getPrevalenceCities`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);
export const getPrevalenceZips = createAsyncThunk(
  'characteristics/getPrevalenceZips',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/characteristics/getPrevalenceZips`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

// HOME SIZE
export const getHomeSizeSearchZipsList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/characteristics/getHomeSizeSearchZipsList`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getHomeSizeSearchCitiesList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/characteristics/getHomeSizeSearchCitiesList`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getHomeSizeSearchCitiesCharList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/characteristics/getHomeSizeSearchCitiesCharList`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getHomeSizeAllCitiesCharList = createAsyncThunk(
  'characteristics/getHomeSizeAllCitiesCharList',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/characteristics/getHomeSizeAllCitiesCharList`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getHomeSizeAllZipsCharList = createAsyncThunk(
  'characteristics/getHomeSizeAllZipsCharList',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/characteristics/getHomeSizeAllZipsCharList`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getHomeSizeCities = createAsyncThunk(
  'characteristics/getHomeSizeCities',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/characteristics/getHomeSizeCities`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getHomeSizeZips = createAsyncThunk(
  'characteristics/getHomeSizeZips',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/characteristics/getHomeSizeZips`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getHomeSizeNightlyValueCities = createAsyncThunk(
  'characteristics/getHomeSizeNightlyValueCities',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/characteristics/getHomeSizeNightlyValueCities/`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getHomeSizeNightlyValueZips = createAsyncThunk(
  'characteristics/getHomeSizeNightlyValueZips',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/characteristics/getHomeSizeNightlyValueZips/`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

// PROFITABILITY
export const getProfitabilitySearchCitiesList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/characteristics/getProfitabilitySearchCitiesList`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getProfitabilitySearchZipsList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/characteristics/getProfitabilitySearchZipsList`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getProfitabilitySearchCitiesCharList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/characteristics/getProfitabilitySearchCitiesCharList`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getProfitabilitySearchZipsCharList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/characteristics/getProfitabilitySearchZipsCharList`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getProfitabilityCities = createAsyncThunk(
  'characteristics/getProfitabilityCities',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/characteristics/getProfitabilityCities`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getProfitabilityZips = createAsyncThunk(
  'characteristics/getProfitabilityZips',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/characteristics/getProfitabilityZips`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

// GEOGRAPHY
export const getGeographySearchCitiesList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/characteristics/getGeographySearchCitiesList`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getGeographySearchCitiesCharList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/characteristics/getGeographySearchCitiesCharList`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getGeographyZipsList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/characteristics/getGeographyZipsList`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getGeographyAllCharList = createAsyncThunk(
  'characteristics/getGeographyAllCharList',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/characteristics/getGeographyAllCharList`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getGeographyZipsCharList = createAsyncThunk(
  'characteristics/getGeographyZipsCharList',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/characteristics/getGeographyZipsCharList`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getGeographyCities = createAsyncThunk(
  'characteristics/getGeographyCities',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/characteristics/getGeographyCities`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getGeographyZips = createAsyncThunk(
  'characteristics/getGeographyZips',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/characteristics/getGeographyZips`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

// SEASONAL
export const getSeasonalSearchZipsList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/characteristics/getSeasonalSearchZipsList`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getSeasonalSearchCitiesList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/characteristics/getSeasonalSearchCitiesList`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getSeasonalSearchCitiesCharList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/characteristics/getSeasonalSearchCitiesCharList`,
      thunkAPI
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getSeasonalAllCitiesCharList = createAsyncThunk(
  'characteristics/getSeasonalAllCitiesCharList',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/characteristics/getSeasonalAllCitiesCharList`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getSeasonalCities = createAsyncThunk(
  'characteristics/getSeasonalCities',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/characteristics/getSeasonalCities`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getSeasonalZips = createAsyncThunk(
  'characteristics/getSeasonalZips',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/characteristics/getSeasonalZips`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);
