const invokeLoad = async id => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASEURL}/invokeFileLoad/${id}`,
    {
      method: 'GET',
    }
  );
  return response.json();
};

export default invokeLoad;
