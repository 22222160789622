import React from 'react';
import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import { Button } from 'react-bootstrap';

const PageNotFound = () => {
  const navigate = useNavigate();
  const isLoggedIn =
    localStorage.getItem('token') !== null &&
    localStorage.getItem('token') !== undefined;
  const navigateToPage = isLoggedIn ? 'dashboard' : '/';
  return (
    <Container className="p-3 page-not-found">
      <div className="mt-5 pt-3 page-not-found-child ">
        <h1>404</h1>
        <h1> Page not found</h1>
        <Button
          onClick={() => {
            navigate(navigateToPage);
          }}
        >
          {isLoggedIn ? 'Dashboard' : 'Login'}
        </Button>
      </div>
    </Container>
  );
};

export default PageNotFound;
