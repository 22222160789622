/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getZipCodes } from '../redux/survey/api';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getZipCodes());
  }, []);

  const user = useSelector(state => state.user);
  return (
    <Container className="p-3">
      <div className=" pt-3">
        {user?.userInfo?.id !== undefined ? (
          <>
            <Row>
              <Col xs={12} className="my-2">
                <h5 className="h5-class">Dashboard</h5>
              </Col>
            </Row>
            <Row>
              {user?.userInfo?.userRoles?.data_ingest ? (
                <Col sm={12} md={4} className="mb-4">
                  <div
                    className="card"
                    onClick={() => {
                      navigate('/dataingest');
                    }}
                  >
                    <div className="bg-one">
                      <h5>Data Ingest</h5>
                    </div>
                  </div>
                </Col>
              ) : null}

              <Col sm={12} md={4} className="mb-4">
                <div
                  className="card"
                  onClick={() => {
                    navigate('/survey');
                  }}
                >
                  <div className="bg-two">
                    <h5>Survey</h5>
                  </div>
                </div>
              </Col>
              {user?.userInfo?.userRoles?.drilldown ? (
                <Col sm={12} md={4} className="mb-4">
                  <div
                    className="card"
                    onClick={() => {
                      navigate('/drilldown');
                    }}
                  >
                    <div className="bg-three">
                      <h5>Drilldown</h5>
                    </div>
                  </div>
                </Col>
              ) : null}
              {user?.userInfo?.userRoles?.homes_review ? (
                <Col sm={12} md={4} className="mb-4">
                  <div
                    className="card"
                    onClick={() => {
                      navigate('/homesreview');
                    }}
                  >
                    <div className="bg-four">
                      <h5>Homes Review</h5>
                    </div>
                  </div>
                </Col>
              ) : null}
              {user?.userInfo?.userRoles?.characteristic ? (
                <Col sm={12} md={4} className="mb-4">
                  <div
                    className="card"
                    onClick={() => {
                      navigate('/characteristicsdrilldown');
                    }}
                  >
                    <div className="bg-four">
                      <h5>Characteristics</h5>
                    </div>
                  </div>
                </Col>
              ) : null}
              {user?.userInfo?.userRoles?.fieldtoolUser === 1 ||
              user?.userInfo?.userRoles?.fieldtoolManager === 1 ? (
                <Col sm={12} md={4} className="mb-4">
                  <div
                    className="card"
                    onClick={() => {
                      navigate('/fat');
                    }}
                  >
                    <div className="bg-five">
                      <h5>Field Analysis Tool</h5>
                    </div>
                  </div>
                </Col>
              ) : null}
            </Row>
          </>
        ) : (
          <div className="text-center">
            <h2 className="text-center mt-4 pt-4 mb-4">Loading.....</h2>
            {/* <Link to="/">
              <span>Back to login</span>
            </Link> */}
          </div>
        )}
      </div>
    </Container>
  );
};

export default Dashboard;
