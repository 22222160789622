/* eslint-disable consistent-return */
/* eslint-disable radix */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-nested-ternary */
import lodash from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import Filter from '../../component/FilterDropdown';
import DataUpdateModal from '../../component/ModelPop/DataUpdateModal';
import HideUnHideModel from '../../component/ModelPop/HideUnHideModal';
import NextGenDataTable from '../../component/NextGenBsTable';
import { notify } from '../../component/Toaster';
import {
  downloadPdf,
  // cityList,
  getSurveyDataUpdateByZips,
  // stateList,
  getSurveyDataUpdateByCities,
} from '../../redux/survey/api';
import {
  surveyDataUpdateByCityColumn,
  surveyDataUpdateByZipColumn,
} from '../../utils/columns';
import data from '../../utils/data.json';
import {
  renderFiltersInputType,
  setSelectFilterOption,
  removeFilter,
  checkedApiCall,
} from '../../utils/helperInput';
import {
  updateGeographyToUpdateData,
  updateUpdateTabNavigationData,
} from '../../redux/navigation/navigationSlice';
import ConfirmModal from '../../component/ModelPop/ConfirmModal';
import { getItem, setItem } from '../../component/services/localStorage';
import useFilters from '../../hooks/useFilters';
import { isParamExists } from '../../utils/queryParams';
import { useDebounce } from '../../hooks/useDebouncing';
import PdfModal from '../../component/ModelPop/PdfDownloadingModal';

const TabDataUpdate = () => {
  const {
    selectOption,
    selectedFilterOption,
    setFilterOptions,
    setSelectedFilterOptions,
  } = useFilters({ moduleName: 'dataupdate' });

  // fetching all local storage variables
  const surveyTypeFromLocalStorage = getItem('DataUpdateTab-surveyType');
  const toppingFromLocalStorage = getItem('DataUpdateTab-selectedTopping');

  // const { updateTab_NavigationData } = props
  const { cloneDeep, isEmpty } = lodash;
  const [isLoad, setIsLoaD] = useState(false);
  const [show, setShow] = useState(false);
  const [notShowModal, setNotShowModal] = useState(false);
  const handleShow = () => setShow(true);
  // const [setselectSortOption] = useState('');
  const dispatch = useDispatch();
  const stateData = useSelector(state => state?.ranking?.states);
  const cityData = useSelector(state => state?.ranking?.cityListDataUpdate);
  const zipCodeData = useSelector(state => state?.ranking?.zipListSurveyUpdate);
  const characteristicsNavigationData = useSelector(
    state => state?.characteristics?.getMoreData_Characteristic_To_DataUpdate
  );

  const updateTabNavigationData = useSelector(
    state => state?.navigation?.updateTab_NavigationData
  );
  const currentSurveyTab = useSelector(
    state => state.navigation.currentSurveyTab
  );
  const [seletedFilter, setSelectedFilter] = useState('');
  const [selectedSortingOption, setSelectedSortingOption] = useState({});
  const [showHideUnHide, setShowHideUnHide] = useState(false);
  const [seletedHideUnHideData, setSeletedHideUnHideData] = useState({});
  const [checkedRows, setCheckedRows] = useState([]);
  const [isDataUpdatedByFilter, setIsDataUpdatedByFilter] = useState(false);
  const [resetPage, setResetPage] = useState(1);
  const user = useSelector(state => state.user);
  const surveyDataUpdateByZip = useSelector(
    state => state.ranking.surveyDataUpdateByZips
  );
  const surveyDataUpdateByCity = useSelector(
    state => state.ranking.surveyDataUpdateByCities
  );
  const surveyDataUpdateByZipLoader = useSelector(
    state => state.ranking.surveyDataUpdateByZipLoader
  );
  const surveyDataUpdateByCityLoader = useSelector(
    state => state.ranking.surveyDataUpdateByCityLoader
  );
  const surveyDataUpdateByCitiesCount = useSelector(
    state => state?.ranking?.surveyDataUpdateByCitiesCount
  );
  const surveyDataUpdateByZipsCount = useSelector(
    state => state?.ranking?.surveyDataUpdateByZipsCount
  );
  const geographyToUpdateData = useSelector(
    state => state.navigation.geography_To_Update_Data
  );
  const [allRecordsCount, setAllRecordsCount] = useState(0);
  const [selectedGeo, setSelectedGeo] = useState(
    toppingFromLocalStorage !== null
      ? {
          city: toppingFromLocalStorage === 'City',
          zip: toppingFromLocalStorage === 'Zip',
          mySurvey: false,
          allSurvey: true,
        }
      : {
          city: true,
          zip: false,
          mySurvey: false,
          allSurvey: true,
        }
  );
  const [filterByData, setFilterByData] = useState(
    data?.dataUpdateFilterOptionsByCity
  );

  const [surveyType, setSurveyType] = useState(
    parseInt(surveyTypeFromLocalStorage) || 0
  );
  const [surveyForUpdate, setSurveyForUpdate] = useState({});
  const [surveyTypeData, setSurveyTypeData] = useState(
    parseInt(surveyTypeFromLocalStorage) === 1 ? 'MySurvey' : 'AllSurvey'
  );
  const [topping, setTopping] = useState(toppingFromLocalStorage || 'City');
  const [tableDataCity, setTableDataCity] = useState([]);
  const [tableDataZip, setTableDataZip] = useState([]);
  const [onClickUpdateBtn, setOnClickUpdateBtn] = useState([]);
  const [showGetUpdateAllModal, setShowGetUpdateAllModal] = useState(false);
  const [showGetUpdateSelectedButton, setShowGetUpdateSelectedButton] =
    useState(false);
  const [showGetUpdateAllButton, setShowGetUpdateAllButton] = useState(false);
  const isPdfDownloading = useSelector(state => state.pdf.isPdfDownloading);
  const [showPdfModal, setShowPdfModel] = useState(false);
  // const prevRoute = useLocation();
  const getData = row => {
    setShow(true);
    setSurveyForUpdate({ ...row, type: 'GET' });
  };

  const handleClose = () => {
    setShow(!show);
  };

  const setFilterOption = value => {
    if (currentSurveyTab.toLowerCase() === 'dataupdate') {
      dispatch(setFilterOptions(value));
    }
  };

  const setSelectedFilterOption = option => {
    if (currentSurveyTab.toLowerCase() === 'dataupdate') {
      dispatch(setSelectedFilterOptions(option));
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleModalClose = () => {
    setSelectedFilterOption({});
    setShow(!show);
    dispatch(updateUpdateTabNavigationData({}));
    dispatch(updateGeographyToUpdateData({}));
  };
  const handleHideUnHideClose = () => setShowHideUnHide(!showHideUnHide);

  const hideRowFromData = hiddenDataSurveyID => {
    const dataToFilter = selectedGeo.zip ? tableDataZip : tableDataCity;
    const hiddenRowIndex = dataToFilter.findIndex(
      item => item.DataSurvey_ID === hiddenDataSurveyID
    );

    if (hiddenRowIndex !== -1) {
      const currentVisibilityStatus =
        dataToFilter[hiddenRowIndex].visibility_status;

      const updatedData = dataToFilter.map((item, index) =>
        index === hiddenRowIndex
          ? {
              ...item,
              visibility_status: currentVisibilityStatus === 1 ? 2 : 1,
            }
          : item
      );

      return selectedGeo.zip
        ? setTableDataZip(updatedData)
        : setTableDataCity(updatedData);
    }
  };

  useEffect(() => {
    if (!lodash.isEmpty(characteristicsNavigationData)) {
      if (characteristicsNavigationData.DataFrom === 'City') {
        setSelectedGeo({
          city: true,
          zip: false,
          mySurvey: false,
          allSurvey: true,
        });
        setTopping('City');
        const filteredData = cityData.filter(item =>
          item.value.includes(characteristicsNavigationData.value)
        );
        setSelectedFilterOption({
          City: [
            {
              value: filteredData[0].value,
              label: filteredData[0].label,
              DataFrom: 'City',
            },
          ],
        });
        setFilterOption(['City']);
      } else if (characteristicsNavigationData.DataFrom === 'Zip') {
        setSelectedGeo({
          city: false,
          zip: true,
          mySurvey: false,
          allSurvey: true,
        });
        setTopping('Zip');
        const filteredData = zipCodeData.filter(item =>
          item.value.includes(characteristicsNavigationData.value)
        );
        setSelectedFilterOption({
          ZIPCode: [
            {
              value: filteredData[0].value,
              label: filteredData[0].label,
              DataFrom: 'ZIPCode',
            },
          ],
        });
        setFilterOption(['ZIPCode']);
      }
    }
  }, [characteristicsNavigationData]);

  const handleClosePdfModel = () => {
    setShowPdfModel(false);
  };

  const onSelectDropDown = async (e, row) => {
    if (e === 'print') {
      notify('Download pdf is in process');
      const pdfApiData = {
        SurveyType: row?.SurveyType,
        ZIPCode: row?.ZIPCode,
        City: row?.City,
        StateAbbr: row?.StateAbbr,
        MarketName: row?.MarketName,
      };
      dispatch(downloadPdf(pdfApiData));
    }
    if (e === 'update') {
      handleShow();
      setSurveyForUpdate({ ...row, type: 'Update' });
    }
    if (e === 'Hide' || e === 'Unhide') {
      setSeletedHideUnHideData(row);
      setShowHideUnHide(true);
    }
  };

  useEffect(() => {
    setAllRecordsCount(
      selectedGeo.zip
        ? surveyDataUpdateByZipsCount
        : surveyDataUpdateByCitiesCount
    );
  }, [selectedGeo, surveyDataUpdateByZipsCount, surveyDataUpdateByCitiesCount]);

  useEffect(() => {
    // Conditions for the Get / Update Selected button
    setShowGetUpdateSelectedButton(
      checkedRows.length > 0 &&
        isDataUpdatedByFilter &&
        (selectedGeo.city
          ? tableDataCity?.length > 0
          : tableDataZip?.length > 0)
    );

    // Conditions for the Get / Update All button
    setShowGetUpdateAllButton(
      isDataUpdatedByFilter &&
        checkedRows.length === 0 &&
        (selectedGeo.city
          ? tableDataCity?.length > 0
          : tableDataZip?.length > 0)
    );
  }, [
    selectOption,
    checkedRows,
    isDataUpdatedByFilter,
    selectedGeo,
    tableDataCity,
    tableDataZip,
  ]);

  useEffect(() => {
    setItem('DataUpdateTab-surveyType', surveyType);
    setItem('DataUpdateTab-selectedTopping', topping);
  }, [surveyType, topping]);

  useEffect(() => {
    if (selectOption.length > 0) {
      const filteredData = filterByData.filter(
        filter => filter.optionValue !== selectOption[selectOption.length - 1]
      );
      setFilterByData(filteredData);
    } else {
      setSelectedFilter('');
      const filterData =
        topping === 'City'
          ? data?.dataUpdateFilterOptionsByCity
          : data?.dataUpdateFilterOptionsByZip;
      setFilterByData(filterData);
    }
  }, [selectOption]);

  useEffect(() => {
    const isAnyFilterSelected = Object.values(selectedFilterOption).some(
      value => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return value !== '' && value !== null && value !== undefined;
      }
    );
    setIsDataUpdatedByFilter(isAnyFilterSelected);
  }, [selectedFilterOption]);

  const fetchSurveyDataUpdateByZipAndCity = (
    start = 0,
    limit = 10,
    columnSort = {}
  ) => {
    const sorts = { ...selectedSortingOption, ...columnSort };
    if (selectedGeo.city) {
      dispatch(
        getSurveyDataUpdateByCities({
          filters: { isStateCityFilter: true, ...selectedFilterOption },
          sortings: sorts,
          surveyType,
          start,
          limit,
        })
      );
    }
    if (selectedGeo.zip) {
      dispatch(
        getSurveyDataUpdateByZips({
          filters: { isStateCityFilter: true, ...selectedFilterOption },
          sortings: sorts,
          surveyType,
          start,
          limit,
        })
      );
    }
  };

  const setGeographyRadioButton = () => {
    const isZIPCodeSelected = isParamExists('ZIPCode');
    const selectedTopping = isZIPCodeSelected ? 'Zip' : 'City';
    setSelectedGeo({
      city: !isZIPCodeSelected,
      zip: isZIPCodeSelected,
      mySurvey: false,
      allSurvey: true,
    });
    setTopping(selectedTopping);
  };

  useEffect(() => {
    if (
      currentSurveyTab === 'Dataupdate' &&
      isEmpty(characteristicsNavigationData)
    ) {
      setNotShowModal(true);
      setGeographyRadioButton();
    } else {
      setNotShowModal(false);
    }
  }, [currentSurveyTab]);

  useEffect(() => {
    if (
      currentSurveyTab === 'Dataupdate' &&
      isEmpty(characteristicsNavigationData)
    ) {
      if (updateTabNavigationData?.SurveyType === 2) {
        setSelectedGeo({
          city: false,
          zip: true,
          mySurvey: false,
          allSurvey: true,
        });
        setTopping('Zip');
      } else if (updateTabNavigationData?.SurveyType === 1) {
        setSelectedGeo({
          city: true,
          zip: false,
          mySurvey: false,
          allSurvey: true,
        });
        setTopping('City');
      }
    }
    return () => {
      if (!lodash.isEmpty(updateTabNavigationData))
        dispatch(updateUpdateTabNavigationData({}));
      if (!lodash.isEmpty(geographyToUpdateData))
        dispatch(updateGeographyToUpdateData({}));
    };
  }, [updateTabNavigationData]);

  useEffect(() => {
    let timer;
    if (notShowModal === true) {
      if (updateTabNavigationData?.SurveyType === 1) {
        if (
          Object.keys(updateTabNavigationData).length &&
          Object.keys(surveyDataUpdateByCity).length
        ) {
          timer = setTimeout(() => {
            handleShow();
            setSurveyForUpdate({
              ...surveyDataUpdateByCity[0],
              type: 'Update',
            });
          }, 200);
        }
      } else if (updateTabNavigationData?.SurveyType === 2) {
        if (
          Object.keys(updateTabNavigationData).length &&
          Object.keys(surveyDataUpdateByZip).length
        ) {
          timer = setTimeout(() => {
            handleShow();
            setSurveyForUpdate({ ...surveyDataUpdateByZip[0], type: 'Update' });
          }, 200);
        }
      }
    }
    return () => {
      clearTimeout(timer);
    };
  }, [
    updateTabNavigationData,
    surveyDataUpdateByCity,
    surveyDataUpdateByZip,
    notShowModal,
  ]);

  const columns = selectedGeo.city
    ? surveyDataUpdateByCityColumn(onSelectDropDown, getData, user)
    : surveyDataUpdateByZipColumn(onSelectDropDown, getData, user);

  const debouncedApiCall = useDebounce(() => {
    const isCallApi = checkedApiCall(selectedFilterOption);
    if (isCallApi || Object.keys(selectedSortingOption).length > 0) {
      fetchSurveyDataUpdateByZipAndCity();
    }
  }, 1000);

  useEffect(() => {
    if (currentSurveyTab?.toLowerCase() === 'dataupdate') {
      debouncedApiCall();
    }
  }, [
    selectedFilterOption,
    selectedSortingOption,
    selectedGeo,
    surveyType,
    currentSurveyTab,
    topping,
  ]);

  const setSelectFilterOptionFunction = value => {
    if (value === 'City') {
      setTopping('City');
      setSelectedGeo({ city: true, zip: false });
    }
    if (value === 'ZIPCode') {
      setTopping('Zip');
      setSelectedGeo({ city: false, zip: true });
    }
    setSelectFilterOption(
      value,
      data,
      selectOption,
      setFilterOption,
      setSelectedFilter,
      selectedFilterOption,
      setSelectedFilterOption,
      filterByData,
      setFilterByData
    );
  };

  const removeFilterFunction = id => {
    if (
      (selectOption[id] === 'ZIPCode' || selectOption[id] === 'City') &&
      !lodash.isEmpty(updateUpdateTabNavigationData)
    ) {
      dispatch(updateUpdateTabNavigationData({}));
    }
    if (
      (selectOption[id] === 'ZIPCode' || selectOption[id] === 'City') &&
      !lodash.isEmpty(geographyToUpdateData)
    ) {
      dispatch(updateGeographyToUpdateData({}));
    }
    removeFilter(
      id,
      topping === 'City'
        ? data?.dataUpdateFilterOptionsByCity
        : data?.dataUpdateFilterOptionsByZip,
      selectOption,
      selectedFilterOption,
      setFilterOption,
      setSelectedFilter,
      setSelectedFilterOption,
      setFilterByData,
      filterByData
    );
  };

  const resetFilters = () => {
    setFilterOption([]);
    setSelectedFilter('');
    setSelectedSortingOption({});
    setSelectedFilterOption({});
    if (topping === 'City') {
      setFilterByData(data?.dataUpdateFilterOptionsByCity);
    }
    if (topping === 'Zip') {
      setFilterByData(data?.dataUpdateFilterOptionsByZip);
    }
    dispatch(updateUpdateTabNavigationData({}));
    // fetchSurveyDataUpdateByZip({}, {});
  };

  // const applyFilters = () => {
  //   // fetchSurveyDataUpdateByZip(selectedFilterOption, selectedSortingOption);
  // };
  // const setSortingOption = option => {
  //   const tempOption = {};
  //   if (option !== 'default') {
  //     setselectSortOption(option);
  //     if (option === 'monthly_revenue') {
  //       tempOption.Revenue_Baseline = true;
  //     } else {
  //       tempOption[option] = true;
  //     }
  //   }

  //   setSelectedSortingOption(tempOption);
  // };

  const inputOnChangeFunction = (type, value) => {
    const tempSelectedFilterOption = cloneDeep(selectedFilterOption);
    tempSelectedFilterOption[type] = value;
    setSelectedFilterOption(tempSelectedFilterOption);
  };

  const renderFilterOptions = () => {
    return (
      <Row className="mb-2">
        {selectOption?.map((res, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Col xs={3} key={`filters-${i}`}>
              {renderFiltersInputType(
                res,
                i,
                data,
                topping === 'City'
                  ? data?.dataUpdateFilterOptionsByCity
                  : data?.dataUpdateFilterOptionsByZip,
                inputOnChangeFunction,
                removeFilterFunction,
                stateData,
                cityData,
                zipCodeData,
                selectedFilterOption
              )}
            </Col>
          );
        })}
      </Row>
    );
  };

  // const dropdownOption = () => {
  //   if (selectedGeo.city) {
  //     setFilterByData(data.dataUpdateFilterOptionsByCity);
  //     return data.dataUpdateFilterOptionsByCity;
  //   }
  //   setFilterByData(data?.dataUpdateFilterOptionsByZip);
  //   return data?.dataUpdateFilterOptionsByZip;
  // };
  const surveyTypeDataChange = e => {
    setResetPage(1);
    setSurveyTypeData(e.target.value);
    if (e.target.value === 'AllSurvey') {
      setSurveyType(0);
    } else {
      setSurveyType(1);
    }
  };

  const onOptionChange = e => {
    resetFilters();
    setNotShowModal(false);
    setResetPage(1);
    setTopping(e.target.value);
    if (e.target.value === 'City') {
      setFilterByData(data?.dataUpdateFilterOptionsByCity);
      setSelectedGeo({ city: true, zip: false });
    }
    if (e.target.value === 'Zip') {
      setFilterByData(data?.dataUpdateFilterOptionsByZip);
      setSelectedGeo({ city: false, zip: true });
    }
  };

  useEffect(() => {
    if (!lodash.isEmpty(updateTabNavigationData)) {
      if (updateTabNavigationData?.SurveyType === 1) {
        setSelectedFilterOption({
          City: [
            {
              value: updateTabNavigationData?.City,
              label: updateTabNavigationData?.City,
              DataFrom: 'City',
            },
          ],
        });
      } else if (updateTabNavigationData?.SurveyType === 2) {
        setSelectedFilterOption({
          ZIPCode: [
            {
              value: updateTabNavigationData?.ZIPCode,
              label: updateTabNavigationData?.ZIPCode,
              DataFrom: 'ZIPCode',
            },
          ],
        });
      }
    }
  }, [updateTabNavigationData]);

  useEffect(() => {
    if (updateTabNavigationData?.SurveyType === 1) {
      setFilterOption(['City']);
    } else if (updateTabNavigationData?.SurveyType === 2) {
      setFilterOption(['ZIPCode']);
    }
    if (!isEmpty(geographyToUpdateData)) {
      if (geographyToUpdateData.DataFrom === 'City') {
        setSelectedGeo({ city: true, zip: false });
        setFilterOption(['City']);
        setTopping('City');
      }
      if (geographyToUpdateData.DataFrom === 'Zip') {
        setSelectedGeo({ city: false, zip: true });
        setFilterOption(['ZIPCode']);
        setTopping('Zip');
      }

      setSelectedFilterOption({
        ...selectedFilterOption,
        [geographyToUpdateData.DataFrom === 'City' ? 'City' : 'ZIPCode']: [
          geographyToUpdateData,
        ],
      });
    }
  }, [geographyToUpdateData]);

  useEffect(() => {
    if (!isEmpty(geographyToUpdateData)) {
      if (!isEmpty(tableDataCity)) {
        setShow(true);
        setSurveyForUpdate({
          ...tableDataCity[0],
          type: 'Update',
        });
      }
      if (!isEmpty(tableDataZip)) {
        setShow(true);
        setSurveyForUpdate({
          ...tableDataZip[0],
          type: 'Update',
        });
      }
    }
  }, [tableDataCity, tableDataZip]);

  useEffect(() => {
    if (selectedGeo.zip) setTableDataZip(surveyDataUpdateByZip);
    else setTableDataCity(surveyDataUpdateByCity);
  }, [surveyDataUpdateByZip, surveyDataUpdateByCity, selectedGeo]);

  useEffect(() => {
    if (onClickUpdateBtn.length > 0) {
      if (selectedGeo.city) {
        const retData = surveyDataUpdateByCity?.map(element => {
          const isExits = onClickUpdateBtn.some(
            sElement => sElement.MarketName === element.MarketName
          );
          const retObj = { ...element };
          if (isExits) {
            retObj.isLoading = true;
          }

          return retObj;
        });

        setTableDataCity(retData);
      } else {
        const retData = surveyDataUpdateByZip?.map(element => {
          const isExits = onClickUpdateBtn.some(
            sElement => sElement.MarketName === element.MarketName
          );
          const retObj = { ...element };
          if (isExits) {
            retObj.isLoading = true;
          }
          return retObj;
        });

        setTableDataZip(retData);
      }
    } else if (selectedGeo.city) {
      const retData = surveyDataUpdateByCity?.map(element => {
        const retObj = { ...element };
        delete retObj.isLoading;
        return retObj;
      });

      setTableDataCity(retData);
    } else {
      const retData = surveyDataUpdateByZip?.map(element => {
        const retObj = { ...element };
        delete retObj.isLoading;
        return retObj;
      });
      setTableDataZip(retData);
    }
  }, [onClickUpdateBtn]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaD(false);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [isLoad]);

  useEffect(() => {
    if (currentSurveyTab.toLowerCase() === 'dataupdate')
      setShowPdfModel(isPdfDownloading);
  }, [isPdfDownloading]);

  useEffect(() => {
    if (!lodash.isEmpty(characteristicsNavigationData)) {
      if (characteristicsNavigationData.DataFrom === 'City') {
        const filteredData = cityData.filter(item =>
          item.value.includes(characteristicsNavigationData.value)
        );
        setSelectedGeo({
          city: true,
          zip: false,
          mySurvey: false,
          allSurvey: true,
        });
        setTopping('City');
        setFilterOption(['City']);
        setSelectedFilterOption({
          City: [
            {
              value: filteredData[0].value,
              label: filteredData[0].label,
              DataFrom: 'City',
            },
          ],
        });
      } else if (characteristicsNavigationData.DataFrom === 'Zip') {
        const filteredData = zipCodeData.filter(item =>
          item.value.includes(characteristicsNavigationData.value)
        );
        setSelectedGeo({
          city: false,
          zip: true,
          mySurvey: false,
          allSurvey: true,
        });
        setTopping('Zip');
        setFilterOption(['ZIPCode']);
        setSelectedFilterOption({
          ZIPCode: [
            {
              value: filteredData[0].value,
              label: filteredData[0].label,
              DataFrom: 'ZIPCode',
            },
          ],
        });
      }
    }
  }, [characteristicsNavigationData]);

  return (
    <div>
      <Container className="px-0">
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={isLoad}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" size="3rem" />
        </Backdrop>
        <Row>
          <Col xs={5} className="flex-style">
            <div>
              <b>Show:</b>
            </div>

            <div className="flex-style">
              <label className="cursor-pointer" htmlFor="AllSurveyUpdate">
                <input
                  className="cursor-pointer"
                  type="radio"
                  name="AllSurveydataupdate"
                  value="AllSurvey"
                  id="AllSurveyUpdate"
                  checked={surveyTypeData === 'AllSurvey'}
                  onChange={surveyTypeDataChange}
                />
                All Surveys
              </label>

              <label className="cursor-pointer" htmlFor="MySurveyUpdate">
                <input
                  className="cursor-pointer"
                  type="radio"
                  name="AllSurveydataupdate"
                  value="MySurvey"
                  id="MySurveyUpdate"
                  checked={surveyTypeData === 'MySurvey'}
                  onChange={surveyTypeDataChange}
                />
                My Surveys
              </label>
            </div>
          </Col>
          <Col xs={5} className="flex-style">
            <div>
              <b>Geography:</b>
            </div>

            <div className="flex-style">
              <label className="cursor-pointer" htmlFor="CityUpdate">
                <input
                  className="cursor-pointer"
                  type="radio"
                  name="toppingUpdate"
                  value="City"
                  id="CityUpdate"
                  checked={topping === 'City'}
                  onChange={onOptionChange}
                />
                Cities
              </label>

              <label className="cursor-pointer" htmlFor="ZipUpdate">
                <input
                  className="cursor-pointer"
                  type="radio"
                  name="toppingUpdate"
                  value="Zip"
                  id="ZipUpdate"
                  checked={topping === 'Zip'}
                  onChange={onOptionChange}
                />
                ZIP Codes
              </label>
            </div>
          </Col>
        </Row>
        <Row className="">
          <Col xs={3}>
            <Filter
              setOption={setSelectFilterOptionFunction}
              options={filterByData}
              title="Filter By"
              value={seletedFilter}
            />
          </Col>
          <Col xs={4} className="reset-top-surveyUpdate">
            <Row>
              <div className="button-div-class">
                {selectOption?.length > 0 && (
                  <button
                    type="button"
                    variant="primary"
                    className="allbutton"
                    onClick={() => {
                      resetFilters();
                    }}
                  >
                    Reset Filter
                  </button>
                )}
                {showGetUpdateSelectedButton && (
                  <div>
                    <button
                      type="button"
                      variant="primary"
                      className="allbutton"
                      // onClick={handleShow}
                    >
                      Get / Update Selected
                    </button>
                  </div>
                )}
                {showGetUpdateAllButton && (
                  <div>
                    <button
                      type="button"
                      variant="primary"
                      className="allbutton"
                      onClick={() => setShowGetUpdateAllModal(true)}
                    >
                      Get / Update All
                    </button>
                  </div>
                )}
              </div>
            </Row>
          </Col>
        </Row>
        {((Object.keys(updateTabNavigationData).length &&
          (Object.keys(surveyDataUpdateByCity).length ||
            Object.keys(surveyDataUpdateByZip).length) &&
          currentSurveyTab === 'Dataupdate') ||
          show) && (
          <DataUpdateModal
            show={show}
            handleClose={handleClose}
            surveyDataForUpdate={surveyForUpdate}
            geo={topping}
            setOnClickUpdateBtn={setOnClickUpdateBtn}
            fetchDataFunction={fetchSurveyDataUpdateByZipAndCity}
          />
        )}
        {showHideUnHide && (
          <HideUnHideModel
            show={showHideUnHide}
            handleClose={handleHideUnHideClose}
            row={seletedHideUnHideData}
            hideRowFromData={hideRowFromData}
          />
        )}
        <PdfModal show={showPdfModal} onClose={handleClosePdfModel} />
        <ConfirmModal
          heading="Get/Update All"
          message={`This will update ${
            selectedGeo.zip ? 'ZIP Codes' : 'Cities'
          } geographies with the latest data, Continue?`}
          show={showGetUpdateAllModal}
          setShow={setShowGetUpdateAllModal}
          onConfirm={() => {}}
        />

        <Row>
          <Col xs={12}>{renderFilterOptions()}</Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="data-update-mainDiv">
              <NextGenDataTable
                columns={columns}
                data={selectedGeo.zip ? tableDataZip : tableDataCity}
                keyField="id"
                loading={
                  selectedGeo.zip
                    ? surveyDataUpdateByZipLoader
                    : surveyDataUpdateByCityLoader
                }
                hideSizePerPage
                enableSearching={false}
                striped
                showCheckboxCell
                setCheckedRows={setCheckedRows}
                count={allRecordsCount}
                fetchData={fetchSurveyDataUpdateByZipAndCity}
                remote
                resetPage={resetPage}
                setResetPage={setResetPage}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TabDataUpdate;
