import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../axiosConfig';

export const getHomeReviewCitiesList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/homeReview/getHomeReviewCitiesList`,
      thunkAPI
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const getHomeReviewZipsList = async thunkAPI => {
  try {
    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    const response = await axiosConfig.post(
      `/homeReview/getHomeReviewZipsList`,
      thunkAPI
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const getHomeReviewList = createAsyncThunk(
  'homeReview/getHomeReviewList',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(
        `/homeReview/getHomeReviewList`,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getHomeReviewDetails = createAsyncThunk(
  'homeReview/getHomeReviewDetails',
  async thunkAPI => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.get(
        `/homeReview/getHomeReviewDetails/${thunkAPI}`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getKeywordsList = createAsyncThunk(
  'homeReview/getKeywordsList',
  async () => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.get(`/homeReview/getKeywordsList/`);
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getKeywordsListCities = createAsyncThunk(
  'homeReview/getKeywordsListCities',
  async () => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.get(
        `/homeReview/getKeywordsListCities/`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getKeywordsListZips = createAsyncThunk(
  'homeReview/getKeywordsListZips',
  async () => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.get(
        `/homeReview/getKeywordsListZips/`
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const zipListHomeReview = createAsyncThunk(
  'homeReview/zipListHomeReview',
  async () => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(`/zipcode/zipListHomeReview/`);
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const cityListHomeReview = createAsyncThunk(
  'homeReview/cityListHomeReview',
  async () => {
    try {
      axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token'
      )}`;
      const response = await axiosConfig.post(`/city/cityListHomeReview/`);
      return response.data;
    } catch (error) {
      return null;
    }
  }
);
