/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectOption: [],
  selectedFilterOption: {},
};

const validateOptions = options => {
  let results = [...options];
  if (Array.isArray(options) && options.length > 1) {
    const REMOVE_FILTER_MAP = {
      ZIPCode: ['City'],
      City: ['ZIPCode'],
    };
    const lastAdded = options.slice(-1)[0];
    const hasRemovableFilter =
      Object.keys(REMOVE_FILTER_MAP).includes(lastAdded);
    if (hasRemovableFilter)
      results = options.filter(
        item => !REMOVE_FILTER_MAP[lastAdded].includes(item)
      );
  }

  return results;
};

export const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilterOptions: (state, action) => {
      const options = validateOptions(action.payload);
      state.selectOption = options;
    },
    setSelectedFilterOptions: (state, action) => {
      state.selectedFilterOption = action.payload;
    },
    setListAndOptions: (state, action) => {
      const { options, list } = action.payload;
      state.selectOption = list;
      state.selectedFilterOption = options;
    },
  },
});

export const { setFilterOptions, setSelectedFilterOptions, setListAndOptions } =
  filterSlice.actions;

export default filterSlice.reducer;
