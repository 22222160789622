/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  getDrilldownBaseline,
  getDrilldownOptimized,
  cityListDrilldown,
  zipListDrilldown,
} from './api';

const initialState = {
  drilldownBaseline: [],
  drilldownBaselineCount: 0,
  drilldownBaselineLoader: false,
  drilldownOptimized: [],
  drilldownOptimizedCount: 0,
  drilldownOptimizedLoader: false,
  surveyToDrilldownData: {},
  drilldownCityList: [],
  drilldowmZipList: [],
  quickSearch: '',
};

export const drilldownSlice = createSlice({
  name: 'drilldown',
  initialState,
  reducers: {
    updateSurveyToDrilldown: (state, action) => {
      state.surveyToDrilldownData = action.payload;
    },
    setQuickSearchValue: (state, action) => {
      state.quickSearch = action.payload;
    },
  },
  extraReducers(builders) {
    builders.addCase(getDrilldownBaseline.fulfilled, (state, action) => {
      state.drilldownBaseline = action?.payload?.data;
      state.drilldownBaselineCount = action?.payload.count;
      state.drilldownBaselineLoader = false;
    });
    builders.addCase(getDrilldownBaseline.pending, state => {
      state.drilldownBaselineLoader = true;
      state.drilldownBaseline = [];
      state.drilldownBaselineCount = 0;
    });
    builders.addCase(getDrilldownBaseline.rejected, state => {
      state.drilldownBaselineLoader = true;
      state.drilldownBaselineCount = 0;
      state.drilldownBaseline = [];
    });

    builders.addCase(getDrilldownOptimized.fulfilled, (state, action) => {
      state.drilldownOptimized = action?.payload.data;
      state.drilldownOptimizedLoader = false;
      state.drilldownOptimizedCount = action?.payload?.count;
    });
    builders.addCase(getDrilldownOptimized.pending, state => {
      state.drilldownOptimized = [];
      state.drilldownOptimizedLoader = true;
      state.drilldownOptimizedCount = 0;
    });
    builders.addCase(getDrilldownOptimized.rejected, state => {
      state.drilldownOptimized = [];
      state.drilldownOptimizedLoader = true;
      state.drilldownOptimizedCount = 0;
    });

    builders.addCase(cityListDrilldown.fulfilled, (state, action) => {
      state.drilldownCityList = action.payload;
    });

    builders.addCase(zipListDrilldown.fulfilled, (state, action) => {
      state.drilldowmZipList = action.payload;
    });
  },
});

export const { updateSurveyToDrilldown, setQuickSearchValue } =
  drilldownSlice.actions;

export default drilldownSlice.reducer;
