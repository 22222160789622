/* eslint-disable react/prop-types */
import React from 'react';
import Form from 'react-bootstrap/Form';
// import propTypes from 'prop-types';

const Filter = props => {
  const { title, options, setOption, value } = props;
  const changeCountryType = e => {
    setOption(e.target.value);
  };

  const renderOptions = () => {
    return options?.map((res, i) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <option key={`f--${i}`} value={res.optionValue}>
          {res.optionName}
        </option>
      );
    });
  };

  return (
    <div>
      <h6>{title}</h6>
      <Form.Select onChange={changeCountryType} value={value} na="true">
        {renderOptions()}
      </Form.Select>{' '}
    </div>
  );
};

// Filter.defaultProps = {
//   value: 0,
// };

// Filter.propTypes = {
//   title: propTypes.string.isRequired,
//   options: propTypes.arrayOf(
//     propTypes.shape({
//       optionValue: propTypes.string,
//       optionName: propTypes.string,
//     })
//   ).isRequired,
//   setOption: propTypes.func.isRequired,
//   // eslint-disable-next-line react/forbid-prop-types
//   value: propTypes.any,
// };

export default Filter;
