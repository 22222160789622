// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#recent-properties thead th {
  background-color: #2472BB;
  font-weight: 400;
  padding: 5px 10px;
  color: #fff;
  white-space: nowrap;
}

#recent-properties th, #recent-properties td {
  padding: 5px 10px;
  font-weight: 400;
}

#recent-properties tbody tr:nth-child(odd) td {
  background-color: rgba(24, 119, 242, 0.19);
}

table#recent-properties input[type="checkbox"] {
  margin: .5rem .5rem;
  width: 1.5rem;
  height: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/FieldAnalysisTool/recentProperties.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,cAAc;AAChB","sourcesContent":["#recent-properties thead th {\n  background-color: #2472BB;\n  font-weight: 400;\n  padding: 5px 10px;\n  color: #fff;\n  white-space: nowrap;\n}\n\n#recent-properties th, #recent-properties td {\n  padding: 5px 10px;\n  font-weight: 400;\n}\n\n#recent-properties tbody tr:nth-child(odd) td {\n  background-color: rgba(24, 119, 242, 0.19);\n}\n\ntable#recent-properties input[type=\"checkbox\"] {\n  margin: .5rem .5rem;\n  width: 1.5rem;\n  height: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
