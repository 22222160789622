/* eslint-disable no-nested-ternary */
/* eslint-disable no-lonely-if */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import AsyncSelect from 'react-select/async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'debounce-promise';
import propTypes from 'prop-types';
import NextGenBsTable from '../../component/NextGenBsTable';
import {
  getSeasonalSearchZipsList,
  getSeasonalSearchCitiesList,
  getSeasonalSearchCitiesCharList,
  getSeasonalAllCitiesCharList,
  getSeasonalCities,
  getSeasonalZips,
} from '../../redux/characteristics/api';
import {
  seasonalByCharcterstics,
  seasonalByTopCharSeason,
} from '../../utils/columns';
import Filter from '../../component/FilterDropdown';
import data from '../../utils/data.json';
import { updateCurrentSurveyTab } from '../../redux/navigation/navigationSlice';
import {
  getMoreDataCharacteristicToDataUpdate,
  setSearchValue,
} from '../../redux/characteristics/characteristicsSlice';
import {
  removeFilter,
  renderFiltersInputType,
  setSelectFilterOption,
} from '../../utils/helperInput';

const SEASONS = ['Jan-Mar', 'Apr-Jun', 'Jul-Sep', 'Oct-Dec'];

const TabSeasonal = ({ selectedTab }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [viewBy, setViewBy] = useState('byChar');
  const [selectOption, setFilterOption] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [selectedFilterOption, setSelectedFilterOption] = useState({});
  const [selectedSortingOption, setSelectedSortingOption] = useState({});
  const [selectedCharacteristic, setSelectedCharacteristic] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedZip, setSelectedZip] = useState('');
  const [seasonNumber, setSeasonNumber] = useState(1);
  const [filterByData, setFilterByData] = useState(
    data?.characteristicsFilters
  );
  const seasonalCharList = useSelector(
    state => state.characteristics.seasonalCharList
  );
  const seasonalCities = useSelector(
    state => state?.characteristics?.seasonalCities
  );
  const seasonalZips = useSelector(
    state => state?.characteristics?.seasonalZips
  );
  const seasonalCitiesLoader = useSelector(
    state => state?.characteristics?.seasonalCitiesLoader
  );
  const seasonalZipsLoader = useSelector(
    state => state?.characteristics?.seasonalZipsLoader
  );
  const quickSearchValue = useSelector(
    state => state.characteristics.searchValue
  );

  const handleGetMoreData = () => {
    const DataFrom =
      selectedCity !== '' ? 'City' : selectedZip !== '' ? 'Zip' : 'char';
    if (DataFrom === 'char') {
      dispatch(getMoreDataCharacteristicToDataUpdate({ DataFrom, value: '' }));
      dispatch(updateCurrentSurveyTab('Dataupdate'));
      navigate('/survey');
    } else {
      const value = selectedCity !== '' ? selectedCity : selectedZip;
      dispatch(getMoreDataCharacteristicToDataUpdate({ DataFrom, value }));
      dispatch(updateCurrentSurveyTab('Dataupdate'));
      navigate('/survey');
    }
  };

  const setSelectedOption = options => {
    if (!options || options.length === 0) {
      setViewBy('byChar');
      setSelectedCity('');
      setSelectedZip('');
      setSelectedCharacteristic('');
    }
    const hasCityOrZip = !!options.find(
      o => o.DataFrom === 'City' || o.DataFrom === 'Zip'
    );

    let newSelectedCity = '';
    let newSelectedZip = '';
    let newSelectedCharacteristics = '';

    options.forEach(o => {
      if (o?.DataFrom === 'City') newSelectedCity = o.value;
      if (o?.DataFrom === 'Zip') newSelectedZip = o.value;
      if (hasCityOrZip && o?.DataFrom === 'Char')
        newSelectedCharacteristics = o.value;
    });

    setSelectedCity(newSelectedCity);
    setSelectedZip(newSelectedZip);
    setSelectedCharacteristic(newSelectedCharacteristics);

    if (hasCityOrZip) dispatch(setSearchValue(options));
    else dispatch(setSearchValue([]));
  };

  useEffect(() => {
    setSelectedOption(quickSearchValue);
  }, [selectedTab]);

  const handleSelectedCharacteristic = value => {
    if (quickSearchValue.length > 0) {
      setSelectedOption([
        ...quickSearchValue.filter(o => o?.DataFrom !== 'Char'),
        { label: value, value, DataFrom: 'Char' },
      ]);
    }
  };

  const getSeasonal = (start, limit, sort = {}) => {
    const sortings = { ...sort, ...selectedSortingOption };
    if (selectedZip) {
      if (viewBy === 'bySeason' && selectedCharacteristic) {
        dispatch(
          getSeasonalZips({
            searchQuery: selectedZip,
            filters: {
              ...selectedFilterOption,
              CharacteristicName: selectedCharacteristic,
            },
            sortings,
          })
        );
      }
      if (viewBy === 'byChar') {
        dispatch(
          getSeasonalZips({
            searchQuery: selectedZip,
            filters: {
              ...selectedFilterOption,
              SeasonNumber: seasonNumber,
              CharacteristicName: selectedCharacteristic,
            },
            sortings,
          })
        );
      }
    }
    if (selectedCity) {
      if (viewBy === 'bySeason' && selectedCharacteristic) {
        dispatch(
          getSeasonalCities({
            searchQuery: selectedCity,
            filters: {
              ...selectedFilterOption,
              CharacteristicName: selectedCharacteristic,
            },
            sortings,
          })
        );
      }
      if (viewBy === 'byChar') {
        dispatch(
          getSeasonalCities({
            searchQuery: selectedCity,
            filters: {
              ...selectedFilterOption,
              SeasonNumber: seasonNumber,
              CharacteristicName: selectedCharacteristic,
            },
            sortings,
          })
        );
      }
    }
  };

  const handleSortNumberChange = type => {
    if (type === 'increment') setSeasonNumber(prev => prev + 1);
    else setSeasonNumber(prev => prev - 1);
  };

  useEffect(() => {
    if (selectedTab === 'Seasonal') getSeasonal();
  }, [
    viewBy,
    selectedCity,
    selectedZip,
    seasonNumber,
    selectedCharacteristic,
    selectedFilterOption,
    selectedSortingOption,
    selectedTab,
  ]);

  useEffect(() => {
    if (viewBy === 'bySeason') dispatch(getSeasonalAllCitiesCharList());
  }, [viewBy]);

  const setSelectFilterOptionFunction = value => {
    setSelectFilterOption(
      value,
      data,
      selectOption,
      setFilterOption,
      setSelectedFilter,
      selectedFilterOption,
      setSelectedFilterOption,
      filterByData,
      setFilterByData
    );
  };

  const setSortingOption = option => {
    const tempOption = {};
    if (option !== 'default') {
      setSelectedSortingOption(option);
      if (option === 'monthly_revenue') {
        tempOption.Revenue_Baseline = true;
      } else {
        tempOption[option] = true;
      }
    }

    setSelectedSortingOption(tempOption);
  };

  const inputOnChangeFunction = (type, value) => {
    const tempSelectedFilterOption = cloneDeep(selectedFilterOption);
    tempSelectedFilterOption[type] = value;
    setSelectedFilterOption(tempSelectedFilterOption);
  };

  const removeFilterFunction = id => {
    removeFilter(
      id,
      data.characteristicsFilters,
      selectOption,
      selectedFilterOption,
      setFilterOption,
      setSelectedFilter,
      setSelectedFilterOption,
      setFilterByData,
      filterByData
    );
  };

  const resetFilters = () => {
    setFilterOption([]);
    setSelectedFilter('');
    setSelectedSortingOption({});
    setSelectedFilterOption({});
  };

  const renderFilterOptions = () => {
    return (
      <Row className="mb-2 align-items-end">
        {selectOption?.map((res, i) => {
          return (
            // TODO: fix the key
            // eslint-disable-next-line react/no-array-index-key
            <Col xs={3} key={`filters-${i}`}>
              {renderFiltersInputType(
                res,
                i,
                data,
                data.characteristicsFilters,
                inputOnChangeFunction,
                removeFilterFunction,
                [],
                [],
                [],
                selectedFilterOption,
                [],
                'dropdown-select',
                'minimumOccupancyPercentage'
              )}
            </Col>
          );
        })}
        <Col xs={3}>
          <div className="mb-2">
            {selectOption?.length ? (
              <button
                type="button"
                className="allbutton"
                onClick={() => {
                  resetFilters();
                }}
              >
                Reset Filter
              </button>
            ) : null}
          </div>
        </Col>
      </Row>
    );
  };

  const getData = () => {
    if (selectedZip)
      return seasonalZips.map(row => ({
        ...row,
        CalendarText: row.CalendarText || '',
        CharacteristicName: row.CharacteristicName || '',
      }));
    return seasonalCities.map(row => ({
      ...row,
      CalendarText: row.CalendarText || '',
      CharacteristicName: row.CharacteristicName || '',
    }));
  };

  const getStructrualList = () => {
    let struct = [
      { optionValue: '', optionName: 'Structural Characteristics' },
    ];
    if (selectedZip) {
      if (seasonalCharList?.structural?.length > 0) {
        const structData = seasonalCharList?.structural?.map(d => ({
          optionValue: d?.CharacteristicName,
          optionName: d?.CharacteristicName,
        }));
        struct = [...struct, ...structData];
      }
    } else {
      if (seasonalCharList?.structural?.length > 0) {
        const structData = seasonalCharList?.structural?.map(d => ({
          optionValue: d?.CharacteristicName,
          optionName: d?.CharacteristicName,
        }));
        struct = [...struct, ...structData];
      }
    }

    return struct;
  };

  const getAmenitiesList = () => {
    let amenit = [{ optionValue: '', optionName: 'Amenities ' }];
    if (selectedZip) {
      if (seasonalCharList?.amenities?.length > 0) {
        const amenitData = seasonalCharList?.amenities?.map(d => ({
          optionValue: d?.CharacteristicName,
          optionName: d?.CharacteristicName,
        }));
        amenit = [...amenit, ...amenitData];
      }
    } else {
      if (seasonalCharList?.amenities?.length > 0) {
        const amenitData = seasonalCharList?.amenities?.map(d => ({
          optionValue: d?.CharacteristicName,
          optionName: d?.CharacteristicName,
        }));
        amenit = [...amenit, ...amenitData];
      }
    }

    return amenit;
  };

  const debounceFunc = debounce(async inputValue => {
    let options = [];
    if (inputValue.length <= 2) {
      return [];
    }

    const isNumber = !Number.isNaN(Number(inputValue));
    if (isNumber) {
      const zipsList = await getSeasonalSearchZipsList({
        searchQuery: inputValue,
      });

      if (zipsList?.length)
        options = [
          ...options,
          { value: '', label: '----Zips----', isDisabled: true },
          ...zipsList,
        ];
      else
        options = [
          ...options,
          { value: '', label: '----Zips----', isDisabled: true },
          { value: '', label: 'No zips found', isDisabled: true },
        ];

      return options;
    }
    const citiesList = await getSeasonalSearchCitiesList({
      searchQuery: inputValue,
    });

    if (citiesList?.length)
      options = [
        ...options,
        { value: '', label: '----Cities----', isDisabled: true },
        ...citiesList,
      ];
    else
      options = [
        ...options,
        { value: '', label: '----Cities----', isDisabled: true },
        { value: '', label: 'No cities found', isDisabled: true },
      ];

    const charList = await getSeasonalSearchCitiesCharList({
      Char: inputValue,
    });

    if (charList?.length)
      options = [
        ...options,
        { value: '', label: '----Characteristics----', isDisabled: true },
        ...charList.map(d => ({
          value: d.CharacteristicName,
          label: d.CharacteristicName,
          DataFrom: d.DataFrom,
        })),
      ];
    else {
      options = [
        ...options,
        { value: '', label: '----Characteristics----', isDisabled: true },
        { value: '', label: 'No characteristics found', isDisabled: true },
      ];
    }

    return options;
  }, 1000);

  return (
    <div>
      <Row className="mb-2">
        <Col xs={6}>
          <h6>Quick Search</h6>
          <AsyncSelect
            isMulti
            isClearable
            loadOptions={debounceFunc}
            onChange={setSelectedOption}
            getOptionValue={option => option.label}
            placeholder={
              <div style={{ width: '100vh' }}>
                Enter City or ZIP Code(s) or charecteristic name separated by
                commas
              </div>
            }
            value={quickSearchValue}
          />
        </Col>
        <Col xs={2}>
          <Filter
            setOption={setSelectFilterOptionFunction}
            options={filterByData}
            title="Filter By"
            value={selectedFilter}
          />
        </Col>
        <Col xs={2}>
          <Filter
            setOption={setSortingOption}
            options={data?.characteristicsSorting}
            title="Sort By"
          />
        </Col>
      </Row>
      <Row>{renderFilterOptions()}</Row>
      {!selectedCity && !selectedZip && !selectedCharacteristic ? (
        <Row className="mt-3">
          <Col>
            <b>Enter City or ZIP in the search bar.</b>
          </Col>
        </Row>
      ) : (
        <>
          <Row className="mb-3">
            <div>
              <b> Current Geography:</b> {selectedCity || selectedZip}
            </div>
          </Row>
          <Row>
            <Col xs={8}>
              <div className="flex-style">
                <div>
                  <b>View:</b>
                </div>
                <div>
                  <Form.Check
                    inline
                    label="Top Characteristics by Season"
                    name="group22season"
                    type="radio"
                    defaultChecked
                    id="cityseason"
                    onClick={() => {
                      setViewBy('byChar');
                    }}
                  />
                </div>
                <div>
                  <Form.Check
                    inline
                    label="View Seasonal Values by Characteristic"
                    name="group22season"
                    type="radio"
                    id="zipseason"
                    onClick={() => {
                      setViewBy('bySeason');
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {viewBy === 'byChar' ? (
              <Col xs={6}>
                <div
                  className="mb-4 d-flex align-items-center justify-content-between"
                  style={{ width: '60%' }}
                >
                  <b>Season:</b>
                  <div
                    className="d-flex align-items-center justify-content-between w-100"
                    style={{ marginLeft: 10 }}
                  >
                    <button
                      type="button"
                      className="nextButton"
                      onClick={() => handleSortNumberChange('decrement')}
                      disabled={seasonNumber < 2}
                    >
                      &#8592; Prev
                    </button>
                    <b>{SEASONS[seasonNumber - 1]}</b>
                    <button
                      type="button"
                      className="nextButton"
                      onClick={() => handleSortNumberChange('increment')}
                      disabled={seasonNumber > 3}
                    >
                      Next &#8594;
                    </button>
                  </div>
                </div>
              </Col>
            ) : (
              <>
                <Col xs={3}>
                  <Filter
                    setOption={handleSelectedCharacteristic}
                    options={getStructrualList()}
                    value={selectedCharacteristic}
                  />
                </Col>
                <Col xs={3}>
                  <Filter
                    setOption={handleSelectedCharacteristic}
                    options={getAmenitiesList()}
                    value={selectedCharacteristic}
                  />
                </Col>
              </>
            )}
            <Col xs={2}>
              <button
                type="button"
                className="allbutton"
                onClick={handleGetMoreData}
                style={{ marginLeft: 'auto' }}
              >
                Get More Data
              </button>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={8}>
              {viewBy === 'bySeason' && !selectedCharacteristic ? (
                <div className="mt-3">
                  <b>Select Structural Characteristic or Amenity First</b>
                </div>
              ) : (
                <NextGenBsTable
                  columns={
                    viewBy === 'byChar'
                      ? seasonalByCharcterstics
                      : seasonalByTopCharSeason
                  }
                  data={getData()}
                  keyField="id"
                  loading={
                    selectedCity ? seasonalCitiesLoader : seasonalZipsLoader
                  }
                  showPagination={viewBy === 'byChar'}
                  hideSizePerPage
                  enableSearching={false}
                  striped
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

// TabSeasonal.propTypes = {
//   search: propTypes.object.isRequired,
//   // setActiveKey: propTypes.func.isRequired,
//   selectedFilterOption: propTypes.string.isRequired,
//   selectedSortingOption: propTypes.string.isRequired,
//   characteristicsList: propTypes.array.isRequired,
// };

TabSeasonal.propTypes = {
  selectedTab: propTypes.string.isRequired,
};

export default TabSeasonal;
