import * as Yup from 'yup';

const city = require('./city.json');
// const data = require('./data.json');
const color = [
  '#e8f6fd',
  '#e8f6fd',
  '#8bd1f3',
  '#5dbeee',
  '#2facea',
  '#1592d0',
  '#1172a2',
  '#0c5174',
];

const info = () => {
  const defaultOptions = {
    strokeOpacity: 0.5,
    strokeWeight: 1,
    visible: true,
  };
  const closeOptions = {
    ...defaultOptions,
    zIndex: Math.floor(Math.random() * 10),
    fillOpacity: Math.floor(Math.random() * 10) * 0.1,
    strokeColor: '#8BC34A',
    fillColor: color[Math.floor(Math.random() * 9)],
  };
  return closeOptions;
};

const circleData = () => {
  let data = [];
  data = city?.map(dt => {
    const retData = { ...dt };
    retData.closeOptions = info();
    retData.center = {
      lat: parseFloat(dt.latitude),
      lng: parseFloat(dt.longitude),
    };
    retData.radius = Math.floor(Math.random() * (50000 - 20000) + 20000);
    return retData;
  });
  return data;
};

const payloadObj = values => {
  const dta = {
    ...values,
    role: values.role === 'user' ? 1 : 0,
    status: values.status === 'active' ? 1 : 0,
    userRoles: {
      name: values.name,
      description: values.description,
      data_ingest: values.userRoles.data_ingest ? 1 : 0,
      surveys_full: values.survey === 'full' ? 1 : 0,
      surveys_readonly: values.survey === 'readonly' ? 1 : 0,
      drilldown: values.userRoles.drilldown ? 1 : 0,
      data_update: values.userRoles.data_update ? 1 : 0,
      homes_review: values.userRoles.homes_review ? 1 : 0,
      characteristic: values.userRoles.characteristic ? 1 : 0,
    },
  };
  return dta;
};

const responseObj = row => {
  const dta = {
    ...row,
    status: row?.status ? 'active' : 'inactive',
    role: row?.role ? 'user' : 'admin',
    name: row?.userRoles?.name,
    description: row?.userRoles?.description,
    survey: row?.userRoles?.surveys_full === 1 ? 'full' : 'readonly',

    userRoles: {
      data_ingest: row?.userRoles?.data_ingest === 1,
      surveys_full: row?.userRoles?.surveys_full === 1,
      surveys_readonly: row?.userRoles?.surveys_readonly === 1,
      drilldown: row?.userRoles?.drilldown === 1,
      data_update: row?.userRoles?.data_update === 1,
      homes_review: row?.userRoles?.homes_review === 1,
      characteristic: row?.userRoles?.characteristic === 1,
    },
  };
  return dta;
};

const defaultInitialValues = {
  email: '',
  password: '',
  role: 'user',
  status: 'active',
  name: '',
  description: '',
  survey: 'full',

  userRoles: {
    data_ingest: true,
    surveys_full: true,
    surveys_readonly: false,
    drilldown: true,
    data_update: true,
    homes_review: true,
    characteristic: true,
  },
};
const addUserSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email Required'),
  password: Yup.string()
    .min(8, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Password Required'),
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(200, 'Too Long!')
    .required('name Required'),
});

const updateUserSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email Required'),
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(200, 'Too Long!')
    .required('name Required'),
});

const groupBy = array => {
  return array.reduce((result, currentValue) => {
    if (
      result[currentValue.Sleeps] === undefined &&
      result[currentValue.Beds] === undefined &&
      result[currentValue.Baths] === undefined
    ) {
      // TODO: invetigate this reduce function
      // eslint-disable-next-line no-param-reassign
      (result[
        `${currentValue.Sleeps}/${currentValue.Beds}/${currentValue.Baths}`
      ] =
        result[
          `${currentValue.Sleeps}/${currentValue.Beds}/${currentValue.Baths}`
        ] || []).push(currentValue);
    }
    return result;
  }, {});
};

const mergedGroupedObject = personGroupedByHomeSize => {
  const newArray = Object.values(personGroupedByHomeSize).flatMap(
    (value, idx) =>
      idx !== 0
        ? {
            ...value,
            Sleeps: null,
            Beds: null,
            Baths: null,
            OptimizedROI: null,
            BaselineROI: null,
          }
        : value
  );

  return newArray;
};

const convertInputToZipArray = inputString => {
  const zipArray = inputString
    .split(',')
    .map(zip => zip.trim())
    .filter(zip => {
      if (!/^\d{5}$/.test(zip)) return false;
      return true;
    });
  return zipArray;
};

export {
  circleData,
  payloadObj,
  responseObj,
  defaultInitialValues,
  addUserSchema,
  updateUserSchema,
  groupBy,
  mergedGroupedObject,
  convertInputToZipArray,
};
