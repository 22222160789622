/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { login, userInfo, updateProfile, usersList } from './api';

const initialState = {
  isUserLoggedIn: false,
  userInfo: {},
  usersList: [],
  userListLoader: false,
  userCount: 0,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsUserLoggedIn: (state, action) => {
      state.isUserLoggedIn = action.payload;
    },
  },
  extraReducers(builders) {
    builders.addCase(login.fulfilled, (state, action) => {
      state.userInfo = action?.payload?.userInfo;
    });
    builders.addCase(userInfo.fulfilled, (state, action) => {
      state.userInfo = action?.payload?.userInfo;
    });
    builders.addCase(userInfo.rejected, (state, action) => {
      throw action.error;
    });
    builders.addCase(updateProfile.fulfilled, (state, action) => {
      state.userInfo = action?.payload?.data?.userInfo;
    });
    builders.addCase(usersList.fulfilled, (state, action) => {
      state.usersList = action?.payload?.users;
      state.userCount = action?.payload?.count;
      state.userListLoader = false;
    });
    builders.addCase(usersList.pending, state => {
      state.usersList = [];
      state.userListLoader = true;
      state.userCount = 0;
    });
    builders.addCase(usersList.rejected, state => {
      state.usersList = [];
      state.userListLoader = true;
      state.userCount = 0;
    });
  },
});

export const { addTodo, setIsUserLoggedIn } = userSlice.actions;

export default userSlice.reducer;
