/* eslint-disable jsx-a11y/label-has-associated-control */
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import propTypes from 'prop-types';
import { updatePassword, updateProfile } from '../../redux/user/api';
import { notify, notifyError } from '../Toaster';

const ModelPop = ({
  show,
  setShow,
  type,
  modelHeading,
  profileInitialValues,
}) => {
  const [profileInit, setProfileInit] = useState({
    name: '',
    description: '',
  });

  useEffect(() => {
    setProfileInit(profileInitialValues);
  }, [profileInitialValues]);

  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const updatePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old Password Required'),
    newPassword: Yup.string()
      .min(8, 'Too Short!')
      .max(20, 'Too Long!')
      .required('Password Required'),
    confirmPassword: Yup.string()
      .min(8, 'Too Short!')
      .max(20, 'Too Long!')
      .required('Password Required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const updateProfileSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(255, 'Too Long!')
      .required('Name Required'),
  });

  const errorMessageStyle = {
    fontSize: '12px',
    color: 'red',
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{modelHeading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {type === 'updatePassword' ? (
          <Formik
            initialValues={{
              oldPassword: '',
              newPassword: '',
              confirmPassword: '',
            }}
            validationSchema={updatePasswordSchema}
            onSubmit={async (values, { setSubmitting }) => {
              const data = await updatePassword(values);
              if (data?.status === 200) {
                notify('Password updated successfully');
              } else {
                notifyError('Something went wrong');
              }
              handleClose();
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-group-login">
                  <label className="label-class">Old Password</label>
                  <Field
                    name="oldPassword"
                    className="form-control form-field"
                    type="text"
                  />
                  <ErrorMessage
                    name="oldPassword"
                    component="div"
                    className="errorClass"
                    style={errorMessageStyle}
                  />
                </div>
                <div className="form-group-login panel__forms ">
                  <label className="label-class">New Password</label>
                  <Field
                    name="newPassword"
                    className="form-control form-field"
                    type="password"
                  />
                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="errorClass"
                    style={errorMessageStyle}
                  />
                </div>
                <div className="form-group-login panel__forms ">
                  <label className="label-class">Confirm Password</label>
                  <Field
                    name="confirmPassword"
                    className="form-control form-field"
                    type="password"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="errorClass"
                    style={errorMessageStyle}
                  />
                </div>
                <div className="form-group-login panel__forms ">
                  <button
                    type="submit"
                    className="allbutton update-btn"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Please wait...' : 'Update'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <Formik
            initialValues={profileInit}
            validationSchema={updateProfileSchema}
            enableReinitialize
            onSubmit={async (values, { setSubmitting }) => {
              const data = await dispatch(updateProfile(values));
              if (data?.payload?.status === 200) {
                notify('Profile updated successfully');
              } else {
                notifyError('Something went wrong');
              }
              handleClose();
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-group-login">
                  <label className="label-class">Name</label>
                  <Field
                    name="name"
                    className="form-control form-field"
                    type="text"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="errorClass"
                    style={errorMessageStyle}
                  />
                </div>
                <div className="form-group-login panel__forms ">
                  <label className="label-class">Description</label>
                  <Field
                    name="description"
                    className="form-control form-field"
                    type="text"
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="errorClass"
                    style={errorMessageStyle}
                  />
                </div>
                <div className="form-group-login panel__forms ">
                  <button
                    type="submit"
                    className="allbutton update-btn"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Please wait...' : 'Update'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
};

ModelPop.propTypes = {
  show: propTypes.bool.isRequired,
  setShow: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  modelHeading: propTypes.string.isRequired,
  profileInitialValues: propTypes.shape({
    name: propTypes.string.isRequired,
    description: propTypes.string.isRequired,
  }).isRequired,
};

export default ModelPop;
