/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../component/ModelPop/ConfirmModal';
import UserModal from '../../component/ModelPop/UserModal';
import NextGenDataTable from '../../component/NextGenBsTable';
import { notify, notifyError } from '../../component/Toaster';
import { deleteUser, usersList } from '../../redux/user/api';
import deleteIcon from '../../assets/images/delete.svg';
import editIcon from '../../assets/images/edit.svg';
import { responseObj, defaultInitialValues } from '../../utils/helper';

const headerStyle = {
  textAlign: 'center',
};

const Users = () => {
  const [pageStart, setPageStart] = useState(0);
  const user = useSelector(state => state.user);
  const [show, setShow] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [actionType, setActionType] = useState('addUser');
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [resetPage, setResetPage] = useState(1);
  const dispatch = useDispatch();

  const getUsers = (start = 0, limit = 10, columnSort = {}) => {
    setPageStart(start);
    // let sorts ={...selectedSortingOption,...columnSort}
    dispatch(
      usersList({
        start,
        limit,
        columnSort,
      })
    );
  };

  useEffect(() => {
    getUsers();
  }, []);

  const onDelete = async () => {
    if (user?.userInfo?.id === selectedRow.id) {
      // TODO: investigate this alert
      // eslint-disable-next-line no-alert
      alert("You can't delete yourself");
    } else {
      const res = await deleteUser(selectedRow.id);
      if (res.status === 200) {
        notify('User deleted successfully');
        dispatch(usersList());
      } else {
        notifyError('Something went wrong');
      }
    }
  };

  const linkFollow = (cell, row) => {
    return (
      <div className="user-button-div">
        <div
          className="action-icon "
          onClick={() => {
            const dta = responseObj(row);
            setSelectedRow(dta);
            setInitialValues(dta);
            setActionType('editUser');
            setShowUserModal(true);
          }}
        >
          {' '}
          <img src={editIcon} alt="" />{' '}
        </div>
        <div
          className=" action-icon delete-button"
          onClick={() => {
            setShow(true);
            setSelectedRow(row);
          }}
        >
          <img src={deleteIcon} alt="" />
        </div>
      </div>
    );
  };

  const singleCellStatusFormater = cell => {
    return <div className="user-cell-div">{cell ? 'active' : 'inactive'}</div>;
  };
  const singleCellRoleFormater = cell => {
    return <div className="user-cell-div">{cell ? 'user' : 'admin'}</div>;
  };
  const rowFormatter = (cell, row, rowIndex) => {
    return <div className="user-cell-div">{pageStart + rowIndex + 1}</div>;
  };
  const singleTextFormater = column => {
    return <div className="singleTextButtonDiv">{column.text}</div>;
  };

  const columns = [
    {
      dataField: 'id',
      text: 'S.No.',
      formatter: rowFormatter,
      sort: false,
      headerStyle: {
        fontSize: '13px',
        fontWeight: 'normal',
        ...headerStyle,
      },
    },
    {
      dataField: 'userRoles.name',
      text: 'Name',
      sort: true,
      headerStyle,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: singleCellStatusFormater,
      headerStyle,
    },
    {
      dataField: 'role',
      text: 'Role',
      sort: true,
      formatter: singleCellRoleFormater,
      headerStyle,
    },
    {
      dataField: 'Action',
      text: 'Action',
      formatter: linkFollow,
      headerFormatter: singleTextFormater,
      sort: false,
      headerStyle: {
        fontSize: '13px',
        fontWeight: 'normal',
        ...headerStyle,
      },
    },
  ];
  return (
    <>
      <Container className="p-3">
        <Row>
          <Col xs={12}>
            <Row className="pe-0">
              <Col xs={8}>
                <div className="d-flex justify-content-between mb-4">
                  <h3>Users</h3>
                  <div>
                    <Button
                      className="allbutton"
                      style={{ fontSize: 16 }}
                      onClick={() => {
                        setInitialValues(defaultInitialValues);
                        setActionType('addUser');
                        setShowUserModal(true);
                      }}
                    >
                      Add User
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={8}>
            <div>
              <NextGenDataTable
                columns={columns}
                data={user.usersList}
                keyField="id"
                loading={user.userListLoader}
                hideSizePerPage
                enableSearching={false}
                striped
                remote
                resetPage={resetPage}
                fetchData={getUsers}
                setResetPage={setResetPage}
                count={user?.userCount}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <UserModal
        show={showUserModal}
        setShow={setShowUserModal}
        initVals={initialValues}
        actionType={actionType}
      />
      <ConfirmModal
        heading="Delete User"
        message="Are you sure delete this user?"
        show={show}
        setShow={setShow}
        onConfirm={onDelete}
      />
    </>
  );
};

export default Users;
