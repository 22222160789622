// TODO: investigate this
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  getMoreDataSurvey_To_Drilldown: false,
  getMoreDataDrillDown_To_Survey: false,
  currentSurveyTab: 'Ranking',
  currentDrilldownTab: 'Baseline',
  currentCharacteristicsDrilldownTab: 'Prevalence',
  surveyTab_NavigationData: {},
  drilldown_To_SurveyUpdate_Data: {},
  survey_To_DrillDown_Data: {},
  updateTab_NavigationData: {},
  navigation_from_drilldown_to_servey: 'survey',
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    getMoreDataSurveyToDrilldownUpdate: (state, action) => {
      state.getMoreDataSurvey_To_Drilldown = action.payload;
    },
    getMoreDataDrillDownToSurveyUpdate: (state, action) => {
      state.getMoreDataDrillDown_To_Survey = action.payload;
    },

    updateCurrentSurveyTab: (state, action) => {
      if (action.payload !== 'Survey') {
        state.surveyTab_NavigationData = {};
      }
      if (action.payload !== 'Dataupdate') {
        state.updateTab_NavigationData = {};
      }
      state.currentSurveyTab = action.payload;
    },
    updateCurrentDrilldownTab: (state, action) => {
      state.currentDrilldownTab = action.payload;
    },
    updateCurrentCharacteristicsDrilldownTab: (state, action) => {
      state.currentCharacteristicsDrilldownTab = action.payload;
    },
    updateSurveyTabNavigationData: (state, action) => {
      state.surveyTab_NavigationData = action.payload;
    },

    updateDrilldownToSurveyUpdateData: (state, action) => {
      state.drilldown_To_SurveyUpdate_Data = action.payload;
    },

    updateSurveyToDrillDownData: (state, action) => {
      state.survey_To_DrillDown_Data = action.payload;
    },
    updateUpdateTabNavigationData: (state, action) => {
      state.updateTab_NavigationData = action.payload;
    },

    updateNavigationFromDrilldownToSurvey: (state, action) => {
      state.navigation_from_drilldown_to_servey = action.payload;
    },
    updateGeographyToUpdateData: (state, action) => {
      state.geography_To_Update_Data = action.payload;
    },
  },
});

export const {
  getMoreDataDrillDownToSurveyUpdate,
  getMoreDataSurveyToDrilldownUpdate,
  updateCurrentSurveyTab,
  updateCurrentDrilldownTab,
  updateCurrentCharacteristicsDrilldownTab,
  updateSurveyTabNavigationData,
  updateDrilldownToSurveyUpdateData,
  updateSurveyToDrillDownData,
  updateUpdateTabNavigationData,
  updateNavigationFromDrilldownToSurvey,
  updateGeographyToUpdateData,
} = navigationSlice.actions;

export default navigationSlice.reducer;
