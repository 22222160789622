import React from 'react';
import { PropTypes } from 'prop-types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

const renderInput = (type, value, options, placeholder, onChange) => {
  const [touched, setTouched] = React.useState(false);
  switch (type) {
    case 'text':
      return (
        <TextField
          variant="filled"
          label={placeholder}
          className="form-control pb-3"
          defaultValue={value}
          size="small"
          onFocus={() => setTouched(true)}
          onBlur={e =>
            touched &&
            e.target.value !== value &&
            typeof onChange === 'function' &&
            onChange(e.target.value)
          }
        />
      );
    case 'multiline':
      return (
        <TextField
          variant="filled"
          label={placeholder}
          className="form-control pb-3"
          defaultValue={value}
          size="small"
          multiline
          rows={4}
          onFocus={() => setTouched(true)}
          onBlur={e =>
            touched &&
            e.target.value !== value &&
            typeof onChange === 'function' &&
            onChange(e.target.value)
          }
        />
      );
    case 'select':
      return (
        <FormControl sx={{ minWidth: '50%', mb: 1, pb: 3 }}>
          <InputLabel id="select-filled-label">{placeholder}</InputLabel>
          <Select
            labelId="select-filled-label"
            variant="filled"
            label={placeholder}
            defaultValue={value}
            size="small"
            multiline
            rows={4}
            onChange={e =>
              e.target.value !== value &&
              typeof onChange === 'function' &&
              onChange(e.target.value)
            }
          >
            {options &&
              options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      );
    default:
      return null;
  }
};

const InfoComponent = ({
  type,
  value = '',
  options,
  fieldName,
  placeholder,
  readOnly = false,
  onChange: valueChanged,
}) => {
  const onChange = newValue => {
    valueChanged({ fieldName, value, newValue });
  };
  return (
    <div>
      {readOnly ? (
        <Typography variant="body1" fontWeight="400">
          {value}
        </Typography>
      ) : (
        renderInput(type, value, options, placeholder, onChange)
      )}
    </div>
  );
};

InfoComponent.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.string),
  fieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

InfoComponent.defaultProps = {
  value: '',
  readOnly: false,
  options: [],
  placeholder: '',
  onChange: () => {},
};

export default InfoComponent;
