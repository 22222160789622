import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import InfoComponent from './InfoComponent';
import {
  getNotesAndRatingsAsync,
  getPropertyByIdAsync,
  updatePropertyInfoAsync,
} from '../../redux/fieldAnalysis/fieldAnalysisApi';
import {
  calculateScore,
  updateNotesAndRatings,
  getMedia,
  saveUserMedia,
  getSurveyCharacteristics,
  parseCharacteristics,
} from '../../redux/fieldAnalysis/fieldAnalysisSlice';
import { notifyError } from '../Toaster';
import upload from '../../assets/images/upload.svg';
import flower from '../../assets/images/flower.svg';
import axiosConfig from '../../redux/axiosConfig';
import CharacteristicComponent from './CharacteristicComponent';

const PropertyInfo = ({ listedPropertyId, readOnly = false }) => {
  const [activeTab, setActiveTab] = React.useState('home-info');
  const [property, setProperty] = React.useState(null);
  const [notesAndRatings, setNotesAndRatings] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [mapURI, setMapURI] = React.useState('');
  const [openMapModal, setOpenMapModal] = React.useState(false);
  const [userMedia, setUserMedia] = React.useState(null);
  const [mlsMedia, setMlsMedia] = React.useState(null);
  const [showUpload, setShowUpload] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [uploading, setUploading] = React.useState(false);
  const [characteristics, setCharacteristics] = React.useState(null);
  const [hideImport, setHideImport] = React.useState(false);
  const [notes, setNotes] = React.useState('');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    masterData: { surveyStatuses },
  } = useSelector(state => state.fieldAnalysis);

  useEffect(() => {
    if (listedPropertyId) {
      try {
        const fetch = async () => {
          const prop = await getPropertyByIdAsync(listedPropertyId);
          setProperty(prop);
        };
        fetch();
      } catch (error) {
        notifyError(error.message);
      }
    }
  }, [listedPropertyId]);

  const fetchMediaDetails = async () => {
    if (property) {
      const { ID: propertyId, DatafinitiID } = property;
      dispatch(getMedia({ DatafinitiID, propertyId })).then(action => {
        if (action.error) {
          notifyError(action.error.message);
        }
        if (action.payload) {
          setUserMedia(action.payload[0]);
          setMlsMedia(action.payload[1]);
          if (action.payload[0].length === 0) {
            setShowUpload(true);
          }
        }
      });
    }
  };

  useEffect(() => {
    if (['field-survey', 'notes'].includes(activeTab) && property) {
      const fetch = async () => {
        const response = (await getNotesAndRatingsAsync(property.ID)) || {};
        setNotesAndRatings(response);
        setNotes(response.Notes);
      };
      fetch();
      dispatch(getSurveyCharacteristics(property.ID)).then(action => {
        if (action.error) {
          notifyError(action.error.message);
        }
        if (action.payload) {
          setCharacteristics(action.payload);
        }
      });
    }
    if (activeTab === 'photo' && property && !userMedia && !mlsMedia) {
      fetchMediaDetails();
    }
  }, [activeTab]);

  const importCharacteristics = async () => {
    try {
      dispatch(parseCharacteristics(property.ID)).then(parseAction => {
        if (parseAction.error) {
          notifyError(parseAction.error.message);
        }
        if (parseAction.payload) {
          const { resultCode } = parseAction.payload;
          if (resultCode === 0) {
            dispatch(getSurveyCharacteristics(property.ID)).then(action => {
              if (action.error) {
                notifyError(action.error.message);
              }
              if (action.payload) {
                setCharacteristics(action.payload);
              }
            });
          }
        }
      });
    } catch (error) {
      notifyError(error.message);
    }
  };

  useEffect(() => {
    setHideImport(characteristics?.some(char => char.isChecked === 1));
  }, [characteristics]);

  useEffect(() => {
    if (notesAndRatings) {
      dispatch(
        updateNotesAndRatings({
          ...notesAndRatings,
          ListedPropertyId: listedPropertyId,
        })
      ).then(action => {
        if (action.error) {
          notifyError(action.error.message);
        }
      });
    }
  }, [notesAndRatings]);

  const openMap = () => {
    const address = encodeURI(
      `${property.Address}, ${property.City}, ${property.Province} ${property.ZIPCode}`
    );
    setMapURI(
      `https://maps.google.com/maps?q=${address}&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=`
    );
    setOpenMapModal(true);
  };
  const handleClose = () => {
    setOpenMapModal(false);
    setOpen(false);
  };

  const updatePropertyInfo = async data => {
    if (data.value !== data.newValue) {
      const values = {};
      values[data.fieldName] = data.newValue;
      try {
        const prop = await updatePropertyInfoAsync({ ID: property.ID, values });
        setProperty(prop);
      } catch (error) {
        notifyError(error.message);
      }
    }
  };

  const calculateAlgoScore = () => {
    try {
      dispatch(calculateScore(property.ID))
        .then(action => {
          if (action.error) {
            notifyError(action.error.message);
          }
          if (action.payload) {
            const { overallScore } = action.payload;
            if (overallScore === 0) {
              setOpen(true);
            } else {
              setNotesAndRatings({
                ...notesAndRatings,
                AlgorithmicRating: overallScore,
              });
            }
          }
        })
        .catch(error => notifyError(error.message));
    } catch (error) {
      notifyError(error.message);
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    border: '0 solid #000',
    borderRadius: '8px',
    boxShadow: 4,
    p: 2,
  };

  const dropzoneStyles = {
    border: '2px dashed #d9d9d9',
    borderRadius: '20px',
    textAlign: 'center',
    padding: '40px 20px',
    cursor: 'pointer',
    backgroundColor: 'rgba(205, 231, 255, 0.65)',
    marginTop: '20px',
  };

  const onDrop = async acceptedFiles => {
    setUploading(true);
    const formData = new FormData();
    acceptedFiles.forEach(file => {
      formData.append('file', file);
    });

    axiosConfig.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;

    try {
      const response = await axiosConfig.post('/fat/upload', formData, {
        onUploadProgress: progressEvent => {
          setProgress(
            Math.round((100 * progressEvent.loaded) / progressEvent.total)
          );
        },
      });
      if (response) {
        dispatch(
          saveUserMedia({
            ListedPropertiesID: property.ID,
            DatafinitiID: property.DatafinitiID,
            MediaFiles: response.data,
          })
        ).then(action => {
          if (action.error) {
            notifyError(action.error.message);
          }
          if (action.payload) {
            fetchMediaDetails();
          }
          setUploading(false);
          setShowUpload(false);
        });
      }
    } catch (error) {
      notifyError(error.message);
    } finally {
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
      'video/mp4': ['.mp4'],
    },
    multiple: true,
  });

  return (
    property && (
      <Box>
        <Box>
          <div>
            <button type="button" onClick={() => navigate(-1)}>
              Back
            </button>
          </div>
          <Typography
            variant="body1"
            sx={{ paddingTop: '1rem' }}
            fontSize="18px"
          >
            <strong>Address: </strong>
            {property.Address}, {property.City}, {property.Province}{' '}
            {property.ZIPCode}
          </Typography>
          <div>
            <div id="property-details" className="container-flex">
              <ul id="property-tabs">
                <li className={activeTab === 'home-info' ? 'active' : ''}>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => {
                      setActiveTab('home-info');
                    }}
                  >
                    Home Info
                  </button>
                </li>
                <li className={activeTab === 'field-survey' ? 'active' : ''}>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => {
                      setActiveTab('field-survey');
                    }}
                  >
                    Field Survey
                  </button>
                </li>
                <li className={activeTab === 'notes' ? 'active' : ''}>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => {
                      setActiveTab('notes');
                    }}
                  >
                    Notes
                  </button>
                </li>
                <li className={activeTab === 'photo' ? 'active' : ''}>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => {
                      setActiveTab('photo');
                    }}
                  >
                    Photos &amp; Videos
                  </button>
                </li>
              </ul>
              {activeTab === 'home-info' && (
                <div id="home-info" className="container-flex">
                  <div className="row">
                    <div id="left-column" className="col-md-8 col-sm-12">
                      <div className="row">
                        <div className="col-3">
                          <strong>Property Address:</strong>
                        </div>
                        <div className="col-9">
                          <Typography fontWeight={400} fontSize="18px">
                            {property.Address}, {property.City},{' '}
                            {property.Province} {property.ZIPCode}
                          </Typography>
                        </div>
                      </div>
                      <div className="row">&nbsp;</div>
                      <div className="row">
                        <div className="col-3">
                          <strong>Property Description:</strong>
                        </div>
                        <div className="col-9">
                          <InfoComponent
                            type="multiline"
                            value={property.PropertyDescription}
                            readOnly={readOnly}
                            fieldName="PropertyDescription"
                            placeholder="Enter property description"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <strong>MLS Number</strong>
                        </div>
                        <div className="col-9">
                          <InfoComponent
                            type="text"
                            value={property.mlsNumber}
                            readOnly={readOnly}
                            fieldName="mlsNumber"
                            placeholder="Enter MLS number"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">&nbsp;</div>
                      <div className="row">
                        <div className="col-3">
                          <strong>Bedrooms:</strong>
                        </div>
                        <div className="col-9">
                          <InfoComponent
                            type="select"
                            value={property.NumBedrooms}
                            options={[
                              { value: '1', label: '1' },
                              { value: '2', label: '2' },
                              { value: '3', label: '3' },
                              { value: '4', label: '4' },
                              { value: '5', label: '5' },
                              { value: '6', label: '6' },
                              { value: '7', label: '7' },
                              { value: '8', label: '8' },
                              { value: '9', label: '9' },
                              { value: '10', label: '10' },
                              { value: '11', label: '11' },
                              { value: '12', label: '12' },
                              { value: '13', label: '13' },
                              { value: '14', label: '14' },
                              { value: '15', label: '15' },
                              { value: '16', label: '16' },
                              { value: '17', label: '17' },
                              { value: '18', label: '18' },
                              { value: '19', label: '19' },
                              { value: '20', label: '20' },
                            ]}
                            readOnly={readOnly}
                            fieldName="NumBedrooms"
                            placeholder="Number of Bedrooms"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <strong>Bathrooms:</strong>
                        </div>
                        <div className="col-9">
                          <InfoComponent
                            type="select"
                            value={property.NumBathrooms}
                            options={[
                              { value: '1', label: '1' },
                              { value: '1.5', label: '1.5' },
                              { value: '2', label: '2' },
                              { value: '2.5', label: '2.5' },
                              { value: '3', label: '3' },
                              { value: '3.5', label: '3.5' },
                              { value: '4', label: '4' },
                              { value: '4.5', label: '4.5' },
                              { value: '5', label: '5' },
                              { value: '5.5', label: '5.5' },
                              { value: '6', label: '6' },
                              { value: '6.5', label: '6.5' },
                              { value: '7', label: '7' },
                              { value: '7.5', label: '7.5' },
                              { value: '8', label: '8' },
                            ]}
                            readOnly={readOnly}
                            fieldName="NumBathrooms"
                            placeholder="Number of Bathrooms"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <strong>Number of Floors:</strong>
                        </div>
                        <div className="col-9">
                          <InfoComponent
                            type="text"
                            value={property.NumFloors}
                            readOnly={readOnly}
                            fieldName="NumFloors"
                            placeholder="Enter number of floors"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <strong>Parking:</strong>
                        </div>
                        <div className="col-9">
                          <InfoComponent
                            type="text"
                            value={property.Parking}
                            readOnly={readOnly}
                            fieldName="Parking"
                            placeholder="Enter number of parking spots"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">&nbsp;</div>
                      <div className="row">
                        <div className="col-3">
                          <strong>Lot Size Value:</strong>
                        </div>
                        <div className="col-9">
                          <InfoComponent
                            type="text"
                            value={property.LotSizeValue}
                            readOnly={readOnly}
                            fieldName="LotSizeValue"
                            placeholder="Enter lot size value"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <strong>Lot Size Units:</strong>
                        </div>
                        <div className="col-9">
                          <InfoComponent
                            type="text"
                            value={property.LotSizeUnits}
                            readOnly={readOnly}
                            fieldName="LotSizeUnits"
                            placeholder="Enter lot size units"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <strong>Neighborhood:</strong>
                        </div>
                        <div className="col-9">
                          <InfoComponent
                            type="text"
                            value={property.Neighborhood}
                            readOnly={readOnly}
                            fieldName="Neighborhood"
                            placeholder="Enter neighborhood"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <strong>Longitude:</strong>
                        </div>
                        <div className="col-9">
                          <InfoComponent
                            type="text"
                            value={property.Longitude}
                            readOnly={readOnly}
                            fieldName="Longitude"
                            placeholder="Enter longitude"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <strong>Latitude:</strong>
                        </div>
                        <div className="col-3">
                          <InfoComponent
                            type="text"
                            value={property.Latitude}
                            readOnly={readOnly}
                            fieldName="Latitude"
                            placeholder="Enter latitude"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                        <div className="col-6">
                          <Button variant="contained" onClick={openMap}>
                            Show on Map
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div id="right-column" className="col-md-4 col-sm-12">
                      <div className="row">
                        <div className="col-6">
                          <strong>Listing Price:</strong>
                        </div>
                        <div className="col-6">
                          <InfoComponent
                            type="text"
                            value={property.ListingPrice}
                            readOnly={readOnly}
                            fieldName="ListingPrice"
                            placeholder="Enter listing price"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">&nbsp;</div>
                      <div className="row">
                        <div className="col-6">
                          <strong>Data Updated Date</strong>
                        </div>
                        <div className="col-6">
                          <Typography variant="body1" fontWeight="400">
                            {property.dateUpdated}
                          </Typography>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <strong>Status:</strong>
                        </div>
                        <div className="col-6">
                          <InfoComponent
                            type="select"
                            value={property.mostRecentStatus}
                            options={[
                              { value: 'Active', label: 'Active' },
                              { value: 'Inactive', label: 'Inactive' },
                              { value: 'Pending', label: 'Pending' },
                              { value: 'Expired', label: 'Expired' },
                              {
                                value: 'Under Contract',
                                label: 'Under Contract',
                              },
                            ]}
                            readOnly={readOnly}
                            fieldName="mostRecentStatus"
                            placeholder="Listing Status"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <strong>Status Updated Date</strong>
                        </div>
                        <div className="col-6">
                          <Typography variant="body1" fontWeight="400">
                            {property.mostRecentStatusDate}
                          </Typography>
                        </div>
                      </div>
                      <div className="row">&nbsp;</div>
                      <div className="row">
                        <div className="col-6">
                          <strong>Agent Company:</strong>
                        </div>
                        <div className="col-6">
                          <InfoComponent
                            type="text"
                            value={property.AgentCompany}
                            readOnly={readOnly}
                            fieldName="AgentCompany"
                            placeholder="Enter agent company"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <strong>Agent Name:</strong>
                        </div>
                        <div className="col-6">
                          <InfoComponent
                            type="text"
                            value={property.AgentName}
                            readOnly={readOnly}
                            fieldName="AgentName"
                            placeholder="Enter agent name"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <strong>Agent Email:</strong>
                        </div>
                        <div className="col-6">
                          <InfoComponent
                            type="text"
                            value={property.AgentEmail}
                            readOnly={readOnly}
                            fieldName="AgentEmail"
                            placeholder="Enter agent email"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <strong>Agent Phone:</strong>
                        </div>
                        <div className="col-6">
                          <InfoComponent
                            type="text"
                            value={property.AgentPhones}
                            readOnly={readOnly}
                            fieldName="AgentPhones"
                            placeholder="Enter agent phone"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <strong>Agent Phone Updated Date:</strong>
                        </div>
                        <div className="col-6">
                          <InfoComponent
                            type="text"
                            value={property.AgentPhoneUpdatedDate}
                            readOnly={readOnly}
                            fieldName="AgentPhoneUpdatedDate"
                            placeholder="Enter agent phone updated date"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <strong>Property Manager URL:</strong>
                        </div>
                        <div className="col-6">
                          <InfoComponent
                            type="text"
                            value={property.PropertyManagerURL}
                            readOnly={readOnly}
                            fieldName="PropertyManagerURL"
                            placeholder="Enter property manager url"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">&nbsp;</div>
                      <div className="row">
                        <div className="col-12">
                          <strong style={{ textDecoration: 'underline' }}>
                            Property Taxes:
                          </strong>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <strong>Amount:</strong>
                        </div>
                        <div className="col-6">
                          <InfoComponent
                            type="text"
                            value={property.PropertyTaxesAmount}
                            readOnly={readOnly}
                            fieldName="PropertyTaxesAmount"
                            placeholder="Enter property tax amount"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <strong>Tax Source URL:</strong>
                        </div>
                        <div className="col-6">
                          <InfoComponent
                            type="text"
                            value={property.PropertyTaxesSourceURLs}
                            readOnly={readOnly}
                            fieldName="PropertyTaxesSourceURLs"
                            placeholder="Enter property tax source url"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <strong>Tax Data Updated Date:</strong>
                        </div>
                        <div className="col-6">
                          <InfoComponent
                            type="text"
                            value={property.PropertyTaxesDateSeen}
                            readOnly={readOnly}
                            fieldName="PropertyTaxesDateSeen"
                            placeholder="Enter tax data updated date"
                            onChange={updatePropertyInfo}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'field-survey' && (
                <div id="field-survey">
                  {!hideImport && (
                    <h4>
                      Automatically import detected characteristics?{' '}
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        onClick={importCharacteristics}
                      >
                        Import
                      </button>
                      <button
                        type="button"
                        className="btn btn-link btn-lg"
                        style={{ textDecoration: 'none', color: '#000' }}
                      >
                        <span style={{ fontSize: '2rem' }}>&times;</span>
                      </button>
                    </h4>
                  )}
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    gap="1cm"
                    alignItems="center"
                  >
                    <div className="">
                      <select
                        className=""
                        value={notesAndRatings?.SurveyStatus}
                        onChange={e =>
                          setNotesAndRatings({
                            ...notesAndRatings,
                            SurveyStatus: e.target.value,
                          })
                        }
                      >
                        {surveyStatuses.map(status => (
                          <option key={status.id} value={status.id}>
                            {status.Status}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="">
                      <select
                        className=""
                        value={notesAndRatings?.UserRating || ''}
                        onChange={e =>
                          setNotesAndRatings({
                            ...notesAndRatings,
                            UserRating: e.target.value,
                          })
                        }
                      >
                        <option value="">Overall Rating</option>
                        <option value="1">1 (worst)</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10 (best)</option>
                      </select>
                    </div>
                    <div className="">
                      <span>
                        Algorithmic Rating:{' '}
                        <strong>
                          {notesAndRatings?.AlgorithmicRating || 'n/a'}
                        </strong>{' '}
                      </span>
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        style={{ marginLeft: '3mm' }}
                        onClick={() => calculateAlgoScore()}
                      >
                        Update
                      </button>
                    </div>
                  </Stack>
                  <div className="text-end">
                    <span className="text-danger font-weight-bold small">
                      Desirable for this geography *
                    </span>
                  </div>
                  <div id="characteristics" className="row">
                    <div id="structural" className="col-6">
                      <div id="structural-basic" className="row">
                        <div className="col-12">
                          <h6>Structural Characteristics</h6>
                          {characteristics
                            ?.filter(
                              char =>
                                char.CharacteristicType === 1 &&
                                char.CharacteristicSection === 0
                            )
                            .map(char => (
                              <CharacteristicComponent
                                key={`${char.CharacteristicType}-${char.CharacteristicID}`}
                                ListedPropertiesID={property.ID}
                                characteristic={char}
                              />
                            ))}
                        </div>
                      </div>
                      <div id="structural-hp" className="row">
                        <div className="col-12">
                          <h6>
                            Structural Characteristics for High Performance
                          </h6>
                          {characteristics
                            ?.filter(
                              char =>
                                char.CharacteristicType === 1 &&
                                char.CharacteristicSection === 1
                            )
                            .map(char => (
                              <CharacteristicComponent
                                key={`${char.CharacteristicType}-${char.CharacteristicID}`}
                                ListedPropertiesID={property.ID}
                                characteristic={char}
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                    <div id="amenities" className="col-6">
                      <div id="amenities-basic" className="row">
                        <div className="col-12">
                          <h6>Potential Amenities</h6>
                          {characteristics
                            ?.filter(
                              char =>
                                char.CharacteristicType === 2 &&
                                char.CharacteristicSection === 0
                            )
                            .map(char => (
                              <CharacteristicComponent
                                key={`${char.CharacteristicType}-${char.CharacteristicID}`}
                                ListedPropertiesID={property.ID}
                                characteristic={char}
                              />
                            ))}
                        </div>
                      </div>
                      <div id="amenities-hp" className="row">
                        <div className="col-12">
                          <h6>Potential Amenities for High Performance</h6>
                          {characteristics
                            ?.filter(
                              char =>
                                char.CharacteristicType === 2 &&
                                char.CharacteristicSection === 1
                            )
                            .map(char => (
                              <CharacteristicComponent
                                key={`${char.CharacteristicType}-${char.CharacteristicID}`}
                                ListedPropertiesID={property.ID}
                                characteristic={char}
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'photo' && (
                <div>
                  {!showUpload ? (
                    <div id="photos">
                      <div className="row">
                        <div className="col-6">
                          <span>
                            <strong>Source:</strong> User Uploads
                          </span>
                        </div>
                        <div className="col-6" style={{ textAlign: 'right' }}>
                          <button
                            type="button"
                            className="btn btn-primary btn-lg px-4"
                            onClick={() => {
                              setShowUpload(true);
                            }}
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                      <div id="user-uploads" className="row mt-3">
                        {userMedia &&
                          userMedia.length > 0 &&
                          userMedia.map(media => (
                            <div key={media.MediaURL} className="col-3">
                              {media.AssetType === 1 ? (
                                <img
                                  src={media.MediaURL}
                                  alt="Property"
                                  style={{
                                    maxWidth: '100%',
                                    maxHeight: '240px',
                                    margin: '0 auto 1rem',
                                    display: 'block',
                                  }}
                                />
                              ) : (
                                <video
                                  src={media.MediaURL}
                                  controls
                                  style={{
                                    maxWidth: '100%',
                                    maxHeight: '240px',
                                    margin: '0 auto 1rem',
                                    display: 'block',
                                  }}
                                >
                                  <track kind="captions" srcLang="en" />
                                </video>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <Box>
                      {!uploading ? (
                        <Box>
                          <div
                            style={{
                              position: 'relative',
                              height: '460px',
                              width: '100%',
                            }}
                          >
                            <div
                              style={{
                                overflow: 'hidden',
                                height: '460px',
                                width: '100%',
                              }}
                            >
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Stack direction="column" spacing={1}>
                                  <Typography variant="h6" fontWeight="600">
                                    Upload Photos or Videos
                                  </Typography>
                                  <Typography variant="body1">
                                    Upload and attach files to this address.
                                  </Typography>
                                </Stack>
                                <Button
                                  variant="text"
                                  className="nav-link"
                                  onClick={() => setShowUpload(false)}
                                >
                                  Cancel
                                </Button>
                              </Stack>
                              <div {...getRootProps()} style={dropzoneStyles}>
                                <input {...getInputProps()} />
                                <img src={upload} alt="upload" />
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  justifyContent="center"
                                  alignItems="center"
                                  backgroundColor="rgba(217, 217, 217, 0.5)"
                                  marginY="2rem"
                                  width="auto"
                                >
                                  <img src={flower} alt="upload" />
                                  <Typography
                                    variant="body1"
                                    fontWeight="500"
                                    color="#006FFF"
                                  >
                                    Upload Photos and Videos from Library
                                  </Typography>
                                </Stack>

                                <span>
                                  Support .jpg, .png, .jpeg, .mp4 files
                                </span>
                                <br />
                                <span>Maximum file size 50 MB.</span>
                              </div>
                            </div>
                          </div>
                          {/* <Box textAlign="center">
                            {filesToUpload.length && (
                              <Button
                                variant="contained"
                                size="large"
                                onClick={handleUpload}
                              >
                                Upload
                              </Button>
                            )}
                          </Box> */}
                        </Box>
                      ) : (
                        <Box>
                          <div
                            style={{
                              position: 'relative',
                              height: '460px',
                              width: '100%',
                            }}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="h6" fontWeight="600">
                                {progress > 0 && <p>Uploading: {progress}%</p>}
                              </Typography>
                            </Stack>
                          </div>
                        </Box>
                      )}
                    </Box>
                  )}
                  <div className="row">
                    <div className="row pt-4 mt-4">
                      <div className="col-6 mb-3">
                        <span>
                          <strong>Source:</strong> MLS
                        </span>
                      </div>
                    </div>
                    <div id="mls" className="row">
                      {mlsMedia && mlsMedia.length > 0 ? (
                        mlsMedia.map(media => (
                          <div key={media.MediaURL} className="col-3">
                            {media.AssetType === 1 ? (
                              <img
                                src={media.MediaURL}
                                alt="Property"
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '240px',
                                  margin: '0 auto 1rem',
                                  display: 'block',
                                }}
                              />
                            ) : (
                              <video
                                src={media.MediaURL}
                                controls
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '240px',
                                  margin: '0 auto 1rem',
                                  display: 'block',
                                }}
                              >
                                <track kind="captions" srcLang="en" />
                              </video>
                            )}
                          </div>
                        ))
                      ) : (
                        <div className="col-12">
                          <span>No photos uploaded</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'notes' && (
                <div className="row">
                  <div className="col-12">
                    <textarea
                      className="form-control"
                      rows="5"
                      value={notes}
                      onChange={e => setNotes(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <button
                      type="button"
                      className="mt-3"
                      onClick={() =>
                        setNotesAndRatings({
                          ...notesAndRatings,
                          Notes: notes,
                        })
                      }
                      disabled={!notes || notesAndRatings?.Notes === notes}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Modal open={openMapModal} onClose={handleClose}>
            <Box sx={style}>
              <div
                style={{
                  position: 'relative',
                  height: '460px',
                  width: '590px',
                }}
              >
                <div
                  style={{
                    overflow: 'hidden',
                    height: '460px',
                    width: '590px',
                  }}
                >
                  <iframe
                    title="map"
                    style={{
                      position: 'relative',
                      zIndex: 2,
                      border: 0,
                      height: '460px',
                      width: '590px',
                    }}
                    // src="https://maps.google.com/maps?q=3333%2BCabrillo%2BAve%2C%2BSanta%2BClara%2C%2BCA%2B95051%2C%2BUSA&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                    src={`${mapURI}&output=embed`}
                  />
                  <style />
                </div>
              </div>
            </Box>
          </Modal>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} textAlign="center">
              <Box paddingX="2rem">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{
                    color: 'rgba(0, 111, 255, 1)',
                    fontSize: '18px',
                    marginTop: '2rem',
                  }}
                >
                  Not enough data to publish algorithmic rating.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
                  Continue to provide characteristics and amenities data then
                  press update.
                </Typography>
                <Stack>
                  <Stack direction="row" spacing={2} justifyContent="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setOpen(false)}
                    >
                      OK
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    )
  );
};

PropertyInfo.propTypes = {
  listedPropertyId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

PropertyInfo.defaultProps = {
  readOnly: false,
};

export default PropertyInfo;
