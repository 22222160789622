import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  addCharacteristicsAsync,
  removeCharacteristicsAsync,
  addCharacteristicNotesAsync,
} from '../../redux/fieldAnalysis/fieldAnalysisApi';
import { notifyError } from '../Toaster';

const CharacteristicComponent = ({ ListedPropertiesID, characteristic }) => {
  const [char, setChar] = useState({});

  useEffect(() => {
    setChar(characteristic);
  }, [characteristic]);

  const handleAdd = async () => {
    try {
      const [id] = await addCharacteristicsAsync({
        CharacteristicID: char.CharacteristicID,
        ListedPropertiesID,
      });
      setChar({ ...char, isChecked: 1, id });
    } catch (error) {
      notifyError(error.message);
    }
  };

  const handleRemove = async () => {
    try {
      await removeCharacteristicsAsync(char.id);
      setChar({ ...char, isChecked: 0, Notes: '' });
    } catch (error) {
      notifyError(error.message);
    }
  };

  const handleChange = () => {
    if (char.isChecked === 1) {
      handleRemove();
    } else {
      handleAdd();
    }
  };

  const updateNotes = e => {
    addCharacteristicNotesAsync({
      CharacteristicID: char.id,
      ListedPropertiesID,
      Notes: e.target.value,
    })
      .then(() => {
        setChar({ ...char, Notes: e.target.value });
      })
      .catch(error => {
        notifyError(error.message);
      });
  };

  return (
    <div className="inplace-editor" style={{ marginBottom: '10px' }}>
      <label htmlFor={`char-${char.CharacteristicID}`}>
        <input
          type="checkbox"
          id={`char-${char.CharacteristicID}`}
          checked={char.isChecked === 1}
          onChange={handleChange}
        />
        <span className="m-2">
          {char.CharacteristicText}
          {char.hasAsterisk === 1 && (
            <span className="text-danger font-weight-bold">*</span>
          )}
        </span>
      </label>
      <input
        type="text"
        className="form-control"
        value={char.Notes || ''}
        onBlur={updateNotes}
        onChange={e => setChar({ ...char, Notes: e.target.value })}
        disabled={char.isChecked !== 1}
      />
    </div>
  );
};

CharacteristicComponent.propTypes = {
  ListedPropertiesID: PropTypes.number.isRequired,
  characteristic: PropTypes.shape({
    id: PropTypes.number,
    CharacteristicID: PropTypes.number.isRequired,
    CharacteristicText: PropTypes.string.isRequired,
    hasAsterisk: PropTypes.number,
    isChecked: PropTypes.number.isRequired,
  }),
};

CharacteristicComponent.defaultProps = {
  characteristic: {
    id: 0,
    CharacteristicID: 0,
    CharacteristicText: '',
    hasAsterisk: 0,
    isChecked: 0,
  },
};

export default CharacteristicComponent;
