// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --white100: #ffff;
  --blue100: #0d6efd;
  --black100: #000;
  --grey100: #f8f9fa;
  --grey200: #f2f2f2;
  --yellow100: #ffc300;
  --green100: #1a7803;
  --red100: #ff0000;
  --pink100: #f3733f1a;
}
`, "",{"version":3,"sources":["webpack://./src/style/core.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,oBAAoB;EACpB,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[":root {\n  --white100: #ffff;\n  --blue100: #0d6efd;\n  --black100: #000;\n  --grey100: #f8f9fa;\n  --grey200: #f2f2f2;\n  --yellow100: #ffc300;\n  --green100: #1a7803;\n  --red100: #ff0000;\n  --pink100: #f3733f1a;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
