// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link {
  color: #1A08EB;
  font-size: 20px;
}

.overflow-break-word {
  overflow-wrap: break-word;
}

h6.characteristics-heading {
  color: #2472BB;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/FieldAnalysisTool/FieldAnalysisTool.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".nav-link {\n  color: #1A08EB;\n  font-size: 20px;\n}\n\n.overflow-break-word {\n  overflow-wrap: break-word;\n}\n\nh6.characteristics-heading {\n  color: #2472BB;\n  font-weight: 500;\n  font-size: 13px;\n  line-height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
