/* eslint-disable import/no-cycle */
import axios from 'axios';
import { setIsUserLoggedIn } from './user/userSlice';

let store;

// Will logout the user when the session is expired
const handleSessionExpired = error => {
  if (error?.response?.status === 498) {
    window.location.replace('/');
    store.dispatch(setIsUserLoggedIn(false));
    localStorage.clear();
  }
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
});

instance.interceptors.response.use(
  response => response,
  error => {
    handleSessionExpired(error);
    return Promise.reject(error);
  }
);

// Functions for getting store access in the axios config
const injectStore = _store => {
  store = _store;
};

export default instance;
export { injectStore };
