import getLoadState from './getLoadState';

const processFileParse = (id, onLoadChange) =>
  new Promise(resolve => {
    let prevValue;
    const interval = setInterval(async () => {
      const loadState = await getLoadState(id);
      prevValue = loadState?.loaded;
      if (loadState.loadFinished) {
        clearInterval(interval);
        resolve(
          loadState.error
            ? { result: loadState.error }
            : { status: 200, result: loadState.amount || prevValue }
        );
      }
      if (!loadState.countStarted) {
        onLoadChange({ type: 'calculateRows' });
      } else {
        const percentage = (
          (loadState.loaded / loadState.amount) *
          100
        ).toFixed(2);
        onLoadChange({
          type: 'uploadDB',
          percentage,
          loadMessage: `${loadState.loaded} lines written`,
        });
      }
    }, 1000);
  });

export default processFileParse;
