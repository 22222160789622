import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import propTypes from 'prop-types';

const SingleSelect = props => {
  const {
    title,
    options,
    setOption,
    name,
    defaultValue,
    removeFilter,
    toBeDeleted,
  } = props;
  const changeCountryType = e => {
    if (e.target.value) {
      setOption(name, e.target.value);
    }
  };

  const renderOptions = () => {
    return options?.map(res => {
      return (
        <option key={res.optionValue} value={res.optionValue}>
          {res.optionName}
        </option>
      );
    });
  };

  return (
    <div>
      <div className="position-relative mb-2">
        <span className="label-heading">{title}</span>
        <Row className="row-width">
          <Col>
            <Form.Select
              onChange={changeCountryType}
              value={defaultValue?.key}
              name={name}
            >
              {renderOptions()}
            </Form.Select>{' '}
          </Col>
          <Col xs={1}>
            <div className="add-remove">
              <button
                type="button"
                // eslint-disable-next-line react/no-unknown-property
                variant="primary"
                className="close"
                onClick={() => removeFilter(toBeDeleted)}
              >
                <FontAwesomeIcon icon={faClose} />
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

SingleSelect.propTypes = {
  title: propTypes.string.isRequired,
  options: propTypes.arrayOf(
    propTypes.shape({
      optionName: propTypes.string,
      optionValue: propTypes.string,
    })
  ).isRequired,
  setOption: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  defaultValue: propTypes.string.isRequired,
  removeFilter: propTypes.func.isRequired,
  toBeDeleted: propTypes.bool.isRequired,
};

export default SingleSelect;
