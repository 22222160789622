/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  getPrevalenceCities,
  getPrevalenceZips,
  getHomeSizeAllCitiesCharList,
  getHomeSizeAllZipsCharList,
  getHomeSizeCities,
  getHomeSizeZips,
  getHomeSizeNightlyValueCities,
  getHomeSizeNightlyValueZips,
  getProfitabilityCities,
  getProfitabilityZips,
  getGeographyAllCharList,
  getGeographyCities,
  getSeasonalAllCitiesCharList,
  getSeasonalZips,
  getSeasonalCities,
} from './api';

const initialState = {
  prevalenceCitiesLoader: false,
  prevalenceZipsLoader: false,
  prevalenceCitiesStructuralCount: 0,
  prevalenceCitiesAmenitiesCount: 0,
  prevalenceZipsStructuralCount: 0,
  prevalenceZipsAmenitiesCount: 0,
  prevalenceCitiesStructural: [],
  prevalenceCitiesAmenities: [],
  prevalenceZipsStructural: [],
  prevalenceZipsAmenities: [],
  homeSizeCitiesLoader: false,
  homeSizeZipsLoader: false,
  homeSizeAllCitiesCharList: [],
  homeSizeAllZipsCharList: [],
  homeSizeCities: [],
  homeSizeZips: [],
  homeSizeNightlyValueCitiesLoader: false,
  homeSizeNightlyValueZipsLoader: false,
  homeSizeNightlyValueCities: [],
  homeSizeNightlyValueZips: [],
  profitabilityCitiesLoader: false,
  profitabilityZipsLoader: false,
  profitabilityCitiesStructuralCount: 0,
  profitabilityCitiesAmenitiesCount: 0,
  profitabilityZipsStructuralCount: 0,
  profitabilityZipsAmenitiesCount: 0,
  profitabilityCitiesStructural: [],
  profitabilityCitiesAmenities: [],
  profitabilityZipsStructural: [],
  profitabilityZipsAmenities: [],
  geographyCharList: [],
  geographyZipsCharList: [],
  geographyCities: [],
  geographyCitiesCount: 0,
  geographyCitiesLoader: false,
  geographyZips: [],
  geographyZipsCount: 0,
  geographyZipsLoader: false,
  seasonalCharList: [],
  seasonalCities: [],
  seasonalCitiesLoader: false,
  seasonalZips: [],
  seasonalZipsLoader: false,
  searchValue: [],
  searchValueGeography: [],
  searchValueHomeSize: [],
  getMoreData_Characteristic_To_DataUpdate: {},
};

export const characteristicsSlice = createSlice({
  name: 'characteristics',
  initialState,
  reducers: {
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    getMoreDataCharacteristicToDataUpdate: (state, action) => {
      state.getMoreData_Characteristic_To_DataUpdate = action.payload;
    },
  },
  extraReducers(builders) {
    builders.addCase(getPrevalenceCities.pending, state => {
      state.prevalenceCitiesLoader = true;
      state.prevalenceCitiesStructuralCount = 0;
      state.prevalenceCitiesAmenitiesCount = 0;
      state.prevalenceCitiesStructural = [];
      state.prevalenceCitiesAmenities = [];
      state.prevalenceZipsStructuralCount = 0;
      state.prevalenceZipsAmenitiesCount = 0;
      state.prevalenceZipsStructural = [];
      state.prevalenceZipsAmenities = [];
    });
    builders.addCase(getPrevalenceCities.fulfilled, (state, action) => {
      state.prevalenceCitiesLoader = false;
      state.prevalenceCitiesStructuralCount =
        action?.payload?.structural?.count;
      state.prevalenceCitiesAmenitiesCount = action?.payload?.amenities?.count;
      state.prevalenceCitiesStructural = action?.payload?.structural?.data;
      state.prevalenceCitiesAmenities = action?.payload?.amenities?.data;
    });
    builders.addCase(getPrevalenceZips.pending, state => {
      state.prevalenceZipsLoader = true;
      state.prevalenceCitiesStructuralCount = 0;
      state.prevalenceCitiesAmenitiesCount = 0;
      state.prevalenceCitiesStructural = [];
      state.prevalenceCitiesAmenities = [];
      state.prevalenceZipsStructuralCount = 0;
      state.prevalenceZipsAmenitiesCount = 0;
      state.prevalenceZipsStructural = [];
      state.prevalenceZipsAmenities = [];
    });
    builders.addCase(getPrevalenceZips.fulfilled, (state, action) => {
      state.prevalenceZipsLoader = false;
      state.prevalenceZipsStructuralCount = action?.payload?.structural?.count;
      state.prevalenceZipsAmenitiesCount = action?.payload?.amenities?.count;
      state.prevalenceZipsStructural = action?.payload?.structural?.data;
      state.prevalenceZipsAmenities = action?.payload?.amenities?.data;
    });

    // Home Size
    // Characteristics List
    builders.addCase(
      getHomeSizeAllCitiesCharList.fulfilled,
      (state, action) => {
        state.homeSizeAllCitiesCharList = action?.payload;
      }
    );
    builders.addCase(getHomeSizeAllZipsCharList.fulfilled, (state, action) => {
      state.homeSizeAllZipsCharList = action?.payload;
    });

    // Cities Data
    builders.addCase(getHomeSizeCities.pending, state => {
      state.homeSizeCities = [];
      state.homeSizeCitiesLoader = true;
    });
    builders.addCase(getHomeSizeCities.fulfilled, (state, action) => {
      state.homeSizeCities = action?.payload;
      state.homeSizeCitiesLoader = false;
    });
    builders.addCase(getHomeSizeCities.rejected, state => {
      state.homeSizeCities = [];
      state.homeSizeCitiesLoader = false;
    });

    // Zips Data
    builders.addCase(getHomeSizeZips.pending, state => {
      state.homeSizeZips = [];
      state.homeSizeZipsLoader = true;
    });
    builders.addCase(getHomeSizeZips.fulfilled, (state, action) => {
      state.homeSizeZips = action?.payload;
      state.homeSizeZipsLoader = false;
    });
    builders.addCase(getHomeSizeZips.rejected, state => {
      state.homeSizeZips = [];
      state.homeSizeZipsLoader = false;
    });

    // Nightly Values - Cities Data
    builders.addCase(getHomeSizeNightlyValueCities.pending, state => {
      state.homeSizeNightlyValueCities = [];
      state.homeSizeNightlyValueCitiesLoader = true;
    });
    builders.addCase(
      getHomeSizeNightlyValueCities.fulfilled,
      (state, action) => {
        state.homeSizeNightlyValueCities = action?.payload;
        state.homeSizeNightlyValueCitiesLoader = false;
      }
    );
    builders.addCase(getHomeSizeNightlyValueCities.rejected, state => {
      state.homeSizeNightlyValueCities = [];
      state.homeSizeNightlyValueCitiesLoader = false;
    });

    // Nightly Values - Zips Data
    builders.addCase(getHomeSizeNightlyValueZips.pending, state => {
      state.homeSizeNightlyValueZips = [];
      state.homeSizeNightlyValueZipsLoader = true;
    });
    builders.addCase(getHomeSizeNightlyValueZips.fulfilled, (state, action) => {
      state.homeSizeNightlyValueZips = action?.payload;
      state.homeSizeNightlyValueZipsLoader = false;
    });
    builders.addCase(getHomeSizeNightlyValueZips.rejected, state => {
      state.homeSizeNightlyValueZips = [];
      state.homeSizeNightlyValueZipsLoader = true;
    });

    // Profitability
    builders.addCase(getProfitabilityCities.pending, state => {
      state.profitabilityCitiesLoader = true;
      state.profitabilityCitiesStructuralCount = 0;
      state.profitabilityCitiesAmenitiesCount = 0;
      state.profitabilityCitiesStructural = [];
      state.profitabilityCitiesAmenities = [];
    });
    builders.addCase(getProfitabilityCities.fulfilled, (state, action) => {
      state.profitabilityCitiesLoader = false;
      state.profitabilityCitiesStructuralCount =
        action?.payload?.structural?.count;
      state.profitabilityCitiesAmenitiesCount =
        action?.payload?.amenities?.count;
      state.profitabilityCitiesStructural = action?.payload?.structural?.data;
      state.profitabilityCitiesAmenities = action?.payload?.amenities?.data;
    });
    builders.addCase(getProfitabilityZips.pending, state => {
      state.profitabilityZipsLoader = true;
      state.profitabilityZipsStructuralCount = 0;
      state.profitabilityZipsAmenitiesCount = 0;
      state.profitabilityZipsStructural = [];
      state.profitabilityZipsAmenities = [];
    });
    builders.addCase(getProfitabilityZips.fulfilled, (state, action) => {
      state.profitabilityZipsLoader = false;
      state.profitabilityZipsStructuralCount =
        action?.payload?.structural?.count;
      state.profitabilityZipsAmenitiesCount = action?.payload?.amenities?.count;
      state.profitabilityZipsStructural = action?.payload?.structural?.data;
      state.profitabilityZipsAmenities = action?.payload?.amenities?.data;
    });

    // Seasonal
    builders.addCase(
      getSeasonalAllCitiesCharList.fulfilled,
      (state, action) => {
        state.seasonalCharList = action?.payload;
      }
    );
    builders.addCase(getSeasonalCities.fulfilled, (state, action) => {
      state.seasonalCities = action?.payload;
      state.seasonalCitiesLoader = false;
    });

    builders.addCase(getSeasonalCities.pending, state => {
      state.seasonalCities = [];
      state.seasonalCitiesLoader = true;
    });

    builders.addCase(getSeasonalZips.fulfilled, (state, action) => {
      state.seasonalZips = action?.payload;
      state.seasonalZipsLoader = false;
    });

    builders.addCase(getSeasonalZips.pending, state => {
      state.seasonalZips = [];
      state.seasonalZipsLoader = true;
    });

    // Geography
    // Characteristics List
    builders.addCase(getGeographyAllCharList.fulfilled, (state, action) => {
      state.geographyCharList = action?.payload;
    });

    // Cities Data
    builders.addCase(getGeographyCities.fulfilled, (state, action) => {
      state.geographyCities = action.payload;
      state.geographyCitiesLoader = false;
      state.surveyDataUpdateByCitiesCount = action.payload?.count;
    });
    builders.addCase(getGeographyCities.pending, state => {
      state.geographyCitiesLoader = true;
      state.geographyCities = [];
      state.geographyCitiesCount = 0;
    });
    builders.addCase(getGeographyCities.rejected, state => {
      state.geographyCitiesLoader = false;
      state.geographyCities = [];
      state.geographyCitiesCount = 0;
    });
  },
});

// this is for dispatch
export const { setSearchValue, getMoreDataCharacteristicToDataUpdate } =
  characteristicsSlice.actions;

// this is for configureStore
export default characteristicsSlice.reducer;
