/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import debounce from 'debounce-promise';
// import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import Filter from '../../component/FilterDropdown';
import NextGenBsTable from '../../component/NextGenBsTable';

import { geographyColumn } from '../../utils/columns';

import {
  getGeographySearchCitiesList,
  getGeographySearchCitiesCharList,
  getGeographyAllCharList,
  getGeographyCities,
} from '../../redux/characteristics/api';
import data from '../../utils/data.json';
import { updateCurrentSurveyTab } from '../../redux/navigation/navigationSlice';
import {
  removeFilter,
  renderFiltersInputType,
  setSelectFilterOption,
} from '../../utils/helperInput';
import {
  getMoreDataCharacteristicToDataUpdate,
  setSearchValue,
} from '../../redux/characteristics/characteristicsSlice';

const TabGeography = ({ selectedTab }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectOption, setFilterOption] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [selectedFilterOption, setSelectedFilterOption] = useState({});
  const [selectedSortingOption, setSelectedSortingOption] = useState({});
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCharacteristic, setSelectedCharacteristic] = useState('');
  const [filterByData, setFilterByData] = useState(
    data?.characteristicsFilters
  );
  const geographyCharList = useSelector(
    state => state.characteristics.geographyCharList
  );
  const geographyCitiesLoader = useSelector(
    state => state.characteristics.geographyCitiesLoader
  );
  const geographyCities = useSelector(
    state => state.characteristics.geographyCities
  );
  const quickSearchValue = useSelector(
    state => state.characteristics.searchValue
  );

  const setSelectedOption = options => {
    if (!options || options.length === 0) {
      setSelectedCity('');
      setSelectedCharacteristic('');
    }

    let newSelectedCity = '';
    let newSelectedCharacteristics = '';

    options.forEach(o => {
      if (o?.DataFrom === 'City') newSelectedCity = o.value;
      if (o?.DataFrom === 'Char') newSelectedCharacteristics = o.value;
    });

    setSelectedCity(newSelectedCity);
    setSelectedCharacteristic(newSelectedCharacteristics);

    dispatch(setSearchValue(options));
  };

  useEffect(() => {
    setSelectedOption(quickSearchValue);
  }, [selectedTab]);

  const handleSelectedCharacteristic = value => {
    if (quickSearchValue.length > 0) {
      setSelectedOption([
        ...quickSearchValue.filter(o => o?.DataFrom !== 'Char'),
        { label: value, value, DataFrom: 'Char' },
      ]);
    }
  };

  const handleGetMoreData = () => {
    const DataFrom = selectedCity !== '' ? 'City' : 'char';
    if (DataFrom === 'char') {
      dispatch(getMoreDataCharacteristicToDataUpdate({ DataFrom, value: '' }));
      dispatch(updateCurrentSurveyTab('Dataupdate'));
      navigate('/survey');
    } else {
      const value = selectedCity;
      dispatch(getMoreDataCharacteristicToDataUpdate({ DataFrom, value }));
      dispatch(updateCurrentSurveyTab('Dataupdate'));
      navigate('/survey');
    }
  };

  const setSelectFilterOptionFunction = value => {
    setSelectFilterOption(
      value,
      data,
      selectOption,
      setFilterOption,
      setSelectedFilter,
      selectedFilterOption,
      setSelectedFilterOption,
      filterByData,
      setFilterByData
    );
  };

  const setSortingOption = option => {
    const tempOption = {};
    if (option !== 'default') {
      setSelectedSortingOption(option);
      if (option === 'monthly_revenue') {
        tempOption.Revenue_Baseline = true;
      } else {
        tempOption[option] = true;
      }
    }

    setSelectedSortingOption(tempOption);
  };

  const inputOnChangeFunction = (type, value) => {
    const tempSelectedFilterOption = cloneDeep(selectedFilterOption);
    tempSelectedFilterOption[type] = value;
    setSelectedFilterOption(tempSelectedFilterOption);
  };

  const removeFilterFunction = id => {
    removeFilter(
      id,
      data.characteristicsFilters,
      selectOption,
      selectedFilterOption,
      setFilterOption,
      setSelectedFilter,
      setSelectedFilterOption,
      setFilterByData,
      filterByData
    );
  };

  const resetFilters = () => {
    setFilterOption([]);
    setSelectedFilter('');
    setSelectedSortingOption({});
    setSelectedFilterOption({});
  };

  const renderFilterOptions = () => {
    return (
      <Row className="mb-2 align-items-end">
        {selectOption?.map((res, i) => {
          return (
            // TODO: fix the key
            // eslint-disable-next-line react/no-array-index-key
            <Col xs={3} key={`filters-${i}`}>
              {renderFiltersInputType(
                res,
                i,
                data,
                data.characteristicsFilters,
                inputOnChangeFunction,
                removeFilterFunction,
                [],
                [],
                [],
                selectedFilterOption,
                [],
                'dropdown-select',
                'minimumOccupancyPercentage'
              )}
            </Col>
          );
        })}
        <Col xs={3}>
          <div className="mb-2">
            {selectOption?.length ? (
              <button
                type="button"
                className="allbutton"
                onClick={() => {
                  resetFilters();
                }}
              >
                Reset Filter
              </button>
            ) : null}
          </div>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    if (selectedCharacteristic)
      dispatch(
        getGeographyCities({
          searchQuery: selectedCity,
          filters: {
            ...selectedFilterOption,
            CharacteristicName: selectedCharacteristic,
          },
          sortings: selectedSortingOption,
        })
      );
  }, [
    selectedCity,
    selectedCharacteristic,
    selectedFilterOption,
    selectedSortingOption,
  ]);

  useEffect(() => {
    if (selectedTab === 'Geography' && (selectedCity || selectedCharacteristic))
      dispatch(getGeographyAllCharList({ City: selectedCity }));
  }, [selectedCity, selectedTab]);

  useEffect(() => {
    const newSelectedCharecteristic = quickSearchValue.find(
      item => item.DataFrom === 'Char'
    )?.value;
    if (selectedTab === 'Geography' && newSelectedCharecteristic) {
      setSelectedCharacteristic(newSelectedCharecteristic);
    }
  }, [selectedTab]);

  const column = geographyColumn();

  const getStructrualList = () => {
    let struct = [
      { optionValue: '', optionName: 'Structural Characteristics' },
    ];
    if (geographyCharList?.structural?.length > 0) {
      const structData = geographyCharList?.structural?.map(d => ({
        optionValue: d?.CharacteristicName,
        optionName: d?.CharacteristicName,
      }));
      struct = [...struct, ...structData];
    }

    return struct;
  };

  const getAmenitiesList = () => {
    let amenit = [{ optionValue: '', optionName: 'Amenities ' }];
    if (geographyCharList?.amenities?.length > 0) {
      const amenitData = geographyCharList?.amenities?.map(d => ({
        optionValue: d?.CharacteristicName,
        optionName: d?.CharacteristicName,
      }));
      amenit = [...amenit, ...amenitData];
    }

    return amenit;
  };

  const debounceFunc = debounce(async inputValue => {
    let options = [];
    if (inputValue.length <= 2) {
      return [];
    }
    if (!Number.isNaN(Number(inputValue)))
      return [
        { value: '', label: 'Zips search not allowed', isDisabled: true },
      ];
    const citiesList = await getGeographySearchCitiesList({
      searchQuery: inputValue,
    });

    if (citiesList?.length)
      options = [
        ...options,
        { value: '', label: '----Cities----', isDisabled: true },
        ...citiesList,
      ];
    else
      options = [
        ...options,
        { value: '', label: '----Cities----', isDisabled: true },
        { value: '', label: 'No cities found', isDisabled: true },
      ];

    const charList = await getGeographySearchCitiesCharList({
      Char: inputValue,
    });
    if (charList?.length)
      options = [
        ...options,
        { value: '', label: '----Characteristics----', isDisabled: true },
        ...charList.map(d => ({
          value: d.CharacteristicName,
          label: d.CharacteristicName,
          DataFrom: d.DataFrom,
        })),
      ];
    else {
      options = [
        ...options,
        { value: '', label: '----Characteristics----', isDisabled: true },
        { value: '', label: 'No characteristics found', isDisabled: true },
      ];
    }

    return options;
  }, 1000);

  return (
    <div>
      <Row className="mb-2">
        <Col xs={6}>
          <h6>Quick Search</h6>
          <AsyncSelect
            isMulti
            isClearable
            loadOptions={debounceFunc}
            onChange={setSelectedOption}
            getOptionValue={option => option.label}
            placeholder={
              <div style={{ width: '100vh' }}>
                Enter City or a Characteristic name
              </div>
            }
            value={quickSearchValue?.filter(v => v?.DataFrom !== 'Zip')}
          />
        </Col>
        <Col xs={2}>
          <Filter
            setOption={setSelectFilterOptionFunction}
            options={filterByData}
            title="Filter By"
            value={selectedFilter}
          />
        </Col>
        <Col xs={2}>
          <Filter
            setOption={setSortingOption}
            options={data?.characteristicsSorting}
            title="Sort By"
          />
        </Col>
      </Row>
      <Row>{renderFilterOptions()}</Row>
      {!selectedCity && !selectedCharacteristic ? (
        <Row className="mt-2">
          <b>Enter City or a Characteristic name in the search bar.</b>
        </Row>
      ) : (
        <div>
          <Row>
            <Col xs={12} className="flex-style">
              <div>
                <b> Current Geography:</b> {selectedCity}
              </div>
            </Col>
          </Row>
          <Row className="mb-4 mt-2">
            <Col xs={3}>
              <Filter
                setOption={handleSelectedCharacteristic}
                options={getStructrualList()}
                value={selectedCharacteristic}
              />
            </Col>
            <Col xs={3}>
              <Filter
                setOption={handleSelectedCharacteristic}
                options={getAmenitiesList()}
                value={selectedCharacteristic}
              />
            </Col>
            <Col xs={2}>
              <button
                type="button"
                className="allbutton"
                onClick={handleGetMoreData}
                style={{ marginLeft: 'auto' }}
              >
                Get More Data
              </button>
            </Col>
          </Row>
          <Row className="mt-2">
            {selectedCity && !selectedCharacteristic ? (
              <b>Select Structural Characteristic or Amenity First</b>
            ) : (
              <Col xs={8}>
                <NextGenBsTable
                  keyField="id"
                  columns={column}
                  data={geographyCities}
                  loading={geographyCitiesLoader}
                  hideSizePerPage
                  enableSearching={false}
                  striped
                />
              </Col>
            )}
          </Row>
        </div>
      )}
    </div>
  );
};

export default TabGeography;
