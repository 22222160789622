/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
// TODO: must fix this
/* eslint-disable react/no-unstable-nested-components */
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useDispatch } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import { updateCurrentCharacteristicsDrilldownTab } from '../../redux/navigation/navigationSlice';
// import propTypes from 'prop-types';

const { SearchBar } = Search;

const navigateToCharacteristicsTab = (divName, dispatch) => {
  switch (divName) {
    case 'geo-zip-cell':
    case 'geo-prevalenceCountPerCell homeCursorCell':
    case 'prevalenceCountCell':
      dispatch(updateCurrentCharacteristicsDrilldownTab('Prevalence'));
      break;
    case 'prev-annualRevenueCell':
      dispatch(updateCurrentCharacteristicsDrilldownTab('Seasonal'));
      break;
    case 'profit-mult-cell':
      dispatch(updateCurrentCharacteristicsDrilldownTab('Profitability'));
      break;
    default:
      break;
  }
};

const NextGenDataTable = props => {
  const dispatch = useDispatch();
  const [sizePerPageState, setSizePerPageState] = React.useState(10);
  const [selectedRow, setSelectedRow] = useState(null);
  const paginationTotalRenderer = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
  };

  const {
    columns,
    data,
    keyField,
    loading,
    enableSearching,
    onClickRow,
    striped,
    hoverClass,
    showRowText = false,
    showCheckboxCell = false,
    setCheckedRows,
    count = 0,
    fetchData,
    remote = false,
    resetPage = 1,
    setResetPage,
    showPagination = true,
    highlightActiveRow = false,
  } = props;

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => {
    return (
      <div className="show-size-per-page">
        <span>Show</span>
        {options.map(option => (
          <button
            key={option.page}
            type="button"
            onClick={() => {
              onSizePerPageChange(option.page);
              setSizePerPageState(option.page);
            }}
            className={`pagination-btn ${
              currSizePerPage === String(option.page) ? 'active' : ''
            }`}
            style={{
              fontWeight:
                currSizePerPage === String(option.page) ? 'bold' : 'normal',
            }}
          >
            {option.page}
          </button>
        ))}
      </div>
    );
  };

  const pageButtonRenderer = ({ page, active, disabled, onPageChange }) => {
    const getButtonClassName = (pageNumber, isActive, isDisabled) => {
      let baseClassName = 'pagination-btn';

      if ((pageNumber === '>' || pageNumber === '<') && isDisabled)
        baseClassName += ' pagination-btn-disabled';
      else if (pageNumber === '>' || pageNumber === '<')
        baseClassName += ' pagination-btn-bold';

      if (isActive) baseClassName += ' active';

      return baseClassName;
    };

    return (
      <button
        key={page}
        type="button"
        onClick={() => onPageChange(page)}
        className={getButtonClassName(page, active, disabled)}
        disabled={disabled}
      >
        {page}
      </button>
    );
  };

  const options = {
    custom: true,
    page: resetPage,
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: 'First',
    lastPageText: 'Last',
    nextButtonText: '>',
    preButtonText: '<',
    nextPageTitle: 'Next page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: false,
    totalSize: remote ? count : data?.length,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    sizePerPage: !showPagination
      ? data?.length
      : [10, 100, 1000].includes(data?.length)
      ? data?.length
      : sizePerPageState,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: '1000',
        value: 1000,
      },
    ],
    sizePerPageRenderer, // A numeric array is also available. the purpose of above example is custom the text
    pageButtonRenderer,
    paginationTotalRenderer,
  };

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      navigateToCharacteristicsTab(e.target?.className, dispatch);

      setSelectedRow(row);
      if (onClickRow !== undefined) onClickRow(e, row, rowIndex);
    },
    // onMouseEnter: (e, row, rowIndex) => {
    // }
  };

  const rowClasses = row => {
    const baseRowClasses = `text-nowrap ${
      hoverClass !== undefined ? hoverClass : ''
    }`;
    if (highlightActiveRow && selectedRow && selectedRow.id === row.id) {
      return `${baseRowClasses} custom-active-row`;
    }
    return baseRowClasses;
  };

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    setResetPage(page);
    if (sortField !== undefined && sortField !== null) {
      const sort = {};
      sort[`${sortField}`] = sortOrder;
      fetchData((page - 1) * sizePerPage, sizePerPage, sort);
    } else fetchData((page - 1) * sizePerPage, sizePerPage, {});
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    clickToEdit: false,
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setCheckedRows(prev => [...prev, ...rows]);
      } else {
        setCheckedRows(prev => {
          const newArray = prev?.filter(
            array22 => !rows.some(array11 => array11.id === array22.id)
          );
          return newArray;
        });
      }
    },
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setCheckedRows(prev => [...prev, row]);
      } else {
        setCheckedRows(prev => prev?.filter(dt => dt.id !== row?.id));
      }
    },
    headerColumnStyle: { paddingLeft: '8px', cursor: 'pointer' },
  };

  const getDefaultSortedOrders = () => {
    if (columns.length > 0) {
      return columns
        ?.filter(c => c.defaultSortOrder)
        .map(c => ({ dataField: c.dataField, order: c.defaultSortOrder }));
    }
    return [];
  };

  const headerSortingClasses = order => {
    if (!order) return <span className="order-4" />;
    if (order === 'asc') return <span className="caret-4-asc" />;
    if (order === 'desc') return <span className="caret-4-desc" />;
    return null;
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <ToolkitProvider keyField={keyField} columns={columns} data={data} search>
        {toolkitprops => (
          <div>
            <div className="search_div">
              {enableSearching && <SearchBar {...toolkitprops.searchProps} />}
            </div>
            {showCheckboxCell ? (
              <span className="surveyDateUpdateTable">
                <BootstrapTable
                  remote={remote}
                  onTableChange={onTableChange}
                  keyField={keyField}
                  wrapperClasses="table-responsive"
                  rowClasses={rowClasses}
                  bootstrap4
                  responsive
                  striped={striped}
                  selectRow={selectRow}
                  rowEvents={tableRowEvents}
                  noDataIndication={() =>
                    loading ? (
                      <div className="table-spinner-div">
                        <ScaleLoader
                          color="#000"
                          loading
                          height={35}
                          width={4}
                          margin={2}
                          radius={2}
                        />
                      </div>
                    ) : (
                      'No Results Found Matching Selected Filters'
                    )
                  }
                  defaultSorted={getDefaultSortedOrders()}
                  sort={{
                    sortCaret: headerSortingClasses,
                  }}
                  {...toolkitprops.baseProps}
                  {...paginationTableProps}
                />
              </span>
            ) : (
              <BootstrapTable
                remote={remote}
                onTableChange={onTableChange}
                keyField={keyField}
                wrapperClasses="table-responsive"
                rowClasses={rowClasses}
                bootstrap4
                responsive
                striped={striped}
                rowEvents={tableRowEvents}
                noDataIndication={() =>
                  loading ? (
                    <div className="table-spinner-div">
                      <ScaleLoader
                        color="#000"
                        loading
                        height={35}
                        width={4}
                        margin={2}
                        radius={2}
                      />
                    </div>
                  ) : (
                    'No Results Found Matching Selected Filters'
                  )
                }
                defaultSorted={getDefaultSortedOrders()}
                sort={{
                  sortCaret: headerSortingClasses,
                }}
                {...toolkitprops.baseProps}
                {...paginationTableProps}
              />
            )}
          </div>
        )}
      </ToolkitProvider>

      {showPagination && (
        <div className={`bottomDiv ${showRowText ? '' : 'showRowText'}`}>
          {!showRowText && <PaginationTotalStandalone {...paginationProps} />}
          <span className="last-pagination-div">
            {!showRowText && <PaginationListStandalone {...paginationProps} />}
            {!showRowText && (
              <SizePerPageDropdownStandalone {...paginationProps} />
            )}
          </span>
        </div>
      )}
    </div>
  );

  return (
    <div className="expanded-container">
      <PaginationProvider pagination={paginationFactory(options)}>
        {contentTable}
      </PaginationProvider>
    </div>
  );
};

// NextGenDataTable.propTypes = {
//   columns: propTypes.arrayOf(propTypes.string).isRequired,
//   data: propTypes.arrayOf(propTypes.string).isRequired,
//   keyField: propTypes.string.isRequired,
//   loading: propTypes.bool.isRequired,
//   hideSizePerPage: propTypes.bool.isRequired,
//   enableSearching: propTypes.bool.isRequired,
//   onClickRow: propTypes.func.isRequired,
//   striped: propTypes.bool.isRequired,
//   hoverClass: propTypes.string.isRequired,
//   showRowText: propTypes.bool.isRequired,
//   showCheckboxCell: propTypes.bool.isRequired,
//   setCheckedRows: propTypes.func.isRequired,
//   count: propTypes.number.isRequired,
//   fetchData: propTypes.func.isRequired,
//   remote: propTypes.bool.isRequired,
//   resetPage: propTypes.number.isRequired,
//   setResetPage: propTypes.func.isRequired,
// };

export default React.memo(NextGenDataTable);
