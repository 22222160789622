import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import PropTypes from 'prop-types';
import Dashboard from './pages/Dashboard';
import Survey from './pages/Survey';
import DataIngest from './pages/DataIngest';
import DrilDown from './pages/DrilDown';
import HomesReview from './pages/HomesReview';
import Account from './pages/Account';
import store from './redux/store';
import Login from './pages/Login';
import './style/core.css';
import './style/global.css';
import Layout from './component/layout';
import Users from './pages/Users';
import Toaster from './component/Toaster';
import 'react-toastify/dist/ReactToastify.css';
import Protected from './component/Protected';
import CharacteristicsDrilldown from './pages/CharacteristicsDrilldown';
import FieldAnalysisTool from './pages/FieldAnalysisTool';
import PageNotFound from './pages/PageNotFound';
import useFilters from './hooks/useFilters';
import { injectStore } from './redux/axiosConfig';

const persistor = persistStore(store);

export const AppProvider = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>{children}</PersistGate>
    </Provider>
  );
};

const App = () => {
  // injecting store in the axios config
  injectStore(store);

  const { updateClickBackFilters } = useFilters();
  const isLoggedIn = useSelector(state => state.user.isUserLoggedIn);

  useEffect(() => {
    updateClickBackFilters();
    const handlePopState = () => {
      updateClickBackFilters();
    };
    // when app starts, add a event listener to update the filters
    // when user clicks the back button
    window.addEventListener('popstate', handlePopState);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);
  // TODO: source: https://stackoverflow.com/questions/52448909/onbeforeunload-not-working-inside-react-component
  // useEffect(() => {
  //   const preventUnload = event => {
  //     // NOTE: This message isn't used in modern browsers, but is required
  //     const message = 'Sure you want to leave?';
  //     event.preventDefault();
  //     localStorage.removeItem('persist:root');
  //     // eslint-disable-next-line no-param-reassign
  //     event.returnValue = message;
  //   };

  //   window.addEventListener('beforeunload', preventUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', preventUnload);
  //   };
  // }, []);
  return (
    <BrowserRouter>
      <Layout isLoggedIn={isLoggedIn}>
        <Toaster />
        <Routes>
          <Route
            exact
            path="/"
            element={
              !isLoggedIn ? <Login /> : <Navigate to="/dashboard" replace />
            }
          />
          {/* <Route exact path="/settings" element={<DB />} /> */}
          {isLoggedIn && (
            <>
              <Route
                exact
                path="/dashboard"
                element={
                  <Protected isLoggedIn={isLoggedIn}>
                    <Dashboard />
                  </Protected>
                }
              />
              <Route
                exact
                path="/survey"
                element={
                  <Protected isLoggedIn={isLoggedIn}>
                    <Survey />
                  </Protected>
                }
              />
              <Route
                exact
                path="/dataingest"
                element={
                  <Protected isLoggedIn={isLoggedIn}>
                    {' '}
                    <DataIngest />
                  </Protected>
                }
              />
              <Route
                exact
                path="/drilldown"
                element={
                  <Protected isLoggedIn={isLoggedIn}>
                    {' '}
                    <DrilDown />
                  </Protected>
                }
              />
              <Route
                exact
                path="/homesreview"
                element={
                  <Protected isLoggedIn={isLoggedIn}>
                    {' '}
                    <HomesReview />
                  </Protected>
                }
              />
              <Route
                exact
                path="/account"
                element={
                  <Protected isLoggedIn={isLoggedIn}>
                    {' '}
                    <Account />
                  </Protected>
                }
              />
              <Route
                exact
                path="/users"
                element={
                  <Protected isLoggedIn={isLoggedIn}>
                    <Users />
                  </Protected>
                }
              />
              <Route
                exact
                path="/characteristicsdrilldown"
                element={
                  <Protected isLoggedIn={isLoggedIn}>
                    <CharacteristicsDrilldown />
                  </Protected>
                }
              />
              <Route
                path="/fat/*"
                element={
                  <Protected isLoggedIn={isLoggedIn}>
                    <FieldAnalysisTool />
                  </Protected>
                }
              />
            </>
          )}
          <Route
            path="*"
            element={
              !isLoggedIn ? <Navigate to="/" replace /> : <PageNotFound />
            }
          />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
