/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { downloadPdf } from './api';

const initialState = { isPdfDownloading: false };

export const rankingSlice = createSlice({
  name: 'pdf',
  initialState,
  // reducers: {},
  extraReducers(builders) {
    builders.addCase(downloadPdf.fulfilled, (state, action) => {
      state.isPdfDownloading = action.payload;
    });
    builders.addCase(downloadPdf.pending, state => {
      state.isPdfDownloading = true;
    });
    builders.addCase(downloadPdf.rejected, state => {
      state.isPdfDownloading = true;
    });
  },
});

export default rankingSlice.reducer;
