/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// TODO: fix the key
/* eslint-disable react/no-array-index-key */
import lodash from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import debounce from 'debounce-promise';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import Filter from '../../component/FilterDropdown';
import NextGenDataTable from '../../component/NextGenBsTable';
import {
  getHomeReviewCitiesList,
  getHomeReviewDetails,
  getHomeReviewList,
  getHomeReviewZipsList,
  getKeywordsList,
} from '../../redux/homeReview/api';
import { homeReviewColumns } from '../../utils/columns';
import data from '../../utils/data.json';
import {
  checkForAppliedFilters,
  removeFilter,
  renderFiltersInputType,
  setSelectFilterOption,
} from '../../utils/helperInput';
import { cityList } from '../../redux/survey/api';
import {
  updateCurrentSurveyTab,
  updateUpdateTabNavigationData,
} from '../../redux/navigation/navigationSlice';
import NoRecord from '../../component/NoRecord';
import useFilters from '../../hooks/useFilters';
import { setQuickSearchValue } from '../../redux/homeReview/homeReviewSlice';

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 2,
});

const HomesReview = () => {
  const {
    selectOption,
    selectedFilterOption,
    setFilterOptions,
    setSelectedFilterOptions,
  } = useFilters({ moduleName: 'homesreview' });
  const { cloneDeep, isEmpty } = lodash;
  const quickSearchValue = useSelector(state => state.homeReview.quickSearch);
  const [search, setSearch] = useState(quickSearchValue);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [selectedSortingOption, setSelectedSortingOption] = useState({});
  const [resetPage, setResetPage] = useState(1);
  const homeReviewList = useSelector(state => state.homeReview.homeReviewList);

  const homeReviewListLoader = useSelector(
    state => state.homeReview.homeReviewListLoader
  );
  const keywordsList = useSelector(state => state.homeReview.keywordsList);

  const homeReviewDetails = useSelector(
    state => state.homeReview.homeReviewDetails[0]
  );
  const homeReviewCount = useSelector(
    state => state.homeReview.homeReviewCount
  );
  const [detailsData, setDetailsData] = useState({
    amenities: [],
    propertyImage: [],
  });
  const stateData = useSelector(state => state?.ranking?.states);
  const cityData = useSelector(state => state?.homeReview?.homeReviewCityList);
  const zipCodeData = useSelector(
    state => state?.homeReview?.homeReviewZipCodeList
  );
  const [filterByData, setFilterByData] = useState(data?.homesReviewFilters);
  const [clickedRow, setSelectedClickedRow] = useState({});
  const [show, setShow] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedImage, setSelectedImage] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let amenities = homeReviewDetails?.Amenities?.replace(
      /"([^"]+(?="))"/g,
      '$1'
    );
    amenities =
      amenities?.replace('[', '')?.replace(']', '')?.split(',')?.sort() || [];
    let img = homeReviewDetails?.ListingImages?.replace(/'/g, '');
    img = img?.replace('[', '')?.replace(']', '')?.split(',') || [];
    setDetailsData(prev => ({
      ...prev,
      amenities,
      propertyImage: img,
    }));
  }, [homeReviewDetails]);

  const getHomeReviewData = (start = 0, limit = 10, textSearch = {}) => {
    if (!checkForAppliedFilters(selectOption, selectedFilterOption)) return;

    dispatch(
      getHomeReviewList({
        filters: selectedFilterOption,
        sortings: selectedSortingOption,
        searchQuery: textSearch?.value || search.value,
        start,
        limit,
      })
    );
  };

  useEffect(() => {
    dispatch(getKeywordsList());
  }, []);

  const setFilterOption = value => {
    dispatch(setFilterOptions(value));
  };

  const setSelectedFilterOption = option => {
    dispatch(setSelectedFilterOptions(option));
  };

  useEffect(() => {
    getHomeReviewData();
  }, [selectedFilterOption, selectedSortingOption]);

  const onClickRow = (e, row) => {
    setSelectedClickedRow(row);
    setShowDetails(true);
    dispatch(getHomeReviewDetails(row.PropertyID));
  };

  const setSortingOption = option => {
    const tempOption = {};
    if (option !== 'default') {
      if (option === 'monthly_revenue') {
        tempOption.Revenue_Baseline = true;
      } else {
        tempOption[option] = true;
      }
    }

    setSelectedSortingOption(tempOption);
    setResetPage(1);
  };

  const inputOnChangeFunction = (type, value) => {
    const tempSelectedFilterOption = cloneDeep(selectedFilterOption);
    if (type === 'StateAbbr') {
      const tempData = [];
      value?.map(res => {
        return tempData.push(res.value);
      });
      tempSelectedFilterOption[type] = tempData;
      dispatch(cityList(tempData));
    }
    if (type === 'Sleeps') {
      tempSelectedFilterOption[type] = value;
    } else if (type === 'BedsAndBath') {
      tempSelectedFilterOption[type] = value;
    } else {
      tempSelectedFilterOption[type] = value;
    }
    setSelectedFilterOption(tempSelectedFilterOption);
  };

  const removeFilterFunction = id => {
    removeFilter(
      id,
      data.homesReviewFilters,
      selectOption,
      selectedFilterOption,
      setFilterOption,
      setSelectedFilter,
      setSelectedFilterOption,
      setFilterByData,
      filterByData
    );
  };

  const renderFilterOptions = () => {
    return (
      <Row className="mb-2">
        {selectOption?.map((res, i) => {
          return (
            <Col xs={res === 'Segment' ? 5 : 3} key={`filters-${i}`}>
              {renderFiltersInputType(
                res,
                i,
                data,
                data.homesReviewFilters,
                inputOnChangeFunction,
                removeFilterFunction,
                stateData,
                cityData,
                zipCodeData,
                selectedFilterOption,
                keywordsList
              )}
            </Col>
          );
        })}
      </Row>
    );
  };

  const setSelectFilterOptionFunction = value => {
    setSelectFilterOption(
      value,
      data,
      selectOption,
      setFilterOption,
      setSelectedFilter,
      selectedFilterOption,
      setSelectedFilterOption,
      filterByData,
      setFilterByData
    );
  };

  const resetFilters = () => {
    setFilterOption([]);
    setFilterByData(data?.homesReviewFilters);
    setSelectedFilter('');
  };

  const setSelectedOption = options => {
    if (options.length > 0) {
      dispatch(setQuickSearchValue(options[0]));
      setSearch(options[0]);
      getHomeReviewData(0, 10, options[0]);
    } else {
      dispatch(setQuickSearchValue({}));
      setSearch({});
    }
  };

  const isAllNumbers = array => {
    return array.every(element => !Number.isNaN(Number(element)));
  };

  const debounceFunc = debounce(async inputValue => {
    if (inputValue.length <= 2) {
      return [];
    }
    const value = inputValue.trim();
    const listofSearchValue = value.split(',');
    const isNumber = isAllNumbers(listofSearchValue);

    if (isNumber) {
      const res = await getHomeReviewZipsList({
        searchQuery: inputValue,
      });

      if (res?.data?.length) {
        return [
          { value: '', label: '----ZIPCodes----', isDisabled: true },
          ...res.data,
        ];
      }
    } else {
      const res = await getHomeReviewCitiesList({
        searchQuery: inputValue,
      });

      if (res?.data?.length) {
        return [
          { value: '', label: '----Cities----', isDisabled: true },
          ...res.data,
        ];
      }
    }

    return [{ value: '', label: 'No record found', isDisabled: true }];
  }, 1000);

  useEffect(() => {
    if (selectOption.length > 0) {
      const filteredData = filterByData.filter(
        filter => filter.optionValue !== selectOption[selectOption.length - 1]
      );
      setFilterByData(filteredData);
    } else {
      setSelectedFilter('');
      setFilterByData(data?.homesReviewFilters);
    }
  }, [selectOption]);

  return (
    <Container className="p-3">
      <Row>
        <Col xs={12}>
          <h3> Homes Review</h3>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={4}>
          <h6>Quick Search</h6>
          <AsyncSelect
            isMulti
            isClearable
            value={!lodash.isEmpty(search) ? [search] : []}
            getOptionValue={option => !lodash.isEmpty(option) && option.label}
            onChange={setSelectedOption}
            loadOptions={debounceFunc}
            placeholder={
              <div style={{ width: '100vh' }}>
                Enter City or ZIP Code(s) separated by commas
              </div>
            }
          />
        </Col>

        <Col xs={3}>
          <Filter
            setOption={setSelectFilterOptionFunction}
            options={filterByData}
            title="Filter By"
            value={selectedFilter}
          />
        </Col>
        <Col xs={2}>
          <Filter
            setOption={setSortingOption}
            options={data?.homesReviewSorting}
            title="Sort By"
          />
        </Col>
        <Col xs={3} className="reset-top getbtn-end">
          <div className="reset-get-div">
            {selectOption?.length ? (
              <button
                type="button"
                // TODO: investigate this
                // eslint-disable-next-line react/no-unknown-property
                variant="primary"
                className="allbutton"
                onClick={() => {
                  resetFilters();
                }}
              >
                Reset Filter
              </button>
            ) : null}

            <button
              type="button"
              // TODO: investigate this
              // eslint-disable-next-line react/no-unknown-property
              variant="primary"
              className="allbutton"
              onClick={() => {
                if (clickedRow?.PropertyID === undefined)
                  dispatch(updateUpdateTabNavigationData({}));
                else {
                  dispatch(
                    updateUpdateTabNavigationData({
                      ...clickedRow,
                      SurveyType: 2,
                    })
                  );
                }
                // dispatch(update_UpdateTab_NavigationData({...clickedRow,SurveyType:2}))
                dispatch(updateCurrentSurveyTab('Dataupdate'));
                navigate('/survey');
              }}
            >
              Get More Data
            </button>
          </div>
        </Col>
      </Row>
      {renderFilterOptions()}
      {isEmpty(search) ? (
        <NoRecord />
      ) : (
        <Row>
          <Col xs={6} className="mb-2">
            {(null || undefined) ?? homeReviewList ? (
              <div>
                <NextGenDataTable
                  columns={homeReviewColumns}
                  data={homeReviewList}
                  keyField="ProductID"
                  loading={homeReviewListLoader}
                  hideSizePerPage
                  enableSearching={false}
                  hoverClass="row-hoverclass"
                  onClickRow={onClickRow}
                  striped
                  count={homeReviewCount}
                  fetchData={getHomeReviewData}
                  remote
                  resetPage={resetPage}
                  setResetPage={setResetPage}
                />
              </div>
            ) : (
              'No Record Found'
            )}
          </Col>
          {showDetails && homeReviewDetails?.PropertyID !== undefined ? (
            <Col xs={6} className="mb-2">
              <div className="property-info">
                <Row>
                  <Col xs={12} className="mb-0">
                    <Row>
                      <Col xs={2} className="mb-0">
                        <p
                          className="servey"
                          style={{ fontWeight: 'bold', fontSize: '18px' }}
                        >
                          PropertyID:
                        </p>
                      </Col>
                      <Col xs={10} className="mb-0">
                        <p
                          className="servey"
                          style={{ fontWeight: 'bold', fontSize: '18px' }}
                        >
                          {homeReviewDetails?.PropertyID}{' '}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-0">
                    <Row>
                      <Col xs={2} className="mb-0">
                        <p
                          className="servey"
                          style={{ fontWeight: 'bold', fontSize: '18px' }}
                        >
                          Name:{' '}
                        </p>
                      </Col>
                      <Col xs={10} className="mb-0">
                        <p
                          className="servey"
                          style={{ fontWeight: 'bold', fontSize: '18px' }}
                        >
                          {homeReviewDetails?.ListingTitle}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-0">
                    <Row>
                      <Col xs={2} className="mb-0">
                        <p className="servey">URL: </p>
                      </Col>
                      <Col xs={10} className="mb-0">
                        <p className="servey">
                          <a
                            href={homeReviewDetails?.ListingURL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {' '}
                            {homeReviewDetails?.ListingURL}
                          </a>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div>
                <Tabs
                  defaultActiveKey="Basic"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="Basic" title="Basic">
                    <Row>
                      <Col xs={12} className="mb-0">
                        <Row>
                          <Col xs={4} className="mb-0">
                            <p className="servey">Sleeps: </p>
                          </Col>
                          <Col xs={6} className="mb-0">
                            <p className="servey">
                              {homeReviewDetails?.MaxGuests}{' '}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} className="mb-0">
                        <Row>
                          <Col xs={4} className="mb-0">
                            <p className="servey">Bedrooms: </p>
                          </Col>
                          <Col xs={6} className="mb-0">
                            <p className="servey">
                              {homeReviewDetails?.Bedrooms}{' '}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="mb-0">
                        <Row>
                          <Col xs={4} className="mb-0">
                            <p className="servey">Bathroom: </p>
                          </Col>
                          <Col xs={6} className="mb-0">
                            <p className="servey">
                              {homeReviewDetails?.Bathrooms}{' '}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="mb-0">
                        <Row>
                          <Col xs={4} className="mb-0">
                            <p className="servey">ADR: </p>
                          </Col>
                          <Col xs={6} className="mb-0">
                            <p className="servey">
                              {homeReviewDetails?.ADR
                                ? homeReviewDetails?.ADR
                                : 'n/a'}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} className="mb-0">
                        <Row>
                          <Col xs={4} className="mb-0">
                            <p className="servey"> Occupancy %: </p>
                          </Col>
                          <Col xs={6} className="mb-0">
                            <p className="servey">
                              {homeReviewDetails?.Occupy
                                ? homeReviewDetails?.Occupy
                                : 'n/a'}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="mb-0">
                        <Row>
                          <Col xs={4} className="mb-0">
                            <p className="servey">Neighborhood: </p>
                          </Col>
                          <Col xs={6} className="mb-0">
                            <p className="servey">
                              {homeReviewDetails?.Neighborhood
                                ? homeReviewDetails?.Neighborhood
                                : 'n/a'}{' '}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="locationRow">
                      <button
                        type="button"
                        className="allbutton locationBtn"
                        onClick={() => setShowMap(true)}
                      >
                        Show On Map
                      </button>
                      <Col xs={12} className="mb-0">
                        <Row>
                          <Col xs={4} className="mb-0">
                            <p className="servey">Longitude: </p>
                          </Col>
                          <Col xs={6} className="mb-0">
                            <p className="servey">
                              {homeReviewDetails?.Longitude}{' '}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} className="mb-0">
                        <Row>
                          <Col xs={4} className="mb-0">
                            <p className="servey">Latitude: </p>
                          </Col>
                          <Col xs={6} className="mb-0">
                            <p className="servey">
                              {homeReviewDetails?.Latitude}{' '}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="mb-0">
                        <Row>
                          <Col xs={4} className="mb-0">
                            <p className="servey">Annual Revenue: </p>
                          </Col>
                          <Col xs={6} className="mb-0">
                            <p className="servey">
                              {USDollar.format(
                                parseFloat(homeReviewDetails?.AnnualRevenue)
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="mb-0">
                        <Row>
                          <Col xs={4} className="mb-0">
                            <p className="servey">Description: </p>
                          </Col>
                          <Col xs={8} className="mb-0">
                            <p className="servey">
                              {homeReviewDetails?.ListingTitle}{' '}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="mb-0">
                        <Row>
                          <Col xs={4} className="mb-0">
                            <p className="servey">MSA (Metro Area): </p>
                          </Col>
                          <Col xs={8} className="mb-0">
                            <p className="servey">
                              {homeReviewDetails?.MetropolitanStatisticalArea}{' '}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="Amenities" title="Amenities">
                    <b className="mb-0">Amenities are listed here</b>
                    <div className="list-scroll">
                      <ListGroup as="ol" numbered>
                        {detailsData?.amenities?.length > 0 &&
                          detailsData?.amenities?.map((dt, idx) => (
                            <ListGroup.Item as="li" key={`dt${idx}`}>
                              {(dt
                                ?.trim()
                                ?.replace(/_/g, ' ')
                                ?.charAt(0)
                                ?.toUpperCase() ?? '') +
                                (dt?.trim()?.replace(/_/g, ' ')?.slice(1) ??
                                  '')}
                            </ListGroup.Item>
                          ))}
                      </ListGroup>
                    </div>
                  </Tab>
                  <Tab eventKey="Financial" title="Financial">
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey">Median Home Price:</p>
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">
                          {' '}
                          {homeReviewDetails?.MedianHomePrice &&
                          homeReviewDetails?.MedianHomePrice > 0
                            ? USDollar.format(
                                parseFloat(homeReviewDetails?.MedianHomePrice)
                              )
                            : 'n/a'}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey">Annual Revenue:</p>
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">
                          {' '}
                          {homeReviewDetails?.AnnualRevenue
                            ? USDollar.format(
                                parseFloat(homeReviewDetails?.AnnualRevenue)
                              )
                            : 'n/a'}{' '}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey">ADR:</p>
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">
                          {' '}
                          {homeReviewDetails?.ADR
                            ? homeReviewDetails?.ADR
                            : 'n/a'}{' '}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey">Occupancy %:</p>
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">
                          {' '}
                          {homeReviewDetails?.Occupy
                            ? homeReviewDetails?.Occupy
                            : 'n/a'}{' '}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey"># of Bookings:</p>
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">
                          {homeReviewDetails?.Booking
                            ? homeReviewDetails?.Booking
                            : 'n/a'}{' '}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey">Security Deposit:</p>{' '}
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">
                          {homeReviewDetails?.SecurityDepositNative
                            ? USDollar.format(
                                parseFloat(
                                  homeReviewDetails?.SecurityDepositUSD
                                )
                              )
                            : 'n/a'}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey">Cleaning Fee:</p>{' '}
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">
                          {' '}
                          {homeReviewDetails?.CleaningFeeUSD
                            ? USDollar.format(
                                parseFloat(homeReviewDetails?.CleaningFeeUSD)
                              )
                            : 'n/a'}{' '}
                        </p>
                      </Col>
                    </Row>{' '}
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey">Published Rates:</p>{' '}
                      </Col>

                      <Col xs={2} className="mb-0">
                        <p className="servey">Daily:</p>
                        <p className="servey">
                          {homeReviewDetails?.PublishedNightlyRateUSD ? (
                            <span>
                              {USDollar.format(
                                parseFloat(
                                  homeReviewDetails?.PublishedNightlyRateUSD
                                )
                              )}
                            </span>
                          ) : (
                            'n/a'
                          )}
                        </p>
                      </Col>
                      <Col xs={2} className="mb-0">
                        <p className="servey"> Wkly:</p>
                        <p className="servey">
                          {' '}
                          {homeReviewDetails?.PublishedWeeklyRateUSD ? (
                            <span>
                              {USDollar.format(
                                parseFloat(
                                  homeReviewDetails?.PublishedWeeklyRateUSD
                                )
                              )}
                            </span>
                          ) : (
                            'n/a'
                          )}
                        </p>
                      </Col>
                      <Col xs={2} className="mb-0">
                        <p className="servey">Mthly:</p>
                        <p className="servey">
                          {' '}
                          {homeReviewDetails.PublishedMonthlyRateUSD ? (
                            <span>
                              {USDollar.format(
                                parseFloat(
                                  homeReviewDetails.PublishedMonthlyRateUSD
                                )
                              )}
                            </span>
                          ) : (
                            'n/a'
                          )}
                        </p>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="Service Level" title="Service Level">
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey">Overall Rating:</p>
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">
                          {homeReviewDetails?.OverallRating
                            ? homeReviewDetails?.OverallRating
                            : 'n/a'}{' '}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey">Number of Reviews:</p>
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">
                          {' '}
                          {homeReviewDetails?.NumberofReviews
                            ? homeReviewDetails?.NumberofReviews
                            : 'n/a'}{' '}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey">Number of Photos:</p>
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">
                          {homeReviewDetails?.NumberofPhotos
                            ? homeReviewDetails?.NumberofPhotos
                            : 'n/a'}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey">Response Rate: (%)</p>
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">
                          {homeReviewDetails?.ResponseRate
                            ? `${homeReviewDetails?.ResponseRate}%`
                            : 'n/a'}{' '}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey">Superhost:</p>
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">
                          {homeReviewDetails?.AirbnbSuperhost !== undefined
                            ? `${
                                homeReviewDetails?.AirbnbSuperhost === 1
                                  ? 'yes'
                                  : 'no'
                              }`
                            : 'n/a'}{' '}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey">Cancellation Policy:</p>
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">
                          {homeReviewDetails?.CancellationPolicyText !==
                          undefined
                            ? `${homeReviewDetails?.CancellationPolicyText}`
                            : 'n/a'}{' '}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey">Pets Allowed:</p>
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">
                          {homeReviewDetails?.PetsAllowed !== undefined
                            ? `${
                                homeReviewDetails?.PetsAllowed === 1
                                  ? 'yes'
                                  : 'no'
                              }`
                            : 'n/a'}{' '}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey">Cleaning Fee:</p>{' '}
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">
                          {' '}
                          {homeReviewDetails?.CleaningFeeUSD
                            ? USDollar.format(
                                parseFloat(homeReviewDetails?.CleaningFeeUSD)
                              )
                            : 'n/a'}{' '}
                        </p>
                      </Col>
                    </Row>{' '}
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey">Security Deposit:</p>{' '}
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">
                          {' '}
                          {homeReviewDetails?.SecurityDepositUSD
                            ? USDollar.format(
                                parseFloat(
                                  homeReviewDetails?.SecurityDepositUSD
                                )
                              )
                            : 'n/a'}
                        </p>
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">Extra People Fee:</p>
                      </Col>
                      <Col xs={6} className="mb-0">
                        <p className="servey">
                          {homeReviewDetails?.ExtraPeopleFeeUSD
                            ? USDollar.format(
                                parseFloat(homeReviewDetails?.ExtraPeopleFeeUSD)
                              )
                            : 'n/a'}
                        </p>
                      </Col>
                    </Row>{' '}
                    <Row>
                      <Col xs={6} className="mb-0">
                        <p className="servey">Other Ratings:</p>{' '}
                      </Col>
                      <Col xs={2} className="mb-0">
                        <p className="servey">Daily:</p>
                        <p className="servey">n/a</p>
                      </Col>
                      <Col xs={2} className="mb-0">
                        <p className="servey"> Wkly:</p>
                        <p className="servey">n/a</p>
                      </Col>
                      <Col xs={2} className="mb-0">
                        <p className="servey">Mthly:</p>
                        <p className="servey">n/a</p>
                      </Col>
                    </Row>
                  </Tab>{' '}
                  <Tab eventKey="Images" title="Images">
                    <Row>
                      <Col xs={12} className="mb-0">
                        <div className="gallery">
                          <div className="wrapper">
                            {detailsData?.propertyImage?.map((ig, id) => (
                              <div className={`box${id + 1}`}>
                                <div
                                  className="gallery__images-item one"
                                  onClick={() => {
                                    setSelectedImage(ig);
                                    handleShow();
                                  }}
                                >
                                  <img
                                    src={ig}
                                    alt="Logo"
                                    className="gallery__images-small imgone"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          ) : (
            <Col xs={6} className="mb-0">
              {' '}
              <h6>Click row to view property details </h6>
            </Col>
          )}
        </Row>
      )}
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="image-model-body">
            <img className="image-model-img" src={selectedImage} alt="" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showMap} onHide={() => setShowMap(false)} centered size="lg">
        <Modal.Header closeButton>
          {homeReviewDetails?.ListingTitle}
        </Modal.Header>
        <Modal.Body>
          <div className="image-model-body">
            <iframe
              title="map"
              style={{
                position: 'relative',
                zIndex: 2,
                border: 0,
                height: '100%',
                width: '100%',
              }}
              src={`https://maps.google.com/maps?q=${homeReviewDetails?.Latitude},${homeReviewDetails?.Longitude}&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&maptype=satellite&output=embed`}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowMap(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default HomesReview;
