/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { PropTypes } from 'prop-types';
import MenuData from './HamburgerMenu.json';

const HamburgerMenu = ({ setShowMenu }) => {
  const handleClickOutside = () => {
    setShowMenu(false);
  };
  const handleClick = () => {
    setShowMenu(false);
  };

  const useOutsideClick = callback => {
    const ref = React.useRef();
    React.useEffect(() => {
      const handleEvent = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener('click', handleEvent, true);
      return () => {
        document.removeEventListener('click', handleEvent, true);
      };
    }, [ref]);
    return ref;
  };
  const ref = useOutsideClick(handleClickOutside);
  // const handleHeaderClick = event => {
  //   // do something
  //   event.stopPropagation();
  // };

  return (
    <div
      className="ham-drop-container"
      ref={ref}
      type="button"
      onClick={handleClick}
    >
      {MenuData.map(item => {
        return (
          <div className="d-flex justify-content-start align-items-center cursor-pointer mb-3">
            <img
              className="pr-2"
              // eslint-disable-next-line import/no-dynamic-require, global-require
              src={require(`../../assets/images/${item.icon_name}.svg`)}
              alt="Logo"
            />
            <span>{item.name} </span>
          </div>
        );
      })}
    </div>
  );
};

HamburgerMenu.propTypes = {
  setShowMenu: PropTypes.func.isRequired,
};

export default HamburgerMenu;
