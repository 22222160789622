/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  zipListHomeReview,
  cityListHomeReview,
  getHomeReviewList,
  getHomeReviewDetails,
  getKeywordsList,
  getKeywordsListCities,
  getKeywordsListZips,
} from './api';

const initialState = {
  quickSearch: {},
  homeReviewList: [],
  homeReviewCount: 0,
  homeReviewDetails: {},
  homeReviewListLoader: false,
  keywordsList: [],
  homeReviewCityList: [],
  homeReviewZipCodeList: [],
  keywordsListCities: [],
  keywordsListZips: [],
};

export const homeReviewSlice = createSlice({
  name: 'homeReview',
  initialState,
  reducers: {
    setQuickSearchValue: (state, action) => {
      state.quickSearch = action.payload;
    },
  },
  extraReducers(builders) {
    builders.addCase(getHomeReviewList.fulfilled, (state, action) => {
      state.homeReviewList = action?.payload?.data;
      state.homeReviewListLoader = false;
      state.homeReviewCount = action?.payload?.count;
    });
    builders.addCase(getHomeReviewList.pending, state => {
      state.homeReviewListLoader = true;
      state.homeReviewList = [];
      state.homeReviewCount = 0;
    });

    builders.addCase(getHomeReviewList.rejected, state => {
      state.homeReviewListLoader = true;
      state.homeReviewList = [];
      state.homeReviewCount = 0;
    });
    builders.addCase(getHomeReviewDetails.fulfilled, (state, action) => {
      state.homeReviewDetails = action?.payload;
    });
    builders.addCase(getKeywordsList.fulfilled, (state, action) => {
      const kw = action?.payload?.map(d => ({
        label: d.Kword,
        value: d.Kword,
      }));
      state.keywordsList = kw;
    });
    builders.addCase(getKeywordsList.pending, () => {
      // const kw = [];
    });
    builders.addCase(getKeywordsList.rejected, state => {
      // const kw = action?.payload?.map(d => ({
      //   label: d.Kword,
      //   value: d.Kword,
      // }));
      state.keywordsList = [];
    });

    builders.addCase(getKeywordsListCities.fulfilled, (state, action) => {
      const kw = action?.payload?.map(d => ({
        label: d.Kword,
        value: d.Kword,
      }));
      state.keywordsListCities = kw;
    });

    builders.addCase(getKeywordsListZips.fulfilled, (state, action) => {
      const kw = action?.payload?.map(d => ({
        label: d.Kword,
        value: d.Kword,
      }));
      state.keywordsListZips = kw;
    });

    builders.addCase(zipListHomeReview.fulfilled, (state, action) => {
      state.homeReviewZipCodeList = action?.payload;
    });
    builders.addCase(cityListHomeReview.fulfilled, (state, action) => {
      state.homeReviewCityList = action?.payload;
    });
  },
});

export const { setQuickSearchValue } = homeReviewSlice.actions;

export default homeReviewSlice.reducer;
