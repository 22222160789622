import React from 'react';
import lodash from 'lodash';
import SegmentFilter from '../component/SegmentFilter';
import SingleSelect from '../component/SingleSelect';
import InputField from '../component/InputField';
import MultiSelect from '../component/MultiSelectField';

const { cloneDeep, isEmpty } = lodash;

export const checkedApiCall = filters => {
  const filterList = { ...filters };
  delete filterList?.RelativeOverperformance;
  delete filterList.RelativeUnderperformance;
  return Object.values(filterList).every(value => value !== '');
};

export const setSelectFilterOption = (
  value,
  filterDataOption,
  selectOption,
  setFilterOption,
  setSelectedFilter,
  selectedFilterOption,
  setSelectedFilterOption,
  filterByData,
  setFilterByData
) => {
  const tempData = cloneDeep(selectOption);
  tempData.push(value);
  setFilterOption(tempData);
  setSelectedFilter(value);
  const tempSelectedFilterOption = cloneDeep(selectedFilterOption);
  if (
    value === 'MeetsInvestmentCriteria_Baseline' ||
    value === 'MeetsInvestmentCriteria_Optimized'
  ) {
    tempSelectedFilterOption[value] =
      filterDataOption?.booleanOption[0].optionValue;
  } else if (value === 'PercentileRank') {
    tempSelectedFilterOption[value] =
      filterDataOption?.homesOption[0].optionValue;
  } else if (value === 'City' || value === 'ZIPCode') {
    tempSelectedFilterOption[value] = [];
  } else if (value === 'Segment') {
    tempSelectedFilterOption.Sleeps = 'Any';
    tempSelectedFilterOption.BedsAndBath = 'Any';
  } else {
    tempSelectedFilterOption[value] = '';
  }

  setSelectedFilterOption(tempSelectedFilterOption);
  const tempFilterByData = cloneDeep(filterByData);
  tempFilterByData.splice(
    filterByData.findIndex(res => res.optionValue === value),
    1
  );

  setFilterByData(tempFilterByData);

  setSelectedFilterOption(tempSelectedFilterOption);
};

export const removeFilter = (
  id,
  filterByDataOption,
  selectOption,
  selectedFilterOption,
  setFilterOption,
  setSelectedFilter,
  setSelectedFilterOption,
  setFilterByData,
  filterByData
) => {
  const tempData = cloneDeep(selectOption);
  const spliceItem = tempData.splice(id, 1);
  const tempSelectedFilterOption = cloneDeep(selectedFilterOption);
  if (spliceItem[0] === 'Segment') {
    delete tempSelectedFilterOption.Sleeps;
    delete tempSelectedFilterOption.BedsAndBath;
  }
  delete tempSelectedFilterOption[spliceItem[0]];
  setFilterOption(tempData);
  if (tempData.length === 0) {
    setSelectedFilter('');
  }
  setSelectedFilterOption(tempSelectedFilterOption);
  const tempFilterByData = cloneDeep(filterByData); //
  const s = filterByDataOption.find(res =>
    spliceItem?.includes(res?.optionValue)
  );
  if (s) {
    const existingFilterIndex = tempFilterByData.findIndex(
      filterOption => filterOption.optionValue === s.optionValue
    );
    if (existingFilterIndex === -1)
      tempFilterByData.push({
        optionName: s.optionName,
        optionValue: s.optionValue,
      });
    setFilterByData(tempFilterByData);
    setSelectedFilter('');
  }
};

export const renderFiltersInputType = (
  filtersOption,
  i,
  data,
  filterDataOption,
  inputOnChangeFunction,
  removeFilterFlag,
  stateData,
  cityData,
  zipCodeData,
  selectedFilterOption,
  keywordsList,
  InputFieldType = '',
  InputFieldDataKeyName = ''
) => {
  if (!selectedFilterOption) return '';
  // const key = filtersOption;
  const defaultValue = { key: selectedFilterOption[`${filtersOption}`] };

  const filteredData = filterDataOption.find(
    res => res.optionValue === filtersOption
  );
  const type = filteredData ? filteredData.optionValue : '';
  // const inputType = filteredData ? filteredData.optionType : '';
  switch (type) {
    case 'MeetsInvestmentCriteria_Baseline':
      return (
        <SingleSelect
          setOption={inputOnChangeFunction}
          options={data.booleanOption}
          title={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          defaultValue={defaultValue}
          name={type}
        />
      );
    case 'MeetsInvestmentCriteria_Optimized':
      return (
        <SingleSelect
          setOption={inputOnChangeFunction}
          options={data.booleanOption}
          title={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          defaultValue={defaultValue}
          name={type}
        />
      );
    case 'StateAbbr':
      return (
        <MultiSelect
          optionHeading={filteredData.optionName}
          option={stateData}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          name={type}
          type="StateAbbr"
          setOption={inputOnChangeFunction}
          defaultValue={
            selectedFilterOption[`${filtersOption}`] === ''
              ? null
              : selectedFilterOption[`${filtersOption}`]
          }
        />
      );
    case 'City':
      return (
        <MultiSelect
          optionHeading={filteredData.optionName}
          option={cityData}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          name={type}
          setOption={inputOnChangeFunction}
          type="City"
          defaultValue={
            selectedFilterOption[`${filtersOption}`] === ''
              ? null
              : selectedFilterOption[`${filtersOption}`]
          }
        />
      );
    case 'ZIPCode':
      return (
        <MultiSelect
          optionHeading={filteredData.optionName}
          option={zipCodeData}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          name={type}
          setOption={inputOnChangeFunction}
          type="ZIPCode"
          defaultValue={
            selectedFilterOption[`${filtersOption}`] === ''
              ? null
              : selectedFilterOption[`${filtersOption}`]
          }
        />
      );
    case 'PercentileRank':
      return (
        <SingleSelect
          setOption={inputOnChangeFunction}
          options={data.homesOption}
          title={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          defaultValue={defaultValue}
          name={type}
        />
      );
    case 'BaselineMax_Purchase_Price':
      return (
        <InputField
          optionHeading={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          onChange={inputOnChangeFunction}
          name={type}
          inputType="number"
          defaultValue={selectedFilterOption[`${filtersOption}`]}
        />
      );
    case 'BaselineMedianADR':
      return (
        <InputField
          optionHeading={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          onChange={inputOnChangeFunction}
          name={type}
          inputType="number"
          defaultValue={selectedFilterOption[`${filtersOption}`]}
        />
      );
    case 'OptimizedProjectedRevenue':
      return (
        <InputField
          optionHeading={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          onChange={inputOnChangeFunction}
          name={type}
          inputType="number"
          defaultValue={selectedFilterOption[`${filtersOption}`]}
        />
      );
    case 'BaselineMedianAnnualRevenue':
    case 'AnnualRevenue':
      return (
        <InputField
          optionHeading={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          onChange={inputOnChangeFunction}
          name={type}
          inputType="number"
          defaultValue={selectedFilterOption[`${filtersOption}`]}
        />
      );
    case 'OptimizedMax_Purchase_Price':
      return (
        <InputField
          optionHeading={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          onChange={inputOnChangeFunction}
          name={type}
          inputType="number"
          defaultValue={selectedFilterOption[`${filtersOption}`]}
        />
      );
    case 'OptimizedROI':
      return (
        <InputField
          optionHeading={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          onChange={inputOnChangeFunction}
          name={type}
          inputType="number"
          defaultValue={selectedFilterOption[`${filtersOption}`]}
        />
      );
    case 'BaselineROI':
      return (
        <InputField
          optionHeading={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          onChange={inputOnChangeFunction}
          name={type}
          inputType="number"
          defaultValue={selectedFilterOption[`${filtersOption}`]}
        />
      );
    case 'BaselineOccupancyPercent':
      return InputFieldType === 'dropdown-select' &&
        InputFieldDataKeyName !== '' ? (
        <SingleSelect
          setOption={inputOnChangeFunction}
          options={data[InputFieldDataKeyName]}
          title={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          defaultValue={defaultValue}
          name={type}
        />
      ) : (
        <InputField
          optionHeading={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          onChange={inputOnChangeFunction}
          name={type}
          inputType="number"
          defaultValue={selectedFilterOption[`${filtersOption}`]}
        />
      );
    case 'OptimizedOccupancyPercent':
      return (
        <InputField
          optionHeading={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          onChange={inputOnChangeFunction}
          name={type}
          inputType="number"
          defaultValue={selectedFilterOption[`${filtersOption}`]}
        />
      );
    case 'OptimizedADR':
      return (
        <InputField
          optionHeading={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          onChange={inputOnChangeFunction}
          name={type}
          inputType="number"
          defaultValue={selectedFilterOption[`${filtersOption}`]}
        />
      );
    case 'BaselineADR':
      return (
        <InputField
          optionHeading={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          onChange={inputOnChangeFunction}
          name={type}
          inputType="number"
          defaultValue={selectedFilterOption[`${filtersOption}`]}
        />
      );
    case 'Revenue_Optimized':
      return (
        <InputField
          optionHeading={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          onChange={inputOnChangeFunction}
          name={type}
          inputType="number"
          defaultValue={selectedFilterOption[`${filtersOption}`]}
        />
      );
    case 'Revenue_Baseline':
      return (
        <InputField
          optionHeading={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          onChange={inputOnChangeFunction}
          name={type}
          inputType="number"
          defaultValue={selectedFilterOption[`${filtersOption}`]}
        />
      );
    case 'Segment':
      return (
        <SegmentFilter
          title={filteredData.optionName}
          options={data?.sleepsOption}
          setOption={inputOnChangeFunction}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          defaultValue={selectedFilterOption}
        />
      );
    case 'Superhost':
      return (
        <SingleSelect
          setOption={inputOnChangeFunction}
          options={data.superhostOption}
          title={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          defaultValue={defaultValue}
          // value={}
          name={type}
        />
      );
    case 'PetsAllowed':
      return (
        <SingleSelect
          setOption={inputOnChangeFunction}
          options={data.petsOption}
          title={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          defaultValue={defaultValue}
          // value={}
          name={type}
        />
      );
    case 'CancellationPolicyText':
      return (
        <SingleSelect
          setOption={inputOnChangeFunction}
          options={data.cancellationPolicy}
          title={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          defaultValue={defaultValue}
          // value={}
          name={type}
        />
      );
    case 'Keywords':
      return (
        <MultiSelect
          optionHeading={filteredData.optionName}
          option={keywordsList}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          name={type}
          setOption={inputOnChangeFunction}
          defaultValue={
            selectedFilterOption[`${filtersOption}`] === ''
              ? null
              : selectedFilterOption[`${filtersOption}`]
          }
          listSize={500}
        />
      );
    case 'HomeReviewMinimumADR':
      return (
        <InputField
          optionHeading={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          onChange={inputOnChangeFunction}
          name={type}
          inputType="number"
          defaultValue={selectedFilterOption[`${filtersOption}`]}
        />
      );
    case 'OverallRating':
      return (
        <SingleSelect
          setOption={inputOnChangeFunction}
          options={data.reviews}
          title={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          defaultValue={defaultValue}
          // value={}
          name={type}
        />
      );
    case 'RelativeUnderperformance':
      return (
        <SingleSelect
          setOption={inputOnChangeFunction}
          options={data.relativeUnderperformance}
          title={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          defaultValue={defaultValue}
          // value={}
          name={type}
        />
      );
    case 'RelativePerformance':
      return (
        <SingleSelect
          setOption={inputOnChangeFunction}
          options={data.relativePerformance}
          title={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          defaultValue={defaultValue}
          // value={}
          name={type}
        />
      );
    case 'NumberofPhotos':
      return (
        <SingleSelect
          setOption={inputOnChangeFunction}
          options={data.photos}
          title={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          defaultValue={defaultValue}
          // value={}
          name={type}
        />
      );
    case 'HomesCountInZIP':
      return (
        <InputField
          optionHeading={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          onChange={inputOnChangeFunction}
          name={type}
          inputType="number"
          defaultValue={selectedFilterOption[`${filtersOption}`]}
        />
      );
    case 'HomesCountInCity':
      return (
        <InputField
          optionHeading={filteredData.optionName}
          toBeDeleted={i}
          removeFilter={removeFilterFlag}
          onChange={inputOnChangeFunction}
          name={type}
          inputType="number"
          defaultValue={selectedFilterOption[`${filtersOption}`]}
        />
      );

    default:
      return null;
  }
};

export const checkForAppliedFilters = (selectOption, selectedFilterOption) => {
  if (isEmpty(selectOption) && isEmpty(selectedFilterOption)) return true;
  // eslint-disable-next-line no-restricted-syntax
  for (const option of selectOption) {
    if (
      selectedFilterOption[option] &&
      selectedFilterOption[option].length > 0
    ) {
      return true;
    }
  }
  return false;
};
