/* eslint-disable react/prop-types */
/* eslint-disable radix */
/* eslint-disable react/no-array-index-key */
import lodash from 'lodash';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Filter from '../../component/FilterDropdown';
import NextGenDataTable from '../../component/NextGenBsTable';
import {
  downloadPdf,
  getSurveyDataCities,
  getSurveyDataZips,
} from '../../redux/survey/api';
import {
  headerForDataDetailsByCity,
  headerForDataDetailsByZip,
  headerForServiceLevelByCity,
  headerForServiceLevelByZip,
  headerForbaseLineFinancialDetailsByCity,
  headerForHousingDetailsByCity,
  headerForHousingDetailsByZip,
  headerForoptimizedFinancialDetailsByCity,
  headerForbaseLineFinancialDetailsByZip,
  headerForoptimizedFinancialDetailsByZip,
} from '../../utils/columns';
import data from '../../utils/data.json';
import {
  removeFilter,
  renderFiltersInputType,
  setSelectFilterOption,
  checkForAppliedFilters,
} from '../../utils/helperInput';
import DataUpdateModal from '../../component/ModelPop/DataUpdateModal';
import { notify } from '../../component/Toaster';
import {
  updateSurveyToDrillDownData,
  updateCurrentSurveyTab,
  updateUpdateTabNavigationData,
  updateSurveyTabNavigationData,
} from '../../redux/navigation/navigationSlice';
import { getItem, setItem } from '../../component/services/localStorage';
import useFilters from '../../hooks/useFilters';
import PdfModal from '../../component/ModelPop/PdfDownloadingModal';

const TabSurvey = ({ currentSurveyTab, setActiveTab }) => {
  const {
    selectOption,
    selectedFilterOption,
    setFilterOptions,
    setSelectedFilterOptions,
  } = useFilters({ moduleName: 'survey' });

  // fetching all local storage variables
  const surveyTypeFromLocalStorage = getItem('SurveyTab-surveyType');
  const toppingFromLocalStorage = getItem('SurveyTab-selectedTopping');

  const { cloneDeep } = lodash;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState('Baseline');
  const [seletedFilter, setSelectedFilter] = useState('');
  const [isLoad, setIsLoaD] = useState(false);
  const rankingData = useSelector(state => state.ranking);
  const stateData = useSelector(state => state?.ranking?.states);
  const surveyTabNavigationData = useSelector(
    state => state?.navigation?.surveyTab_NavigationData
  );
  const [filterByData, setFilterByData] = useState(data?.surveyFilterBy);
  const [selectedSortingOption, setSelectedSortingOption] = useState({});

  const cityData = useSelector(state => state?.ranking?.cityListSurvey);
  const zipCodeData = useSelector(state => state?.ranking?.zipListSurvey);
  const [showPdfModal, setShowPdfModel] = useState(false);
  const isPdfDownloading = useSelector(state => state?.pdf?.isPdfDownloading);
  const [topping, setTopping] = useState(toppingFromLocalStorage || 'City');
  const [surveyTypeData, setSurveyTypeData] = useState(
    parseInt(surveyTypeFromLocalStorage) === 1 ? 'MySurvey' : 'AllSurvey'
  );
  const [radioState, setRadioState] = useState(
    toppingFromLocalStorage
      ? {
          city: toppingFromLocalStorage === 'City',
          zip: toppingFromLocalStorage === 'Zip',
        }
      : {
          city: true,
          zip: false,
        }
  );
  const [surveyType, setSurveyType] = useState(
    parseInt(surveyTypeFromLocalStorage) || 0
  );
  const [detailsType, setDetailsType] = useState(1);
  const onClickRankingRow = (e, row) => {
    dispatch(updateSurveyToDrillDownData(row));
    navigate('/drilldown');
  };
  const [tableHeader, setTableHeader] = useState(
    headerForHousingDetailsByCity(onClickRankingRow)
  );
  const [baseLineColumn, setBaseLineColumn] = useState(
    headerForbaseLineFinancialDetailsByCity(onClickRankingRow)
  );
  const [optimisedColumn, setOptimisedColumn] = useState(
    headerForoptimizedFinancialDetailsByCity(onClickRankingRow)
  );
  const [show, handleClose] = useState(false);
  const [surveyForUpdate] = useState({});
  const [resetPage, setResetPage] = useState(1);
  const user = useSelector(state => state.user);

  const setFilterOption = value => {
    if (currentSurveyTab.toLowerCase() === 'survey') {
      dispatch(setFilterOptions(value));
    }
  };

  const setSelectedFilterOption = option => {
    if (currentSurveyTab.toLowerCase() === 'survey') {
      dispatch(setSelectedFilterOptions(option));
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaD(false);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [isLoad]);

  useEffect(() => {
    setResetPage(1);
  }, [currentSurveyTab]);

  const onSelectDropDown = async (e, row) => {
    if (e === 'print') {
      notify('Download pdf is in process');
      const pdfData = {
        rowID: row.id,
        SurveyType: row?.SurveyType,
        ZIPCode: row?.ZIPCode,
        City: row?.City,
        StateAbbr: row?.StateAbbr,
        MarketName: row?.MarketName,
      };
      dispatch(downloadPdf(pdfData));
    } else {
      dispatch(updateUpdateTabNavigationData(row));
      dispatch(updateCurrentSurveyTab('Dataupdate'));
    }
  };

  const setSelectFilterOptionFunction = value => {
    if (value === 'City') {
      setTopping('City');
      setRadioState({ city: true, zip: false });
    }
    if (value === 'ZIPCode') {
      setTopping('Zip');
      setRadioState({ city: false, zip: true });
    }
    setSelectFilterOption(
      value,
      data,
      selectOption,
      setFilterOption,
      setSelectedFilter,
      selectedFilterOption,
      setSelectedFilterOption,
      filterByData,
      setFilterByData
    );
  };

  const removeFilterFunction = id => {
    if (
      (selectOption[id] === 'ZIPCode' || selectOption[id] === 'City') &&
      !lodash.isEmpty(surveyTabNavigationData)
    ) {
      dispatch(updateSurveyTabNavigationData({}));
    }
    removeFilter(
      id,
      data.surveyFilterBy,
      selectOption,
      selectedFilterOption,
      setFilterOption,
      setSelectedFilter,
      setSelectedFilterOption,
      setFilterByData,
      filterByData
    );
  };

  const resetFilters = () => {
    setFilterByData(data?.surveyFilterBy);
    setFilterOption([]);
    setSelectedFilter('');
    setSelectedSortingOption({});
    setSelectedFilterOption({});
    dispatch(updateSurveyTabNavigationData({}));
  };

  useEffect(() => {
    setItem('SurveyTab-surveyType', surveyType);
    setItem('SurveyTab-selectedTopping', topping);
  }, [surveyType, topping]);

  useEffect(() => {
    if (selectOption.length > 0) {
      const filteredData = filterByData.filter(
        filter => filter.optionValue !== selectOption[selectOption.length - 1]
      );
      setFilterByData(filteredData);
    } else {
      setSelectedFilter('');
      setFilterByData(data?.surveyFilterBy);
    }
  }, [selectOption]);

  useEffect(() => {
    if (surveyTabNavigationData?.SurveyType === 1) {
      setTopping('City');
      setFilterOption(['City']);
      setRadioState({ city: true, zip: false });
      setSelectedFilterOption({
        City: [
          {
            value: surveyTabNavigationData?.City,
            label: surveyTabNavigationData?.City,
            DataFrom: 'City',
          },
        ],
      });
    } else if (surveyTabNavigationData?.SurveyType === 2) {
      setTopping('Zip');
      setRadioState({ city: false, zip: true });
      setFilterOption(['ZIPCode']);
      setSelectedFilterOption({
        ZIPCode: [
          {
            value: surveyTabNavigationData?.ZIPCode,
            label: surveyTabNavigationData?.ZIPCode,
            DataFrom: 'ZIPCode',
          },
        ],
      });
    }
  }, [surveyTabNavigationData]);

  const getSurvey = (start = 0, limit = 10, columnSort = {}) => {
    const sorts = { ...selectedSortingOption, ...columnSort };
    if (selectOption[selectOption.length - 1]?.includes('Optimized'))
      setActiveKey('Optimized');
    else if (selectOption[selectOption.length - 1]?.includes('Baseline'))
      setActiveKey('Baseline');
    if (!checkForAppliedFilters(selectOption, selectedFilterOption));
    if (radioState.city) {
      dispatch(
        getSurveyDataCities({
          filters: selectedFilterOption,
          sortings: sorts,
          surveyType,
          start,
          limit,
        })
      );
    }
    if (radioState.zip) {
      dispatch(
        getSurveyDataZips({
          filters: selectedFilterOption,
          sortings: sorts,
          surveyType,
          start,
          limit,
        })
      );
    }
  };

  useEffect(() => {
    if (currentSurveyTab.toLowerCase() === 'survey') {
      getSurvey();
    }
  }, [
    selectedFilterOption,
    selectedSortingOption,
    radioState,
    surveyType,
    currentSurveyTab,
  ]);

  const setSortingOption = option => {
    const tempOption = {};
    if (option !== 'default') {
      if (option === 'monthly_revenue') {
        tempOption.Revenue_Baseline = true;
      } else {
        tempOption[option] = true;
      }
    }

    setSelectedSortingOption(tempOption);
    setResetPage(1);
  };

  const inputOnChangeFunction = (type, value) => {
    const tempSelectedFilterOption = cloneDeep(selectedFilterOption);
    tempSelectedFilterOption[type] = value;
    setSelectedFilterOption(tempSelectedFilterOption);
  };

  const renderFilterOptions = () => {
    return (
      <Row className="mb-2">
        {selectOption?.map((res, i) => {
          return (
            <Col xs={3} key={`filters-${i}`}>
              {renderFiltersInputType(
                res,
                i,
                data,
                data?.surveyFilterBy,
                inputOnChangeFunction,
                removeFilterFunction,
                stateData,
                cityData,
                zipCodeData,
                selectedFilterOption
              )}
            </Col>
          );
        })}
      </Row>
    );
  };

  const setSurveyDetails = type => {
    setDetailsType(parseInt(type, 10));
  };

  useEffect(() => {
    setResetPage(1);
    let column = '';
    if (detailsType === 1) {
      column = radioState.city
        ? headerForHousingDetailsByCity(onClickRankingRow)
        : headerForHousingDetailsByZip(onClickRankingRow);
    } else if (detailsType === 2) {
      if (radioState.city) {
        setBaseLineColumn(
          headerForbaseLineFinancialDetailsByCity(onClickRankingRow)
        );
        setOptimisedColumn(
          headerForoptimizedFinancialDetailsByCity(onClickRankingRow)
        );
      } else {
        setBaseLineColumn(
          headerForbaseLineFinancialDetailsByZip(onClickRankingRow)
        );
        setOptimisedColumn(
          headerForoptimizedFinancialDetailsByZip(onClickRankingRow)
        );
      }
    } else if (detailsType === 3) {
      column = radioState.city
        ? headerForServiceLevelByCity(onClickRankingRow)
        : headerForServiceLevelByZip(onClickRankingRow);
    } else {
      column = radioState.city
        ? headerForDataDetailsByCity(
            onSelectDropDown,
            onClickRankingRow,
            user?.userInfo?.userRoles?.surveys_readonly
          )
        : headerForDataDetailsByZip(
            onSelectDropDown,
            onClickRankingRow,
            user?.userInfo?.userRoles?.surveys_readonly
          );
    }
    if (column) {
      setTableHeader(column);
    }
  }, [detailsType, radioState]);

  useEffect(() => {
    if (currentSurveyTab.toLowerCase() === 'survey')
      setShowPdfModel(isPdfDownloading);
  }, [isPdfDownloading]);

  const clearFilterOnRadioChange = () => {
    if (selectOption.length > 0 && !lodash.isEmpty(surveyTabNavigationData)) {
      setFilterOption([]);
      setSelectedFilterOption({});
      dispatch(updateSurveyTabNavigationData({}));
    }
  };

  const onOptionChange = e => {
    clearFilterOnRadioChange();
    setResetPage(1);
    setTopping(e.target.value);
    if (e.target.value === 'City') {
      setRadioState({ city: true, zip: false });
    }

    if (e.target.value === 'Zip') {
      setRadioState({ city: false, zip: true });
    }
  };

  const surveyTypeDataChange = e => {
    clearFilterOnRadioChange();
    setResetPage(1);
    setSurveyTypeData(e.target.value);
    if (e.target.value === 'AllSurvey') {
      setSurveyType(0);
    } else {
      setSurveyType(1);
    }
  };

  const handleClosePdfModel = () => {
    setShowPdfModel(false);
  };

  const handleClickGetMoreData = () => {
    dispatch(updateCurrentSurveyTab('Dataupdate'));
    setActiveTab('Dataupdate');
  };

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isLoad}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" size="3rem" />
      </Backdrop>
      <Row>
        {show && (
          <DataUpdateModal
            show={show}
            handleClose={handleClose}
            surveyDataForUpdate={surveyForUpdate}
          />
        )}
        {showPdfModal && (
          <PdfModal show={showPdfModal} onClose={handleClosePdfModel} />
        )}
        <Col xs={4} className="flex-style-survey">
          <div className="margin-bottom-for-map-radio">
            <b>Show:</b>
          </div>
          <div className="flex-style-survey">
            <label className="cursor-pointer" htmlFor="AllSurvey">
              <input
                className="cursor-pointer"
                type="radio"
                name="AllSurvey"
                value="AllSurvey"
                id="AllSurvey"
                checked={surveyTypeData === 'AllSurvey'}
                onChange={surveyTypeDataChange}
              />
              All Surveys
            </label>

            <label className="cursor-pointer" htmlFor="MySurvey">
              <input
                className="cursor-pointer"
                type="radio"
                name="AllSurvey"
                value="MySurvey"
                id="MySurvey"
                checked={surveyTypeData === 'MySurvey'}
                onChange={surveyTypeDataChange}
              />
              My Surveys
            </label>
          </div>
        </Col>
        <Col xs={4} className="flex-style-survey">
          <div className="margin-bottom-for-map-radio">
            <b>Geography:</b>
          </div>
          <div className="flex-style-survey">
            <label className="cursor-pointer" htmlFor="City">
              <input
                className="cursor-pointer"
                type="radio"
                name="topping"
                value="City"
                id="City"
                checked={topping === 'City'}
                onChange={onOptionChange}
              />
              Cities
            </label>

            <label className="cursor-pointer" htmlFor="Zip">
              <input
                className="cursor-pointer"
                type="radio"
                name="topping"
                value="Zip"
                id="Zip"
                checked={topping === 'Zip'}
                onChange={onOptionChange}
              />
              ZIP Codes
            </label>
          </div>
        </Col>
        <Col xs={4}>
          <div className="survey-getmore-data">
            <button
              type="button"
              // TODO: investigate this
              // eslint-disable-next-line react/no-unknown-property
              variant="primary"
              className="allbutton"
              onClick={handleClickGetMoreData}
            >
              Get More Data
            </button>
          </div>
        </Col>
      </Row>

      <Row className="mb-2">
        <div className="width-230">
          <Filter
            setOption={setSurveyDetails}
            options={data?.suveyDetailsOptions}
            title="Details"
            value={detailsType}
          />
        </div>
        <Col xs={selectOption.length ? 3 : 4}>
          <Filter
            setOption={setSelectFilterOptionFunction}
            options={filterByData}
            title="Filter By"
            value={seletedFilter}
          />
        </Col>
        <Col xs={selectOption.length ? 3 : 4}>
          <Filter
            setOption={setSortingOption}
            options={data?.dataUpdateSortOptionsByZip}
            title="Sort By"
          />
        </Col>

        <Col xs={3} className="mt-4">
          <Row>
            {selectOption?.length ? (
              <Col>
                <button
                  type="button"
                  // eslint-disable-next-line react/no-unknown-property
                  variant="primary"
                  className="allbutton"
                  onClick={() => {
                    resetFilters();
                  }}
                >
                  Reset Filter
                </button>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      {renderFilterOptions()}

      <Row>
        {detailsType === 2 ? (
          <div className="mt-3 financial-table">
            <Tabs
              defaultActiveKey={activeKey}
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={key => setActiveKey(key)}
              activeKey={activeKey}
            >
              <Tab eventKey="Baseline" title="Baseline">
                <div className="base-opt-table-div">
                  <NextGenDataTable
                    columns={baseLineColumn}
                    data={
                      radioState.city
                        ? rankingData?.surveyDataCities
                        : rankingData.surveyDataZips
                    }
                    keyField="id"
                    loading={
                      radioState.city
                        ? rankingData?.surveyDataCitiesLoader
                        : rankingData?.surveyDataZipsLoader
                    }
                    hideSizePerPage
                    enableSearching={false}
                    striped
                    remote
                    resetPage={resetPage}
                    fetchData={getSurvey}
                    setResetPage={setResetPage}
                    count={rankingData.surveyDataCitiesCount}
                  />
                </div>
              </Tab>
              <Tab eventKey="Optimized" title="Optimized">
                <div className="base-opt-table-div">
                  <NextGenDataTable
                    columns={optimisedColumn}
                    data={
                      radioState.city
                        ? rankingData?.surveyDataCities
                        : rankingData.surveyDataZips
                    }
                    keyField="id"
                    loading={
                      radioState.city
                        ? rankingData?.surveyDataCitiesLoader
                        : rankingData?.surveyDataZipsLoader
                    }
                    hideSizePerPage
                    enableSearching={false}
                    striped
                    remote
                    fetchData={getSurvey}
                    resetPage={resetPage}
                    setResetPage={setResetPage}
                    count={rankingData.surveyDataCitiesCount}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        ) : (
          <div className="base-opt-table-div">
            <NextGenDataTable
              columns={tableHeader}
              data={
                radioState.city
                  ? rankingData?.surveyDataCities
                  : rankingData.surveyDataZips
              }
              keyField="id"
              loading={
                radioState.city
                  ? rankingData?.surveyDataCitiesLoader
                  : rankingData?.surveyDataZipsLoader
              }
              hideSizePerPage
              enableSearching={false}
              striped
              remote
              resetPage={resetPage}
              fetchData={getSurvey}
              setResetPage={setResetPage}
              count={
                radioState.city
                  ? rankingData.surveyDataCitiesCount
                  : rankingData.surveyDataZipsCount
              }
            />
          </div>
        )}
      </Row>
    </div>
  );
};

TabSurvey.propTypes = {
  currentSurveyTab: PropTypes.string.isRequired,
};

export default TabSurvey;
