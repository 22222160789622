/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import TabGeography from './Tabcharacteristicsdrilldown/TabGeography';
import TabHomeSize from './Tabcharacteristicsdrilldown/TabHomeSize';
import TabPrevalence from './Tabcharacteristicsdrilldown/TabPrevalence';
import TabProfitability from './Tabcharacteristicsdrilldown/TabProfitability';
import TabSeasonal from './Tabcharacteristicsdrilldown/TabSeasonal';
import {
  updateCurrentCharacteristicsDrilldownTab,
} from '../redux/navigation/navigationSlice';

const CharacteristicsDrilldown = () => {
  const dispatch = useDispatch();

  const currentCharacteristicsDrilldownTab = useSelector(
    state => state?.navigation.currentCharacteristicsDrilldownTab
  );

  const [activeKey, setActiveKey] = useState(
    currentCharacteristicsDrilldownTab || 'Prevalence'
  );
  const [selectedPrevalenceRow, setSelectedPrevalenceRow] = useState({});

  useEffect(() => {
    setActiveKey(currentCharacteristicsDrilldownTab);
  }, [currentCharacteristicsDrilldownTab]);

  return (
    <Container className="p-3">
      <Row>
        <Col xs={12}>
          <h3> Property Characteristics Drilldown</h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Tabs
            activeKey={activeKey}
            defaultActiveKey={activeKey}
            id="uncontrolled-tab-example-2"
            className="mb-3"
            onSelect={e => {
              setActiveKey(e);
              dispatch(updateCurrentCharacteristicsDrilldownTab(e));
            }}
          >
            <Tab eventKey="Prevalence" title="Prevalence">
              <TabPrevalence
                setActiveKey={setActiveKey}
                setSelectedPrevalenceRow={setSelectedPrevalenceRow}
                selectedTab={currentCharacteristicsDrilldownTab}
              />
            </Tab>
            <Tab eventKey="Home Size" title="Home Size">
              <TabHomeSize
                selectedPrevalenceRow={selectedPrevalenceRow}
                selectedTab={currentCharacteristicsDrilldownTab}
              />
            </Tab>

            <Tab eventKey="Profitability" title="Profitability">
              <TabProfitability selectedTab={currentCharacteristicsDrilldownTab} />
            </Tab>
            <Tab eventKey="Geography" title="Geography">
              <TabGeography selectedTab={currentCharacteristicsDrilldownTab} />
            </Tab>
            <Tab eventKey="Seasonal" title="Seasonal">
              <TabSeasonal selectedTab={currentCharacteristicsDrilldownTab}/>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default CharacteristicsDrilldown;
